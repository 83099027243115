import { useContext, useEffect, useState } from "react";
import D3Can from "@components/shared/D3Can";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { Button, Form, Space, Table, Tag, Transfer } from "antd";
import { useCustomerGroupsStore } from "../store";
import { CustomerType, UpdateGroupType, ViewCustomerGroupType } from "../types";

import SpinLoading from "@components/shared/SpinLoading";

const columns = [
  {
    dataIndex: "id",
    title: "Código",
  },
  {
    dataIndex: "fantasy_name",
    title: "Name Fantasia",
  },
  {
    dataIndex: "name",
    title: "Razão Social",
  },
  {
    dataIndex: "active",
    title: "Status",
    render: (active: boolean) => (
      <Tag color={active ? "success" : "error"}>{active ? "Desbloqueado" : "Bloqueado"}</Tag>
    ),
  },
];

type TransferCustomerProps = {
  cuid: string;
};

function TransferCustomerForm({ cuid }: TransferCustomerProps) {
  const {
    viewCustomerGroup,
    view,
    loadingView,
    simpleListSystemCustomer,
    loadingCustomers,
    listCustomers,
    updateGroup,
    loadingUpdateGroup,
  } = useCustomerGroupsStore();

  const [form] = Form.useForm<UpdateGroupType>();
  const { onClose } = useContext(D3DrawerContext);
  const [input, setInput] = useState<string[]>([]);
  const [output, setOutput] = useState<string[]>([]);

  const [targetKeys, setTargetKeys] = useState<string[]>([]);

  useEffect(() => {
    (async () => {
      await viewCustomerGroup(cuid);
      await simpleListSystemCustomer();
    })();
    return () => {
      useCustomerGroupsStore.setState({
        view: {} as ViewCustomerGroupType,
      });
    };
  }, [cuid, viewCustomerGroup, simpleListSystemCustomer]);

  const [listCustomersGroup, setListCustomersGroup] = useState<CustomerType[]>([]);

  useEffect(() => {
    if (view?.customers?.length > 0) {
      const customersGroup = view.customers.map(({ active, cuid, fantasy_name, id, key, name }) => {
        return {
          active,
          cuid,
          fantasy_name,
          name,
          id,
          key: cuid,
        };
      });
      setListCustomersGroup(customersGroup);
      const keys = view.customers.map((customer) => customer.cuid);
      setTargetKeys(keys);
    }
  }, [view]);

  const [dataSource, setDataSource] = useState<CustomerType[]>([]);

  useEffect(() => {
    if (listCustomersGroup.length > 0) {
      setDataSource([...listCustomers, ...listCustomersGroup]);
    } else {
      setDataSource([...listCustomers]);
    }
  }, [listCustomersGroup, listCustomers]);

  const onChange = (newTargetKeys: any[]) => {
    setTargetKeys(newTargetKeys);
    setInput(newTargetKeys.filter((key) => !targetKeys.includes(key)));
    setOutput(targetKeys.filter((key) => !newTargetKeys.includes(key)));
  };

  return loadingView || loadingCustomers ? (
    <SpinLoading />
  ) : (
    <Form
      form={form}
      layout="vertical"
      onFinish={async (payload: UpdateGroupType) => {
        payload.cuids_to_add = input;
        payload.cuids_to_remove = output;
        const isUpdated = await updateGroup(cuid, payload);
        if (isUpdated) {
          onClose();
        }
      }}
      autoComplete="off"
      disabled={loadingUpdateGroup}
    >
      <Form.Item>
        <Transfer
          showSearch
          dataSource={dataSource}
          targetKeys={targetKeys}
          onChange={onChange}
          showSelectAll={false}
          titles={[
            <div className="flex items-center justify-center">
              <Tag className="flex items-center justify-start w-32 h-6 gap-1 px-2 select-none" color="processing">
                Clientes da Empresa
              </Tag>
            </div>,
            <div className="flex items-center justify-center">
              <Tag className="flex items-center justify-start w-32 h-6 gap-1 px-2" color="processing">
                Clientes do Grupo
              </Tag>
            </div>,
          ]}
          operations={["Adicionar", "Remover"]}
          filterOption={(inputValue, option) =>
            option.id.toString().toLowerCase().indexOf(inputValue.toLowerCase()) !== -1 ||
            option.name.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1 ||
            option.fantasy_name.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
          }
        >
          {({ direction, filteredItems, disabled, onItemSelectAll, onItemSelect, selectedKeys }) => {
            return (
              <div>
                <Table
                  rowKey="key"
                  rowSelection={{
                    type: "checkbox",
                    selectedRowKeys: selectedKeys,
                    onSelect: (record, selected) => {
                      onItemSelect(record.key, selected);
                    },
                    onSelectAll: (selected, _, changeRows) => {
                      const changedKeys = changeRows.map((r) => r.key);
                      onItemSelectAll(changedKeys, selected);
                    },
                  }}
                  columns={columns}
                  dataSource={filteredItems}
                  size="small"
                />
              </div>
            );
          }}
        </Transfer>
      </Form.Item>
      <Form.Item>
        <div className="text-right">
          <Space size="small">
            <Button type="default" danger onClick={() => onClose()}>
              Cancelar
            </Button>
            <D3Can action="create" resource="customer_group">
              <Button loading={loadingUpdateGroup} type="primary" htmlType="submit">
                Atualizar
              </Button>
            </D3Can>
          </Space>
        </div>
      </Form.Item>
    </Form>
  );
}

export default TransferCustomerForm;
