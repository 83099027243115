import { Form, Select } from "antd";

function SearchSortByForm() {
  return (
    <div className="flex flex-col w-full h-full p-2 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100 !important">
      <Form.Item label="Ordenar por" name={["config", "sort", "by"]} className="p-0 my-2 w-60">
        <Select
          options={[
            {
              label: "Data de criação do documento",
              value: "f_id",
            },
            {
              label: "Descrição do documento",
              value: "name",
            },
          ]}
        />
      </Form.Item>
      <Form.Item label="Direção" name={["config", "sort", "direction"]} className="p-0 my-2 w-60">
        <Select
          options={[
            {
              label: "Ascendente",
              value: "asc",
            },
            {
              label: "Descendente",
              value: "desc",
            },
          ]}
        />
      </Form.Item>
    </div>
  );
}

export default SearchSortByForm;
