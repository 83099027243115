import { useContext, useEffect, useState } from "react";
import { Button, Form, Radio, Select, Space, Spin, Tooltip } from "antd";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { Icon } from "@iconify/react";
import { useNotificationStore } from "../store";
import { ConverteUsersType, UserCustomerNotificationType } from "../types";
import { channelsNotification, systemOrderTypes } from "./OptionsForSelect";

export const CreateUsersForm = () => {
  const { simpleListUsersCustomer, usersCustomer, insertUsersNotification, notifications } = useNotificationStore();
  const { onClose } = useContext(D3DrawerContext);
  const [form] = Form.useForm<ConverteUsersType>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      await simpleListUsersCustomer();
      setLoading(false);
    })();
  }, [simpleListUsersCustomer]);

  return (
    <>
      <Spin spinning={loading}>
        <Form
          className="select-none"
          layout="vertical"
          form={form}
          onFinish={({ users, ...rest }: ConverteUsersType) => {
            const notificationsUsers: UserCustomerNotificationType[] = users
              .map((userCuid: string) => {
                const userList = usersCustomer.find((user) => user.cuid === userCuid);
                if (userList) {
                  const { active, cuid, name } = userList;
                  return {
                    user: {
                      active,
                      cuid,
                      name,
                    },
                    ...rest,
                  };
                }
                return undefined;
              })
              .filter((notification): notification is UserCustomerNotificationType => notification !== undefined);
            insertUsersNotification(notificationsUsers);
            onClose();
          }}
          autoComplete="off"
        >
          <Form.Item
            label="Usuários"
            name="users"
            rules={[{ required: true, message: "Por favor, selecione pelo menos um usuário." }]}
          >
            <Select
              listItemHeight={10}
              listHeight={250}
              allowClear
              removeIcon={
                <Icon
                  icon="iconamoon:close-duotone"
                  className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
                />
              }
              menuItemSelectedIcon={<Icon icon="eva:checkmark-outline" className="w-5 h-5 text-blue-500" />}
              optionLabelProp="dataLabel"
              showSearch
              maxTagCount="responsive"
              mode="multiple"
              size="middle"
              className="truncate select-none min-w-72"
              optionFilterProp="children"
              filterOption={(input, option) => option?.dataFilter.toLowerCase().includes(input.toLowerCase())}
            >
              {usersCustomer.map(({ active, cuid, name, username }) => (
                <Select.Option
                  key={cuid}
                  value={cuid}
                  dataFilter={`${username} ${name}`}
                  dataLabel={`${username}`}
                  disabled={notifications?.user_customer_notifications
                    .map((notification) => notification.user.cuid)
                    .includes(cuid)}
                >
                  <div className="flex items-center justify-between ">
                    <div className="flex flex-col truncate">
                      <span className="truncate">{username}</span>
                      <span className="text-[10px] truncate">{name}</span>
                    </div>
                    <span>
                      {active ? (
                        <Tooltip title="Usuário desbloqueado" placement="right">
                          <Icon icon="basil:unlock-solid" className="text-green-500" height={16} width={16} />
                        </Tooltip>
                      ) : (
                        <Tooltip title="Usuário bloqueado" placement="right">
                          <Icon icon="basil:lock-solid" className="text-red-500" height={16} width={16} />
                        </Tooltip>
                      )}
                    </span>
                  </div>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Canais de notificações"
            name="notification_channel_types"
            rules={[{ required: true, message: "Selecione pelo menos um canal de notificação." }]}
          >
            <Select
              mode="multiple"
              maxTagCount="responsive"
              className="min-w-48"
              allowClear
              showSearch={false}
              options={channelsNotification}
            />
          </Form.Item>
          <Form.Item
            initialValue={false}
            label="Notificar quando documento estiver expirado"
            name="notify_on_expiring_documents"
          >
            <Radio.Group
              options={[
                { label: "Sim", value: true },
                { label: "Não", value: false },
              ]}
              optionType="button"
              buttonStyle="solid"
            />
          </Form.Item>
          <Form.Item label="Notificar ao criar ordem" name="notify_on_order_creation">
            <Select
              mode="multiple"
              maxTagCount="responsive"
              className="min-w-64"
              allowClear
              showSearch={false}
              options={systemOrderTypes}
            />
          </Form.Item>
          <Form.Item label="Notificar na alteração de status de ordem" name="notify_on_order_status_change">
            <Select
              mode="multiple"
              maxTagCount="responsive"
              className="w-64"
              allowClear
              showSearch={false}
              options={systemOrderTypes}
            />
          </Form.Item>
          <Form.Item>
            <div className="text-right">
              <Space size="small">
                <Button type="default" danger onClick={() => onClose()}>
                  Cancelar
                </Button>

                <Button type="primary" htmlType="submit">
                  Cadastrar
                </Button>
              </Space>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};
