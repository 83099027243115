import D3QSGenerator, { FilterDefinition } from "@utils/D3QSGenerator";
import { BadRequest, ClientHttp, StandardError, Success } from "@utils/clientHttp";
import showMessage from "@utils/showMessage";
import { create } from "zustand";
import {
  CancelSpecialOrderType,
  CreateSpecialOrderType,
  ItemSimpleListCustomerType,
  ItemSimpleListDepartmentType,
  ListSpecialOrderType,
  PrintResendType,
  ResendType,
  SearchSpecialOrderType,
  ServiceItemType,
  SimpleListCustomerType,
  SimpleListDepartmentType,
  SimpleListServicesCustomerType,
  SimpleListUserDepartmentType,
  UrlType,
  UserDepartmentItemType,
  ViewSpecialOrderType,
} from "./types";

type SpecialOrderStoreState = {
  apiResult: ListSpecialOrderType;
  filter: SearchSpecialOrderType;
  pagination: { page: number; pageSize: number };
  loading: boolean;
  setFilter: (filter: SearchSpecialOrderType) => void;
  setPagination: (page: number, pageSize: number) => void;
  loadSpecialOrders: () => Promise<void>;
  getSpecialOrder: (cuid: string) => Promise<Success<ViewSpecialOrderType> | StandardError>;
  getUrl: (orderCuid: string) => Promise<Success<UrlType> | StandardError>;
  createSpecialOrder: (specialOrder: CreateSpecialOrderType) => Promise<boolean>;
  savedSpecialOrder: (orderCuid: string) => Promise<void>;
  cancelSpecialOrder: (orderCuid: string, cancelReason: string) => Promise<void>;
  simpleListDepartmentCustomer: () => Promise<ItemSimpleListDepartmentType[]>;
  simpleListServicesCustomer: () => Promise<ServiceItemType[]>;
  simpleListUsersByDepartmentCuid: (departmentCuid: string) => Promise<UserDepartmentItemType[]>;

  simpleListServices: () => Promise<void>;
  loadingServices: boolean;
  listServices: ServiceItemType[];

  urlResendOrder: string;
  resendSpecialOrderByCuid: (orderCuid: string) => Promise<void>;

  printResendOrderSpecialByCuid: (orderCuid: string) => Promise<void>;
  urlPrintResendOrder: string;

  resetFilter: () => void;
  resetState: () => void;

  simpleListCustomer: () => Promise<void>;
  loadingCustomer: boolean;
  listCustomer: ItemSimpleListCustomerType[];

  simpleListDepartmentByCustomerCuid: (customerCuid: string) => Promise<void>;
  loadingDepartments: boolean;
  listDepartments: ItemSimpleListDepartmentType[];
};

const operationsFilter: FilterDefinition = {
  c_cuid: { op: "eq", type: "string" },
  dep_cuid: { op: "eq", type: "string" },
  eo_id: { op: "eq", type: "string" },
  eo_status: { op: "eq", type: "string" },
  s_cuid: { op: "eq", type: "string" },
  uc_name: { op: "lk", type: "string" },
  ur_name: { op: "lk", type: "string" },
  eo_has_order_resent: { op: "eq", type: "active" },
};

const defaultState = {
  apiResult: {
    total: 0,
    data: [],
  },
  filter: {
    c_cuid: "",
    dep_cuid: "",
    eo_id: "",
    eo_status: "WTG",
    s_cuid: "",
    uc_name: "",
    ur_name: "",
    eo_has_order_resent: "all",
  } as SearchSpecialOrderType,
  pagination: {
    page: 1,
    pageSize: 10,
  },
  loading: false,
  urlResendOrder: "",
  urlPrintResendOrder: "",

  loadingCustomer: false,
  listCustomer: [],

  loadingDepartments: false,
  listDepartments: [],

  loadingServices: false,
  listServices: [],
};

export const useSpecialOrdersStore = create<SpecialOrderStoreState>((set, get) => ({
  ...defaultState,
  resetState: () => set(defaultState),

  setFilter: (filter: SearchSpecialOrderType) => {
    set({
      filter,
      pagination: { ...get().pagination, page: 1 },
    });
    get().loadSpecialOrders();
  },
  resetFilter: () => {
    set({
      filter: { ...defaultState.filter, eo_status: "" },
    });
    get().loadSpecialOrders();
  },

  setPagination: (page: number, pageSize: number) => {
    if (pageSize !== get().pagination.pageSize) {
      page = 1;
    }
    set({ pagination: { page, pageSize } });
    get().loadSpecialOrders();
  },

  loadSpecialOrders: async (): Promise<void> => {
    set({ loading: true });
    let qs = D3QSGenerator(get().filter, operationsFilter, get().pagination);

    await new ClientHttp().get<Success<ListSpecialOrderType>, BadRequest | StandardError>(
      `/api/v1/orders/specials?${qs}`,
      (result: Success<ListSpecialOrderType>) => {
        set({ apiResult: result.body, loading: false });
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
        set({ loading: false });
      }
    );
  },

  getSpecialOrder: async (cuid: string): Promise<Success<ViewSpecialOrderType> | StandardError> => {
    return await new ClientHttp().get<Success<ViewSpecialOrderType>, StandardError>(`/api/v1/orders/specials/${cuid}`);
  },

  createSpecialOrder: async (specialOrder: CreateSpecialOrderType): Promise<boolean> => {
    const result = await new ClientHttp().post<CreateSpecialOrderType, Success<void>, BadRequest | StandardError>(
      "/api/v1/orders/specials",
      specialOrder,
      (result: Success<void>) => {
        showMessage(result, "Ordem especial cadastrada com sucesso.");
        get().loadSpecialOrders();
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
    return result.status === "success";
  },

  savedSpecialOrder: async (orderCuid: string): Promise<void> => {
    await new ClientHttp().patch<
      null,
      Success<{
        data: string;
        message: string;
        status: string;
      }>,
      BadRequest | StandardError
    >(
      `/api/v1/orders/specials/${orderCuid}/serve`,
      null,
      (
        result: Success<{
          data: string;
          message: string;
          status: string;
        }>
      ) => {
        if (result.status === "success") {
          showMessage(result, "Ordem baixada com sucesso.");
          get().loadSpecialOrders();
        } else {
          showMessage(result, "Algo deu errado na baixa da ordem.");
        }
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
  },

  cancelSpecialOrder: async (orderCuid: string, cancelReason: string): Promise<void> => {
    await new ClientHttp().patch<
      CancelSpecialOrderType,
      Success<{
        data: string;
        message: string;
        status: string;
      }>,
      BadRequest | StandardError
    >(
      `/api/v1/orders/specials/${orderCuid}/cancel`,
      { cancel_reason: cancelReason },
      (
        result: Success<{
          data: string;
          message: string;
          status: string;
        }>
      ) => {
        if (result.status === "success") {
          showMessage(result, "Ordem cancelada com sucesso.");
          get().loadSpecialOrders();
        } else {
          showMessage(result, "Algo deu errado no cancelamento da ordem.");
        }
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
  },

  getUrl: async (orderCuid: string): Promise<Success<UrlType> | StandardError> => {
    return await new ClientHttp().get<Success<UrlType>, StandardError>(
      `/api/v1/orders/specials/${orderCuid}/print`,
      (result: Success<UrlType>): UrlType => {
        return result.body;
      },
      (error: StandardError): void => {
        showMessage(error);
      }
    );
  },
  simpleListDepartmentCustomer: async (): Promise<ItemSimpleListDepartmentType[]> => {
    return await new ClientHttp().getItensForSelect<
      Success<SimpleListDepartmentType>,
      StandardError,
      ItemSimpleListDepartmentType[]
    >(
      "/api/v1/customer/departments/simple-list",
      (result: Success<SimpleListDepartmentType>): ItemSimpleListDepartmentType[] => {
        return result.body.data as ItemSimpleListDepartmentType[];
      },
      (error: StandardError): ItemSimpleListDepartmentType[] => {
        showMessage(error, "Erro ao carregar lista de departamentos.");
        return [] as ItemSimpleListDepartmentType[];
      }
    );
  },

  simpleListServicesCustomer: async (): Promise<ServiceItemType[]> => {
    return await new ClientHttp().getItensForSelect<
      Success<SimpleListServicesCustomerType>,
      StandardError,
      ServiceItemType[]
    >(
      "/api/v1/customer/services/simple-list?special=true",
      (result: Success<SimpleListServicesCustomerType>): ServiceItemType[] => {
        return result.body.data as ServiceItemType[];
      },
      (error: StandardError): ServiceItemType[] => {
        showMessage(error);
        return [] as ServiceItemType[];
      }
    );
  },
  simpleListUsersByDepartmentCuid: async (departmentCuid: string): Promise<UserDepartmentItemType[]> => {
    return await new ClientHttp().getItensForSelect<
      Success<SimpleListUserDepartmentType>,
      StandardError,
      UserDepartmentItemType[]
    >(
      `/api/v1/customer/departments/${departmentCuid}/users/simple-list`,
      (result: Success<SimpleListUserDepartmentType>): UserDepartmentItemType[] => {
        return result.body.data as UserDepartmentItemType[];
      },
      (error: StandardError): UserDepartmentItemType[] => {
        showMessage(error);
        return [] as UserDepartmentItemType[];
      }
    );
  },
  simpleListServices: async (): Promise<void> => {
    set({ loadingServices: true });
    await new ClientHttp().getItensForSelect<Success<SimpleListServicesCustomerType>, StandardError, void>(
      `/api/v1/orders/specials/services/simple-list`,
      (result: Success<SimpleListServicesCustomerType>): void => {
        set({ listServices: result.body.data });
      },
      (error: StandardError): ServiceItemType[] => {
        showMessage(error);
        return [] as ServiceItemType[];
      }
    );
    set({ loadingServices: false });
  },
  resendSpecialOrderByCuid: async (orderCuid: string): Promise<void> => {
    await new ClientHttp().post<undefined, Success<ResendType>, BadRequest | StandardError>(
      `/api/v1/orders/specials/${orderCuid}/resend`,
      undefined,
      (result: Success<ResendType>): void => {
        set({
          urlResendOrder: result.body.url,
        });
      },
      (error: BadRequest | StandardError): void => {
        showMessage(error);
      }
    );
  },
  printResendOrderSpecialByCuid: async (orderCuid: string): Promise<void> => {
    await new ClientHttp().get<Success<PrintResendType>, StandardError>(
      `/api/v1/orders/specials/${orderCuid}/print-resend`,
      (result: Success<PrintResendType>): void => {
        set({
          urlPrintResendOrder: result.body.url,
        });
      },
      (error: BadRequest | StandardError): void => {
        showMessage(error);
      }
    );
  },

  simpleListCustomer: async (): Promise<void> => {
    set({ loadingCustomer: true });
    await new ClientHttp().getItensForSelect<Success<SimpleListCustomerType>, StandardError, void>(
      "/api/v1/system/customers/simple-list",
      (result: Success<SimpleListCustomerType>): void => {
        set({ listCustomer: result.body.data });
      },
      (error: StandardError): void => {
        showMessage(error);
      }
    );
    set({ loadingCustomer: false });
  },
  simpleListDepartmentByCustomerCuid: async (customerCuid: string): Promise<void> => {
    set({ loadingDepartments: true });
    await new ClientHttp().getItensForSelect<Success<SimpleListDepartmentType>, StandardError, void>(
      `/api/v1/system/customers/${customerCuid}/departments/simple-list`,
      (result: Success<SimpleListDepartmentType>): void => {
        set({ listDepartments: result.body.data });
      },
      (error: StandardError): void => {
        showMessage(error);
      }
    );
    set({ loadingDepartments: false });
  },
}));
