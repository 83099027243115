import { Button, Form, Input, Space } from "antd";
import { useCustomerGroupsStore } from "../store";
import { useContext } from "react";
import { CreateCustomerGroupType } from "../types";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import D3Can from "@components/shared/D3Can";

export const CreateCustomerGroupForm = () => {
  const { create, loadingCreate } = useCustomerGroupsStore();
  const { onClose } = useContext(D3DrawerContext);
  const [form] = Form.useForm<CreateCustomerGroupType>();

  return (
    <>
      <Form
        className="select-none"
        form={form}
        layout="vertical"
        onFinish={async (payload: CreateCustomerGroupType) => {
          const isCreated = await create(payload);
          if (isCreated) {
            onClose();
          }
        }}
        autoComplete="off"
      >
        <Form.Item<CreateCustomerGroupType>
          name="description"
          label="Descrição"
          rules={[{ required: true, message: "Por favor, insira descrição." }]}
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <div className="text-right">
            <Space size="small">
              <Button type="default" danger onClick={() => onClose()}>
                Cancelar
              </Button>
              <D3Can action="create" resource="customer_group">
                <Button loading={loadingCreate} type="primary" htmlType="submit">
                  Cadastrar
                </Button>
              </D3Can>
            </Space>
          </div>
        </Form.Item>
      </Form>
    </>
  );
};
