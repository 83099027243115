import FormItemConditionalDate from "@components/shared/FormItemConditionalDate";
import { Form, FormInstance, Input, Select, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { SearchType } from "./types";
import { Icon } from "@iconify/react";
import FormItemSwitcher from "./FormItemSwitcher";
import useSearchStore from "./store";

type SearchRepositoryFormFormProps = {
  form: FormInstance<SearchType>;
};

const options = [
  { label: "Igual a", value: "equal" },
  { label: "Maior que", value: "greater" },
  { label: "Menor que", value: "less" },
  { label: "No intervalo", value: "range" },
];

function SearchRepositoryForm({ form }: SearchRepositoryFormFormProps) {
  const { simpleListRepositoryType, repositoryTypes } = useSearchStore();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      await simpleListRepositoryType();
      setLoading(false);
    })();
  }, [form, simpleListRepositoryType]);

  return (
    <div className="flex flex-col w-full h-full p-2 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100 !important">
      <FormItemSwitcher
        attribute={["filter_repository", "repository_physical_tag_id"]}
        label="Etiquetas de repositório"
        form={form}
      />
      <Form.Item label="Intervalo de repositórios" className="p-0 my-2">
        <div className="flex items-center gap-3">
          <Form.Item
            className="w-full p-0 m-0"
            name={["filter_repository", "repository_range", "start_repository_physical_tag_id"]}
          >
            <Input showCount maxLength={12} />
          </Form.Item>
          até
          <Form.Item
            className="w-full p-0 m-0"
            name={["filter_repository", "repository_range", "end_repository_physical_tag_id"]}
          >
            <Input showCount maxLength={12} />
          </Form.Item>
        </div>
      </Form.Item>

      <Form.Item
        className="p-0 my-2"
        label="Tipo de Repositório"
        name={["filter_repository", "repository_types_cuids"]}
      >
        <Select
          loading={loading}
          disabled={loading}
          listItemHeight={10}
          allowClear
          removeIcon={
            <Icon
              icon="iconamoon:close-duotone"
              className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
            />
          }
          menuItemSelectedIcon={<Icon icon="eva:checkmark-outline" className="w-5 h-5 text-blue-500" />}
          optionLabelProp="dataLabel"
          showSearch
          maxTagCount="responsive"
          size="middle"
          mode="multiple"
          className="w-full truncate select-none"
          optionFilterProp="children"
          filterOption={(input, option) => option?.dataFilter.toLowerCase().includes(input.toLowerCase())}
        >
          {repositoryTypes.map(({ active, cuid, description }) => (
            <Select.Option key={cuid} disabled={!active} value={cuid} dataFilter={description} dataLabel={description}>
              <div className="flex items-center justify-between ">
                <div className="flex flex-col truncate">
                  <span className="truncate">{description}</span>
                </div>
                <span>
                  {active ? (
                    <Tooltip trigger="hover" title="Departamento desbloqueado" placement="right">
                      <Icon icon="basil:unlock-solid" className="text-green-500" height={16} width={16} />
                    </Tooltip>
                  ) : (
                    <Tooltip trigger="hover" title="Departamento bloqueado" placement="right">
                      <Icon icon="basil:lock-solid" className="text-red-500" height={16} width={16} />
                    </Tooltip>
                  )}
                </span>
              </div>
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <FormItemConditionalDate<SearchType>
        attribute={["filter_repository", "created_at"]}
        label="Data cadastramento"
        options={options}
        form={form}
      />

      <Form.Item label="Posição" className="p-0 my-2" name={["filter_repository", "position"]}>
        <Input />
      </Form.Item>

      <Form.Item label="Origem" className="p-0 my-2" name={["filter_repository", "origin"]}>
        <Input />
      </Form.Item>
    </div>
  );
}

export default SearchRepositoryForm;
