import { useContext, useState } from "react";
import { Button, Form, Input, Space, Spin } from "antd";
import { useOperationsStore } from "../store";
import { CreateOperationType } from "../types";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import showMessage from "@utils/showMessage";
import D3Can from "@components/shared/D3Can";
import FormItemInputMoney from "@components/shared/FormItemInputMoney";

export const CreateOperationForm = () => {
  const { createOperation } = useOperationsStore();
  const { onClose } = useContext(D3DrawerContext);
  const [form] = Form.useForm<CreateOperationType>();

  const [loading, setLoading] = useState(false);

  return (
    <>
      <Spin spinning={loading}>
        <Form
          className="select-none"
          form={form}
          layout="vertical"
          onFinish={async (payload: CreateOperationType) => {
            setLoading(true);
            const result = await createOperation(payload);
            if (result.status === "success") {
              showMessage(result, "Operação cadastrada com sucesso.");
              onClose();
            } else {
              showMessage(result);
            }
            setLoading(false);
          }}
          autoComplete="off"
        >
          <Form.Item<CreateOperationType>
            name="description"
            label="Descrição"
            rules={[{ required: true, message: "Por favor, insira descrição." }]}
          >
            <Input />
          </Form.Item>

          <FormItemInputMoney form={form} name="price" label="Preço" numberType="positive" />

          <Form.Item>
            <div className="text-right">
              <Space size="small">
                <Button type="default" danger onClick={() => onClose()}>
                  Cancelar
                </Button>
                <D3Can action="create" resource="system_operation">
                  <Button type="primary" htmlType="submit">
                    Cadastrar
                  </Button>
                </D3Can>
              </Space>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};
