import { Button, Form, Input, Space, Spin } from "antd";
import { useCustomerGroupsStore } from "../store";
import { useContext, useEffect } from "react";
import { UpdateCustomerGroupType, ViewCustomerGroupType } from "../types";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import D3Can from "@components/shared/D3Can";

type UpdateCustomerGroupProps = {
  cuid: string;
};

export const UpdateCustomerGroupForm = ({ cuid }: UpdateCustomerGroupProps) => {
  const { update, loadingUpdate, viewCustomerGroup, view, loadingView } = useCustomerGroupsStore();
  const { onClose } = useContext(D3DrawerContext);
  const [form] = Form.useForm<UpdateCustomerGroupType>();

  useEffect(() => {
    (async () => {
      await viewCustomerGroup(cuid);
    })();
    return () => {
      useCustomerGroupsStore.setState({ view: {} as ViewCustomerGroupType });
    };
  }, [cuid, viewCustomerGroup]);

  useEffect(() => {
    form.setFieldsValue(view);
  }, [view, form]);

  return (
    <>
      <Spin spinning={loadingView}>
        <Form
          className="select-none"
          layout="vertical"
          form={form}
          onFinish={async (payload: UpdateCustomerGroupType) => {
            const isUpdated = await update(cuid, payload);
            if (isUpdated) {
              onClose();
            }
          }}
          autoComplete="off"
          disabled={loadingUpdate}
        >
          <Form.Item<UpdateCustomerGroupType>
            name="description"
            label="Descrição"
            rules={[{ required: true, message: "Por favor, insira descrição." }]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <div className="text-right">
              <Space size="small">
                <Button type="default" danger onClick={() => onClose()}>
                  Cancelar
                </Button>
                <D3Can action="update" resource="customer_group">
                  <Button loading={loadingUpdate} type="primary" htmlType="submit">
                    Atualizar
                  </Button>
                </D3Can>
              </Space>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};
