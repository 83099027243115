import { Form, InputNumber } from "antd";
import { IndexItemType } from "../physical/types";

type ItemNMIPropsType = {
  item: IndexItemType;
};

function ItemNMI({ item }: ItemNMIPropsType) {
  const { id, label,  end_interval_required, start_interval_required } = item;
  return (
    <>
      <Form.Item label={label} className="p-0 m-0">
        <div className="flex gap-2 ">
          <Form.Item
            name={[id, "NMI", "start_value"]}
            rules={[
              {
                required: start_interval_required,
                message: `Campo obrigatório.`,
              },
            ]}
          >
            <InputNumber placeholder="Número inicial" name="start_value" className="w-full" />
          </Form.Item>
          <div className="flex items-center h-full p-1">ao</div>
          <Form.Item
            name={[id, "NMI", "end_value"]}
            rules={[
              {
                required: end_interval_required,
                message: `Campo obrigatório`,
              },
            ]}
          >
            <InputNumber placeholder="Número final" name="end_value" className="w-full" />
          </Form.Item>
        </div>
      </Form.Item>
    </>
  );
}

export default ItemNMI;
