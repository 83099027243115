export default function D3DateHourSorter(date?: Date | string): string {
  if (date) {
    return new Date(date.toString())
      .toLocaleString("pt-BR", {
        dateStyle: "short",
        timeStyle: "short",
      })
      .replace(",", " às ");
  } else {
    return "";
  }
}
