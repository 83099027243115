import { Button, DatePicker, Form, Input, Radio, Space } from "antd";
import { useForm } from "antd/es/form/Form";
import { CreateContractType } from "../types";
import { useContext, useState } from "react";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { useContractStore } from "../store";
import dayjs from "dayjs";

function CreateContractForm() {
  const [form] = useForm<CreateContractType>();
  const { createContract, loadingCreateContract } = useContractStore();
  const { onClose } = useContext(D3DrawerContext);
  const [date, setDate] = useState<string>("");

  return (
    <Form
      form={form}
      layout="vertical"
      className="p-4 select-none"
      onFinish={async (values: CreateContractType) => {
        const isSuccess = await createContract(values);
        if (isSuccess) {
          onClose();
        }
      }}
      onValuesChange={(changedValues: CreateContractType, values: CreateContractType) => {
        if (changedValues.date) {
          setDate(changedValues.date);
        }
      }}
    >
      <Form.Item<CreateContractType>
        name="description"
        label="Descrição"
        rules={[
          {
            required: true,
            message: "Por favor, preencha a descrição.",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <div className="flex gap-2">
        <Form.Item<CreateContractType>
          className="w-48"
          name="date"
          label="Data"
          rules={[
            {
              required: true,
              message: "Por favor, selecione a data.",
            },
          ]}
          getValueProps={(value) => value}
          normalize={(value) => (value ? dayjs(value).format("YYYY-MM-DD") : "")}
        >
          <DatePicker format="DD/MM/YYYY" />
        </Form.Item>

        <Form.Item<CreateContractType>
          name="expiration_date"
          label="Data de vencimento"
          rules={[
            {
              required: true,
              message: "Por favor, selecione a data de vencimento.",
            },
          ]}
          getValueProps={(value) => value}
          normalize={(value) => (value ? dayjs(value).format("YYYY-MM-DD") : "")}
        >
          <DatePicker disabled={!date} minDate={dayjs(date)} format="DD/MM/YYYY" />
        </Form.Item>
      </div>
      <Form.Item<CreateContractType> name="renovation" label="Renovável">
        <Radio.Group
          optionType="button"
          buttonStyle="solid"
          defaultValue={false}
          options={[
            { label: "Sim", value: true },
            { label: "Não", value: false },
          ]}
        />
      </Form.Item>
      <Form.Item>
        <div className="text-right">
          <Space size="small">
            <Button type="default" danger onClick={() => onClose()}>
              Cancelar
            </Button>

            <Button loading={loadingCreateContract} type="primary" htmlType="submit">
              Cadastrar
            </Button>
          </Space>
        </div>
      </Form.Item>
    </Form>
  );
}

export default CreateContractForm;
