import { useEffect } from "react";

import { Button, Form, Input, Radio, Space } from "antd";

import { useTranslation } from "react-i18next";
import { useMaterialsStore } from "../store";

import { SearchMaterialType } from "../types";

type SearchMaterialFormProps = {
  onCallBackFilter: () => void;
  onCallBackResetFilter: () => void;
};

export const SearchMaterialForm = ({ onCallBackFilter, onCallBackResetFilter }: SearchMaterialFormProps) => {
  const [form] = Form.useForm<SearchMaterialType>();
  const { filter, setFilter } = useMaterialsStore();
  const { t } = useTranslation();
  useEffect(() => {
    form.setFieldsValue(filter);
  }, [form, filter]);

  return (
    <>
      <Form
        className="select-none"
        layout="vertical"
        form={form}
        onFinish={async (values) => {
          if (form.isFieldsTouched(true)) {
            setFilter(values);
          }
          onCallBackFilter();
        }}
        autoComplete="off"
      >
        <Form.Item<SearchMaterialType> name="mat_description" label={t("system.material.label_description")}>
          <Input />
        </Form.Item>
        <Form.Item<SearchMaterialType> name="mat_active" label={t("shared.filter.label_status")}>
          <Radio.Group value={filter.mat_active}>
            <Radio value={"off"}>{t("shared.filter.radio_blocked")}</Radio>
            <Radio value={"on"}>{t("shared.filter.radio_unlocked")}</Radio>
            <Radio value={"all"}>{t("shared.filter.radio_all")}</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item>
          <div className="text-right">
            <Space size="small">
              <Button type="default" danger onClick={onCallBackResetFilter}>
                {t("shared.filter.btn_clean")}
              </Button>
              <Button type="primary" htmlType="submit">
                {t("shared.filter.btn_filter")}
              </Button>
            </Space>
          </div>
        </Form.Item>
      </Form>
    </>
  );
};
