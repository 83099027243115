import { create } from "zustand";
import { ListTrashType, RestoreType, RowTrashType, SearchTrashType } from "./types";
import showMessage from "@utils/showMessage";
import { ClientHttp, BadRequest, StandardError, Success } from "@utils/clientHttp";
import { SetSort, SortType } from "@utils/table/SetSort";
import D3QSGenerator, { FilterDefinition } from "@utils/D3QSGenerator";

type TrashStoreState = {
  loading: boolean;
  filter: SearchTrashType;
  selectedRows: { cuids: string[] };
  apiResult: ListTrashType;
  pagination: { page: number; pageSize: number };
  sort: { sort_by: string; sort_direction: string };
  setFilter: (filter: SearchTrashType) => void;
  onMultiSelectedRowChange: (selectedRows: RowTrashType[]) => void;
  resetSelectedRows: () => void;
  setPagination: (page: number, pageSize: number) => void;
  setSort: (newSort: SortType) => void;
  loadTrash: () => Promise<Success<ListTrashType> | BadRequest | StandardError>;
  restore: (fs_cuid?: string) => Promise<void>;
  deleteToDay: (fs_cuid?: string) => Promise<void>;
  empty: () => Promise<boolean>;
  loadingEmpty: boolean;
  resetFilter: () => void;
  resetState: () => void;
};

const defaultSort = {
  sort_by: "fs_type",
  sort_direction: "asc",
};

const operationsFilter: FilterDefinition = {
  fs_type: { op: "eq", type: "string" },
  fs_name: { op: "lk", type: "string" },
  deleted_user_name: { op: "lk", type: "string" },
  trash_scheduled_deletion_date: { op: "eq", type: "string" },
};

const defaultState = {
  apiResult: {
    total: 0,
    data: [],
  },
  selectedRows: {
    cuids: [],
  },
  pagination: {
    page: 1,
    pageSize: 10,
  },
  filter: {
    fs_type: "",
    fs_name: "",
    deleted_user_name: "",
    trash_scheduled_deletion_date: "",
  } as SearchTrashType,
  sort: defaultSort,
  loading: false,
  loadingEmpty: false,
};

export const useRepositoriesStore = create<TrashStoreState>((set, get) => ({
  ...defaultState,
  resetState: () => set(defaultState),
  onMultiSelectedRowChange: (selectedRows: RowTrashType[]): void => {
    set({
      selectedRows: {
        cuids: selectedRows.map((item) => item.fs_cuid),
      },
    });
  },
  resetSelectedRows: () => {
    set({ selectedRows: { cuids: [] } });
  },
  setFilter: (filter: SearchTrashType) => {
    set({
      filter,
      selectedRows: { cuids: [] },
      pagination: { ...get().pagination, page: 1 },
    });
    get().loadTrash();
  },
  resetFilter: () => {
    set({
      filter: {
        fs_type: "",
        fs_name: "",
        deleted_user_name: "",
        trash_scheduled_deletion_date: "",
      },
    });
    get().loadTrash();
  },
  setPagination: (page: number, pageSize: number) => {
    if (pageSize !== get().pagination.pageSize) {
      page = 1;
    }
    set({ pagination: { page, pageSize } });
    get().loadTrash();
  },
  setSort: (newSort: SortType) =>
    SetSort(
      newSort,
      get().sort,
      defaultSort,
      new Map<string, string>([
        ["fs_type", "fs_type"],
        ["fs_name", "fs_name"],
        ["trash_deleted_user_name", "deleted_user_name"],
        ["trash_scheduled_deletion_date", "trash_scheduled_deletion_date"],
      ]),
      set,
      get().loadTrash
    ),
  loadTrash: async (): Promise<Success<ListTrashType> | BadRequest | StandardError> => {
    set({ loading: true });
    let qs = D3QSGenerator(get().filter, operationsFilter, get().pagination, get().sort);
    return await new ClientHttp().get<Success<ListTrashType>, BadRequest | StandardError>(
      `/api/v1/customer/fs/trash?${qs}`,
      (result: Success<ListTrashType>) => {
        let newPage = get().pagination.page;
        const { body } = result;
        if (body.total > 0 && body.data.length === 0) {
          newPage = newPage - 1;
          set({
            apiResult: body,
            loading: false,
            pagination: { ...get().pagination, page: newPage },
          });
          get().loadTrash();
        } else {
          set({ apiResult: body, loading: false });
        }
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
  },
  restore: async (fs_cuid?: string): Promise<void> => {
    const selectedCuids = get().selectedRows.cuids;
    await new ClientHttp().patch<RestoreType, Success<{ rows_affected: number }>, BadRequest | StandardError>(
      `/api/v1/customer/fs/trash/restore`,
      { fs_cuids: fs_cuid ? [fs_cuid] : selectedCuids },
      (result: Success<{ rows_affected: number }>) => {
        showMessage(
          result,
          selectedCuids.length > 1 ? "Itens restaurados com sucesso." : "Item restaurado com sucesso."
        );
        get().resetSelectedRows();
        get().loadTrash();
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
  },
  deleteToDay: async (fs_cuid?: string): Promise<void> => {
    const selectedCuids = get().selectedRows.cuids;
    await new ClientHttp().patch<RestoreType, Success<{ rows_affected: number }>, BadRequest | StandardError>(
      `/api/v1/customer/fs/trash/delete-today`,
      { fs_cuids: fs_cuid ? [fs_cuid] : selectedCuids },
      (result: Success<{ rows_affected: number }>) => {
        showMessage(result, selectedCuids.length > 1 ? "Itens excluídos com sucesso." : "Item excluído com sucesso.");
        get().resetSelectedRows();
        get().loadTrash();
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
  },
  empty: async (): Promise<boolean> => {
    set({ loadingEmpty: true });
    const result = await new ClientHttp().patch<null, Success<void>, BadRequest | StandardError>(
      `/api/v1/customer/fs/trash/empty`,
      null,
      (result: Success<void>): void => {
        showMessage(result, "Todos os itens foram removidos com sucesso da lixeira.");
        get().loadTrash();
      },
      (error: BadRequest | StandardError): void => {
        showMessage(error);
      }
    );
    set({ loadingEmpty: false });
    return result.status === "success";
  },
}));
