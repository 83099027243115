import { Icon } from "@iconify/react";
import useAuthStore from "@provider/AuthProvider";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { Drawer, Tag } from "antd";
import { useContext, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useD3SelectEntityStore } from "../Layout/D3HeaderBar/components/store";

const D3Drawer = () => {
  const { isOpen, content, onClose, placement, size, title, withOutBorder } = useContext(D3DrawerContext);
  const { pathname } = useLocation();
  const customerName = useD3SelectEntityStore.getState().getCustomerName();
  const showSelectedCustomer = useMemo(() => {
    const userInfo = useAuthStore.getState().getUserInfo();
    const isPathValid = ["/workspace/", "/customer/"].some((path) => pathname.includes(path));
    return isPathValid && userInfo?.Type !== "CTM" && customerName !== undefined && customerName.length > 0;
  }, [pathname, customerName]);

  const SelectedCustomer = showSelectedCustomer ? (
    <Tag
      color="blue"
      className="flex flex-row items-center justify-start max-w-xs gap-1 py-[2px] truncate"
    >
      <Icon className="min-w-4 min-h-4" icon="fluent:info-24-regular" />
      <span className="font-bold">Cliente:</span>
      <span className="text-xs uppercase truncate">{customerName}</span>
    </Tag>
  ) : (
    <></>
  );

  return (
    <Drawer
      className={`${withOutBorder ? "d3-pdf-view" : ""} `}
      title={
        <div className="grid grid-cols-[repeat(2,min-content)] gap-2 items-center select-none">
          <div className="max-w-max whitespace-nowrap">{title}</div>
          <div className="flex-1 max-w-max">{SelectedCustomer}</div>
        </div>
      }
      maskClosable={false}
      placement={placement}
      width={size}
      onClose={onClose}
      open={isOpen}
    >
      {content}
    </Drawer>
  );
};

export default D3Drawer;
