import extractOptions from "@utils/extractOptions";
import { Form, Input, Select } from "antd";
import { useState } from "react";

type ItemCACProps = {
  options: string;
  name: number;
};

const maskCPF = (value: any) => {
  if (!value) return value;
  return value
    .replace(/\D/g, "") // Remove todos os caracteres não numéricos
    .replace(/(\d{3})(\d)/, "$1.$2") // Adiciona o primeiro ponto
    .replace(/(\d{3})(\d)/, "$1.$2") // Adiciona o segundo ponto
    .replace(/(\d{3})(\d{1,2})$/, "$1-$2"); // Adiciona o traço
};

const maskCNPJ = (value: any) => {
  if (!value) return value;
  return value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1/$2")
    .replace(/(\d{4})(\d{1,2})$/, "$1-$2");
};

function ItemCAC({ name, options, ...props }: ItemCACProps) {
  const [option, setOption] = useState<string>("");
  const [count, setCount] = useState<number>(0);
  return (
    <div className="flex w-full gap-3 ">
      <Form.Item
        className="w-40 p-0 m-0"
        {...props}
        name={[name, "search_option"]}
      >
        <Select
          className="min-w-40"
          onChange={(e) => setOption(e)}
          placeholder="Opções de busca"
          options={extractOptions(options)}
        />
      </Form.Item>

      {option === "MV" ? (
        <Form.Item
          {...props}
          className="w-full p-0 m-0"
          name={[name, "values"]}
        >
          <Select allowClear open={false} mode="tags" suffixIcon={<></>} />
        </Form.Item>
      ) : (
        <Form.Item
          {...props}
          className="w-full p-0 m-0"
          getValueProps={(value) => ({
            value: count > 14 ? maskCNPJ(value) : maskCPF(value),
          })}
          normalize={(value) => value.replace(/[^0-9]/g, "")}
          name={[name, option === "MV" ? "values" : "value"]}
        >
          {option === "MV" ? (
            <Select allowClear open={false} mode="tags" suffixIcon={<></>} />
          ) : (
            <Input
              maxLength={18}
              onChange={(e: any) => {
                const value = e.target.value;
                setCount(value.length);
              }}
            />
          )}
        </Form.Item>
      )}
    </div>
  );
}

export default ItemCAC;
