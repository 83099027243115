import FormItemConditionalDate from "@components/shared/FormItemConditionalDate";
import { Button, Form, FormInstance, Input, List, Radio, Select, Tooltip } from "antd";

import { useEffect, useState } from "react";
import { useSpecialsStore } from "../store";
import { SearchPhysicalDocumentType } from "../types";
import { Icon } from "@iconify/react";
import FormItemSwitcher from "./FormItemSwitcher";
const { TextArea } = Input;

type SearchRepositoryFormFormProps = {
  form: FormInstance<SearchPhysicalDocumentType>;
};

const options = [
  { label: "Igual a", value: "equal" },
  { label: "Maior que", value: "greater" },
  { label: "Menor que", value: "less" },
  { label: "No intervalo", value: "range" },
];

function SearchRepositoryForm({ form }: SearchRepositoryFormFormProps) {
  const { filter, simpleListDepartmentCustomer, departments, documentsType } = useSpecialsStore();
  const [isMultiple, setIsMultiple] = useState<boolean>(false);
  const [documentsCuid, setDocumentsCuid] = useState<string[]>(filter.filter_document.document_physical_tag_id);
  const [textAreaDocuments, setTextAreaDocuments] = useState<string[]>([]);

  useEffect(() => {
    (async () => {
      await simpleListDepartmentCustomer();
      form.setFieldsValue({
        filter_repository: filter.filter_repository,
      });
    })();
  }, [simpleListDepartmentCustomer, form]);

  const removeItem = (index: any) => {
    const newArray = [...documentsCuid];
    newArray.splice(index, 1);
    setDocumentsCuid(newArray);
  };

  return (
    <div className="flex flex-col w-full h-full p-2 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100 !important">
      <Form.Item label="Departamentos" name={["filter_repository", "departments_cuids"]} className="w-full p-0 my-2">
        <Select
          listItemHeight={10}
          allowClear
          removeIcon={
            <Icon
              icon="iconamoon:close-duotone"
              className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
            />
          }
          menuItemSelectedIcon={<Icon icon="eva:checkmark-outline" className="w-5 h-5 text-blue-500" />}
          optionLabelProp="dataLabel"
          showSearch
          maxTagCount="responsive"
          mode="multiple"
          size="middle"
          className="w-full truncate select-none"
          optionFilterProp="children"
          filterOption={(input, option) => option?.dataFilter.toLowerCase().includes(input.toLowerCase())}
        >
          {departments.map(({ abbreviation, active, cuid, description }) => (
            <Select.Option
              key={cuid}
              value={cuid}
              dataFilter={`${abbreviation} - ${description}`}
              dataLabel={`${abbreviation} - ${description}`}
            >
              <div className="flex items-center justify-between ">
                <div className="flex flex-col truncate">
                  <span className="truncate">{`${abbreviation} - ${description}`}</span>
                </div>
                <span>
                  {active ? (
                    <Tooltip title="Departamento desbloqueado" placement="right">
                      <Icon icon="basil:unlock-solid" className="text-green-500" height={16} width={16} />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Departamento bloqueado" placement="right">
                      <Icon icon="basil:lock-solid" className="text-red-500" height={16} width={16} />
                    </Tooltip>
                  )}
                </span>
              </div>
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <FormItemSwitcher
        attribute={["filter_repository", "repository_physical_tag_id"]}
        label="Etiquetas de Repositório"
        form={form}
      />
      <Form.Item label="Intervalo de repositórios" className="p-0 my-2">
        <div className="flex items-center gap-3">
          <Form.Item
            className="w-full p-0 m-0"
            name={["filter_repository", "repository_range", "start_repository_physical_tag_id"]}
          >
            <Input />
          </Form.Item>
          até
          <Form.Item
            className="w-full p-0 m-0"
            name={["filter_repository", "repository_range", "end_repository_physical_tag_id"]}
          >
            <Input />
          </Form.Item>
        </div>
      </Form.Item>

      <Form.Item
        name={["filter_repository", "repository_types_cuids"]}
        label="Tipo de Documento"
        className="w-full p-0 my-2"
      >
        <Select
          disabled={documentsType.length === 0}
          listItemHeight={10}
          allowClear
          removeIcon={
            <Icon
              icon="iconamoon:close-duotone"
              className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
            />
          }
          menuItemSelectedIcon={<Icon icon="eva:checkmark-outline" className="w-5 h-5 text-blue-500" />}
          optionLabelProp="dataLabel"
          showSearch
          maxTagCount="responsive"
          mode="multiple"
          size="middle"
          className="w-full truncate select-none"
          optionFilterProp="children"
          filterOption={(input, option) => option?.dataFilter.toLowerCase().includes(input.toLowerCase())}
          onClear={() => useSpecialsStore.setState({ listIndexers: [] })}
        >
          {documentsType.map(({ active, cuid, description, temporality_permanent_guard, temporality_year }) => (
            <Select.Option
              disabled={!active}
              key={cuid}
              value={cuid}
              dataFilter={`${description}`}
              dataLabel={`${description} - ${
                temporality_permanent_guard === true
                  ? "Tempo indeterminado"
                  : `${temporality_year} ${temporality_year > 1 ? "Anos" : "Ano"}`
              }`}
            >
              <div className="flex items-center justify-between ">
                <div className="flex flex-col truncate">
                  <span className="truncate">{`${description} - ${
                    temporality_permanent_guard === true
                      ? "Tempo indeterminado"
                      : `${temporality_year} ${temporality_year > 1 ? "Anos" : "Ano"}`
                  }`}</span>
                </div>
                <span>
                  {active ? (
                    <Tooltip title="Tipo de documento desbloqueado" placement="right">
                      <Icon icon="basil:unlock-solid" className="text-green-500" height={16} width={16} />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Tipo de documento bloqueado" placement="right">
                      <Icon icon="basil:lock-solid" className="text-red-500 " height={16} width={16} />
                    </Tooltip>
                  )}
                </span>
              </div>
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <FormItemConditionalDate<SearchPhysicalDocumentType>
        attribute={["filter_repository", "created_at"]}
        label="Data cadastramento"
        options={options}
        form={form}
      />

      <Form.Item label="Posição" className="p-0 my-2" name={["filter_repository", "position"]}>
        <Input />
      </Form.Item>

      <Form.Item label="Origem" className="p-0 my-2" name={["filter_repository", "repository_types_cuids"]}>
        <Input />
      </Form.Item>
    </div>
  );
}

export default SearchRepositoryForm;
