import { Icon } from "@iconify/react";
import { Button } from "antd";

interface PopoverContentProps {
	dataIndex: string;
	title: string;
	direction: string;
	setFixed: (callback: (prevFixed: any) => any) => void;
	active: boolean;
}

const FixedColumn: React.FC<PopoverContentProps> = ({ dataIndex, title, setFixed, direction, active }) => {
	return (
		<div className="flex justify-between">
			<span>{title}</span>
			<div onClick={(e) => e.stopPropagation()}>
				<Button
					className={`flex items-center justify-center ${
						active ? "text-blue-500 rotate-45 duration-200 ease-in-out transition-all" : "text-gray-500"
					}`}
					type="link"
					size="small"
					icon={<Icon height={14} icon="mdi:pin" />}
					onClick={() =>
						setFixed((prevFixed: any) =>
							prevFixed.map((item: any) =>
								item.dataIndex === dataIndex && item.direction === direction
									? { ...item, dataIndex: "" }
									: item.direction === direction
									? { ...item, dataIndex }
									: item
							)
						)
					}
				/>
			</div>
		</div>
	);
};

export default FixedColumn;
