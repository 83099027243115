import { Checkbox, Form, Table } from "antd";
import { ViewPermissionGroupType } from "../../types";
import { useContext, useEffect } from "react";
import { Icon } from "@iconify/react";
import { useTablePermissions } from "@components/shared/D3PermCheckTable/store";
import { ItemsPermissionType, PermissionType } from "@components/shared/D3PermCheckTable/type";
import { D3DrawerContext } from "@provider/D3DrawerContext";

type TabPermissionsProps = {
  data: ViewPermissionGroupType | undefined;
};

function TabPermissions({ data }: TabPermissionsProps) {
  const [form] = Form.useForm<ViewPermissionGroupType>();
  const { apiResult, listPermissions, loading } = useTablePermissions();
  const { setSize } = useContext(D3DrawerContext);

  useEffect(() => {
    setSize(1200);
    (async () => {
      await listPermissions("CPN");
    })();
    if (data !== undefined) {
      form.setFieldsValue({ permissions: data.permissions });
    }
    return () => setSize(700);
  }, [data, form, listPermissions]);

  const allActionLabels = Array.from(
    new Set(apiResult.data.flatMap((dataItem) => dataItem.permissions.map((action) => action.action_group)))
  );
  const columns = [
    {
      title: "Tela",
      dataIndex: "permission_group",
      key: "permission_group",
      render: (_: any, record: PermissionType) => <div className="whitespace-nowrap">{record.permission_group}</div>,
    },
    ...allActionLabels.map((actionLabel) => {
      const correspondingAction = apiResult.data
        .flatMap((dataItem) => dataItem.permissions)
        .find((action) => action.action_group === actionLabel);
      return {
        align: "center" as const,
        title: actionLabel,
        dataIndex: ["permissions"],
        key: correspondingAction?.key || actionLabel,
        render: (actions: ItemsPermissionType[]) => {
          if (!data?.permissions || data.permissions.length === 0) {
            return null;
          }

          const actionName = actions.find((action) => action.action_group === actionLabel)?.key;

          if (actionName) {
            const isPermitted = data?.permissions.includes(actionName);
            return <Checkbox disabled checked={isPermitted} />;
          } else {
            return (
              <div className="flex items-center justify-center">
                <Icon icon="gg:block" className="w-5 h-5 text-red-500" />
              </div>
            );
          }
        },
      };
    }),
  ];
  return (
    <>
      <Form layout="vertical" form={form}>
        <Form.Item label="Permissões do usuário" name="permissions">
          <Table
            loading={loading}
            bordered
            size="small"
            dataSource={data?.permissions.length === 0 ? [] : apiResult.data}
            pagination={false}
            columns={columns}
          />
        </Form.Item>
      </Form>
    </>
  );
}

export default TabPermissions;
