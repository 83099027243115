import { BadRequest, ClientHttp, StandardError, Success } from "@utils/clientHttp";
import showMessage from "@utils/showMessage";

import { create } from "zustand";
import { useUploadManagerStore } from "../upload-manager/store";
import {
  CreateUrlUpload,
  ItemDepartmentType,
  PayloadFileType,
  SimpleListDepartmentType,
  UploadFileType,
} from "./types";
import useNavigateStore from "../../work/components/navigate/store";

type FormUploadWorkStoreState = {
  simpleListDepartments: () => Promise<ItemDepartmentType[]>;
  createFiles: (payload: PayloadFileType, isDTF?: boolean) => Promise<void>;
};

export const useFormUploadWorkStore = create<FormUploadWorkStoreState>((set, get) => ({
  simpleListDepartments: async (): Promise<ItemDepartmentType[]> => {
    return await new ClientHttp().getItensForSelect<
      Success<SimpleListDepartmentType>,
      StandardError,
      ItemDepartmentType[]
    >(
      "/api/v1/customer/departments/simple-list",
      (result: Success<SimpleListDepartmentType>): ItemDepartmentType[] => {
        return result.body.data as ItemDepartmentType[];
      },
      (error: StandardError): ItemDepartmentType[] => {
        showMessage(error, "Erro ao carregar lista de departamento.");
        return [] as ItemDepartmentType[];
      }
    );
  },
  createFiles: async (payload: PayloadFileType, isDTF?: boolean): Promise<void> => {
    await new ClientHttp().post<PayloadFileType, Success<CreateUrlUpload>, BadRequest | StandardError>(
      "/api/v1/customer/fs/file",
      payload,
      (result: Success<CreateUrlUpload>): void => {
        const data = result.body.data;
        const newChildrens = data.map((item) => item.children);
        if (!isDTF) {
          useNavigateStore.getState().addChildrens(newChildrens);
        }
        const files = payload.files.map((file: UploadFileType) => {
          const itemUrlIndex = data.findIndex((itemUrl) => file.uid === itemUrl.uid);
          const itemUrl = data[itemUrlIndex];
          file.fs_cuid = itemUrl.fs_cuid;
          file.url = itemUrl.url;
          file.icon = data[itemUrlIndex].children.extension.icon;
          return file;
        });
        if (payload.files.length === files.length) {
          useUploadManagerStore.getState().addUploadFiles(files);
        }
      },
      (error): void => {
        showMessage(error, "Erro na geração de url para upload do arquivo.");
      }
    );
  },
}));
