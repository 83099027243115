import { Form, InputNumber } from "antd";

type ItemINTProps = {
  name: number;
};

function ItemINT({ name, ...props }: ItemINTProps) {
  return (
    <Form.Item
      {...props}
      className="w-full p-0 m-0"
      name={[name, "value"]}
      getValueProps={(value: number) => {
        return { value: value !== undefined ? value.toString() : "" };
      }}
      normalize={(value: string) => {
        const numericValue = Number(value);
        return !isNaN(numericValue) ? numericValue.toString() : value;
      }}
    >
      <InputNumber type="number" className="w-32" />
    </Form.Item>
  );
}

export default ItemINT;
