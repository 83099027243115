import D3Can from "@components/shared/D3Can";
import D3Status from "@components/shared/D3Status";
import { Icon } from "@iconify/react";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { ToggleLockAction } from "@utils/ToggleLockAction";
import { D3Money } from "@utils/money";
import { D3Percentage } from "@utils/percentage";
import { Badge, Button, Col, Pagination, Popconfirm, Popover, Row, Space, Tooltip } from "antd";
import Table from "antd/es/table";
import { useContext, useEffect, useState } from "react";
import { CreateRepositoryTypeForm } from "./components/CreateRepositoryTypeForm";
import { SearchRepositoryTypeForm } from "./components/SearchRepositoryTypeForm";
import { UpdateRepositoryTypeForm } from "./components/UpdateRepositoryTypeForm";
import { ViewRepositoryTypeForm } from "./components/ViewRepositoryTypeForm";
import { useRepositoryTypesStore } from "./store";
import { RepositoryTypeListItemType } from "./types";

const RepositoryTypesView: React.FC = () => {
	const { openDrawer } = useContext(D3DrawerContext);
	const [openSearch, setOpenSearch] = useState(false);
	const {
		apiResult,
		pagination,
		loading,
		selectedRows,
		setPagination,
		setSort,
		loadRepositoryTypes,
		lockRepositoryType,
		unLockRepositoryType,
		onMultiSelectedRowChange,
		resetFilter,
		resetState,
	} = useRepositoryTypesStore();

	useEffect(() => {
		(async () => {
			await loadRepositoryTypes();
		})();
		return () => resetState();
	}, [loadRepositoryTypes]);

	return (
		<div className="flex flex-col h-full mb-1 mr-1 overflow-auto bg-white rounded shadow">
			<Table<RepositoryTypeListItemType>
				size="small"
				rowKey="cuid"
				dataSource={apiResult.data}
				bordered
				className="responsive-table"
				pagination={false}
				loading={loading}
				title={() => {
					return (
						<>
							<div className="flex items-center justify-between w-full">
								<div className="flex items-center justify-start">
									<Popover
										open={openSearch}
										placement="rightTop"
										title="Pesquisar Tipos de Repositório"
										content={
											<SearchRepositoryTypeForm
												onCallBackFilter={() => setOpenSearch(false)}
												onCallBackResetFilter={() => {
													setOpenSearch(false);
													resetFilter();
												}}
											/>
										}
										trigger="click"
										onOpenChange={(open) => setOpenSearch(open)}
									>
										<Button
											onClick={() => setOpenSearch(!openSearch)}
											type="primary"
											size="middle"
											icon={<Icon icon="fluent:search-12-filled" height={16} width={16} />}
										>
											Pesquisar
										</Button>
									</Popover>
								</div>
								<div className="flex items-center justify-center">
									<span className="text-xl font-semibold text-gray-600">Tipos de Repositório</span>
								</div>
								<div className="flex items-center justify-end">
									<D3Can action="create" resource="system_repository_type">
										<Button
											type="primary"
											size="middle"
											icon={<Icon icon="ic:round-plus" height={20} width={20} />}
											onClick={() => openDrawer(<CreateRepositoryTypeForm />, "right", 600, "Cadastrar Tipo de Repositório")}
										>
											Novo Tipo de Repositório
										</Button>
									</D3Can>
								</div>
							</div>
						</>
					);
				}}
				rowSelection={{
					hideSelectAll: false,
					type: "checkbox",
					selectedRowKeys: selectedRows.cuids,
					onChange: (_, selectRows) => {
						onMultiSelectedRowChange(selectRows);
					},
				}}
				onChange={(a, b, sorter: any) => {
					if (sorter) {
						setSort({
							sort_by: sorter.field,
							sort_direction: sorter.order,
						});
					}
				}}
				footer={() => {
					return (
						<>
							<div className="flex gap-4">
								<D3Can action="lock" resource="system_repository_type">
									<Badge count={selectedRows.cuids.length > 0 && selectedRows.active === true ? selectedRows.cuids.length : undefined}>
										<Popconfirm
											disabled={!(selectedRows.cuids.length > 0 && selectedRows.active === true)}
											title={`Atenção!`}
											description={`Confirma bloqueio ${selectedRows.cuids.length} ${selectedRows.cuids.length > 1 ? "itens" : "item"}?`}
											onConfirm={async () => await lockRepositoryType(selectedRows.cuids)}
											okText="Sim"
											cancelText="Não"
											placement="top"
										>
											<Button
												type="primary"
												danger
												disabled={!(selectedRows.cuids.length > 0 && selectedRows.active === true)}
												size="middle"
												icon={<Icon icon="basil:lock-solid" height={16} width={16} />}
											>
												Bloquear
											</Button>
										</Popconfirm>
									</Badge>
								</D3Can>
								<D3Can action="unlock" resource="system_repository_type">
									<Badge color="green" count={selectedRows.cuids.length > 0 && selectedRows.active === false ? selectedRows.cuids.length : undefined}>
										<Popconfirm
											disabled={!(selectedRows.cuids.length > 0 && selectedRows.active === false)}
											title={`Atenção!`}
											description={`Confirma desbloqueio ${selectedRows.cuids.length} ${selectedRows.cuids.length > 1 ? "itens" : "item"}?`}
											onConfirm={async () => {
												await unLockRepositoryType(selectedRows.cuids);
											}}
											okText="Sim"
											cancelText="Não"
											placement="top"
										>
											<Button
												className=""
												disabled={!(selectedRows.cuids.length > 0 && selectedRows.active === false)}
												size="middle"
												icon={<Icon icon="basil:unlock-solid" height={16} width={16} />}
											>
												Desbloquear
											</Button>
										</Popconfirm>
									</Badge>
								</D3Can>
							</div>

							<Space>
								<Pagination
									showLessItems
									showTotal={(total, range) => `Exibindo de ${range[0]} a ${range[1]} do total ${total} ${total > 1 ? "registros" : "registro"}`}
									showSizeChanger={true}
									current={pagination.page}
									onChange={setPagination}
									pageSize={pagination.pageSize}
									total={apiResult.total}
								/>
							</Space>
						</>
					);
				}}
			>
				<Table.Column<RepositoryTypeListItemType>
					showSorterTooltip={false}
					defaultSortOrder="ascend"
					key="description"
					title="Descrição"
					dataIndex="description"
					sorter
				/>
				<Table.Column<RepositoryTypeListItemType>
					showSorterTooltip={false}
					defaultSortOrder="ascend"
					key="material"
					title="Material"
					dataIndex={["material", "description"]}
				/>

				<Table.Column<RepositoryTypeListItemType>
					showSorterTooltip={false}
					key="price"
					title="Preço"
					dataIndex="price"
					sorter
					render={(value, repositoryType, index) => D3Money.FormatValueApiToPtBR(repositoryType.price)}
				/>

				<Table.Column<RepositoryTypeListItemType>
					showSorterTooltip={false}
					key="equivalence"
					title="Equivalência"
					dataIndex="equivalence"
					render={(value, repositoryType, index) => D3Percentage.formatValueToPercentage(repositoryType.equivalence)}
				/>

				<Table.Column<RepositoryTypeListItemType>
					showSorterTooltip={false}
					key="active"
					title="Status"
					dataIndex="active"
					sorter
					render={(value, repositoryType) => <D3Status status={repositoryType.active} />}
				/>
				<Table.Column<RepositoryTypeListItemType>
					key="action"
					title="Ações"
					width={140}
					render={(value, repositoryType, index) => {
						return (
							<>
								<Space>
									<D3Can action="update" resource="system_repository_type">
										<Tooltip title="Editar" destroyTooltipOnHide mouseLeaveDelay={0} zIndex={11}>
											<Button
												type="text"
												shape="circle"
												icon={
													<Icon
														icon="iconamoon:edit-fill"
														height={18}
														width={18}
														className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
													/>
												}
												size={"middle"}
												onClick={() =>
													openDrawer(<UpdateRepositoryTypeForm cuid={repositoryType.cuid} />, "right", 600, "Editar Tipo de Repositório")
												}
											/>
										</Tooltip>
									</D3Can>
									<D3Can action="view" resource="system_repository_type">
										<Tooltip title="Visualizar" destroyTooltipOnHide mouseLeaveDelay={0} zIndex={11}>
											<Button
												className="peer"
												type="text"
												shape="circle"
												icon={
													<Icon
														icon="carbon:document-view"
														height={18}
														width={18}
														className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
													/>
												}
												size={"middle"}
												onClick={() =>
													openDrawer(<ViewRepositoryTypeForm cuid={repositoryType.cuid} />, "right", 600, "Visualizar Tipo de Repositório")
												}
											/>
										</Tooltip>
									</D3Can>
									<D3Can action="lock" resource="system_repository_type">
										<ToggleLockAction
											description="Confirma bloqueio?"
											title="Atenção!"
											isVisible={repositoryType.active}
											tooltip="Bloquear"
											paramsToAction={[repositoryType.cuid]}
											action={async (params: string[]) => {
												await lockRepositoryType(params);
											}}
										/>
									</D3Can>
									<D3Can action="unlock" resource="system_repository_type">
										<ToggleLockAction
											title="Atenção!"
											description="Confirma desbloqueio?"
											isVisible={!repositoryType.active}
											tooltip="Desbloquear"
											paramsToAction={[repositoryType.cuid]}
											action={async (params: string[]) => {
												await unLockRepositoryType(params);
											}}
										/>
									</D3Can>
								</Space>
							</>
						);
					}}
				/>
			</Table>
		</div>
	);
};

export default RepositoryTypesView;
