import { DiffOutlined, InboxOutlined, KeyOutlined, UserOutlined } from "@ant-design/icons";
import { Card, Col, Row, Statistic } from "antd";

function DashboardView() {
  return (
    <div className="h-full p-2 mb-1 mr-1 bg-white rounded shadow-md">
      <Row gutter={16}>
        <Col span={12}>
          <Card bordered={true} className="shadow-md">
            <Statistic
              title="Galpão"
              value={63.2}
              precision={2}
              valueStyle={{ color: "#3f8600" }}
              prefix={<InboxOutlined />}
              suffix="%"
            />
          </Card>
        </Col>
        <Col span={12}>
          <Card bordered={true} className="shadow-md">
            <Statistic title="Usuários" value={22} valueStyle={{ color: "#4b5563" }} prefix={<UserOutlined />} />
          </Card>
        </Col>
      </Row>
      <Row gutter={16} className="mt-4">
        <Col span={12}>
          <Card bordered={true} className="shadow-md">
            <Statistic title="Ordens abertas" value={10} valueStyle={{ color: "red" }} prefix={<DiffOutlined />} />
          </Card>
        </Col>
        <Col span={12}>
          <Card bordered={true} className="shadow-md">
            <Statistic
              title="Usuários com senha padrão"
              value={8}
              valueStyle={{ color: "red" }}
              prefix={<KeyOutlined />}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default DashboardView;
