import { Button, Form, Input } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useContext } from "react";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import D3Can from "@components/shared/D3Can";
import { MoveToTrashType } from "./types";
import useTrashStore from "./store";
import useNavigateStore from "../navigate/store";

type MoveToTrashFormProps = {
  fsCuid?: string;
};
const { TextArea } = Input;

function MoveToTrashForm({ fsCuid }: MoveToTrashFormProps) {
  const { moveToTrash } = useTrashStore();
  const [form] = useForm<MoveToTrashType>();
  const { onClose } = useContext(D3DrawerContext);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={async (payload: MoveToTrashType) => {
        await moveToTrash({
          deletion_reason: payload.deletion_reason,
          cuids: fsCuid ? [fsCuid] : useNavigateStore.getState().selected.cuids,
        });
        onClose();
      }}
    >
      <Form.Item<MoveToTrashType>
        label="Motivo"
        name="deletion_reason"
        rules={[
          { required: true, message: "Por favor, informe o motivo da exclusão." },
          { min: 10, message: "O motivo da exclusão deve ter no mínimo 10 caracteres." },
        ]}
      >
        <TextArea rows={6} showCount />
      </Form.Item>
      <Form.Item>
        <div className="flex justify-end gap-2">
          <Button type="default" danger onClick={() => onClose()}>
            Cancelar
          </Button>
          <D3Can action="delete" resource="customer_fs">
            <Button type="primary" htmlType="submit">
              Mover
            </Button>
          </D3Can>
        </div>
      </Form.Item>
    </Form>
  );
}

export default MoveToTrashForm;
