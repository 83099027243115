import { DatePicker, Form } from "antd";
import dayjs from "dayjs";

type ItemDTIProps = {
  name: number;
};

function ItemDTI({ name, ...props }: ItemDTIProps) {
  return (
    <div className="flex w-full gap-3 ">
      <div className="flex items-center">
        <Form.Item
          getValueProps={(value) => ({ value: value ? dayjs(value) : null })}
          normalize={(value) => (value ? dayjs(value).format("YYYY-MM-DD") : "")}
          {...props}
          className="w-40 p-0 m-0"
          name={[name, "start_value"]}
        >
          <DatePicker
            format={{
              format: "DD/MM/YYYY",
              type: "mask",
            }}
          />
        </Form.Item>
        <div className="flex items-center h-full px-2">ao</div>
        <Form.Item
          getValueProps={(value) => ({ value: value ? dayjs(value) : null })}
          normalize={(value) => (value ? dayjs(value).format("YYYY-MM-DD") : "")}
          {...props}
          className="w-40 p-0 m-0"
          name={[name, "end_value"]}
        >
          <DatePicker
            format={{
              format: "DD/MM/YYYY",
              type: "mask",
            }}
          />
        </Form.Item>
      </div>
    </div>
  );
}

export default ItemDTI;
