import { Form, InputNumber } from "antd";
import { IndexItemType } from "../physical/types";

type ItemINTPropsType = {
  item: IndexItemType;
};

function ItemINT({ item }: ItemINTPropsType) {
  const { id, label, required } = item;
  return (
    <Form.Item
      name={[id, "INT", "value"]}
      label={label}
      rules={[
        {
          required: required,
          message: `Por favor, preencha o campo ${label.toLowerCase()}`,
        },
        {
          validator: (_, value) => {
            if (required && !Number.isInteger(value)) {
              return Promise.reject(new Error("Por favor, insira um número inteiro!"));
            }
            return Promise.resolve();
          },
        },
      ]}
    >
      <InputNumber placeholder="Número final" name="end_value" className="w-[126px]" />
    </Form.Item>
  );
}

export default ItemINT;
