import { Form } from "antd";
import { useEffect, useState } from "react";
import { viewIndexer } from "./ListInputs";
import { IndexerType } from "../../types";

type ViewIndexPropsType = {
  indexers: IndexerType[];
};

function ViewIndexers({ indexers }: ViewIndexPropsType) {
  const [listIndexers, setListIndexers] = useState<IndexerType[]>([]);
  useEffect(() => {
    setListIndexers(indexers);
  }, [indexers]);

  return (
    <div className="flex select-none">
      <Form layout="vertical" disabled className="w-full py-2 form-view">
        <ul>
          {listIndexers.map((item) => (
            <li key={item.document_type_index_order}>{viewIndexer(item)}</li>
          ))}
        </ul>
      </Form>
    </div>
  );
}

export default ViewIndexers;
