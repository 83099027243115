import { create } from "zustand";
import {
  ListRepositoryType,
  CreateRepositoryType,
  LockOrUnlockPayloadType,
  SearchRepositoryType,
  UpdateRepositoryType,
  ViewRepositoryType,
  SelectDepartmentItemType,
  SimpleListDepartmentType,
  SelectRepositoryTypeItemType,
  SimpleListRepositoryTypeType,
  SelectCustomerUserItemType,
  SimpleListCustomerUserType,
  DeletionReasonType,
} from "./types";

import showMessage from "@utils/showMessage";

import { ClientHttp, BadRequest, StandardError, Success } from "@utils/clientHttp";
import { SetSort, SortType } from "@utils/table/SetSort";
import D3QSGenerator, { FilterDefinition } from "@utils/D3QSGenerator";

type RepositoryStoreState = {
  apiResult: ListRepositoryType;
  selectedRows: { cuids: string[]; active: boolean };
  filter: SearchRepositoryType;
  pagination: { page: number; pageSize: number };
  sort: { sort_by: string; sort_direction: string };
  loading: boolean;
  setFilter: (filter: SearchRepositoryType) => void;
  setPagination: (page: number, pageSize: number) => void;
  setSort: (newSort: SortType) => void;
  loadRepositories: () => Promise<Success<ListRepositoryType> | BadRequest | StandardError>;
  getRepository: (cuid: string) => Promise<Success<ViewRepositoryType> | StandardError>;
  createRepository: (
    repository: CreateRepositoryType
  ) => Promise<Success<{ cuid: string }> | BadRequest | StandardError>;
  updateRepository: (
    cuid: string,
    repository: UpdateRepositoryType
  ) => Promise<Success<void> | BadRequest | StandardError>;
  getDepartmentsForSelect: () => Promise<SelectDepartmentItemType[]>;
  getRepositoryTypeForSelectInInputForm: (includeCuid?: string) => Promise<SelectRepositoryTypeItemType[]>;
  getRepositoryTypeForSelectInSearchForm: () => Promise<SelectRepositoryTypeItemType[]>;
  getCustomerUsersForSelect: () => Promise<SelectCustomerUserItemType[]>;
  deletionRepository: (
    repositoryCuid: string,
    payload: DeletionReasonType
  ) => Promise<Success<void> | BadRequest | StandardError>;
  resetFilter: () => void;
  resetState: () => void;
};

const defaultSort = {
  sort_by: "rep_created_at",
  sort_direction: "desc",
};

const operationsFilter: FilterDefinition = {
  rep_repository_physical_tag_id: { op: "lk", type: "string" },
  rep_created_at: { op: "eq", type: "string" },
  rep_updated_at: { op: "eq", type: "string" },
  rep_type_cuid: { op: "eq", type: "string" },
  rep_status: { op: "eq", type: "string" },
  dep_cuid: { op: "eq", type: "string" },
  pos_id: { op: "lk", type: "string" },
  cu_cuid: { op: "eq", type: "string" },
};

const defaultState = {
  apiResult: {
    total: 0,
    data: [],
  },
  selectedRows: {
    cuids: [],
    active: false,
  },
  pagination: {
    page: 1,
    pageSize: 10,
  },
  filter: {
    rep_repository_physical_tag_id: "",
    rep_created_at: "",
    rep_updated_at: "",
    rep_type_cuid: "",
    rep_status: "",
    dep_cuid: "",
    pos_id: "",
    cu_cuid: "",
  } as SearchRepositoryType,
  sort: defaultSort,
  loading: false,
};

export const useRepositoriesStore = create<RepositoryStoreState>((set, get) => ({
  ...defaultState,
  resetState: () => set(defaultState),
  setFilter: (filter: SearchRepositoryType) => {
    set({
      filter,
      selectedRows: { cuids: [], active: false },
      pagination: { ...get().pagination, page: 1 },
    });
    get().loadRepositories();
  },
  resetFilter: () => {
    set({
      filter: {
        rep_repository_physical_tag_id: "",
        rep_created_at: "",
        rep_updated_at: "",
        rep_type_cuid: "",
        rep_status: "",
        dep_cuid: "",
        pos_id: "",
        cu_cuid: "",
      },
    });
    get().loadRepositories();
  },
  setPagination: (page: number, pageSize: number) => {
    if (pageSize !== get().pagination.pageSize) {
      page = 1;
    }
    set({ pagination: { page, pageSize } });
    get().loadRepositories();
  },
  setSort: (newSort: SortType) =>
    SetSort(
      newSort,
      get().sort,
      defaultSort,
      new Map<string, string>([
        ["repository_physical_tag_id", "rep_repository_physical_tag_id"], // Column name from table and column name from api
        ["status", "rep_status"],
        ["position_id", "pos_id"],
        ["created_at", "rep_created_at"],
        ["updated_at", "rep_updated_at"],
      ]),
      set,
      get().loadRepositories
    ),
  loadRepositories: async (): Promise<Success<ListRepositoryType> | BadRequest | StandardError> => {
    set({ loading: true });
    let qs = D3QSGenerator(get().filter, operationsFilter, get().pagination, get().sort);
    return await new ClientHttp().get<Success<ListRepositoryType>, BadRequest | StandardError>(
      `/api/v1/customer/repositories?${qs}`,
      (result: Success<ListRepositoryType>) => {
        let newPage = get().pagination.page;
        const { body } = result;
        if (body.total > 0 && body.data.length === 0) {
          newPage = newPage - 1;
          set({
            apiResult: body,
            loading: false,
            pagination: { ...get().pagination, page: newPage },
          });
          get().loadRepositories();
        } else {
          set({ apiResult: body, loading: false });
        }
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
  },
  getRepository: async (cuid: string): Promise<Success<ViewRepositoryType> | StandardError> => {
    return await new ClientHttp().get<Success<ViewRepositoryType>, StandardError>(
      `/api/v1/customer/repositories/${cuid}`
    );
  },
  createRepository: async (
    repository: CreateRepositoryType
  ): Promise<Success<{ cuid: string }> | BadRequest | StandardError> => {
    return await new ClientHttp().post<CreateRepositoryType, Success<{ cuid: string }>, BadRequest | StandardError>(
      "/api/v1/customer/repositories",
      repository,
      (result: Success<{ cuid: string }>) => {
        get().loadRepositories();
      }
    );
  },
  updateRepository: async (
    cuid: string,
    repository: UpdateRepositoryType
  ): Promise<Success<void> | BadRequest | StandardError> => {
    return await new ClientHttp().put<UpdateRepositoryType, Success<void>, BadRequest | StandardError>(
      `/api/v1/customer/repositories/${cuid}`,
      repository,
      (result: Success<void>) => {
        get().loadRepositories();
      }
    );
  },
  getDepartmentsForSelect: async (): Promise<SelectDepartmentItemType[]> => {
    return await new ClientHttp().getItensForSelect<
      Success<SimpleListDepartmentType>,
      StandardError,
      SelectDepartmentItemType[]
    >(
      "/api/v1/customer/departments/simple-list",
      (result: Success<SimpleListDepartmentType>): SelectDepartmentItemType[] => {
        return result.body.data.map((item) => {
          return {
            value: item.cuid,
            label: item.description,
            active: item.active,
            abbreviation: item.abbreviation,
          } as SelectDepartmentItemType;
        });
      },
      (error: StandardError): SelectDepartmentItemType[] => {
        showMessage(error, "Erro ao carregar lista departamentos.");
        return [] as SelectDepartmentItemType[];
      }
    );
  },
  getRepositoryTypeForSelectInInputForm: async (includeCuid?: string): Promise<SelectRepositoryTypeItemType[]> => {
    return await new ClientHttp().getItensForSelect<
      Success<SimpleListRepositoryTypeType>,
      StandardError,
      SelectRepositoryTypeItemType[]
    >(
      `/api/v1/customer/repository-types/simple-list${includeCuid ? `?include_cuid=${includeCuid}` : ""}`,
      (result: Success<SimpleListRepositoryTypeType>): SelectRepositoryTypeItemType[] => {
        return result.body.data.map((item) => {
          return {
            value: item.cuid,
            label: item.description,
            active: item.active,
          } as SelectRepositoryTypeItemType;
        });
      },
      (error: StandardError): SelectRepositoryTypeItemType[] => {
        showMessage(error, "Erro ao carregar lista tipos de repositório.");
        return [] as SelectRepositoryTypeItemType[];
      }
    );
  },
  getRepositoryTypeForSelectInSearchForm: async (): Promise<SelectRepositoryTypeItemType[]> => {
    return await new ClientHttp().getItensForSelect<
      Success<SimpleListRepositoryTypeType>,
      StandardError,
      SelectRepositoryTypeItemType[]
    >(
      "/api/v1/system/repository-types/simple-list",
      (result: Success<SimpleListRepositoryTypeType>): SelectRepositoryTypeItemType[] => {
        return result.body.data.map((item) => {
          return {
            value: item.cuid,
            label: item.description,
            active: item.active,
          } as SelectRepositoryTypeItemType;
        });
      },
      (error: StandardError): SelectRepositoryTypeItemType[] => {
        showMessage(error, "Erro ao carregar lista tipos de repositório.");
        return [] as SelectRepositoryTypeItemType[];
      }
    );
  },
  getCustomerUsersForSelect: async (): Promise<SelectCustomerUserItemType[]> => {
    return await new ClientHttp().getItensForSelect<
      Success<SimpleListCustomerUserType>,
      StandardError,
      SelectCustomerUserItemType[]
    >(
      "/api/v1/customer/users/simple-list",
      (result: Success<SimpleListCustomerUserType>): SelectCustomerUserItemType[] => {
        return result.body.data.map((item) => {
          return {
            value: item.cuid,
            label: item.name,
            active: item.active,
            username: item.username,
          } as SelectCustomerUserItemType;
        });
      },
      (error: StandardError): SelectCustomerUserItemType[] => {
        showMessage(error, "Erro ao carregar lista usuários.");
        return [] as SelectCustomerUserItemType[];
      }
    );
  },
  deletionRepository: async (
    repositoryCuid: string,
    payload: DeletionReasonType
  ): Promise<Success<void> | BadRequest | StandardError> => {
    return await new ClientHttp().patch<DeletionReasonType, Success<void>, BadRequest | StandardError>(
      `/api/v1/customer/repositories/${repositoryCuid}`,
      payload,
      (result: Success<void>) => {
        get().loadRepositories();
        showMessage(result, "Repositório excluído com sucesso.");
      }
    );
  },
}));
