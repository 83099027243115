import { Alert, Button, Form, Input, Space, Spin } from "antd";
import { useContext, useState } from "react";
import D3Can from "@components/shared/D3Can";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { useCustomerStore } from "../../store";
import { CloseIncidentType, RegisterIncidentType } from "../../types";

const { TextArea } = Input;

type NoPaymentsFormProps = {
  isActive: boolean;
};

export const IncidentForm = ({ isActive }: NoPaymentsFormProps) => {
  const { onClose } = useContext(D3DrawerContext);
  const { closeIncident, registerIncident } = useCustomerStore();
  const [form] = Form.useForm<RegisterIncidentType | CloseIncidentType>();
  const [loading, setLoading] = useState(false);
  return (
    <>
      <Spin spinning={loading}>
        <Form
          className="select-none"
          form={form}
          layout="vertical"
          onFinish={async (
            payload: RegisterIncidentType | CloseIncidentType
          ) => {
            setLoading(true);
            isActive
              ? await closeIncident(payload as CloseIncidentType)
              : await registerIncident(payload as RegisterIncidentType);
            setLoading(false);
            onClose();
          }}
          autoComplete="off"
        >
          <Form.Item hidden={isActive}>
            <Alert
              message="Atenção"
              className="p-3 text-justify"
              showIcon
              description="Ao registrar uma ocorrência de inadimplência para o cliente selecionado, é fundamental compreender que os usuários vinculados a esse cliente ficarão temporariamente sem acesso ao sistema até que o referida ocorrência seja encerrada."
              type="info"
            />
          </Form.Item>
          <Form.Item
            name={
              isActive ? "removing_non_payment_reason" : "non_payment_reason"
            }
            label="Motivo"
            rules={[{ required: true, message: "Por favor, insira a razão." }]}
          >
            <TextArea rows={4} />
          </Form.Item>

          <Form.Item>
            <div className="text-right">
              <Space size="small">
                <Button type="default" onClick={() => onClose()}>
                  Cancelar
                </Button>
                <D3Can action="create" resource="system_material">
                  <Button type="primary" danger={!isActive} htmlType="submit">
                    Confirmar
                  </Button>
                </D3Can>
              </Space>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};
