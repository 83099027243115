import { useContext, useEffect, useState } from "react";
import { Button, Form, Input, Space, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { useMaterialsStore } from "../store";
import D3Can from "@components/shared/D3Can";
import showMessage from "@utils/showMessage";
import { UpdateMaterialType } from "../types";
import FormItemInputMoney from "@components/shared/FormItemInputMoney";

type UpdateMaterialProps = {
  cuid: string;
};

export const UpdateMaterialForm = ({ cuid }: UpdateMaterialProps) => {
  const { updateMaterial, getMaterial } = useMaterialsStore();
  const { onClose } = useContext(D3DrawerContext);
  const [form] = Form.useForm<UpdateMaterialType>();
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  useEffect(() => {
    (async () => {
      const result = await getMaterial(cuid);
      if (result.status === "success") {
        const viewMaterial = result.body as UpdateMaterialType;
        form.setFieldsValue(viewMaterial);
        setLoading(false);
      } else {
        showMessage(result);
      }
    })();
  }, [cuid, form, getMaterial]);

  return (
    <>
      <Spin spinning={loading}>
        <Form
          className="select-none"
          layout="vertical"
          form={form}
          onFinish={async (payload: UpdateMaterialType) => {
            setLoading(true);
            const result = await updateMaterial(cuid, payload);
            if (result.code === 200) {
              showMessage(result, t("system.material.edit.success"));
              onClose();
            } else {
              showMessage(result);
            }
            setLoading(false);
          }}
          autoComplete="off"
        >
          <Form.Item<UpdateMaterialType>
            name="description"
            label={t("system.material.label_description")}
            rules={[{ required: true, message: t("system.material.msg_description") }]}
          >
            <Input />
          </Form.Item>
          <FormItemInputMoney form={form} name="price" label={t("system.material.label_price")} numberType="positive" />
          <Form.Item>
            <div className="text-right">
              <Space size="small">
                <Button type="default" danger onClick={() => onClose()}>
                  {t("shared.btn_cancel")}
                </Button>
                <D3Can action="update" resource="system_material">
                  <Button type="primary" htmlType="submit">
                    {t("shared.btn_save")}
                  </Button>
                </D3Can>
              </Space>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};
