import { Icon } from "@iconify/react";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { Button, Checkbox, Dropdown, Tooltip } from "antd";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { typeFile } from "../types";
import { ItemsType } from "./ItemsType";
import FileTypes from "@utils/FileTypes";
import ViewFile from "../../file/view-file/ViewFile";
import useNavigateStore from "../store";

interface RenderGridProps {
  cuid: string;
  name: string;
  type: typeFile;
  isPhysical: boolean;
  fileUploadCompleted: boolean;
  icon: string;
  createdUserName: string;
}

function ItemList({ cuid, name, type, isPhysical, fileUploadCompleted, icon, createdUserName }: RenderGridProps) {
  const { openDrawer } = useContext(D3DrawerContext);
  const navigate = useNavigate();
  const { onChangeCheck, selected } = useNavigateStore();
  function handleItemClick(cuid: string, name: string, type: typeFile) {
    navigate(`/app/customer/spaces/work/${cuid}`);
  }

  return (
    <div
      className="grid cursor-pointer select-none grid-cols-9 w-full divide-x-[1px] divide-gray-200  bg-white border-b-[1px] hover:bg-gray-50  transition-all duration-200 ease-in-out border-gray-200 min-h-12"
      onContextMenu={(event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
      }}
      onDoubleClick={() => {
        if (type === "FLE") {
          openDrawer(<ViewFile fsFileCuid={cuid} />, "right", 1000, "Visualizar arquivo");
        } else {
          useNavigateStore.setState({ isFilter: false });
          handleItemClick(cuid, name, type);
        }
      }}
    >
      <div className="flex items-center col-end-1 px-2 min-h-12 min-w-9 max-w-9">
        <Checkbox
          disabled={type === "DTF" || (type === "SDC" && isPhysical)}
          checked={selected.cuids.includes(cuid)}
          onChange={() => onChangeCheck(cuid)}
        />
      </div>
      <div className="flex items-center justify-start col-span-3 gap-2 px-2 min-h-12 0">
        <Icon height={24} icon={icon} />
        <Tooltip title={name}>
          <span className="truncate"> {name}</span>
        </Tooltip>
      </div>
      <div className="flex items-center justify-start col-span-1 px-2 min-h-1">{FileTypes(type)}</div>
      <div className="flex items-center justify-start col-span-1 px-2 min-h-12">
        {type === "SDC" ? (isPhysical ? "Físico" : "Digital") : ""}
      </div>
      <div className="flex items-center justify-start col-span-2 px-2 min-h-12">{createdUserName}</div>
      <div className="flex items-center justify-start col-span-1 px-2 min-h-12">
        {type === "FLE" ? (fileUploadCompleted ? "Concluído" : "Aguardando upload...") : ""}
      </div>
      <div className="flex items-center justify-end col-span-1 px-2 min-h-12">
        <Dropdown
          menu={{
            items: ItemsType({
              cuid: cuid,
              name: name,
              type: type,
              menu: "FLS",
            }) as any[],
          }}
          placement="bottomLeft"
        >
          <Button className="flex items-center justify-center" type="link" icon={<Icon icon="charm:menu-kebab" />} />
        </Dropdown>
      </div>
    </div>
  );
}

export default ItemList;
