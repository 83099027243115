import { Icon } from "@iconify/react";
import { Button, Form, Input, InputNumber, Select, Space, Spin, Tooltip } from "antd";
import { useContext, useEffect, useState } from "react";
import D3Can from "@components/shared/D3Can";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import showMessage from "@utils/showMessage";
import { useRepositoryTypesStore } from "../store";
import { SelectMaterialItemType, UpdateRepositoryTypeType, ViewRepositoryTypeType } from "../types";
import FormItemInputMoney from "@components/shared/FormItemInputMoney";

type UpdateRepositoryTypeProps = {
  cuid: string;
};

export const UpdateRepositoryTypeForm = ({ cuid }: UpdateRepositoryTypeProps) => {
  const { updateRepositoryType, getRepositoryType, getMaterialForSelect } = useRepositoryTypesStore();

  const { onClose } = useContext(D3DrawerContext);
  const [materials, setMaterials] = useState<SelectMaterialItemType[]>([]);

  const [form] = Form.useForm<UpdateRepositoryTypeType>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const [getRepositoryTypeResult, getMaterialForSelectResult] = await Promise.all([
        getRepositoryType(cuid),
        getMaterialForSelect(),
      ]);

      if (getRepositoryTypeResult.status === "success") {
        const viewRepositoryType = getRepositoryTypeResult.body as ViewRepositoryTypeType;
        form.setFieldsValue({
          description: viewRepositoryType.description,
          material_cuid: viewRepositoryType.material.cuid,
          equivalence: viewRepositoryType.equivalence,
          price: viewRepositoryType.price,
        });
        setMaterials(getMaterialForSelectResult);
        setLoading(false);
      } else {
        showMessage(getRepositoryTypeResult);
      }
    })();
  }, [cuid, form, getMaterialForSelect, getRepositoryType]);

  return (
    <>
      <Spin spinning={loading}>
        <Form
          className="select-none"
          layout="vertical"
          form={form}
          onFinish={async (payload: UpdateRepositoryTypeType) => {
            setLoading(true);
            const result = await updateRepositoryType(cuid, payload);
            if (result.status === "success") {
              showMessage(result, "Tipo de repositório atualizado com sucesso.");
              onClose();
            } else {
              showMessage(result);
            }
            setLoading(false);
          }}
          autoComplete="off"
        >
          <Form.Item<UpdateRepositoryTypeType>
            name="description"
            label="Descrição"
            rules={[{ required: true, message: "Por favor, insira descrição." }]}
          >
            <Input />
          </Form.Item>
          <Form.Item<UpdateRepositoryTypeType>
            name="material_cuid"
            label="Material"
            rules={[{ required: true, message: "Por favor, selecione material." }]}
          >
            <Select
              listItemHeight={10}
              allowClear
              removeIcon={
                <Icon
                  icon="iconamoon:close-duotone"
                  className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
                />
              }
              menuItemSelectedIcon={<Icon icon="eva:checkmark-outline" className="w-5 h-5 text-blue-500" />}
              optionLabelProp="dataLabel"
              showSearch
              maxTagCount="responsive"
              size="middle"
              className="w-full truncate select-none"
              optionFilterProp="children"
              loading={loading}
              filterOption={(input, option) => option?.dataFilter.toLowerCase().includes(input.toLowerCase())}
            >
              {materials.map(({ value, active, label }) => (
                <Select.Option
                  disabled={form.getFieldValue("material_cuid") !== value && !active}
                  key={value}
                  value={value}
                  dataFilter={label}
                  dataLabel={label}
                >
                  <div className="flex items-center justify-between ">
                    <div className="flex flex-col truncate">
                      <span className="truncate">{label}</span>
                    </div>
                    <span>
                      {active ? (
                        <Tooltip title="Desbloqueado" placement="right">
                          <Icon icon="basil:unlock-solid" className="text-green-500" height={16} width={16} />
                        </Tooltip>
                      ) : (
                        <Tooltip title="Bloqueado" placement="right">
                          <Icon icon="basil:lock-solid" className="text-red-500" height={16} width={16} />
                        </Tooltip>
                      )}
                    </span>
                  </div>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item<UpdateRepositoryTypeType>
            name="equivalence"
            label="Equivalência"
            rules={[{ required: true, message: "Por favor, insira equivalência." }]}
          >
            <InputNumber addonAfter="%" className="w-32" />
          </Form.Item>
          <FormItemInputMoney form={form} name="price" label="Preço" numberType="positive" />
          <Form.Item>
            <div className="text-right">
              <Space size="small">
                <Button type="default" danger onClick={() => onClose()}>
                  Cancelar
                </Button>
                <D3Can action="update" resource="system_repository_type">
                  <Button type="primary" htmlType="submit">
                    Atualizar
                  </Button>
                </D3Can>
              </Space>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};
