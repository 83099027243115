import { Button, Col, Form, Input, Radio, Row, Space, Spin } from "antd";
import { useCustomersStore } from "../store";
import { useContext, useEffect, useState } from "react";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { UpdateCustomerForm } from "./UpdateCustomerForm";
import { D3Date } from "@utils/date";
import { ViewCustomerType } from "../types";
import showMessage from "@utils/showMessage";
import { PatternFormat } from "react-number-format";
import D3Can from "@components/shared/D3Can";

type ViewCustomerFormProps = {
  cuid: string;
};

export const ViewCustomerForm = ({ cuid }: ViewCustomerFormProps) => {
  const { getCustomer } = useCustomersStore();
  const { openDrawer } = useContext(D3DrawerContext);
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm<ViewCustomerType>();

  useEffect(() => {
    (async () => {
      const result = await getCustomer(cuid);
      if (result.status === "success") {
        const viewCustomer = result.body as ViewCustomerType;
        form.setFieldsValue({
          ...result.body,
          created_at: D3Date.FormatValueApiToPtBR(viewCustomer.created_at),
          updated_at: D3Date.FormatValueApiToPtBR(viewCustomer.updated_at),
        });
        setLoading(false);
      } else {
        showMessage(result);
      }
    })();
  }, [cuid, form, getCustomer]);

  const { onClose } = useContext(D3DrawerContext);

  return (
    <>
      <Spin spinning={loading}>
        <Form
          className="select-none"
          layout="vertical"
          form={form}
          autoComplete="off"
          disabled
        >
          <Form.Item<ViewCustomerType>
            label="Nome Fantasia"
            name="fantasy_name"
          >
            <Input disabled />
          </Form.Item>
          <Form.Item<ViewCustomerType> label="Razão Social" name="name">
            <Input disabled />
          </Form.Item>
          <Form.Item<ViewCustomerType>
            label="Empresa"
            name={["company", "fantasy_name"]}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item<ViewCustomerType>
            label="Responsável pelo contrato"
            name="contract_manager"
          >
            <Input disabled />
          </Form.Item>
          <Form.Item<ViewCustomerType> name="active" label="Status">
            <Radio.Group
              disabled
              options={[
                { label: "Desbloqueado", value: true },
                { label: "Bloqueado", value: false },
              ]}
            />
          </Form.Item>
          <Form.Item<ViewCustomerType> label="Pessoa" name="document_type">
            <Radio.Group
              disabled
              options={[
                { label: "Jurídica", value: "CNPJ" },
                { label: "Física", value: "CPF" },
              ]}
              optionType="button"
              buttonStyle="solid"
            />
          </Form.Item>
          {form.getFieldValue("document_type") === "CPF" ? (
            <Form.Item<ViewCustomerType> label="CPF" name="document">
              <PatternFormat
                format="###.###.###-##"
                customInput={Input}
                disabled
              />
            </Form.Item>
          ) : (
            <Row gutter={10}>
              <Col>
                <Form.Item<ViewCustomerType>
                  label="CNPJ"
                  className="w-[294px]"
                  name="document"
                >
                  <PatternFormat
                    disabled
                    format="##.###.###/####-##"
                    customInput={Input}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item<ViewCustomerType>
                  label="Inscrição Estadual"
                  className="w-[294px]"
                  name="state_inscription"
                >
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
          )}
          <Form.Item<ViewCustomerType> label="Criado em" name="created_at">
            <Input disabled />
          </Form.Item>
          <Form.Item<ViewCustomerType> label="Atualizado em" name="updated_at">
            <Input disabled />
          </Form.Item>
          <Form.Item>
            <div className="text-right">
              <Space size="small">
                <Button
                  disabled={false}
                  type="default"
                  danger
                  onClick={() => onClose()}
                >
                  Cancelar
                </Button>
                <D3Can action="update" resource="customer">
                  <Button
                    disabled={false}
                    type="primary"
                    onClick={() =>
                      openDrawer(
                        <UpdateCustomerForm cuid={cuid} />,
                        "right",
                        646,
                        "Editar Cliente"
                      )
                    }
                  >
                    Editar
                  </Button>
                </D3Can>
              </Space>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};
