import { Button, DatePicker, Form, Input, Radio, Space, Spin } from "antd";
import { useForm } from "antd/es/form/Form";
import { UpdateContractType } from "../types";
import { useContext, useEffect, useState } from "react";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { useContractStore } from "../store";
import dayjs, { Dayjs } from "dayjs";

type UpdateContractFormProps = {
  contractCuid: string;
  contractType?: "C" | "A" | "R";
};

function UpdateContractForm({ contractCuid, contractType = "C" }: UpdateContractFormProps) {
  const [form] = useForm<UpdateContractType>();
  const { viewContract, viewContractForm, loadingView, updateContract, loadingUpdate, resetState } = useContractStore();
  const { onClose } = useContext(D3DrawerContext);
  const [date, setDate] = useState<string | Dayjs | Date>("");

  useEffect(() => {
    (async () => {
      await viewContract(contractCuid);
      form.setFieldsValue(viewContractForm);
    })();
    return () => {
      resetState();
    };
  }, [viewContract]);

  useEffect(() => {
    (async () => {
      form.setFieldsValue(viewContractForm);
    })();
  }, [viewContractForm]);

  return (
    <Spin spinning={loadingView}>
      <Form
        form={form}
        layout="vertical"
        className="p-4 select-none"
        onFinish={async (values: UpdateContractType) => {
          values.date = dayjs(values.date).format("YYYY-MM-DD");
          values.expiration_date = contractType === "R" ? "" : dayjs(values.expiration_date).format("YYYY-MM-DD");
          const isSuccess = await updateContract(contractCuid, contractType, values);
          if (isSuccess) {
            onClose();
          }
        }}
        onValuesChange={(changedValues: UpdateContractType, values: UpdateContractType) => {
          if (changedValues.date) {
            setDate(changedValues.date);
          }
        }}
      >
        <Form.Item<UpdateContractType>
          name="description"
          label="Descrição"
          rules={[
            {
              required: true,
              message: "Por favor, preencha a descrição.",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <div className="flex gap-2">
          <Form.Item<UpdateContractType>
            className="w-48"
            name="date"
            label="Data"
            rules={[
              {
                required: true,
                message: "Por favor, selecione a data.",
              },
            ]}
            getValueProps={(value) => ({ value: value ? dayjs(value) : "" })}
            normalize={(value) => (value ? dayjs(value).format("YYYY-MM-DD") : "")}
          >
            <DatePicker format="DD/MM/YYYY" />
          </Form.Item>

          <Form.Item<UpdateContractType>
            hidden={contractType === "R"}
            name="expiration_date"
            label="Data de vencimento"
            rules={[
              {
                required: contractType !== "R",
                message: "Por favor, selecione a data de vencimento.",
              },
            ]}
            getValueProps={(value) => ({ value: value ? dayjs(value) : "" })}
            normalize={(value) => (value ? dayjs(value).format("YYYY-MM-DD") : "")}
          >
            <DatePicker minDate={dayjs(date)} format="DD/MM/YYYY" />
          </Form.Item>
        </div>
        <Form.Item<UpdateContractType> name="renovation" label="Renovável">
          <Radio.Group
            optionType="button"
            buttonStyle="solid"
            defaultValue={false}
            options={[
              { label: "Sim", value: true },
              { label: "Não", value: false },
            ]}
          />
        </Form.Item>
        <Form.Item>
          <div className="text-right">
            <Space size="small">
              <Button type="default" danger onClick={() => onClose()}>
                Cancelar
              </Button>

              <Button loading={loadingUpdate} type="primary" htmlType="submit">
                Atualizar
              </Button>
            </Space>
          </div>
        </Form.Item>
      </Form>
    </Spin>
  );
}

export default UpdateContractForm;
