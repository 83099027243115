export const channelsNotification = [
  {
    label: "Email",
    value: "E",
  },
  {
    label: "Sistema",
    value: "S",
  },
];

export const systemOrderTypes = [
  {
    label: "Ordem de serviço",
    value: "SVC",
  },
  {
    label: "Ordem de protocolo",
    value: "PTC",
  },
  {
    label: "Ordem de Material",
    value: "MAT",
  },
  {
    label: "Ordem de implantação",
    value: "DPL",
  },
  {
    label: "Ordem especial",
    value: "SPE",
  },
];