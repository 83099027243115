import { Icon } from "@iconify/react";
import { Button, Form, Select, Space, Spin, Tooltip } from "antd";

import { useContext, useEffect, useState } from "react";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import D3Can from "@components/shared/D3Can";
import { useDeleteDocumentTypeIndexers } from "./store";
import {
  DeleteIndexerType,
  ItemDocumentTypesType,
  ItemIndexerType,
} from "./types";

function DeleteDocumentTypeIndexers() {
  const { simpleListDocumentType, simpleListIndexes, deleteIndexers } =
    useDeleteDocumentTypeIndexers();
  const [loading, setLoading] = useState<boolean>(true);

  const { onClose } = useContext(D3DrawerContext);
  const [form] = Form.useForm<DeleteIndexerType>();
  const [documentTypes, setDocumentTypes] = useState<ItemDocumentTypesType[]>(
    []
  );
  const [loadingIndexers, setLoadingIndexers] = useState<boolean>(false);
  const [indexers, setIndexers] = useState<ItemIndexerType[]>([]);

  useEffect(() => {
    (async () => {
      const result = await simpleListDocumentType();
      setDocumentTypes(result);
      setLoading(false);
    })();
  }, [simpleListDocumentType]);

  return (
    <Spin spinning={loading}>
      <Form
        className="select-none"
        form={form}
        layout="vertical"
        onFinish={async (payload: DeleteIndexerType) => {
          setLoading(true);
          const result = await deleteIndexers(
            payload.document_type_cuid,
            payload.document_type_index_id
          );
          if (result.code === 200) {
            onClose();
          }
          setLoading(false);
        }}
        autoComplete="off"
      >
        <Form.Item<DeleteIndexerType>
          name="document_type_cuid"
          label="Tipo de documento"
          rules={[
            {
              required: true,
              message: "Por favor, selecione o tipo de documento.",
            },
          ]}
        >
          <Select
            listItemHeight={10}
            listHeight={250}
            allowClear
            removeIcon={
              <Icon
                icon="iconamoon:close-duotone"
                className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
              />
            }
            menuItemSelectedIcon={
              <Icon
                icon="eva:checkmark-outline"
                className="w-5 h-5 text-blue-500"
              />
            }
            optionLabelProp="dataLabel"
            showSearch
            maxTagCount="responsive"
            size="middle"
            className="w-full truncate select-none"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.dataFilter.toLowerCase().includes(input.toLowerCase())
            }
            onChange={async (e) => {
              form.setFieldValue("document_type_index_id", null);
              setLoadingIndexers(true);
              const indexers = await simpleListIndexes(e);
              setIndexers(indexers);
              setLoadingIndexers(false);
            }}
          >
            {documentTypes.map(({ active, cuid, description }) => (
              <Select.Option
                key={cuid}
                value={cuid}
                dataFilter={`${description}`}
                dataLabel={`${description}`}
              >
                <div className="flex items-center justify-between ">
                  <div className="flex flex-col truncate">
                    <span className="truncate">{description}</span>
                  </div>
                  <span>
                    {active ? (
                      <Tooltip title="Usuário desbloqueado" placement="right">
                        <Icon
                          icon="basil:unlock-solid"
                          className="text-green-500"
                          height={16}
                          width={16}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Usuário bloqueado" placement="right">
                        <Icon
                          icon="basil:lock-solid"
                          className="text-red-500"
                          height={16}
                          width={16}
                        />
                      </Tooltip>
                    )}
                  </span>
                </div>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item<DeleteIndexerType>
          name="document_type_index_id"
          label="Indexador"
          rules={[
            {
              required: true,
              message: "Por favor, selecione o indexador.",
            },
          ]}
        >
          <Select
            disabled={indexers.length === 0 || loadingIndexers}
            listItemHeight={10}
            listHeight={250}
            allowClear
            loading={loadingIndexers}
            removeIcon={
              <Icon
                icon="iconamoon:close-duotone"
                className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
              />
            }
            menuItemSelectedIcon={
              <Icon
                icon="eva:checkmark-outline"
                className="w-5 h-5 text-blue-500"
              />
            }
            optionLabelProp="dataLabel"
            showSearch
            maxTagCount="responsive"
            size="middle"
            className="w-full truncate select-none"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.dataFilter.toLowerCase().includes(input.toLowerCase())
            }
          >
            {indexers.map(({ id, label, system_index_type }) => (
              <Select.Option
                key={id}
                value={id}
                dataFilter={`${label}  Tipo: ${system_index_type.key}`}
                dataLabel={`${label}   -   Tipo: ${system_index_type.key}`}
              >
                <div className="flex items-center justify-between ">
                  <div className="flex flex-col truncate">
                    <span className="truncate">{label}</span>
                  </div>
                  <span>{`Tipo: ${system_index_type.key}`}</span>
                </div>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <div className="text-right">
            <Space size="small">
              <Button type="dashed" danger onClick={() => onClose()}>
                Cancelar
              </Button>
              <D3Can action="manage_password" resource="system_user">
                <Button danger type="primary" htmlType="submit">
                  Excluir
                </Button>
              </D3Can>
            </Space>
          </div>
        </Form.Item>
      </Form>
    </Spin>
  );
}

export default DeleteDocumentTypeIndexers;
