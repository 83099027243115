import { Form, Input } from "antd";

type ItemTXTProps = {
  name: number;
};

function ItemTXT({ name, ...props }: ItemTXTProps) {
  return (
    <Form.Item {...props} className="w-full p-0 m-0" name={[name, "value"]}>
      <Input />
    </Form.Item>
  );
}

export default ItemTXT;
