import { create } from "zustand";

import { BadRequest, ClientHttp, StandardError, Success } from "@utils/clientHttp";
import showMessage from "@utils/showMessage";
import { ForgotPasswordType, ResponseApiType } from "./types";

type ForgotPasswordStoreState = {
  forgotPassword: (data: ForgotPasswordType) => Promise<boolean>;
  loadingForgot: boolean;
};

export const useForgotPasswordStore = create<ForgotPasswordStoreState>((set, get) => ({
  loadingForgot: false,
  forgotPassword: async (data: ForgotPasswordType): Promise<boolean> => {
    set({ loadingForgot: true });
    const result = await new ClientHttp().patch<
      ForgotPasswordType,
      Success<ResponseApiType>,
      BadRequest | StandardError
    >(
      `/api/v1/system/user/forgot-password`,
      data,
      (result: Success<ResponseApiType>) => {
        showMessage(
          result,
          "Uma senha temporária foi enviada para o seu e-mail cadastrado. Por favor, verifique sua caixa de entrada para prosseguir com o acesso.",
          5
        );
        set({ loadingForgot: false });
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
        set({ loadingForgot: false });
      }
    );
    return result.status === "success";
  },
}));
