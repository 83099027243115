import { Form, Input, Modal } from "antd";
import { useState } from "react";
import { useParams } from "react-router-dom";
import useFolderStore from "./store";
import { CreateFolderType } from "./types";

export const CreateFolderFormModal = () => {
  const { openModal, createFolder } = useFolderStore();
  const [form] = Form.useForm<CreateFolderType>();
  const [loading, setLoading] = useState<boolean>(false);
  const { cuid } = useParams<{ cuid?: string }>();

  return (
    <Modal
      open={openModal}
      title="Cadastrar nova pasta"
      okText="Cadastrar"
      cancelText="Cancelar"
      onCancel={() => {
        useFolderStore.setState({ openModal: false });
        form.resetFields();
      }}
      okButtonProps={{
        loading: loading,
      }}
      onOk={() => form.submit()}
    >
      <Form
        form={form}
        layout="vertical"
        disabled={loading}
        onFinish={async (payload: CreateFolderType) => {
          setLoading(true);
          payload.folder_parent_cuid = cuid;
          const result = await createFolder(payload);
          if (result) {
            form.resetFields();
            useFolderStore.setState({ openModal: false });
          }
          setLoading(false);
        }}
      >
        <Form.Item
          name="folder_name"
          label="Nome da pasta"
          rules={[{ required: true, message: "Por favor, insira o nome da pasta." }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
