import { Icon } from "@iconify/react";
import { Tabs } from "antd";
import DefaultPrice from "../prices/default";

function TabPrices() {
	return (
		<div className="flex flex-col h-full overflow-hidden bg-white">
			<Tabs
				tabPosition="top"
				defaultActiveKey="1"
				className="flex h-full overflow-hidden select-none"
				items={[
					{
						key: "1",
						label: (
							<span className="flex items-center justify-center gap-2">
								<Icon icon="ic:baseline-price-change" width={18} height={18} />
								Geral
							</span>
						),
						children: <DefaultPrice />,
					},
				]}
			/>
		</div>
	);
}

export default TabPrices;
