import D3Can from "@components/shared/D3Can";
import { Icon } from "@iconify/react";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { Button, Form, Input, Select, Space, Spin, Tooltip } from "antd";
import { useContext, useEffect } from "react";
import { useManageStore } from "./store";
import { ChangePasswordType } from "./types";

function ChangeUserPassword() {
  const { simpleListUserSystem, listUser, loadingList, changePassword, loadingChange } = useManageStore();
  const { onClose } = useContext(D3DrawerContext);
  const [form] = Form.useForm<ChangePasswordType & { user_cuid: string }>();

  useEffect(() => {
    (async () => {
      await simpleListUserSystem();
    })();
  }, [simpleListUserSystem]);

  return (
    <Spin spinning={loadingList}>
      <Form
        className="select-none"
        form={form}
        layout="vertical"
        onFinish={async (payload: ChangePasswordType & { user_cuid: string }) => {
          const isUpdated = await changePassword(payload, payload.user_cuid);
          if (isUpdated) {
            onClose();
          }
        }}
        autoComplete="off"
      >
        <Form.Item
          name="user_cuid"
          label="Usuários"
          rules={[
            {
              required: true,
              message: "Por favor, selecione o usuário.",
            },
          ]}
        >
          <Select
            listItemHeight={10}
            listHeight={250}
            allowClear
            removeIcon={
              <Icon
                icon="iconamoon:close-duotone"
                className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
              />
            }
            menuItemSelectedIcon={<Icon icon="eva:checkmark-outline" className="w-5 h-5 text-blue-500" />}
            optionLabelProp="dataLabel"
            showSearch
            maxTagCount="responsive"
            size="middle"
            className="w-full truncate select-none"
            optionFilterProp="children"
            filterOption={(input, option) => option?.dataFilter.toLowerCase().includes(input.toLowerCase())}
          >
            {listUser.map(({ active, cuid, login, name }) => (
              <Select.Option key={cuid} value={cuid} dataFilter={`${name} ${login}`} dataLabel={`${name}`}>
                <div className="flex items-center justify-between ">
                  <div className="flex flex-col truncate">
                    <span className="truncate">{name}</span>
                    <span className="text-[10px] truncate">{login}</span>
                  </div>
                  <span>
                    {active ? (
                      <Tooltip title="Usuário desbloqueado" placement="right">
                        <Icon icon="basil:unlock-solid" className="text-green-500" height={16} width={16} />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Usuário bloqueado" placement="right">
                        <Icon icon="basil:lock-solid" className="text-red-500" height={16} width={16} />
                      </Tooltip>
                    )}
                  </span>
                </div>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item<ChangePasswordType>
          name="new_password"
          label="Nova senha"
          rules={[
            {
              required: true,
              message: "Por favor, digite sua nova senha.",
            },
            {
              min: 6,
              message: "A senha deve ter no mínimo 6 caracteres.",
            },
          ]}
          hasFeedback
        >
          <Input.Password className="w-64" autoComplete="new-password" />
        </Form.Item>

        <Form.Item<ChangePasswordType>
          name="repeated_new_password"
          label="Confirme senha"
          dependencies={["new_password"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Por favor, confirme sua nova senha.",
            },
            {
              min: 6,
              message: "A senha deve ter no mínimo 6 caracteres.",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("new_password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("As senhas não são iguais."));
              },
            }),
          ]}
        >
          <Input.Password className="w-64" autoComplete="repeat-new-password" />
        </Form.Item>
        <Form.Item>
          <div className="text-right">
            <Space size="small">
              <Button disabled={loadingChange} type="default" danger onClick={() => onClose()}>
                Cancelar
              </Button>
              <D3Can action="manage_password" resource="system_user">
                <Button loading={loadingChange} type="primary" htmlType="submit">
                  Atualizar
                </Button>
              </D3Can>
            </Space>
          </div>
        </Form.Item>
      </Form>
    </Spin>
  );
}

export default ChangeUserPassword;
