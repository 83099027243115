import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
const resources = {
  en: {
    translation: require("./locales/en/translation.json"),
  },
  pt: {
    translation: require("./locales/pt/translation.json"),
  },
};
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'pt',
    resources,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
