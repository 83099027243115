import { DatePicker, Form } from "antd";
import dayjs from "dayjs";

type ItemDATProps = {
  name: number;
};

function ItemDAT({ name, ...props }: ItemDATProps) {
  return (
    <Form.Item
      getValueProps={(value) => ({ value: value ? dayjs(value) : null })}
      normalize={(value) => value ? dayjs(value).format("YYYY-MM-DD"): ""}
      {...props}
      className="p-0 m-0 w-52"
      name={[name, "value"]}
    >
      <DatePicker
        format={{
          format: "DD/MM/YYYY",
          type: "mask",
        }}
      />
    </Form.Item>
  );
}

export default ItemDAT;
