import { useEffect } from "react";
import { Icon } from "@iconify/react";
import D3Copy from "@utils/D3Copy";
import { Button, Form, Input, Pagination, Popconfirm, Segmented, Table, Tag, Tooltip } from "antd";
import { usePhysicalTagsStore } from "../store";
import { ColumnType, SearchPhysicalTagViewType } from "../types";
import D3Can from "@components/shared/D3Can";

type ViewPhysicalTagFormType = {
  physicalTagBatchCuid: string;
};
export const ViewPhysicalTagForm = ({ physicalTagBatchCuid }: ViewPhysicalTagFormType) => {
  const {
    viewPhysicalTag,
    apiResultView,
    paginationView,
    setPaginationView,
    loadingView,
    setFilterView,
    resetStateView,
    resetFilterView,
    deletionPhysicalTag,
  } = usePhysicalTagsStore();
  const [form] = Form.useForm<SearchPhysicalTagViewType>();

  useEffect(() => {
    (async () => {
      await viewPhysicalTag(physicalTagBatchCuid);
    })();
    return () => {
      resetStateView();
    };
  }, [viewPhysicalTag, physicalTagBatchCuid]);

  return (
    <Table
      className="select-none d3-table-h-full"
      loading={loadingView}
      pagination={false}
      size="small"
      bordered
      rowKey="key"
      dataSource={apiResultView.data.map((tag, index) => {
        return {
          key: index,
          tag: tag.id,
          in_use: tag.in_use,
          deleted: tag.deleted,
        } as ColumnType;
      })}
      title={() => (
        <Form
          className="flex w-full select-none"
          layout="inline"
          form={form}
          onFinish={async (values) => {
            setFilterView(values);
          }}
        >
          <div className="flex justify-between w-full">
            <div className="flex">
              <Form.Item<SearchPhysicalTagViewType> name="id" label="Código de etiqueta">
                <Input maxLength={12} className="w-[133px]" />
              </Form.Item>

              <Form.Item<SearchPhysicalTagViewType> initialValue={""} name="in_use" label="Em uso">
                <Segmented
                  options={[
                    { label: "Sim", value: "true" },
                    { label: "Não", value: "false" },
                    { label: "Todos", value: "" },
                  ]}
                />
              </Form.Item>
            </div>

            <Form.Item>
              <div className="flex w-full gap-2">
                <Button
                  type="default"
                  danger
                  onClick={() => {
                    form.resetFields();
                    resetFilterView(physicalTagBatchCuid);
                  }}
                >
                  Limpar
                </Button>
                <Button type="primary" htmlType="submit">
                  Filtrar
                </Button>
              </div>
            </Form.Item>
          </div>
        </Form>
      )}
      footer={() => (
        <div className="flex items-center justify-end w-full min-h-12">
          <Pagination
            className="flex items-center"
            showLessItems
            showTotal={(total, range) =>
              `Exibindo de ${range[0]} a ${range[1]} do total ${total} ${total > 1 ? "registros" : "registro"}`
            }
            showSizeChanger={false}
            simple
            current={paginationView.page}
            onChange={setPaginationView}
            pageSize={paginationView.pageSize}
            total={apiResultView.total}
          />
        </div>
      )}
    >
      <Table.Column<ColumnType>
        showSorterTooltip={false}
        key="tag"
        title="Código da etiqueta"
        dataIndex="tag"
        render={(value, record) => (
          <div className="flex items-center w-full gap-2">
            <span>{value}</span>
            <Button
              className="peer"
              type="link"
              shape="default"
              color="#3b82f6"
              icon={<Icon icon="fluent:copy-32-filled" height={18} width={18} />}
              size="middle"
              onClick={() => D3Copy({ text: record.tag, success: "Código copiado! " })}
            />
          </div>
        )}
      />
      <Table.Column<ColumnType>
        showSorterTooltip={false}
        key="in_use"
        title="Em uso | Excluído"
        dataIndex="in_use"
        render={(value, record) => {
          return (
            <div className="flex gap-1">
              <Tag
                className="flex items-center gap-1 mr-2 max-w-max"
                icon={<Icon icon={`${record.in_use ? "entypo:check" : "ep:close-bold"}`} />}
                color={`${record.in_use ? "success" : "warning"}`}
              >
                {record.in_use ? "Sim" : "Não"}
              </Tag>
              {record.deleted && (
                <Tag
                  className="flex items-center gap-1 mr-2 max-w-max"
                  icon={<Icon height={16} icon="material-symbols:delete" />}
                  color="error"
                >
                  Excluído
                </Tag>
              )}
            </div>
          );
        }}
      />
      <Table.Column<ColumnType>
        showSorterTooltip={false}
        key="action"
        title="Ação"
        align="center"
        dataIndex="action"
        render={(value, record) => {
          return (
            <div className="flex items-center justify-center">
              <D3Can action="delete" resource="customer_physical_tag">
                <Popconfirm
                  title="Atenção!"
                  description="Confirmar exclusão da etiquetas?"
                  onConfirm={() => deletionPhysicalTag(physicalTagBatchCuid, record.tag)}
                  okText="Sim"
                  cancelText="Não"
                >
                  <Tooltip
                    title={
                      record.deleted
                        ? "Código de etiqueta excluído."
                        : record.in_use
                        ? "Não é possível excluir. A etiqueta está em uso."
                        : ""
                    }
                  >
                    <Button
                      disabled={record.deleted || record.in_use}
                      className="peer"
                      type="text"
                      danger
                      shape="default"
                      icon={<Icon icon="material-symbols:delete" height={18} width={18} />}
                      size={"middle"}
                    />
                  </Tooltip>
                </Popconfirm>
              </D3Can>
            </div>
          );
        }}
      />
    </Table>
  );
};
