import { useEffect } from "react";
import { Button, Form, Input, Radio, Space } from "antd";

import { useCGRCustomersStore } from "../store";
import { SearchCGRCustomerType } from "../types";

type SearchCGRCustomerFormProps = {
  onCallBackFilter: () => void;
  onCallBackResetFilter: () => void;
};

export const SearchCGRCustomerForm = ({
  onCallBackFilter,
  onCallBackResetFilter,
}: SearchCGRCustomerFormProps) => {
  const [form] = Form.useForm<SearchCGRCustomerType>();
  const { filter, setFilter } = useCGRCustomersStore();

  useEffect(() => {
    form.setFieldsValue(filter);
  }, [form, filter]);

  return (
    <>
      <Form
        className="select-none"
        layout="vertical"
        form={form}
        onFinish={async (values) => {
          if (form.isFieldsTouched(true)) {
            setFilter(values);
          }
          onCallBackFilter();
        }}
        autoComplete="off"
      >
        <Form.Item<SearchCGRCustomerType>
          name="cus_id"
          label="Código do cliente"
          rules={[
            {
              validator: (_, value) => {
                if (value && value !== "") {
                  return /^\d+$/.test(value)
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error("O código deve conter apenas números.")
                      );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item<SearchCGRCustomerType>
          name="cus_fantasy_name"
          label="Nome Fantasia"
        >
          <Input />
        </Form.Item>
        <Form.Item<SearchCGRCustomerType> name="cus_name" label="Razão Social">
          <Input />
        </Form.Item>
        <Form.Item<SearchCGRCustomerType> name="cus_active" label="Status">
          <Radio.Group value={filter.cus_active}>
            <Radio value={"off"}>Bloqueados</Radio>
            <Radio value={"on"}>Desbloqueados</Radio>
            <Radio value={"all"}>Todos</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item>
          <div className="text-right">
            <Space size="small">
              <Button type="default" danger onClick={onCallBackResetFilter}>
                Limpar Filtro
              </Button>
              <Button type="primary" htmlType="submit">
                Filtrar
              </Button>
            </Space>
          </div>
        </Form.Item>
      </Form>
    </>
  );
};
