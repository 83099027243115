import useAuthStore from "@provider/AuthProvider";
import { Menu } from "antd";
import Sider from "antd/es/layout/Sider";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import logoL from "../../../assets/images/gedocs.png";
import logoC from "../../../assets/images/logoc.png";
import { useD3LayoutStore } from "../Store/useD3LayoutStore";
import { MenuItem, getMenuItems } from "./D3CompanyMenuItens";

const rootSubmenuKeys = ["menu-customer", "menu-customer-group", "menu-company", "menu-system", "menu-manage"];

const D3SiderBar = () => {
  const navigate = useNavigate();
  const { collapsed } = useD3LayoutStore();
  const { getAbility, getUserInfo, token } = useAuthStore();

  const [menuItens, setMenuItens] = useState<MenuItem[]>([]);
  const [openKeys, setOpenKeys] = useState([""]);

  useEffect(() => {
    if (token !== undefined && token.length > 0) {
      const userInfo = getUserInfo();
      if (userInfo !== undefined && userInfo !== null) {
        setMenuItens(getMenuItems(getUserInfo().Type, navigate, getAbility()));
      }
    }
  }, [token, navigate, getAbility, getUserInfo]);

  const handleItemClick = (onClick: any) => {
    if (typeof onClick === "function") {
      onClick();
    } else if (typeof onClick === "string") {
      navigate(onClick);
    }
  };

  const renderMenuItems = (items: any) => {
    return items.map((item: any) => {
      if (item.children && item.children.length > 0) {
        return {
          key: item.key,
          className: "select-none",
          icon: item.icon,
          label: item.label,
          disabled: item.disable,
          children: renderMenuItems(item.children),
          onClick: () => item.onClick && handleItemClick(item.onClick),
        };
      }
      return {
        key: item.key,
        icon: item.icon,
        className: "select-none",
        label: item.label,
        disabled: item.disable,
        onClick: () => item.onClick && handleItemClick(item.onClick),
      };
    });
  };

  const onOpenChange = (keys: any) => {
    const latestOpenKey = keys.find((key: any) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  return (
    <Sider
      trigger={null}
      collapsible
      collapsedWidth={50}
      collapsed={collapsed}
      width={250}
      style={{ background: "#FFF" }}
      className="m-1 rounded shadow select-none"
    >
      <div className="h-full overflow-hidden bg-white rounded max-h-max">
        <div className="flex flex-col h-full max-h-max ">
          <div className={`h-[58px] flex justify-center items-center ${collapsed ? `w-8 ml-2` : ``}`}>
            <img src={collapsed ? logoC : logoL} alt="Gedocs" className="h-8" />
          </div>

          <div className="flex flex-col overflow-y-auto select-none scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100">
            <Menu
              className="rounded select-none"
              mode="inline"
              theme="light"
              triggerSubMenuAction="click"
              openKeys={openKeys}
              onOpenChange={onOpenChange}
              items={renderMenuItems(menuItens)}
            />
          </div>
        </div>
      </div>
    </Sider>
  );
};

export default D3SiderBar;
