import { diff } from "deep-diff";

export default function hasObjectChanges(objeto1: any, objeto2: any): boolean {
  // Criar um objeto parcial de objeto1, com as mesmas propriedades de objeto2
  const partialObject1 = Object.keys(objeto2).reduce((result: { [key: string]: any }, key: string) => {
    if (objeto1.hasOwnProperty(key)) {
      result[key] = objeto1[key];
    }
    return result;
  }, {});

  // Comparar as propriedades de objeto2 com as correspondentes em partialObject1
  const differences = diff(partialObject1, objeto2);

  // Retornar true se houver alterações, false caso contrário
  return !!differences;
}
