import { Icon } from "@iconify/react";
import { Badge, Button, Form, Input, List, Radio, Select } from "antd";
import { NamePath } from "antd/es/form/interface";
import { FormInstance } from "antd/lib";
import { useEffect, useState } from "react";
import { SearchPhysicalDocumentType } from "../types";
import { useSpecialsStore } from "../store";

type FormItemSwitcherProps = {
  attribute: NamePath | NamePath[];
  label: string;
  form: FormInstance<SearchPhysicalDocumentType>;
};
const { TextArea } = Input;

function FormItemSwitcher({ attribute, label, form }: FormItemSwitcherProps): JSX.Element {
  const { filter } = useSpecialsStore();
  const [isMultiple, setIsMultiple] = useState<boolean>(false);
  const [documentsCuid, setDocumentsCuid] = useState<string[]>([]);
  const [textArea, setTextArea] = useState<string[]>([]);
  const [input, setInput] = useState<string>("");

  const removeItem = (index: any) => {
    const newArray = [...documentsCuid];
    newArray.splice(index, 1);
    setDocumentsCuid(newArray);
  };

  const getFilterAttributesValues = (obj: SearchPhysicalDocumentType, path: NamePath) => {
    return (Array.isArray(path) ? path : [path]).reduce((acc, key) => acc && acc[key], obj);
  };

  useEffect(() => {
    setDocumentsCuid(getFilterAttributesValues(filter, attribute));
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      filter_document: {
        document_physical_tag_id: documentsCuid,
      },
    });
  }, [documentsCuid]);

  const validate = (input: string | string[]) => {
    const validateString = (str: string) => {
      const isValidLength = str.length === 12;
      const isNumeric = /^\d+$/.test(str);
      const isNotInDocumentsCuid = !documentsCuid.includes(str);
      return isValidLength && isNumeric && isNotInDocumentsCuid ? str : "";
    };

    if (Array.isArray(input)) {
      setDocumentsCuid([...input.map(validateString).filter(Boolean), ...documentsCuid]);
    } else {
      const result = validateString(input);
      if (result) {
        setDocumentsCuid([result as string, ...documentsCuid]);
      } else {
        setDocumentsCuid(documentsCuid);
      }
    }
  };

  return (
    <Form.Item label={label} className="w-full p-0 my-2">
      <div className="flex flex-col gap-3">
        <Form.Item hidden name={attribute} normalize={(value) => documentsCuid}>
          <Select
            mode="multiple"
            options={documentsCuid.map((item) => {
              return { label: item, value: item };
            })}
            suffixIcon={<></>}
          />
        </Form.Item>
        <Badge.Ribbon className="select-none" text={documentsCuid.length}>
          <List
            size="small"
            bordered
            className="overflow-y-auto select-none min-h-44 max-h-44 h-44 custom-scroll"
            dataSource={documentsCuid}
            renderItem={(item, index) => (
              <List.Item
                key={index}
                actions={[
                  <Button
                    size="small"
                    icon={<Icon height={18} icon="material-symbols:delete" />}
                    type="text"
                    danger
                    onClick={() => removeItem(index)}
                  />,
                ]}
              >
                {item}
              </List.Item>
            )}
          />
        </Badge.Ribbon>
        <Radio.Group
          className="select-none"
          defaultValue="single"
          onChange={(e) => setIsMultiple(e.target.value === "single" ? false : true)}
          buttonStyle="solid"
        >
          <Radio.Button value="single">Único</Radio.Button>
          <Radio.Button value="multiple">Múltiplos</Radio.Button>
        </Radio.Group>

        {!isMultiple ? (
          <Input
            showCount
            maxLength={12}
            minLength={12}
            value={input}
            onChange={(e) => {
              setInput(e.target.value.trim());
            }}
          />
        ) : (
          <TextArea
            rows={3}
            value={textArea.join("\n")}
            onChange={(e) => {
              const newValues = e.target.value.split("\n");
              const values = newValues.filter((item) => !documentsCuid.includes(item));
              setTextArea(values);
            }}
          />
        )}
        <div className="flex gap-3">
          <Button
            onClick={() => {
              if (isMultiple) {
                validate(textArea);
                setTextArea([]);
              } else {
                validate(input);
                setInput("");
              }
            }}
          >
            Adicionar
          </Button>
          <Button danger type="dashed" onClick={() => setDocumentsCuid([])}>
            Limpar lista
          </Button>
        </div>
      </div>
    </Form.Item>
  );
}

export default FormItemSwitcher;
