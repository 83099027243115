import { DatePicker, Form } from "antd";
import { IndexItemType } from "../physical/types";
import dayjs from "dayjs";

type ItemDATPropsType = {
  item: IndexItemType;
};

function ItemDAT({ item }: ItemDATPropsType) {
  const { id, label, required } = item;

  return (
    <>
      <Form.Item
        name={[id, "DAT", "value"]}
        label={label}
        getValueProps={(value) => ({ value: value ? dayjs(value) : null })}
        normalize={(value) => (value ? dayjs(value).format("YYYY-MM-DD") : null)}
        rules={[
          {
            required: required,
            message: `Por favor, preencha a data.`,
          },
        ]}
      >
        <DatePicker
          format={{
            format: "DD/MM/YYYY",
            type: "mask",
          }}
          className="min-w-[172px]" 
        />
      </Form.Item>
    </>
  );
}
export default ItemDAT;
