import { CreateFolderFormModal } from "./components/folder/CreateFolderFormModal";
import NavigateBody from "./components/navigate/NavigateBody";
import NavigateFooter from "./components/navigate/NavigateFooter";
import NavigateHeader from "./components/navigate/NavigateHeader";

function Navigate() {
  return (
    <div className="flex flex-col h-full mb-1 mr-1 overflow-hidden bg-white rounded shadow-md">
      <NavigateHeader />
      <NavigateBody />
      <NavigateFooter />
      <CreateFolderFormModal />
    </div>
  );
}

export default Navigate;
