import { Icon } from "@iconify/react";
import { Button, Tooltip } from "antd";
import { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDocumentTypesStore } from "../../store";
import { ListPreviewType, ListTypesType } from "../../types";
import ConfigForm from "../ConfigForm";
import { listPreview, listTypes } from "../ListInputs";

type CreateIndexPropsType = {
  inputs: ListTypesType[];
};

function CreateIndex({ inputs }: CreateIndexPropsType) {
  const [inputConfig, setInputConfig] = useState<ListPreviewType>();

  const { getIndexadores, setIndexadores } = useDocumentTypesStore();

  function clickSelectIndexer(item: ListTypesType) {
    const indexadores = getIndexadores();
    setIndexadores([
      ...indexadores,
      {
        label: item.label,
        order: indexadores.length + 1,
        system_index_type_id: item.id,
        default_search_option: item.default_search_option,
        end_interval_required: false,
        is_allowed_copy: false,
        is_global_index: false,
        list_of_key_and_value: [],
        required: false,
        search_key: false,
        size: 0,
        id: undefined,
        start_interval_required: false,
        temporality_date: false,
        default_input: item,
      },
    ]);
  }

  function clickDeleteIndexer(item: ListPreviewType) {
    const listIndexes = getIndexadores().filter((index) => index.order !== item.order);
    const orderIndexes = listIndexes.map((indexador, i) => {
      return {
        ...indexador,
        order: i + 1,
      };
    });
    setIndexadores(orderIndexes);
  }

  function clickConfigureIndexer(item: ListPreviewType) {
    setInputConfig(item);
  }

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    const newItems = Array.from(getIndexadores());
    const [reorderedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, reorderedItem);
    newItems.forEach((item, index) => {
      item.order = index + 1;
    });
    setIndexadores(newItems);
  };

  return (
    <div className="flex mb-2 border rounded-md select-none">
      <div className="grid w-full h-full grid-cols-12 divide-x">
        <div className="relative w-full h-full col-span-3 px-2 ">
          <div className="flex items-center justify-center border-b-[1px] py-2  bg-white ">
            <span className="text-base font-semibold text-gray-500">Tipos de indexadores</span>
          </div>

          <ul className="flex flex-col divide-y ">
            {inputs.map((item) => (
              <li key={item.id} className="flex items-center justify-between gap-2 py-2">
                {listTypes(item)}
                <Tooltip trigger="hover" title="Adicionar">
                  <Button
                    type="link"
                    icon={
                      <Icon
                        height={20}
                        className="text-blue-400 transition-all duration-200 ease-in cursor-pointer hover:scale-110 hover:text-blue-500"
                        icon="icons8:plus"
                        onClick={() => {
                          clickSelectIndexer(item);
                          const items = getIndexadores();
                          setInputConfig(items[items.length === 0 ? 0 : items.length - 1]);
                        }}
                      />
                    }
                  />
                </Tooltip>
              </li>
            ))}
          </ul>
        </div>
        <div className="w-full col-span-6 px-2 ">
          <div className="flex items-center justify-center border-b-[1px] py-2">
            <span className="text-base font-semibold text-gray-500">Pré-visualização do formulário</span>
          </div>

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <ul className="flex flex-col" {...provided.droppableProps} ref={provided.innerRef}>
                  {getIndexadores().map((item, index) => (
                    <Draggable key={item.order} draggableId={String(item.order)} index={index}>
                      {(provided) => (
                        <li
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          key={item.order}
                          className={`flex items-center transition-all duration-150 ease-in justify-between gap-2 py-2 rounded hover:bg-gray-50 ${
                            item.order === inputConfig?.order ? "bg-gray-50" : "bg-white "
                          }`}
                        >
                          <div className="flex items-center gap-3 ">
                            <Tooltip trigger="hover" title="Ordenar">
                              <Button
                                type="link"
                                icon={
                                  <Icon
                                    height={18}
                                    className="text-blue-400 transition-all duration-200 ease-incursor-pointer hover:scale-110 hover:text-blue-500"
                                    icon="ooui:draggable"
                                  />
                                }
                                onClick={() => clickConfigureIndexer(item)}
                              />
                            </Tooltip>
                            {listPreview(item)}
                          </div>
                          <div className="flex items-center ">
                            <Tooltip trigger="hover" title="Excluir">
                              <Button
                                type="link"
                                icon={
                                  <Icon
                                    height={18}
                                    className="text-red-400 transition-all duration-200 ease-in cursor-pointer hover:scale-110 hover:text-red-500"
                                    icon="material-symbols:delete"
                                  />
                                }
                                onClick={() => clickDeleteIndexer(item)}
                              />
                            </Tooltip>
                            <Tooltip trigger="hover" title="Configurar">
                              <Button
                                type="link"
                                icon={
                                  <Icon
                                    height={16}
                                    className="text-blue-400 transition-all duration-200 ease-in cursor-pointer hover:scale-110 hover:text-blue-500"
                                    icon="mynaui:config"
                                  />
                                }
                                onClick={() => clickConfigureIndexer(item)}
                              />
                            </Tooltip>
                          </div>
                        </li>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext>
        </div>
        <div className="col-span-3 px-2">
          <div className="flex items-center justify-center border-b-[1px] py-2">
            <span className="text-base font-semibold text-gray-500">Configuração do indexador</span>
          </div>
          <ConfigForm config={inputConfig ? inputConfig : undefined} />
        </div>
      </div>
    </div>
  );
}

export default CreateIndex;
