import { useContext, useEffect, useState } from "react";

import { Button, Form, Space, Spin, Tabs } from "antd";

import { Icon } from "@iconify/react";
import D3Can from "@components/shared/D3Can";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import showMessage from "@utils/showMessage";
import { useDocumentTypesStore } from "../../../store";
import { DocumentTypeIndex, ViewDocumentTypeType } from "../../../types";
import { TabUpdate } from "../../update/tabs/TabUpdate";
import CreateData from "../ViewData";
import CreateIndex from "../ViewIndex";

type ViewDocumentTypeFormProps = {
  cuid: string;
};

export const TabView = ({ cuid }: ViewDocumentTypeFormProps) => {
  const { getActiveTab, setActiveTab, getDocumentType, resetStateDrawer } =
    useDocumentTypesStore();
  const [loading, setLoading] = useState(true);
  const [docType, setDocType] = useState<ViewDocumentTypeType>();
  const { onClose, openDrawer } = useContext(D3DrawerContext);
  useEffect(() => {
    (async () => {
      const result = await getDocumentType(cuid);
      if (result.status === "success") {
        const viewDocumentType = result.body as ViewDocumentTypeType;
        setDocType(viewDocumentType);
        setLoading(false);
      } else {
        showMessage(result);
      }
    })();
    return () => {
      resetStateDrawer();
    };
  }, [cuid, getDocumentType, setActiveTab]);

  return (
    <>
      <Spin spinning={loading}>
        <Tabs
          tabPosition="top"
          defaultActiveKey="1"
          activeKey={getActiveTab()}
          onTabClick={(tab) => {
            setActiveTab(tab);
          }}
          className="flex h-full overflow-hidden select-none"
          items={[
            {
              key: "1",
              label: (
                <Button
                  className="flex items-center"
                  type="link"
                  icon={<Icon icon="ri:profile-line" />}
                  size="small"
                >
                  Dados
                </Button>
              ),
              children: <CreateData data={docType as ViewDocumentTypeType} />,
            },
            {
              key: "2",
              label: (
                <Button
                  className="flex items-center"
                  type="link"
                  icon={<Icon icon="dashicons:index-card" />}
                  size="small"
                >
                  Indexadores
                </Button>
              ),
              children: (
                <CreateIndex
                  indexes={
                    docType?.document_type_indexes as DocumentTypeIndex[]
                  }
                />
              ),
            },
          ]}
        />
        <Form.Item>
          <div className="mr-3 text-right">
            <Space size="small">
              <Button type="default" danger onClick={() => onClose()}>
                Cancelar
              </Button>
              <D3Can action="update" resource="customer_document_type">
                <Button
                  type="primary"
                  onClick={() =>
                    openDrawer(
                      <TabUpdate cuid={cuid} />,
                      "right",
                      600,
                      "Editar Tipo de Documento"
                    )
                  }
                >
                  Editar
                </Button>
              </D3Can>
            </Space>
          </div>
        </Form.Item>
      </Spin>
    </>
  );
};
