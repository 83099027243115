import { Icon } from "@iconify/react";
import { ItemDocumentTypeType, PayloadDocumentType } from "@views/customer/document-registration/digital/types";
import { DatePicker, Form, Radio, Select, Tooltip } from "antd";
import dayjs from "dayjs";

type DocumentLifeCycleManagerProps = {
  loading: boolean;
  documentTypes: ItemDocumentTypeType[];
  payload: Partial<PayloadDocumentType>;
  documentTypeSelected: ItemDocumentTypeType;
  layout?: "vertical" | "horizontal";
  disabled?: boolean;
};

function DocumentLifeCycleManager({
  loading,
  documentTypes,
  payload,
  documentTypeSelected,
  layout = "vertical",
  disabled = false,
}: DocumentLifeCycleManagerProps) {
  return (
    <>
      <Form.Item<Partial<PayloadDocumentType>>
        name="document_type_cuid"
        label="Tipo de Documento"
        rules={[
          {
            required: true,
            message: "Por favor, selecione o tipo de documento.",
          },
        ]}
      >
        <Select
          loading={loading}
          listItemHeight={10}
          allowClear
          disabled={disabled}
          removeIcon={
            <Icon
              icon="iconamoon:close-duotone"
              className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
            />
          }
          menuItemSelectedIcon={<Icon icon="eva:checkmark-outline" className="w-5 h-5 text-blue-500" />}
          optionLabelProp="dataLabel"
          showSearch
          maxTagCount="responsive"
          size="middle"
          className="w-full truncate select-none"
          optionFilterProp="children"
          filterOption={(input, option) => option?.dataFilter.toLowerCase().includes(input.toLowerCase())}
        >
          {documentTypes.map(({ active, cuid, description, temporality_permanent_guard, temporality_year }) => (
            <Select.Option
              disabled={!active}
              key={cuid}
              value={cuid}
              dataFilter={`${description}`}
              dataLabel={`${description} - ${
                temporality_permanent_guard === true
                  ? "Tempo indeterminado"
                  : `${temporality_year} ${temporality_year > 1 ? "Anos" : "Ano"}`
              }`}
            >
              <div className="flex items-center justify-between ">
                <div className="flex flex-col truncate">
                  <span className="truncate">{`${description} - ${
                    temporality_permanent_guard === true
                      ? "Tempo indeterminado"
                      : `${temporality_year} ${temporality_year > 1 ? "Anos" : "Ano"}`
                  }`}</span>
                </div>
                <span>
                  {active ? (
                    <Tooltip title="Tipo de documento desbloqueado" placement="right">
                      <Icon icon="basil:unlock-solid" className="text-green-500" height={16} width={16} />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Tipo de documento bloqueado" placement="right">
                      <Icon icon="basil:lock-solid" className="text-red-500 " height={16} width={16} />
                    </Tooltip>
                  )}
                </span>
              </div>
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      {payload.document_type_cuid && documentTypeSelected && (
        <div className={`flex ${layout === "vertical" ? "flex-col" : "flex-row gap-2"}`}>
          <Form.Item<Partial<PayloadDocumentType>> name="is_permanent_storage" label="Guarda permanente?">
            <Radio.Group
              disabled={documentTypeSelected.temporality_permanent_guard }
              options={[
                { label: "Sim", value: true },
                { label: "Não", value: false },
              ]}
              optionType="button"
              buttonStyle="solid"
              size="small"
              className="select-none"
            />
          </Form.Item>

          <Form.Item<Partial<PayloadDocumentType>>
            name="original_date"
            label="Data original"
            getValueProps={(value) => ({ value: value ? dayjs(value) : "" })}
            normalize={(value) => (value ? dayjs(value).format("YYYY-MM-DD") : "")}
          >
            <DatePicker format="DD/MM/YYYY"  />
          </Form.Item>

          <Form.Item<Partial<PayloadDocumentType>>
            name="expected_purge_date"
            label="Data previsão expurgo"
            getValueProps={(value) => ({ value: value ? dayjs(value) : "" })}
            normalize={(value) => (value ? dayjs(value).format("YYYY-MM-DD") : "")}
            rules={[
              {
                required: documentTypeSelected.is_temporality_mandatory && payload.is_permanent_storage === false,
                message: "Tipo de documento selecionado requer uma data previsão de expurgo.",
              },
            ]}
          >
            <DatePicker
              disabled={documentTypeSelected.temporality_permanent_guard || payload.is_permanent_storage }
              format="DD/MM/YYYY"
            />
          </Form.Item>
        </div>
      )}
    </>
  );
}

export default DocumentLifeCycleManager;
