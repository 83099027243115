import React, { memo } from "react";
import { Icon } from "@iconify/react";
import { Breadcrumb, Button, Dropdown } from "antd";
import { useNavigate } from "react-router-dom";
import { ItemsType } from "../ItemsType";
import useBreadCrumbStore from "./store";

const D3BreadCrumb = () => {
  const navigate = useNavigate();
  const { breadCrumb } = useBreadCrumbStore();

  const handlerNavigate = (cuid: string) => {
    navigate(`/app/customer/spaces/work/${cuid}`);
  };

  return (
    <Breadcrumb
      separator={
        <div className="flex items-center justify-center h-6 ">
          <Icon height={14} icon="ep:arrow-right-bold" />
        </div>
      }
      items={
        breadCrumb.length >= 3
          ? [
              {
                title: (
                  <Dropdown
                    placement="bottomLeft"
                    menu={{
                      items: breadCrumb.slice(0, breadCrumb.length - 2).map((item: any) => ({
                        label: String(item.name),
                        key: item.cuid,
                        onClick: () => handlerNavigate(item.cuid),
                      })),
                    }}
                  >
                    <Button
                      shape="default"
                      type="dashed"
                      className="flex items-center justify-center"
                      size="small"
                      icon={<Icon height={10} icon="charm:menu-kebab" />}
                    />
                  </Dropdown>
                ),
              },
              ...breadCrumb
                .slice(breadCrumb.length > 2 ? breadCrumb.length - 2 : 0)
                .map((item: any, index: number) => ({
                  title:
                    index === 1 ? (
                      <Dropdown.Button
                        type="dashed"
                        placement="bottomLeft"
                        size="small"
                        menu={{
                          items: ItemsType({
                            cuid: item.cuid,
                            name: item.name,
                            type: item.type,
                            menu: "HDR",
                          }) as any[],
                        }}
                      >
                        {item.name}
                      </Dropdown.Button>
                    ) : (
                      <Button
                        shape="default"
                        type="dashed"
                        size="small"
                        onClick={() => (index === breadCrumb.length - 1 ? null : handlerNavigate(item.cuid))}
                      >
                        {item.name}
                      </Button>
                    ),
                })),
            ]
          : breadCrumb.map((item: any, index: number) => ({
              title:
                index === 0 && breadCrumb.length > 1 ? (
                  <Button shape="default" type="dashed" size="small" onClick={() => handlerNavigate(item.cuid)}>
                    {item.name}
                  </Button>
                ) : (
                  <Dropdown.Button
                    type="dashed"
                    placement="bottomLeft"
                    size="small"
                    menu={{
                      items: ItemsType({
                        cuid: item.cuid,
                        name: item.name,
                        type: item.type,
                        menu: "HDR",
                      }) as any[],
                    }}
                  >
                    {item.name}
                  </Dropdown.Button>
                ),
            }))
      }
    />
  );
};

export default memo(D3BreadCrumb);
