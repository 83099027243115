import { Badge, Button, ColorPicker, Popconfirm } from "antd";
import D3Can from "@components/shared/D3Can";
import { Icon } from "@iconify/react";
import cellStore from "../store";

function FooterCells() {
  const { selected, lock, unlock } = cellStore();
  return (
    <div className="flex justify-start gap-4">
      <div className="flex gap-4">
        <D3Can action="lock" resource="customer_storage">
          <Badge
            count={
              selected.total.length > 0 && selected.totalInactive.length === 0
                ? selected.total.length
                : undefined
            }
          >
            <Popconfirm
              disabled={
                !(
                  selected.total.length > 0 &&
                  selected.totalActive.length === selected.total.length &&
                  selected.totalInactive.length === 0
                )
              }
              title={`Atenção!`}
              description={`Confirma bloqueio ${selected.total.length} ${
                selected.total.length > 1 ? "itens" : "item"
              }?`}
              onConfirm={async () => {
                await lock();
              }}
              okText="Sim"
              cancelText="Não"
              placement="top"
            >
              <Button
                type="primary"
                danger
                disabled={
                  !(
                    selected.total.length > 0 &&
                    selected.totalActive.length === selected.total.length &&
                    selected.totalInactive.length === 0
                  )
                }
                size="middle"
                icon={<Icon icon="basil:lock-solid" height={16} width={16} />}
              >
                Bloquear
              </Button>
            </Popconfirm>
          </Badge>
        </D3Can>
        <D3Can action="unlock" resource="customer_storage">
          <Badge
            color="green"
            count={
              selected.total.length > 0 && selected.totalActive.length === 0
                ? selected.total.length
                : undefined
            }
          >
            <Popconfirm
              disabled={
                !(
                  selected.total.length > 0 &&
                  selected.totalInactive.length === selected.total.length &&
                  selected.totalActive.length === 0
                )
              }
              title={`Atenção!`}
              description={`Confirma desbloqueio ${selected.total.length} ${
                selected.total.length > 1 ? "itens" : "item"
              }?`}
              onConfirm={async () => {
                await unlock();
              }}
              okText="Sim"
              cancelText="Não"
              placement="top"
            >
              <Button
                className=""
                disabled={
                  !(
                    selected.total.length > 0 &&
                    selected.totalInactive.length === selected.total.length &&
                    selected.totalActive.length === 0
                  )
                }
                size="middle"
                icon={<Icon icon="basil:unlock-solid" height={16} width={16} />}
              >
                Desbloquear
              </Button>
            </Popconfirm>
          </Badge>
        </D3Can>
      </div>
      <div className="flex items-center justify-start gap-2 font-[600]">
        <ColorPicker value="#bbf7d0" size="small" disabled /> Desbloqueado /
        Configurado
      </div>
      <div className="flex items-center justify-start gap-2 font-[600]">
        <ColorPicker value="#bfdbfe" size="small" disabled /> Desbloqueado / Sem
        configuração
      </div>
      <div className="flex items-center justify-start gap-2 font-[600]">
        <ColorPicker value="#fecaca" size="small" disabled /> Bloqueado /
        Configurado
      </div>
      <div className="flex items-center justify-start gap-2 font-[600]">
        <ColorPicker value="#fef08a" size="small" disabled /> Bloqueado / Sem
        configuração
      </div>
    </div>
  );
}

export default FooterCells;
