import D3PDFView from "@components/shared/D3PDF/D3PDFView";
import showMessage from "@utils/showMessage";
import { Spin } from "antd";
import { useEffect, useState } from "react";
import { useDeploymentOrdersStore } from "../store";
import { PrintType } from "../types";

type PrintDeploymentOrderFormPropsType = {
  cuid: string;
  fileName: string;
  printed: boolean;
};

function PrintDeploymentOrderForm({ cuid, fileName, printed }: PrintDeploymentOrderFormPropsType) {
  const { getUrl, loadDeploymentOrders } = useDeploymentOrdersStore();
  const [loading, setLoading] = useState<boolean>(true);
  const [base, setBase] = useState<string>("");

  useEffect(() => {
    (async () => {
      const response = await getUrl(cuid);
      if (response.status === "success") {
        const viewDeployment = response.body as PrintType;
        setBase(viewDeployment.url);
        setLoading(false);
      } else {
        showMessage(response);
      }
    })();

    return () => {
      if (!printed) {
        loadDeploymentOrders();
      }
    };
  }, [cuid, getUrl]);

  return (
    <>
      {loading ? (
        <div className="flex items-center justify-center h-full ">
          <Spin spinning />
        </div>
      ) : (
        <D3PDFView url={base} fileName={fileName} />
      )}
    </>
  );
}

export default PrintDeploymentOrderForm;
