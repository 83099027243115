import { DatePicker, Form } from "antd";
import { IndexItemType } from "../physical/types";
import dayjs from "dayjs";

type ItemDTIPropsType = {
  item: IndexItemType;
};

function ItemDTI({ item }: ItemDTIPropsType) {
  const { id, label, end_interval_required, start_interval_required } = item;
  return (
    <>
      <Form.Item label={label} className="p-0 m-0" >
        <div className="flex gap-2 ">
          <Form.Item
            name={[id, "DTI", "start_value"]}
            getValueProps={(value) => ({ value: value ? dayjs(value) : null })}
            normalize={(value) => (value ? dayjs(value).format("YYYY-MM-DD") : null)}
            rules={[
              {
                required: start_interval_required,
                message: `Por favor, preencha a data.`,
              },
            ]}
          >
            <DatePicker placeholder="Data inicial" format="DD/MM/YYYY" className="min-w-[172px]" />
          </Form.Item>
          <div className="flex items-center h-full p-1">até</div>
          <Form.Item
            name={[id, "DTI", "end_value"]}
            getValueProps={(value) => ({ value: value ? dayjs(value) : null })}
            normalize={(value) => (value ? dayjs(value).format("YYYY-MM-DD") : null)}
            rules={[
              {
                required: end_interval_required,
                message: `Por favor, preencha a data.`,
              },
            ]}
          >
            <DatePicker placeholder="Data final" format="DD/MM/YYYY" className="min-w-[172px]"  />
          </Form.Item>
        </div>
      </Form.Item>
    </>
  );
}
export default ItemDTI;
