import { create } from "zustand";
import { BadRequest, ClientHttp, StandardError, Success } from "@utils/clientHttp";
import showMessage from "@utils/showMessage";
import dayjs from "dayjs";
import {
  PayloadDocumentType,
  IndexItemType,
  IndexesValue,
  ItemDocumentTypeType,
  ListIndexesType,
  SimpleListDocumentTypeType,
  SimpleListDepartmentType,
  DepartmentItemType,
  ChildrenType,
} from "./types";

type DocumentStoreState = {
  payload: PayloadDocumentType;
  finish: boolean;
  simpleListDocumentTypeCustomer: () => Promise<void>;
  documentTypes: ItemDocumentTypeType[];
  simpleListDepartments: () => Promise<void>;
  departments: DepartmentItemType[];
  listIndexesDocumentType: (documentTypeCuid: string) => Promise<void>;
  indexes: IndexItemType[];
  registrationDocument: () => Promise<Success<ChildrenType> | BadRequest | StandardError>;
  resetState: () => void;
};

const defaultState = {
  documentTypes: [],
  departments: [],
  indexes: [],
  payload: {
    billable_department_cuid: "",
    global_visibility: false,
    can_insert_in_stored_repository: false,
    description: "",
    document_physical_tag_id: "",
    document_type_cuid: "",
    expected_purge_date: "",
    indexes_values: [] as IndexesValue[],
    is_permanent_storage: false,
    original_date: dayjs().format("YYYY-MM-DD"),
    physical_or_digital: "D",
    repository_physical_tag_id: "",
  } as PayloadDocumentType,
  finish: false,
};

export const useDocumentStore = create<DocumentStoreState>((set, get) => ({
  ...defaultState,
  resetState: () => set(defaultState),
  simpleListDocumentTypeCustomer: async (): Promise<void> => {
    await new ClientHttp().getItensForSelect<Success<SimpleListDocumentTypeType>, StandardError, void>(
      "/api/v1/customer/document-types/simple-list",
      (result: Success<SimpleListDocumentTypeType>): void => {
        set({
          documentTypes: result.body.data,
        });
      },
      (error: StandardError): void => {
        showMessage(error);
      }
    );
  },
  simpleListDepartments: async (): Promise<void> => {
    await new ClientHttp().getItensForSelect<Success<SimpleListDepartmentType>, StandardError, void>(
      "/api/v1/customer/departments/simple-list",
      (result: Success<SimpleListDepartmentType>): void => {
        set({
          departments: result.body.data as DepartmentItemType[],
        });
      },
      (error: StandardError): void => {
        showMessage(error);
        set({
          departments: [],
        });
      }
    );
  },
  listIndexesDocumentType: async (documentTypeCuid: string): Promise<void> => {
    await new ClientHttp().getItensForSelect<Success<ListIndexesType>, StandardError, void>(
      `/api/v1/customer/document-types/${documentTypeCuid}/document-type-indexes`,
      (result: Success<ListIndexesType>): void => {
        set({
          indexes: result.body.data as IndexItemType[],
        });
      },
      (error: StandardError): void => {
        showMessage(error);
        set({
          indexes: [],
        });
      }
    );
  },
  registrationDocument: async (): Promise<Success<ChildrenType> | BadRequest | StandardError> => {
    return await new ClientHttp().post<PayloadDocumentType, Success<ChildrenType>, BadRequest | StandardError>(
      "/api/v1/customer/fs/document",
      get().payload as PayloadDocumentType,
      (result: Success<ChildrenType>): ChildrenType => {
        showMessage(result, "Documento cadastrado com sucesso.");
        return result.body;
      },
      (error: BadRequest | StandardError): void => {
        showMessage(error);
      }
    );
  },
}));
