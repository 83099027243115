import { useContext, useEffect, useState } from "react";
import { Button, Form, Input, Select, Space, Spin, Tabs, Tooltip } from "antd";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import showMessage from "@utils/showMessage";
import { usePermissionGroupsStore } from "../store";
import { SelectCompanyUserItemType, UpdatePermissionGroupType, ViewPermissionGroupType } from "../types";
import { Icon } from "@iconify/react";
import { StandardError } from "@utils/clientHttp";
import D3PermCheckTable from "@components/shared/D3PermCheckTable/D3PermCheckTable";
import D3Can from "@components/shared/D3Can";

type UpdatePermissionGroupProps = {
  cuid: string;
};

export const UpdatePermissionGroupForm = ({ cuid }: UpdatePermissionGroupProps) => {
  const { updatePermissionGroup, getPermissionGroup, getCompanyUsersForSelect } = usePermissionGroupsStore();
  const { onClose, setSize } = useContext(D3DrawerContext);
  const [form] = Form.useForm<UpdatePermissionGroupType>();
  const [companyUsers, setCompanyUsers] = useState<SelectCompanyUserItemType[]>([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const [getPermissionGroupResult, getCompanyUsersForSelectResult] = await Promise.all([
        getPermissionGroup(cuid),
        getCompanyUsersForSelect(),
      ]);
      if (getPermissionGroupResult.status === "success") {
        const viewPermissionGroup = getPermissionGroupResult.body as ViewPermissionGroupType;
        form.setFieldsValue({
          name: viewPermissionGroup.name,
          permissions: viewPermissionGroup.permissions ?? [],
          company_user_cuids: viewPermissionGroup.company_users.map((group) => group.cuid),
        });
        setCompanyUsers(getCompanyUsersForSelectResult);
        setLoading(false);
      } else {
        showMessage(getPermissionGroupResult);
      }
    })();
  }, [cuid, form, getPermissionGroup, getCompanyUsersForSelect]);

  return (
    <>
      <Spin spinning={loading}>
        <Form
          className="select-none"
          form={form}
          layout="vertical"
          onFinish={async (payload: UpdatePermissionGroupType) => {
            setLoading(true);
            const payloadUp = {
              ...payload,
              permissions: form.getFieldValue("permissions"),
            };
            const result = await updatePermissionGroup(cuid, payloadUp);

            if (result.status === "success") {
              showMessage(result, "Grupo Permissões atualizado com sucesso.");
              onClose();
            } else {
              showMessage(result as StandardError);
            }
            setLoading(false);
          }}
          autoComplete="off"
        >
          <Tabs
            defaultActiveKey="1"
            className="select-none"
            onTabClick={(tab) => (tab === "1" ? setSize(600) : setSize(1200))}
            items={[
              {
                key: "1",
                label: (
                  <span className="flex items-center justify-center gap-2 ">
                    <Icon icon="fluent:form-24-filled" className="w-5 h-5" />
                    Formulário
                  </span>
                ),
                children: (
                  <>
                    <Form.Item<UpdatePermissionGroupType>
                      name="name"
                      label="Nome"
                      rules={[{ required: true, message: "Por favor, insira nome." }]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item<UpdatePermissionGroupType> label="Departamentos" name="company_user_cuids">
                      <Select
                        listItemHeight={10}
                        allowClear
                        removeIcon={
                          <Icon
                            icon="iconamoon:close-duotone"
                            className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
                          />
                        }
                        menuItemSelectedIcon={<Icon icon="eva:checkmark-outline" className="w-5 h-5 text-blue-500" />}
                        optionLabelProp="dataLabel"
                        showSearch
                        maxTagCount="responsive"
                        mode="multiple"
                        size="middle"
                        className="w-full truncate select-none"
                        optionFilterProp="children"
                        loading={loading}
                        filterOption={(input, option) => option?.dataFilter.toLowerCase().includes(input.toLowerCase())}
                      >
                        {companyUsers.map(({ value, active, label, username }) => (
                          <Select.Option
                            key={value}
                            value={value}
                            dataFilter={`${label} - ${username}`}
                            dataLabel={`${label} - ${username}`}
                          >
                            <div className="flex items-center justify-between ">
                              <div className="flex flex-col truncate">
                                <span className="truncate">{`${label} - ${username}`}</span>
                              </div>
                              <span>
                                {active ? (
                                  <Tooltip title="Usuário desbloqueado" placement="right">
                                    <Icon icon="basil:unlock-solid" className="text-green-500" height={16} width={16} />
                                  </Tooltip>
                                ) : (
                                  <Tooltip title="Usuário bloqueado" placement="right">
                                    <Icon icon="basil:lock-solid" className="text-red-500" height={16} width={16} />
                                  </Tooltip>
                                )}
                              </span>
                            </div>
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </>
                ),
              },
              {
                key: "w",
                label: (
                  <span className="flex items-center justify-center gap-2 ">
                    <Icon icon="icon-park-outline:permissions" className="w-[18px] h-[18px]" />
                    Permissões
                  </span>
                ),
                children: (
                  <Form.Item<UpdatePermissionGroupType> label="Permissões" name="permissions">
                    <D3PermCheckTable
                      profile="CPN"
                      onChange={(newValues: string[]) => {
                        form.setFieldValue("permissions", newValues);
                      }}
                    />
                  </Form.Item>
                ),
              },
            ]}
          />
          <Form.Item>
            <div className="text-right">
              <Space size="small">
                <Button type="default" danger onClick={() => onClose()}>
                  Cancelar
                </Button>
                <D3Can action="update" resource="company_permission_group">
                  <Button type="primary" htmlType="submit">
                    Atualizar
                  </Button>
                </D3Can>
              </Space>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};
