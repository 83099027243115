import { Icon } from "@iconify/react";
import { Tag } from "antd";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

type D3StatusProps = {
  status: boolean;
};

function D3Status({ status }: D3StatusProps): ReactElement {
  const {t} = useTranslation();
  return (
    <Tag
      className="flex items-center justify-start gap-1 max-w-max"
      icon={
        <Icon
          icon={status ? "basil:unlock-solid" : "basil:lock-solid"}
          className="min-h-[14px] min-w-[14px]"
        />
      }
      color={status ? "success" : "error"}
    >
      {status ? t("shared.utils.status.unlock") : t("shared.utils.status.lock")}
    </Tag>
  );
}

export default D3Status;
