import { create } from "zustand";
import { BadRequest, ClientHttp, StandardError, Success } from "@utils/clientHttp";
import showMessage from "@utils/showMessage";
import { InputMoveToTrashDto } from "./types";
import useNavigateStore from "../navigate/store";

type TrashStoreType = {
  moveToTrash: (payload: InputMoveToTrashDto) => Promise<void>;
};

const useTrashStore = create<TrashStoreType>((set, get) => ({
  moveToTrash: async (payload: InputMoveToTrashDto): Promise<void> => {
    await new ClientHttp().patch<InputMoveToTrashDto, Success<void>, BadRequest | StandardError>(
      `/api/v1/customer/fs/move-to-trash`,
      payload,
      (result: Success<void>): void => {
        useNavigateStore.getState().removeChildren(payload.cuids);
        showMessage(result, "Movido para lixeira com sucesso.");
      },
      (error: BadRequest | StandardError): void => {
        showMessage(error);
      }
    );
  },
}));

export default useTrashStore;
