import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import "dayjs/locale/pt-br";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("America/Sao_Paulo");

export function formatDatePicker(date: string | Date | Dayjs): string {
  return dayjs(date).format("YYYY-MM-DD")
}
