import { Icon } from "@iconify/react";
import { Button, Col, DatePicker, Form, Input, Row, Segmented, Select, Space, Tooltip } from "antd";
import dayjs from "dayjs";
import { useEffect } from "react";
import { SearchInvoiceType } from "../types";
import { useInvoiceStore } from "../store";

type SearchInvoiceFormProps = {
  onCallBackFilter: () => void;
  onCallBackResetFilter: () => void;
};

export const SearchInvoiceForm = ({ onCallBackFilter, onCallBackResetFilter }: SearchInvoiceFormProps) => {
  const [form] = Form.useForm<SearchInvoiceType>();
  const { filter, setFilter, simpleListCustomersSystem, loadingListCustomer, listCustomer } = useInvoiceStore();

  useEffect(() => {
    (async () => {
      await simpleListCustomersSystem({ active: false });
    })();
  }, [simpleListCustomersSystem]);

  useEffect(() => {
    form.setFieldsValue({
      ...filter,
    });
  }, [filter, form]);

  const normalize = (value: any) => {
    return dayjs(value as Date).format("YYYY-MM-DD");
  };
  const getValueProps = (value: any) => {
    return { value: value ? dayjs(value) : "" };
  };

  return (
    <>
      <Form
        className="select-none min-w-max form-view"
        layout="vertical"
        form={form}
        onFinish={async (payload) => {
          setFilter(payload);
          onCallBackFilter();
        }}
        autoComplete="off"
      >
        <Row gutter={20}>
          <Col>
            <Form.Item<SearchInvoiceType> label="Código" name="invoice_id">
              <Input />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item<SearchInvoiceType> label="Número" name="invoice_number">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item<SearchInvoiceType> name="customer_cuid" label="Cliente">
          <Select
            listItemHeight={10}
            allowClear
            removeIcon={
              <Icon
                icon="iconamoon:close-duotone"
                className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
              />
            }
            loading={loadingListCustomer}
            disabled={loadingListCustomer || listCustomer.length === 0}
            menuItemSelectedIcon={<Icon icon="eva:checkmark-outline" className="w-5 h-5 text-blue-500" />}
            optionLabelProp="dataSelected"
            showSearch
            maxTagCount="responsive"
            size="middle"
            className="w-full truncate select-none"
            optionFilterProp="children"
            filterOption={(input, option) => option?.dataFilter.toLowerCase().includes(input.toLowerCase())}
          >
            {listCustomer.map(({ active, cuid, fantasy_name, id, name, document }) => (
              <Select.Option
                key={cuid}
                value={cuid}
                dataFilter={`${fantasy_name} ${name}  ${id} ${document}`}
                dataSelected={`${fantasy_name}`}
              >
                <div className="flex items-center justify-between ">
                  <div className="flex items-center gap-2">
                    <Icon icon="fa6-solid:user" height={12} width={12} />
                    <div className="flex flex-col truncate">
                      <span className="truncate">{fantasy_name}</span>
                      <span className="text-[10px] truncate">{name}</span>
                    </div>
                  </div>
                  <span>
                    {active ? (
                      <Tooltip title="Cliente desbloqueado" placement="right">
                        <Icon icon="basil:unlock-solid" className="text-green-500" height={16} width={16} />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Cliente bloqueado" placement="right">
                        <Icon icon="basil:lock-solid" className="text-red-500" height={16} width={16} />
                      </Tooltip>
                    )}
                  </span>
                </div>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item<SearchInvoiceType> name="customer_fantasy_name" label="Nome fantasia">
          <Input />
        </Form.Item>

        <Row>
          <Col>
            <Form.Item<SearchInvoiceType>
              name="invoice_start_date"
              label="Data inicial"
              className="w-56"
              normalize={normalize}
              getValueProps={getValueProps}
            >
              <DatePicker format="DD/MM/YYYY" />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item<SearchInvoiceType>
              name="invoice_end_date"
              label="Data final"
              normalize={normalize}
              getValueProps={getValueProps}
            >
              <DatePicker format="DD/MM/YYYY" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Item<SearchInvoiceType>
              name="invoice_release_date"
              label="Data de emissão"
              className="w-56"
              normalize={normalize}
              getValueProps={getValueProps}
            >
              <DatePicker format="DD/MM/YYYY" />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item<SearchInvoiceType>
              name="invoice_due_date"
              label="Data de vencimento"
              normalize={normalize}
              getValueProps={getValueProps}
            >
              <DatePicker format="DD/MM/YYYY" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={50}>
          <Col>
            <Form.Item<SearchInvoiceType> name="invoice_printed" label="Impressa">
              <Segmented
                options={[
                  { label: "Sim", value: "on" },
                  { label: "Não", value: "off" },
                  { label: "Todos", value: "all" },
                ]}
                value={filter.invoice_printed}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item<SearchInvoiceType> name="invoice_canceled" label="Cancelada">
              <Segmented
                options={[
                  { label: "Sim", value: "on" },
                  { label: "Não", value: "off" },
                  { label: "Todos", value: "all" },
                ]}
                value={filter.invoice_canceled}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <div className="text-right">
            <Space size="small">
              <Button type="default" danger onClick={onCallBackResetFilter}>
                Limpar Filtro
              </Button>
              <Button type="primary" htmlType="submit">
                Filtrar
              </Button>
            </Space>
          </div>
        </Form.Item>
      </Form>
    </>
  );
};
