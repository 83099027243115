import { useContext, useEffect } from "react";
import { Button, ColorPicker, Form, Input, Space, Spin } from "antd";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { useMarkersStore } from "../store";
import { UpdateMarkerType } from "../types";
import D3Can from "@components/shared/D3Can";
import { Color } from "antd/es/color-picker";

type UpdateMarkerProps = {
  cuid: string;
};

export const UpdateMarkerForm = ({ cuid }: UpdateMarkerProps) => {
  const { updateMarker, loadingUpdate, loadView, view, loadingView } = useMarkersStore();
  const { onClose } = useContext(D3DrawerContext);
  const [form] = Form.useForm<UpdateMarkerType>();

  useEffect(() => {
    (async () => {
      await loadView(cuid);
    })();
  }, [cuid, loadView]);

  useEffect(() => {
    if (view) {
      form.setFieldsValue({
        ...view,
        color: view.color, // Certifique-se de que o valor inicial da cor está correto
      });
    }
  }, [view, form]);

  return (
    <>
      <Spin spinning={loadingView}>
        <Form
          className="select-none"
          layout="vertical"
          form={form}
          onFinish={async (payload: UpdateMarkerType) => {
            const isUpdated = await updateMarker(cuid, payload);
            if (isUpdated) {
              onClose();
            }
          }}
          autoComplete="off"
          disabled={loadingUpdate}
        >
          <Form.Item<UpdateMarkerType>
            name="key"
            label="Nome"
            rules={[{ required: true, message: "Por favor, insira uma descrição." }]}
          >
            <Input />
          </Form.Item>
          <Form.Item<UpdateMarkerType>
            name="color"
            initialValue={view.color}
            label="Cor"
            rules={[{ required: true, message: "Por favor, selecione uma cor." }]}
            normalize={(value: Color) => (value ? value.toHexString() : "#1677FF")}
            getValueProps={(value: string) => ({ value })}
          >
            <ColorPicker defaultValue={"#fff"} format="hex" />
          </Form.Item>
          <Form.Item>
            <div className="text-right">
              <Space size="small">
                <Button type="default" danger onClick={() => onClose()}>
                  Cancelar
                </Button>
                <D3Can action="update" resource="customer_marker">
                  <Button loading={loadingUpdate} type="primary" htmlType="submit">
                    Atualizar
                  </Button>
                </D3Can>
              </Space>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};
