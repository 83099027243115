import { AppstoreOutlined, BarsOutlined } from "@ant-design/icons";
import { Badge, Button, Dropdown, Segmented } from "antd";
import useNavigateStore from "./store";
import D3BreadCrumb from "./components/bread-crumb/D3BreadCrumb";
import { ItemsType } from "./components/ItemsType";
import { DropdownItemType, typeFile } from "./types";
import useBreadCrumbStore from "./components/bread-crumb/store";
import { Icon } from "@iconify/react";
import { SearchNavigateForm } from "./components/search";
import { useContext } from "react";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import useSearchStore from "./components/search/store";
import { useNavigate } from "react-router-dom";

function NavigateHeader() {
  const { openDrawer } = useContext(D3DrawerContext);
  const { viewType, isFilter } = useNavigateStore();
  const { resetFilter } = useSearchStore();
  const { breadCrumb } = useBreadCrumbStore();
  const navigate = useNavigate();

  const handleViewTypeChange = (value: "list" | "grid") => {
    useNavigateStore.setState({ viewType: value });
  };

  const lastBreadCrumb = (): DropdownItemType | object => {
    if (breadCrumb.length > 0) {
      const { cuid, name, type } = breadCrumb[breadCrumb.length - 1];
      const newItem = {
        cuid: cuid,
        name: name,
        type: type as typeFile,
        menu: "HDR" as "HDR" | "FLS",
      };
      return newItem;
    }
    return {};
  };
  return (
    <div className="z-50 items-center flex-none w-full h-12 p-2 border-b border-gray-200 shadow-b">
      <div className="grid grid-cols-3 text-center">
        <div className="flex items-center justify-start gap-4">
          <Badge dot={useSearchStore.getState().diffFilter()}>
            <Button
              onClick={() =>
                openDrawer(
                  <SearchNavigateForm
                    onCallBackResetFilter={() => {
                      resetFilter();
                    }}
                  />,
                  "left",
                  600,
                  "Parâmetros da Pesquisa"
                )
              }
              type="primary"
              icon={<Icon icon="fluent:search-12-filled" height={16} width={16} />}
            >
              Pesquisar
            </Button>
          </Badge>
          {isFilter ? (
            <Button
              size="small"
              className="text-gray-500"
              icon={<Icon icon="streamline:return-2-solid" />}
              onClick={() => {
                useNavigateStore.setState({ isFilter: false });
                navigate("/app/customer/spaces/work");
              }}
              type="dashed"
            >
              Raiz
            </Button>
          ) : (
            <D3BreadCrumb />
          )}
        </div>
        <span className="text-xl font-semibold text-gray-600 ">Área de trabalho</span>
        <div className="flex items-center justify-end gap-2">
          <Dropdown
            disabled={isFilter}
            className="text-gray-500"
            menu={{
              items: ItemsType(lastBreadCrumb() as DropdownItemType) as any[],
            }}
          >
            <Button shape="round" type="primary">
              Ações
            </Button>
          </Dropdown>
          <Segmented
            value={viewType}
            onChange={handleViewTypeChange}
            options={[
              { value: "list", icon: <BarsOutlined /> },
              { value: "grid", icon: <AppstoreOutlined /> },
            ]}
          />
        </div>
      </div>
    </div>
  );
}

export default NavigateHeader;
