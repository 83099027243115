import { create } from "zustand";
import {
  ListPermissionGroupType,
  CreatePermissionGroupType,
  LockOrUnlockPayloadType,
  SearchPermissionGroupType,
  UpdatePermissionGroupType,
  ViewPermissionGroupType,
  PermissionGroupListItemType,
  SelectCompanyUserItemType,
  SimpleListCompanyUserType,
} from "./types";

import showMessage from "@utils/showMessage";

import {
  ClientHttp,
  BadRequest,
  StandardError,
  Success,
} from "@utils/clientHttp";
import IsValidMultiSelectedRow from "@utils/table/IsValidMultiSelectedRow";
import { SetSort, SortType } from "@utils/table/SetSort";
import D3QSGenerator, {
  FilterDefinition,
} from "@utils/D3QSGenerator";

type PermissionGroupStoreState = {
  apiResult: ListPermissionGroupType;
  selectedRows: { cuids: string[]; active: boolean };
  filter: SearchPermissionGroupType;
  pagination: { page: number; pageSize: number };
  sort: { sort_by: string; sort_direction: string };
  loading: boolean;
  setFilter: (filter: SearchPermissionGroupType) => void;
  setPagination: (page: number, pageSize: number) => void;
  setSort: (newSort: SortType) => void;
  loadPermissionGroups: () => Promise<
    Success<ListPermissionGroupType> | BadRequest | StandardError
  >;
  getPermissionGroup: (
    cuid: string
  ) => Promise<Success<ViewPermissionGroupType> | StandardError>;

  getCompanyUsersForSelect: () => Promise<SelectCompanyUserItemType[]>;
  createPermissionGroup: (
    permissionGroup: CreatePermissionGroupType
  ) => Promise<
    Success<{ data: { cuid: string } }> | BadRequest | StandardError
  >;
  updatePermissionGroup: (
    cuid: string,
    permissionGroup: UpdatePermissionGroupType
  ) => Promise<Success<{ status: string }> | BadRequest | StandardError>;
  lockPermissionGroup: (cuids: string[]) => Promise<void>;
  unLockPermissionGroup: (cuids: string[]) => Promise<void>;
  onMultiSelectedRowChange: (
    selectedRows: PermissionGroupListItemType[]
  ) => void;
  resetSelectedRows: () => void;
  resetFilter: () => void;
  resetState: () => void;
};

const defaultSort = {
  sort_by: "pg_name",
  sort_direction: "asc",
};

const operationsFilter: FilterDefinition = {
  pg_name: { op: "lk", type: "string" },
  pg_active: { op: "eq", type: "active" },
};

const defaultState = {
  apiResult: {
    total: 0,
    data: [],
  },
  selectedRows: {
    cuids: [],
    active: false,
  },
  filter: {
    pg_name: "",
    pg_active: "on",
  } as SearchPermissionGroupType,
  pagination: {
    page: 1,
    pageSize: 10,
  },
  sort: defaultSort,
  loading: false,
};
export const usePermissionGroupsStore = create<PermissionGroupStoreState>(
  (set, get) => ({
    ...defaultState,
    resetState: () => set(defaultState),
    setFilter: (filter: SearchPermissionGroupType) => {
      set({
        filter,
        selectedRows: { cuids: [], active: false },
        pagination: { ...get().pagination, page: 1 },
      });
      get().loadPermissionGroups();
    },
    onMultiSelectedRowChange: (
      selectedRows: PermissionGroupListItemType[]
    ): void => IsValidMultiSelectedRow(selectedRows, get, set),
    resetSelectedRows: () => {
      set({ selectedRows: { cuids: [], active: false } });
    },
    resetFilter: () => {
      set({ filter: { pg_name: "", pg_active: "on" } });
      get().loadPermissionGroups();
    },
    setPagination: (page: number, pageSize: number) => {
      if (pageSize !== get().pagination.pageSize) {
        page = 1;
      }
      set({ pagination: { page, pageSize } });
      get().resetSelectedRows();
      get().loadPermissionGroups();
    },
    setSort: (newSort: SortType) =>
      SetSort(
        newSort,
        get().sort,
        defaultSort,
        new Map<string, string>([
          ["name", "pg_name"], // Column name from table and column name from api
          ["active", "pg_active"],
        ]),
        set,
        get().loadPermissionGroups
      ),
    loadPermissionGroups: async (): Promise<
      Success<ListPermissionGroupType> | BadRequest | StandardError
    > => {
      set({ loading: true });

      let qs = D3QSGenerator(
        get().filter,
        operationsFilter,
        get().pagination,
        get().sort
      );

      return await new ClientHttp().get<
        Success<ListPermissionGroupType>,
        BadRequest | StandardError
      >(
        `/api/v1/company/permissions-groups?${qs}`,
        (result: Success<ListPermissionGroupType>) => {
          let newPage = get().pagination.page;
          const { body } = result;
          if (body.total > 0 && body.data.length === 0) {
            newPage = newPage - 1;
            set({
              apiResult: body,
              loading: false,
              pagination: { ...get().pagination, page: newPage },
            });
            get().loadPermissionGroups();
          } else {
            set({ apiResult: body, loading: false });
          }
        },
        (error: BadRequest | StandardError) => {
          showMessage(error);
        }
      );
    },
    getPermissionGroup: async (
      cuid: string
    ): Promise<Success<ViewPermissionGroupType> | StandardError> => {
      return await new ClientHttp().get<
        Success<ViewPermissionGroupType>,
        StandardError
      >(`/api/v1/company/permissions-groups/${cuid}`);
    },
    getCompanyUsersForSelect: async (): Promise<
      SelectCompanyUserItemType[]
    > => {
      return await new ClientHttp().getItensForSelect<
        Success<SimpleListCompanyUserType>,
        StandardError,
        SelectCompanyUserItemType[]
      >(
        "/api/v1/company/users/simple-list",
        (
          result: Success<SimpleListCompanyUserType>
        ): SelectCompanyUserItemType[] => {
          return result.body.data.map((item) => {
            return {
              value: item.cuid,
              label: item.name,
              active: item.active,
              username: item.username,
            } as SelectCompanyUserItemType;
          });
        },
        (error: StandardError): SelectCompanyUserItemType[] => {
          showMessage(error, "Erro ao carregar lista usuários empresa.");
          return [] as SelectCompanyUserItemType[];
        }
      );
    },

    createPermissionGroup: async (
      permissionGroup: CreatePermissionGroupType
    ): Promise<
      Success<{ data: { cuid: string } }> | BadRequest | StandardError
    > => {
      return await new ClientHttp().post<
        CreatePermissionGroupType,
        Success<{ data: { cuid: string } }>,
        BadRequest | StandardError
      >(
        "/api/v1/company/permissions-groups",
        permissionGroup,
        (result: Success<{ data: { cuid: string } }>) => {
          get().loadPermissionGroups();
        }
      );
    },
    updatePermissionGroup: async (
      cuid: string,
      permissionGroup: UpdatePermissionGroupType
    ): Promise<Success<{ status: string }> | BadRequest | StandardError> => {
      return await new ClientHttp().put<
        UpdatePermissionGroupType,
        Success<{ status: string }>,
        BadRequest | StandardError
      >(
        `/api/v1/company/permissions-groups/${cuid}`,
        permissionGroup,
        (result: Success<{ status: string }>) => {
          get().loadPermissionGroups();
        }
      );
    },
    lockPermissionGroup: async (cuids: string[]): Promise<void> => {
      await new ClientHttp().patch<
        LockOrUnlockPayloadType,
        Success<{ rows_affected: number; status: string }>,
        BadRequest | StandardError
      >(
        `/api/v1/company/permissions-groups/lock`,
        { cuids: cuids },
        (result: Success<{ rows_affected: number; status: string }>) => {
          get().resetSelectedRows();
          get().loadPermissionGroups();
          if (result.body.rows_affected > 1) {
            showMessage(result, "Itens bloqueados com sucesso.");
          } else {
            showMessage(result, "Item bloqueado com sucesso.");
          }
        },
        (error: BadRequest | StandardError) => {
          showMessage(error);
        }
      );
    },
    unLockPermissionGroup: async (cuids: string[]): Promise<void> => {
      await new ClientHttp().patch<
        LockOrUnlockPayloadType,
        Success<{ rows_affected: number; status: string }>,
        BadRequest | StandardError
      >(
        `/api/v1/company/permissions-groups/unlock`,
        { cuids: cuids },
        (result: Success<{ rows_affected: number; status: string }>) => {
          get().resetSelectedRows();
          get().loadPermissionGroups();
          if (result.body.rows_affected > 1) {
            showMessage(result, "Itens desbloqueados com sucesso.");
          } else {
            showMessage(result, "Item desbloqueado com sucesso.");
          }
        },
        (error: BadRequest | StandardError) => {
          showMessage(error);
        }
      );
    },
  })
);
