import { ClientHttp, StandardError, Success } from "@utils/clientHttp";
import showMessage from "@utils/showMessage";
import { create } from "zustand";
import { StatusUpload, UploadFileType } from "./types";

type useUploadManagerStoreType = {
	open: boolean;
	completed: number;
	uploadFiles: UploadFileType[];
	addUploadFiles: (uploadFiles: UploadFileType[]) => void;
	setStatusUpload: (cuid: string, new_status_upload: StatusUpload) => void;
	cancelUpload: (cuid: string) => void;
};

const defaultState = {
	open: true,
	uploadFiles: [],
	progress: 0,
	completed: 0,
};

export const useUploadManagerStore = create<useUploadManagerStoreType>((set, get) => ({
	...defaultState,
	addUploadFiles: (uploadFiles: UploadFileType[]): void => {
		set({
			uploadFiles: [...get().uploadFiles, ...uploadFiles],
			open: true,
		});
	},
	setStatusUpload: (cuid: string, new_status_upload: StatusUpload): void => {
		set({
			uploadFiles: get().uploadFiles.map((file) => {
				if (cuid === file.fs_cuid) {
					if (new_status_upload === "S" || new_status_upload === "C") {
						set({ completed: get().completed + 1 });
					}
					file.status_upload = new_status_upload;
				}
				return file;
			}),
		});
	},
	cancelUpload: async (cuid: string): Promise<void> => {
		await new ClientHttp().delete<Success<void>, StandardError>(`/api/v1/customer/fs/file/${cuid}/upload`, undefined, (result: StandardError) =>
			showMessage(result)
		);
	}
}));
