import FormItemConditionalDate from "@components/shared/FormItemConditionalDate";
import { getAllRepositoryStatusForSelect } from "@utils/RepositoryStatus";
import { Form, FormInstance, Input, Radio, Select } from "antd";
import { useEffect } from "react";
import { useSpecialsStore } from "../store";
import { SearchPhysicalDocumentType } from "../types";
import FormItemSwitcher from "./FormItemSwitcher";

type SearchDocumentFormProps = {
  form: FormInstance<SearchPhysicalDocumentType>;
};

const options = [
  { label: "Igual a", value: "equal" },
  { label: "Maior que", value: "greater" },
  { label: "Menor que", value: "less" },
  { label: "No intervalo", value: "range" },
];

function SearchDocumentForm({ form }: SearchDocumentFormProps) {
  const { filter } = useSpecialsStore();

  useEffect(() => {
    form.setFieldsValue({
      filter_document: filter.filter_document,
    });
  }, []);

  return (
    <div className="flex flex-col w-full h-full p-2 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100 !important">
      <FormItemSwitcher
        attribute={["filter_document", "document_physical_tag_id"]}
        label="Etiquetas de documento"
        form={form}
      />

      <Form.Item label="Intervalo de documentos" className="p-0 my-2">
        <div className="flex items-center gap-3">
          <Form.Item
            className="w-full p-0 m-0"
            name={["filter_document", "document_range", "start_document_physical_tag_id"]}
          >
            <Input />
          </Form.Item>
          até
          <Form.Item
            className="w-full p-0 m-0"
            name={["filter_document", "document_range", "end_document_physical_tag_id"]}
          >
            <Input />
          </Form.Item>
        </div>
      </Form.Item>

      <Form.Item label="Descrição" className="p-0 my-2" name={["filter_document", "name"]}>
        <Input />
      </Form.Item>
      <Form.Item className="w-full p-0 my-2" label="Situação" name={["filter_document", "status"]}>
        <Select mode="multiple" options={getAllRepositoryStatusForSelect()} />
      </Form.Item>

      <FormItemConditionalDate<SearchPhysicalDocumentType>
        attribute={["filter_document", "created_at"]}
        label="Data cadastramento"
        options={options}
        form={form}
      />

      <FormItemConditionalDate<SearchPhysicalDocumentType>
        attribute={["filter_document", "expected_purge_date"]}
        label="Data previsão de expurgo"
        options={options}
        form={form}
      />

      <FormItemConditionalDate<SearchPhysicalDocumentType>
        attribute={["filter_document", "purge_date"]}
        label="Data de expurgo"
        options={options}
        form={form}
      />

      <Form.Item
        className="p-0 my-2 w-52 "
        initialValue={filter.filter_document.show_index}
        label="Exibir indexadores?"
        name={["filter_document", "show_index"]}
      >
        <Radio.Group buttonStyle="solid">
          <Radio.Button value={true}>Sim</Radio.Button>
          <Radio.Button value={false}>Não</Radio.Button>
        </Radio.Group>
      </Form.Item>
    </div>
  );
}

export default SearchDocumentForm;
