import { useEffect, useState } from "react";
import { Button, DatePicker, Form, Input, Select, Space, Spin } from "antd";
import dayjs from "dayjs";
import { useRepositoriesStore } from "../store";
import { SearchTrashType } from "../types";

type SearchTrashFormProps = {
  onCallBackFilter: () => void;
  onCallBackResetFilter: () => void;
};

export const SearchTrashForm = ({ onCallBackFilter, onCallBackResetFilter }: SearchTrashFormProps) => {
  const [form] = Form.useForm<SearchTrashType>();
  const { filter, setFilter } = useRepositoriesStore();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    form.setFieldsValue(filter);
    setLoading(false);
  }, [form, filter]);

  return (
    <Spin spinning={loading}>
      <Form
        className="select-none w-96"
        layout="vertical"
        form={form}
        onFinish={async (payload) => {
          setFilter(payload);
          onCallBackFilter();
        }}
        autoComplete="off"
      >
        <Form.Item<SearchTrashType> label="Nome" name="fs_name">
          <Input />
        </Form.Item>
        <Form.Item<SearchTrashType> label="Tipo" name="fs_type">
          <Select
            options={[
              {
                label: "Arquivo",
                value: "FLE",
              },
              {
                label: "Documento Estruturado",
                value: "DTF",
              },
              {
                label: "Pasta",
                value: "FLD",
              },
            ]}
          />
        </Form.Item>
        <Form.Item<SearchTrashType> name="deleted_user_name" label="Excluído por">
          <Input />
        </Form.Item>
        <Form.Item<SearchTrashType>
          name="trash_scheduled_deletion_date"
          label="Data de agendamento para exclusão"
          getValueProps={(value) => ({ value: value ? dayjs(value) : null })}
          normalize={(value) => (value ? dayjs(value).format("YYYY-MM-DD") : "")}
        >
          <DatePicker minDate={dayjs()}  format="DD/MM/YYYY"/>
        </Form.Item>
        <Form.Item>
          <div className="text-right">
            <Space size="small">
              <Button type="default" danger onClick={onCallBackResetFilter}>
                Limpar Filtro
              </Button>
              <Button type="primary" htmlType="submit">
                Filtrar
              </Button>
            </Space>
          </div>
        </Form.Item>
      </Form>
    </Spin>
  );
};
