export type ColumnType = {
  key: string;
  description: string;
  radio: boolean;
  name: string;
};

export const Data: ColumnType[] = [
  {
    key: "1",
    description: "Permite levar documentos originais para o cliente?",
    radio: false,
    name: "allows_taking_original_documents_to_customer",
  },
  {
    key: "2",
    description: "É solicitável na situação cliente?",
    radio: false,
    name: "is_requestable_in_customer_situation",
  },
  {
    key: "3",
    description: "É solicitável na situação cadastrada?",
    radio: false,
    name: "is_requestable_in_registered_situation",
  },
  {
    key: "4",
    description: "É solicitável na ordem de serviço?",
    radio: false,
    name: "is_requestable_in_service_order",
  },
  {
    key: "5",
    description: "É solicitável na situação de armazenada?",
    radio: false,
    name: "is_requestable_in_stored_situation",
  },
  {
    key: "6",
    description: "É serviço especial?",
    radio: false,
    name: "is_special_service",
  },
  {
    key: "7",
    description: "Requer informação de endereço?",
    radio: false,
    name: "requires_address_information",
  },
  {
    key: "8",
    description: "Requer descrição de documento para localização?",
    radio: false,
    name: "requires_document_description_for_location",
  },
  {
    key: "9",
    description: "Requer e-mail para envio?",
    radio: false,
    name: "requires_email_for_sending",
  },
  {
    key: "10",
    description: "Deve purgar documento?",
    radio: false,
    name: "should_purge_document",
  },
];
