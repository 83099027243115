import { useContext, useEffect } from "react";
import { Button, Form, InputNumber, Space, Spin } from "antd";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { useShelvesStore } from "../store";
import { UpdateShelfType } from "../types";
import showMessage from "@utils/showMessage";
import D3Can from "@components/shared/D3Can";

type UpdateShelfProps = {
  cuid: string;
};

export const UpdateShelfForm = ({ cuid }: UpdateShelfProps) => {
  const { updateShelf, loadingUpdate, loadView, view, loadingView } = useShelvesStore();
  const { onClose } = useContext(D3DrawerContext);
  const [form] = Form.useForm<UpdateShelfType>();

  useEffect(() => {
    (async () => {
      const result = await loadView(cuid);
    })();
  }, [cuid, form, loadView]);
  useEffect(() => {
    form.setFieldsValue(view);
  }, [form, view]);

  return (
    <>
      <Spin spinning={loadingView}>
        <Form
          className="select-none"
          layout="vertical"
          form={form}
          onFinish={async (payload: UpdateShelfType) => {
            const isUpdated = await updateShelf(cuid, payload);
            if (isUpdated) {
              onClose();
            }
          }}
          disabled={loadingUpdate}
          autoComplete="off"
        >
          <Form.Item<UpdateShelfType>
            label="Nº de Prateleiras"
            name="number_of_rows"
            rules={[
              {
                required: true,
                message: "Por favor, insira nº de prateleiras.",
              },
              {
                type: "number",
                min: 1,
                message: "O valor deve ser maior que 0.",
              },
            ]}
          >
            <InputNumber min={1} className="w-32" />
          </Form.Item>
          <Form.Item<UpdateShelfType>
            label="Nº de Vãos"
            name="number_of_columns"
            rules={[
              { required: true, message: "Por favor, insira nº de vãos." },
              {
                type: "number",
                min: 1,
                message: "O valor deve ser maior que 0",
              },
            ]}
          >
            <InputNumber className="w-32" min={1} />
          </Form.Item>
          <Form.Item>
            <div className="text-right">
              <Space size="small">
                <Button type="default" danger onClick={() => onClose()}>
                  Cancelar
                </Button>
                <D3Can action="update" resource="company_storage">
                  <Button loading={loadingUpdate} type="primary" htmlType="submit">
                    Atualizar
                  </Button>
                </D3Can>
              </Space>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};
