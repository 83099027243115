import { Button, Form, Input } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useForgotPasswordStore } from "./store";
import { ForgotPasswordType } from "./types";

function ForgotPasswordView() {
  const { forgotPassword, loadingForgot } = useForgotPasswordStore();
  const navigate = useNavigate();
  const [form] = Form.useForm<ForgotPasswordType>();
  const { t } = useTranslation();

  return (
    <>
      <div className="flex flex-col items-center justify-center h-full overflow-y-auto bg-white scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100 au:w-full">
        <span className="mt-8 text-2xl font-extrabold text-center text-gray-700">
          Bem-vindo(a) ao GED da CelulaTech!
        </span>
        <span className="flex py-8 font-bold text-gray-600">Informe suas credenciais para recuperar sua senha</span>
        <div className="w-[350px]">
          <Form
            form={form}
            layout="vertical"
            onFinish={async (payload: ForgotPasswordType) => {
              const result = await forgotPassword(payload);
              if (result) {
                navigate("/auth/login");
              }
            }}
            autoComplete="off"
          >
            <Form.Item
              label="Informe seu login"
              initialValue=""
              name="login"
              rules={[
                {
                  required: true,
                  message: t("shared.login.msg_username"),
                },
              ]}
              normalize={(value) => value.trim().replace(/\s+/g, "")}
            >
              <Input size="large" variant="filled" autoComplete="forgot-login" />
            </Form.Item>
            <Form.Item
              label="Informe seu email"
              name="email"
              rules={[
                {
                  required: true,
                  message: t("shared.login.msg_email"),
                },
              ]}
              normalize={(value) => value.trim().replace(/\s+/g, "")}
            >
              <Input size="large" variant="filled" autoComplete="forgot-email" />
            </Form.Item>
            <Form.Item>
              <Button
                loading={loadingForgot}
                htmlType="submit"
                type="primary"
                className="mt-3 text-base font-semibold text-gray-50"
                block
                size="large"
              >
                Recuperar senha
              </Button>
            </Form.Item>
          </Form>
        </div>
        <div className="flex items-center justify-center">
          <Button
            disabled={loadingForgot}
            onClick={() => navigate("/auth/login")}
            className="text-sm font-semibold"
            type="link"
          >
            voltar ao login
          </Button>
        </div>
      </div>
    </>
  );
}

export default ForgotPasswordView;
