import { useContext, useState } from "react";
import { Button, Form, Input, Spin } from "antd";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { useTranslation } from "react-i18next";
import { useMaterialsStore } from "../store";
import D3Can from "@components/shared/D3Can";
import showMessage from "@utils/showMessage";
import { CreateMaterialType } from "../types";
import FormItemInputMoney from "@components/shared/FormItemInputMoney";

export const CreateMaterialForm = () => {
  const { createMaterial } = useMaterialsStore();
  const { onClose } = useContext(D3DrawerContext);
  const [form] = Form.useForm<CreateMaterialType>();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  return (
    <>
      <Spin spinning={loading}>
        <Form
          className="select-none "
          form={form}
          layout="vertical"
          onFinish={async (payload: CreateMaterialType) => {
            setLoading(true);
            const result = await createMaterial(payload);
            if (result.status === "success") {
              showMessage(result, t("system.material.new.success"));
              onClose();
            } else {
              showMessage(result);
            }
            setLoading(false);
          }}
          autoComplete="off"
        >
          <Form.Item<CreateMaterialType>
            name="description"
            label={t("system.material.label_description")}
            rules={[
              {
                required: true,
                message: t("system.material.msg_description"),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <FormItemInputMoney form={form} name="price" label={t("system.material.label_price")} numberType="positive" />
          <Form.Item>
            <div className="flex justify-end gap-2">
              <Button type="default" danger onClick={() => onClose()}>
                {t("shared.btn_cancel")}
              </Button>
              <D3Can action="create" resource="system_material">
                <Button type="primary" htmlType="submit">
                  {t("shared.btn_save")}
                </Button>
              </D3Can>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};
