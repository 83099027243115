import { Input, Form, Spin, Divider, Radio, InputNumber } from "antd";
import { useCustomerGroupStore } from "../../store";
import { useEffect, useState } from "react";

import { ViewCustomerGroupType } from "../../types";
import showMessage from "@utils/showMessage";
import { D3Date } from "@utils/date";

function TabData() {
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm<ViewCustomerGroupType>();
  const { getCustomerGroup } = useCustomerGroupStore();

  useEffect(() => {
    (async () => {
      const result = await getCustomerGroup();
      if (result.status === "success") {
        const viewCustomerGroup = result.body as ViewCustomerGroupType;
        form.setFieldsValue({
          ...viewCustomerGroup,
          created_at: D3Date.FormatValueApiToPtBR(viewCustomerGroup.created_at),
          updated_at: D3Date.FormatValueApiToPtBR(viewCustomerGroup.updated_at),
        });
      } else {
        showMessage(result);
      }
      setLoading(false);
    })();
  }, [getCustomerGroup, form]);

  return (
    <div className="p-2">
      <Spin spinning={loading}>
        <h2 className="ml-1 text-lg font-semibold text-gray-600">
          Dados do Grupo de Clientes
        </h2>
        <Divider className="mt-1 mb-6 " />
        <Form
          className="w-[600px] select-none"
          form={form}
          layout="vertical"
          autoComplete="off"
          disabled
        >
          <Form.Item<ViewCustomerGroupType>
            label="Descrição"
            name="description"
          >
            <Input />
          </Form.Item>
          <Form.Item<ViewCustomerGroupType>
            name="number_of_customers"
            label="Qtd Clientes"
          >
            <InputNumber />
          </Form.Item>
          <Form.Item<ViewCustomerGroupType> name="active" label="Status">
            <Radio.Group
              options={[
                { label: "Desbloqueado", value: true },
                { label: "Bloqueado", value: false },
              ]}
            />
          </Form.Item>
          <Form.Item<ViewCustomerGroupType> name="created_at" label="Criado em">
            <Input />
          </Form.Item>
          <Form.Item<ViewCustomerGroupType>
            name="updated_at"
            label="Atualizado em"
          >
            <Input />
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
}

export default TabData;
