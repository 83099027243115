import { create } from "zustand";
import showMessage from "@utils/showMessage";
import { ClientHttp, BadRequest, StandardError, Success } from "@utils/clientHttp";
import { CreateMarkerType, ListMarkersType, SearchMarkerType, UpdateMarkerType, ViewMarkerType } from "./types";

type MarkerStoreState = {
  loadMarkers: () => Promise<void>;
  apiResult: ListMarkersType;
  loading: boolean;

  createMarker: (marker: CreateMarkerType) => Promise<boolean>;
  loadingCreate: boolean;

  loadView: (cuid: string) => Promise<void>;
  view: ViewMarkerType;
  loadingView: boolean;

  updateMarker: (cuid: string, marker: UpdateMarkerType) => Promise<boolean>;
  loadingUpdate: boolean;

  deleteMarker: (cuid: string) => Promise<boolean>;
  loadingDelete: boolean;

  filter: SearchMarkerType;
  setFilter: (filter: SearchMarkerType) => void;
  resetFilter: () => void;
  resetState: () => void;
};

const defaultState = {
  apiResult: {
    data: []
  },
  loading: false,

  loadingCreate: false,

  view: {} as ViewMarkerType,
  loadingView: false,

  loadingUpdate: false,

  loadingDelete: false,

  filter: {
    key: "",
  } as SearchMarkerType,
};

export const useMarkersStore = create<MarkerStoreState>((set, get) => ({
  ...defaultState,

  resetState: () => set(defaultState),
  setFilter: (filter: SearchMarkerType) => {
    set({
      filter,
    });
    get().loadMarkers();
  },
  resetFilter: () => {
    set({ filter: { key: "" } });
    get().loadMarkers();
  },

  loadMarkers: async (): Promise<void> => {
    set({ loading: true });
    let qs = "";
    if (get().filter.key !== "") {
      qs = `?key=${get().filter.key}`;
    }
    await new ClientHttp().get<Success<ListMarkersType>, BadRequest | StandardError>(
      `/api/v1/customer/markers${qs}`,
      (result: Success<ListMarkersType>): void => {
        const { body } = result;
        set({
          apiResult: body,
          loading: false,
        });
      },
      (error: BadRequest | StandardError): void => {
        showMessage(error);
        set({ loading: false });
      }
    );
  },

  createMarker: async (marker: CreateMarkerType): Promise<boolean> => {
    set({ loadingCreate: true });
    const result = await new ClientHttp().post<CreateMarkerType, Success<void>, BadRequest | StandardError>(
      "/api/v1/customer/markers",
      marker,
      (result: Success<void>): void => {
        showMessage(result, "Marcador criado com sucesso.");
        get().loadMarkers();
        set({ loadingCreate: false });
      },
      (result: BadRequest | StandardError): void => {
        showMessage(result);
        set({ loadingCreate: false });
      }
    );
    return result.status === "success";
  },

  loadView: async (cuid: string): Promise<void> => {
    set({ loadingView: true });
    await new ClientHttp().get<Success<ViewMarkerType>, BadRequest | StandardError>(
      `/api/v1/customer/markers/${cuid}`,
      (result: Success<ViewMarkerType>): void => {
        set({
          view: result.body,
          loadingView: false,
        });
      },
      (result: BadRequest | StandardError): void => {
        showMessage(result);
        set({ loadingView: false });
      }
    );
  },

  updateMarker: async (cuid: string, marker: UpdateMarkerType): Promise<boolean> => {
    set({ loadingUpdate: false });
    const result = await new ClientHttp().put<UpdateMarkerType, Success<void>, BadRequest | StandardError>(
      `/api/v1/customer/markers/${cuid}`,
      marker,
      (result: Success<void>): void => {
        showMessage(result, "Marcador atualizado com sucesso.");
        get().loadMarkers();
        set({ loadingUpdate: false });
      },
      (result: BadRequest | StandardError): void => {
        showMessage(result);
        set({ loadingUpdate: false });
      }
    );
    return result.status === "success";
  },

  deleteMarker: async (cuid: string): Promise<boolean> => {
    set({ loadingDelete: true });
    const result = await new ClientHttp().delete<Success<void>, BadRequest | StandardError>(
      `/api/v1/customer/markers/${cuid}`,
      (result: Success<void>): void => {
        showMessage(result, "Marcador excluído com sucesso.");
        get().loadMarkers();
        set({ loadingDelete: false });
      },
      (result: BadRequest | StandardError): void => {
        showMessage(result);
        set({ loadingDelete: false });
      }
    );
    return result.status === "success";
  },
}));
