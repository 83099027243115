import { IndexItemType } from "../physical/types";
import { ItemTXT, ItemINT, ItemIFT, ItemBOO, ItemCAC, ItemSEL, ItemDAT, ItemDTI, ItemNMI, ItemEMA } from "./indexers";

const renderFormItem = (item: IndexItemType) => {
  switch (item.system_index_type.key) {
    case "TXT":
      return <ItemTXT key={item.id} item={item} />;
    case "INT":
      return <ItemINT key={item.id} item={item} />;
    case "IFT":
      return <ItemIFT key={item.id} item={item} />;
    case "BOO":
      return <ItemBOO key={item.id} item={item} />;
    case "CAC":
      return <ItemCAC key={item.id} item={item} />;
    case "SEL":
      return <ItemSEL key={item.id} item={item} />;
    case "DAT":
      return <ItemDAT key={item.id} item={item} />;
    case "DTI":
      return <ItemDTI key={item.id} item={item} />;
    case "NMI":
      return <ItemNMI key={item.id} item={item} />;
    case "EMA":
      return <ItemEMA key={item.id} item={item} />;
    default:
      return null;
  }
};

export const DynamicFormElement = (items: IndexItemType[]) => items.map((item) => renderFormItem(item));
