import { create } from "zustand";
import {
  ListStorageType,
  CreateStorageType,
  RowStorageType,
  LockOrUnlockPayloadType,
  SearchStorageType,
  UpdateStorageType,
  ViewStorageType,
  SelectCompanyAddressItemType,
  SimpleListCompanyAddressType,
} from "./types";

import showMessage from "@utils/showMessage";

import {
  ClientHttp,
  BadRequest,
  StandardError,
  Success,
} from "@utils/clientHttp";
import IsValidMultiSelectedRow from "@utils/table/IsValidMultiSelectedRow";
import { SetSort, SortType } from "@utils/table/SetSort";
import D3QSGenerator, {
  FilterDefinition,
} from "@utils/D3QSGenerator";

type StorageStoreState = {
  apiResult: ListStorageType;
  selectedRows: { cuids: string[]; active: boolean };
  filter: SearchStorageType;
  pagination: { page: number; pageSize: number };
  sort: { sort_by: string; sort_direction: string };
  loading: boolean;
  setFilter: (filter: SearchStorageType) => void;
  setPagination: (page: number, pageSize: number) => void;
  setSort: (newSort: SortType) => void;
  loadStorages: () => Promise<
    Success<ListStorageType> | BadRequest | StandardError
  >;
  getStorage: (
    cuid: string
  ) => Promise<Success<ViewStorageType> | StandardError>;
  getCompanyAddressesForSelect: () => Promise<SelectCompanyAddressItemType[]>;
  createStorage: (
    storage: CreateStorageType
  ) => Promise<Success<{ cuid: string }> | BadRequest | StandardError>;
  updateStorage: (
    cuid: string,
    storage: UpdateStorageType
  ) => Promise<Success<void> | BadRequest | StandardError>;
  lockStorage: (cuids: string[]) => Promise<void>;
  unLockStorage: (cuids: string[]) => Promise<void>;
  onMultiSelectedRowChange: (selectedRows: RowStorageType[]) => void;
  resetSelectedRows: () => void;
  resetFilter: () => void;
  resetState: () => void;
};

const defaultSort = {
  sort_by: "stg_description",
  sort_direction: "asc",
};

const operationsFilter: FilterDefinition = {
  stg_description: { op: "lk", type: "string" },
  stg_active: { op: "eq", type: "active" },
};

const defaultState = {
  apiResult: {
    total: 0,
    data: [],
  },
  selectedRows: {
    cuids: [],
    active: false,
  },
  filter: {
    stg_description: "",
    stg_active: "on",
  } as SearchStorageType,
  pagination: {
    page: 1,
    pageSize: 10,
  },
  sort: defaultSort,
  loading: false,
};
export const useStoragesStore = create<StorageStoreState>((set, get) => ({
  ...defaultState,
  resetState: () => set(defaultState),
  setFilter: (filter: SearchStorageType) => {
    set({
      filter,
      selectedRows: { cuids: [], active: false },
      pagination: { ...get().pagination, page: 1 },
    });
    get().loadStorages();
  },
  onMultiSelectedRowChange: (selectedRows: RowStorageType[]): void =>
    IsValidMultiSelectedRow(selectedRows, get, set),
  resetSelectedRows: () => {
    set({ selectedRows: { cuids: [], active: false } });
  },
  resetFilter: () => {
    set({ filter: { stg_description: "", stg_active: "on" } });
    get().loadStorages();
  },
  setPagination: (page: number, pageSize: number) => {
    if (pageSize !== get().pagination.pageSize) {
      page = 1;
    }
    set({ pagination: { page, pageSize } });
    get().resetSelectedRows();
    get().loadStorages();
  },
  setSort: (newSort: SortType) =>
    SetSort(
      newSort,
      get().sort,
      defaultSort,
      new Map<string, string>([
        ["description", "stg_description"], // Column name from table and column name from api
        ["active", "stg_active"],
      ]),
      set,
      get().loadStorages
    ),
  loadStorages: async (): Promise<
    Success<ListStorageType> | BadRequest | StandardError
  > => {
    set({ loading: true });

    let qs = D3QSGenerator(
      get().filter,
      operationsFilter,
      get().pagination,
      get().sort
    );

    return await new ClientHttp().get<
      Success<ListStorageType>,
      BadRequest | StandardError
    >(
      `/api/v1/company/storages?${qs}`,
      (result: Success<ListStorageType>) => {
        let newPage = get().pagination.page;
        const { body } = result;
        if (body.total > 0 && body.data.length === 0) {
          newPage = newPage - 1;
          set({
            apiResult: body,
            loading: false,
            pagination: { ...get().pagination, page: newPage },
          });
          get().loadStorages();
        } else {
          set({ apiResult: body, loading: false });
        }
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
  },
  getStorage: async (
    cuid: string
  ): Promise<Success<ViewStorageType> | StandardError> => {
    return await new ClientHttp().get<Success<ViewStorageType>, StandardError>(
      `/api/v1/company/storages/${cuid}`
    );
  },

  getCompanyAddressesForSelect: async (): Promise<
    SelectCompanyAddressItemType[]
  > => {
    return await new ClientHttp().getItensForSelect<
      Success<SimpleListCompanyAddressType>,
      StandardError,
      SelectCompanyAddressItemType[]
    >(
      "/api/v1/company/addresses/simple-list",
      (
        result: Success<SimpleListCompanyAddressType>
      ): SelectCompanyAddressItemType[] => {
        return result.body.data.map(
          ({
            cuid,
            description,
            active,
            street_address,
            street_number,
            city,
            complement,
            zip_code,
            state,
            is_default,
          }) => {
            return {
              value: cuid,
              label: description,
              active: active,
              is_default: is_default,
              address: `${street_address}, nº ${street_number}${
                complement ? ", " + complement : ""
              }, ${city} - ${state}, Cep ${zip_code}`,
            } as SelectCompanyAddressItemType;
          }
        );
      },
      (error: StandardError): SelectCompanyAddressItemType[] => {
        showMessage(error, "Erro ao carregar lista de endereços.");
        return [] as SelectCompanyAddressItemType[];
      }
    );
  },

  createStorage: async (
    storage: CreateStorageType
  ): Promise<Success<{ cuid: string }> | BadRequest | StandardError> => {
    return await new ClientHttp().post<
      CreateStorageType,
      Success<{ cuid: string }>,
      BadRequest | StandardError
    >(
      "/api/v1/company/storages",
      storage,
      (result: Success<{ cuid: string }>) => {
        get().loadStorages();
      }
    );
  },
  updateStorage: async (
    cuid: string,
    storage: UpdateStorageType
  ): Promise<Success<void> | BadRequest | StandardError> => {
    return await new ClientHttp().put<
      UpdateStorageType,
      Success<void>,
      BadRequest | StandardError
    >(`/api/v1/company/storages/${cuid}`, storage, (result: Success<void>) => {
      get().loadStorages();
    });
  },
  lockStorage: async (cuids: string[]): Promise<void> => {
    await new ClientHttp().patch<
      LockOrUnlockPayloadType,
      Success<{ rows_affected: number }>,
      BadRequest | StandardError
    >(
      `/api/v1/company/storages/lock`,
      { cuids: cuids },
      (result: Success<{ rows_affected: number }>) => {
        get().resetSelectedRows();
        get().loadStorages();
        if (result.body.rows_affected > 1) {
          showMessage(result, "Itens bloqueados com sucesso.");
        } else {
          showMessage(result, "Item bloqueado com sucesso.");
        }
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
  },
  unLockStorage: async (cuids: string[]): Promise<void> => {
    await new ClientHttp().patch<
      LockOrUnlockPayloadType,
      Success<{ rows_affected: number }>,
      BadRequest | StandardError
    >(
      `/api/v1/company/storages/unlock`,
      { cuids: cuids },
      (result: Success<{ rows_affected: number }>) => {
        get().resetSelectedRows();
        get().loadStorages();
        if (result.body.rows_affected > 1) {
          showMessage(result, "Itens desbloqueados com sucesso.");
        } else {
          showMessage(result, "Item desbloqueado com sucesso.");
        }
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
  },
}));
