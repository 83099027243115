import { create } from "zustand";
import { BadRequest, ClientHttp, StandardError, Success } from "@utils/clientHttp";
import showMessage from "@utils/showMessage";
import { ChangePasswordType, ItemSimpleListUserSystemType, SimpleListUserSystemType } from "./types";

type PermissionGroupStoreState = {
  simpleListUserSystem: () => Promise<void>;
  listUser: ItemSimpleListUserSystemType[];
  loadingList: boolean;
  changePassword: (payload: ChangePasswordType, userCuid: string) => Promise<boolean>;
  loadingChange: boolean;
};

export const useManageStore = create<PermissionGroupStoreState>((set, get) => ({
  loadingList: false,
  listUser: [],
  loadingChange: false,
  simpleListUserSystem: async (): Promise<void> => {
    await new ClientHttp().get<Success<SimpleListUserSystemType>, BadRequest | StandardError>(
      "/api/v1/system/user/simple-list",
      (result: Success<SimpleListUserSystemType>): void => {
        set({ listUser: result.body.data });
      },
      (error: BadRequest | StandardError): void => {
        showMessage(error);
      }
    );
  },
  changePassword: async (payload: ChangePasswordType, userCuid: string): Promise<boolean> => {
    const result = await new ClientHttp().patch<
      ChangePasswordType,
      Success<{ rows_affected: number; status: string }>,
      BadRequest | StandardError
    >(
      `/api/v1/admin-tool/system/user/${userCuid}`,
      {
        new_password: payload.new_password,
        repeated_new_password: payload.repeated_new_password,
      },
      (result: Success<{}>) => {
        showMessage(result, "Senha atualizada com sucesso.");
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
    return result.status === "success";
  },
}));
