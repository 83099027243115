import { Button, ColorPicker, Form, Input, Space, Spin } from "antd";
import { useContext, useEffect } from "react";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { D3Date } from "@utils/date";
import D3Can from "@components/shared/D3Can";
import { useMarkersStore } from "../store";
import { ViewMarkerType } from "../types";
import { UpdateMarkerForm } from "./UpdateMarkerForm";

type ViewMarkerFormProps = {
  cuid: string;
};

export const ViewMarkerForm = ({ cuid }: ViewMarkerFormProps) => {
  const { loadView, view, loadingView } = useMarkersStore();
  const { openDrawer, onClose } = useContext(D3DrawerContext);
  const [form] = Form.useForm<ViewMarkerType>();

  useEffect(() => {
    (async () => {
      await loadView(cuid);
    })();
    return () => useMarkersStore.setState({ view: {} as ViewMarkerType });
  }, [cuid, loadView]);

  useEffect(() => {
    if (view.cuid) {
      console.log("entrou");
      form.setFieldsValue({
        ...view,
        created_at: D3Date.FormatValueApiToPtBR(view.created_at),
        updated_at: D3Date.FormatValueApiToPtBR(view.updated_at),
      });
    }
  }, [form, view]);

  return (
    <>
      <Spin spinning={loadingView}>
        <Form className="select-none" layout="vertical" form={form} autoComplete="off" disabled>
          <Form.Item<ViewMarkerType> name="key" label="Marcador">
            <Input />
          </Form.Item>

          <Form.Item<ViewMarkerType> initialValue={view.color} name="color" label="Cor">
            <ColorPicker defaultValue={"#fff"} format="hex" />
          </Form.Item>

          <Form.Item<ViewMarkerType> name={["created_by", "name"]} label="Criado por">
            <Input disabled />
          </Form.Item>

          <Form.Item<ViewMarkerType> name="created_at" label="Criado em">
            <Input />
          </Form.Item>
          <Form.Item<ViewMarkerType> name={["updated_by", "name"]} label="Atualizado por">
            <Input disabled />
          </Form.Item>
          <Form.Item<ViewMarkerType> name="updated_at" label="Atualizado em">
            <Input />
          </Form.Item>
          <Form.Item>
            <div className="text-right">
              <Space size="small">
                <Button disabled={false} type="default" danger onClick={() => onClose()}>
                  Cancelar
                </Button>
                <D3Can action="update" resource="customer_marker">
                  <Button
                  disabled={false}                  
                    type="primary"
                    onClick={() => openDrawer(<UpdateMarkerForm cuid={cuid} />, "right", 600, "Editar Marcador")}
                  >
                    Editar
                  </Button>
                </D3Can>
              </Space>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};
