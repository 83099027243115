import { useContext, useEffect, useState } from "react";

import { Button, Col, Form, Input, Radio, Row, Space, Spin } from "antd";

import { D3DrawerContext } from "@provider/D3DrawerContext";
import { useCGRCustomersStore } from "../store";
import { UpdateCGRCustomerType } from "../types";
import showMessage from "@utils/showMessage";
import cpfValidation from "@utils/formInputValidation/cpfValidation";
import { PatternFormat } from "react-number-format";
import cnpjValidation from "@utils/formInputValidation/cnpjValidation";
import D3Can from "@components/shared/D3Can";

type UpdateCGRCustomerProps = {
  cuid: string;
};

export const UpdateCGRCustomerForm = ({ cuid }: UpdateCGRCustomerProps) => {
  const [typeDocument, setTypeDocument] = useState("CNPJ");
  const { updateCGRCustomer, getCGRCustomer } = useCGRCustomersStore();
  const { onClose } = useContext(D3DrawerContext);
  const [form] = Form.useForm<UpdateCGRCustomerType>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const result = await getCGRCustomer(cuid);
      if (result.status === "success") {
        const viewCGRCustomer = result.body as UpdateCGRCustomerType;
        form.setFieldsValue(viewCGRCustomer);
        setTypeDocument(viewCGRCustomer.document_type);
        setLoading(false);
      } else {
        showMessage(result);
      }
    })();
  }, [cuid, form, getCGRCustomer]);

  return (
    <>
      <Spin spinning={loading}>
        <Form
          className="select-none"
          layout="vertical"
          form={form}
          onFinish={async (payload: UpdateCGRCustomerType) => {
            setLoading(true);
            const result = await updateCGRCustomer(cuid, payload);
            if (result.code === 200) {
              showMessage(result, "Cliente atualizado com sucesso.");
              onClose();
            } else {
              showMessage(result);
            }
            setLoading(false);
          }}
          autoComplete="off"
        >
          <Form.Item<UpdateCGRCustomerType>
            label="Nome Fantasia"
            name="fantasy_name"
            rules={[
              {
                required: true,
                message: "Por favor, insira o nome fantasia.",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Razão Social"
            name="name"
            rules={[
              {
                required: true,
                message: "Por favor, insira a razão social.",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item<UpdateCGRCustomerType>
            label="Pessoa"
            name="document_type"
            rules={[
              {
                required: true,
                message: "Por favor, selecione uma opção.",
              },
            ]}
          >
            <Radio.Group
              options={[
                { label: "Jurídica", value: "CNPJ" },
                { label: "Física", value: "CPF" },
              ]}
              onChange={(e) => {
                setTypeDocument(e.target.value);
                form.setFieldsValue({ document_type: e.target.value });
              }}
              optionType="button"
              buttonStyle="solid"
            />
          </Form.Item>
          {typeDocument === "CPF" ? (
            <Form.Item
              label="CPF"
              name="document"
              rules={[
                {
                  required: true,
                  message: "Por favor, insira o cpf.",
                },
                () => ({
                  validator(_, value) {
                    if (!value || cpfValidation(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("Ops! CPF inválido."));
                  },
                }),
              ]}
            >
              <PatternFormat format="###.###.###-##" customInput={Input} />
            </Form.Item>
          ) : (
            <Row gutter={10}>
              <Col>
                <Form.Item
                  label="CNPJ"
                  className="w-[294px]"
                  name="document"
                  rules={[
                    {
                      required: true,
                      message: "Por favor, insira o cnpj.",
                    },
                    () => ({
                      validator(_, value) {
                        if (!value || cnpjValidation(value)) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error("Ops! CNPJ inválido."));
                      },
                    }),
                  ]}
                >
                  <PatternFormat
                    format="##.###.###/####-##"
                    customInput={Input}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  label="Inscrição Estadual"
                  className="w-[294px]"
                  name="stateInscription"
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          )}

          <Form.Item<UpdateCGRCustomerType>
            label="Responsável pelo Contrato"
            name="contract_manager"
            rules={[
              {
                required: true,
                message: "Por favor, insira o responsável pelo contrato.",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <div className="text-right">
              <Space size="small">
                <Button type="default" danger onClick={() => onClose()}>
                  Cancelar
                </Button>
                <D3Can action="update" resource="customer">
                  <Button type="primary" htmlType="submit">
                    Atualizar
                  </Button>
                </D3Can>
              </Space>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};
