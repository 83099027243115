import { create } from "zustand";

import {
  CreateOperationType,
  ListOperationType,
  LockOrUnlockPayloadType,
  RowOperationType,
  SearchOperationType,
  UpdateOperationType,
  ViewOperationType,
} from "./types";

import { BadRequest, ClientHttp, StandardError, Success } from "@utils/clientHttp";
import showMessage from "@utils/showMessage";
import IsValidMultiSelectedRow from "@utils/table/IsValidMultiSelectedRow";
import { SetSort, SortType } from "@utils/table/SetSort";
import D3QSGenerator, { FilterDefinition } from "@utils/D3QSGenerator";

type OperationStoreState = {
  apiResult: ListOperationType;
  selectedRows: { cuids: string[]; active: boolean };
  filter: SearchOperationType;
  pagination: { page: number; pageSize: number };
  sort: { sort_by: string; sort_direction: string };
  loading: boolean;
  setFilter: (filter: SearchOperationType) => void;
  setPagination: (page: number, pageSize: number) => void;
  setSort: (newSort: SortType) => void;
  loadOperations: () => Promise<void>;
  getOperation: (cuid: string) => Promise<Success<ViewOperationType> | StandardError>;
  createOperation: (operation: CreateOperationType) => Promise<Success<{ cuid: string }> | BadRequest | StandardError>;
  updateOperation: (
    cuid: string,
    operation: UpdateOperationType
  ) => Promise<Success<void> | BadRequest | StandardError>;
  lockOperation: (cuids: string[]) => Promise<void>;
  unLockOperation: (cuids: string[]) => Promise<void>;
  onMultiSelectedRowChange: (selectedRows: RowOperationType[]) => void;
  resetSelectedRows: () => void;
  resetFilter: () => void;
  resetState: () => void;
};

const defaultSort = {
  sort_by: "op_description",
  sort_direction: "asc",
};

const operationsFilter: FilterDefinition = {
  op_description: { op: "lk", type: "string" },
  op_active: { op: "eq", type: "active" },
};

const defaultState = {
  apiResult: {
    total: 0,
    data: [],
  },
  selectedRows: {
    cuids: [],
    active: false,
  },
  filter: {
    op_description: "",
    op_active: "on",
  } as SearchOperationType,
  pagination: {
    page: 1,
    pageSize: 10,
  },
  sort: defaultSort,
  loading: false,
};

export const useOperationsStore = create<OperationStoreState>((set, get) => ({
  ...defaultState,
  resetState: () => set(defaultState),
  setFilter: (filter: SearchOperationType) => {
    set({
      filter,
      selectedRows: { cuids: [], active: false },
      pagination: { ...get().pagination, page: 1 },
    });
    get().loadOperations();
  },
  onMultiSelectedRowChange: (selectedRows: RowOperationType[]) => IsValidMultiSelectedRow(selectedRows, get, set),
  resetSelectedRows: () => {
    set({ selectedRows: { cuids: [], active: false } });
  },
  resetFilter: () => {
    set({ filter: { op_description: "", op_active: "on" } });
    get().loadOperations();
  },
  setPagination: (page: number, pageSize: number) => {
    if (pageSize !== get().pagination.pageSize) {
      page = 1;
    }
    set({ pagination: { page, pageSize } });
    get().resetSelectedRows();
    get().loadOperations();
  },
  setSort: (newSort: SortType) =>
    SetSort(
      newSort,
      get().sort,
      defaultSort,
      new Map<string, string>([
        ["description", "op_description"], // Column name from table and column name from api
        ["price", "op_price"],
        ["active", "op_active"],
      ]),
      set,
      get().loadOperations
    ),
  loadOperations: async (): Promise<void> => {
    set({ loading: true });

    let qs = D3QSGenerator(get().filter, operationsFilter, get().pagination, get().sort);

    await new ClientHttp().get<Success<ListOperationType>, BadRequest | StandardError>(
      `/api/v1/system/operations?${qs}`,
      (result: Success<ListOperationType>) => {
        let newPage = get().pagination.page;
        const { body } = result;
        if (body.total > 0 && body.data.length === 0) {
          newPage = newPage - 1;
          set({
            apiResult: body,
            loading: false,
            pagination: { ...get().pagination, page: newPage },
          });
          get().loadOperations();
        } else {
          set({ apiResult: body, loading: false });
        }
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
  },
  getOperation: async (cuid: string): Promise<Success<ViewOperationType> | StandardError> => {
    return await new ClientHttp().get<Success<ViewOperationType>, StandardError>(`/api/v1/system/operations/${cuid}`);
  },
  createOperation: async (
    operation: CreateOperationType
  ): Promise<Success<{ cuid: string }> | BadRequest | StandardError> => {
    return await new ClientHttp().post<CreateOperationType, Success<{ cuid: string }>, BadRequest | StandardError>(
      "/api/v1/system/operations",
      operation,
      (result: Success<{ cuid: string }>) => {
        get().loadOperations();
      }
    );
  },
  updateOperation: async (
    cuid: string,
    operation: UpdateOperationType
  ): Promise<Success<void> | BadRequest | StandardError> => {
    return await new ClientHttp().put<UpdateOperationType, Success<void>, BadRequest | StandardError>(
      `/api/v1/system/operations/${cuid}`,
      operation,
      (result: Success<void>) => {
        get().loadOperations();
      }
    );
  },
  lockOperation: async (cuids: string[]): Promise<void> => {
    await new ClientHttp().patch<
      LockOrUnlockPayloadType,
      Success<{ rows_affected: number }>,
      BadRequest | StandardError
    >(
      `/api/v1/system/operations/lock`,
      { cuids: cuids },
      (result: Success<{ rows_affected: number }>) => {
        get().resetSelectedRows();
        get().loadOperations();
        if (result.body.rows_affected > 1) {
          showMessage(result, "Operações bloqueadas com sucesso.");
        } else {
          showMessage(result, "Operação bloqueada com sucesso.");
        }
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
  },
  unLockOperation: async (cuids: string[]): Promise<void> => {
    await new ClientHttp().patch<
      LockOrUnlockPayloadType,
      Success<{ rows_affected: number }>,
      BadRequest | StandardError
    >(
      `/api/v1/system/operations/unlock`,
      { cuids: cuids },
      (result: Success<{ rows_affected: number }>) => {
        get().resetSelectedRows();
        get().loadOperations();
        if (result.body.rows_affected > 1) {
          showMessage(result, "Operações desbloqueadas com sucesso.");
        } else {
          showMessage(result, "Operação desbloqueada com sucesso.");
        }
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
  },
}));
