import { Icon } from "@iconify/react";
import { Form, FormInstance, Select, Tag } from "antd";
import { useEffect, useState } from "react";
import { SelectProps } from "antd/lib";
import { SearchType } from "./types";
import useSearchStore from "./store";

type SearchDocumentFormProps = {
  form: FormInstance<any>;
};

type TagRender = SelectProps["tagRender"];

function SearchMarkersForm({ form }: SearchDocumentFormProps) {
  const { simpleListMarkers, markers } = useSearchStore();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      await simpleListMarkers();
      setLoading(false);
    })();
  }, [simpleListMarkers]);

  const tagRender: TagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    const item = markers.find((item) => item.cuid === value);
    return (
      <Tag
        color={item?.color}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginInlineEnd: 4 }}
      >
        {label}
      </Tag>
    );
  };

  return (
    <div className="flex flex-col w-full h-full p-2 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100 !important">
      <Form.Item<SearchType> className="w-full p-0 my-2" label="Marcadores" name={["filter_marker", "markers_cuids"]}>
        <Select
          loading={loading}
          disabled={loading}
          tagRender={tagRender}
          listItemHeight={10}
          listHeight={250}
          allowClear
          removeIcon={
            <Icon
              icon="iconamoon:close-duotone"
              className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
            />
          }
          menuItemSelectedIcon={<Icon icon="eva:checkmark-outline" className="w-5 h-5 text-blue-500" />}
          optionLabelProp="dataLabel"
          showSearch
          maxTagCount="responsive"
          mode="multiple"
          size="middle"
          className="w-full truncate select-none"
          optionFilterProp="children"
          filterOption={(input, option) => option?.dataFilter.toLowerCase().includes(input.toLowerCase())}
        >
          {markers.map(({ color, cuid, key }) => (
            <Select.Option key={cuid} value={cuid} dataFilter={`${key}`} dataLabel={`${key}`}>
              <div className="flex items-center h-8 ">
                <Tag color={color}>{key}</Tag>
              </div>
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  );
}

export default SearchMarkersForm;
