import { useContext, useEffect, useState } from "react";

import { Icon } from "@iconify/react";
import { Badge, Button, Col, Pagination, Popconfirm, Popover, Row, Space, Tooltip } from "antd";

import Table from "antd/es/table";

import D3Can from "@components/shared/D3Can";
import D3Status from "@components/shared/D3Status";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { ToggleLockAction } from "@utils/ToggleLockAction";
import { CreateServiceForm } from "./components/CreateServiceForm";
import { SearchServiceForm } from "./components/SearchServiceForm";
import { UpdateServiceForm } from "./components/UpdateServiceForm";
import { ViewServiceForm } from "./components/ViewServiceForm";
import { useServicesStore } from "./store";
import { RowServiceType } from "./types";

const ServicesView: React.FC = () => {
	const [openSearch, setOpenSearch] = useState(false);

	const { openDrawer } = useContext(D3DrawerContext);
	const {
		apiResult,
		pagination,
		loading,
		selectedRows,
		setPagination,
		setSort,
		loadServices,
		lockService,
		unLockService,
		onMultiSelectedRowChange,
		resetFilter,
		resetState,
	} = useServicesStore();

	useEffect(() => {
		(async () => {
			await loadServices();
		})();
		return () => resetState();
	}, [loadServices]);

	return (
		<div className="flex flex-col h-full mb-1 mr-1 overflow-auto bg-white rounded shadow">
			<Table<RowServiceType>
				size="small"
				rowKey="cuid"
				dataSource={apiResult.data as RowServiceType[]}
				bordered
				className="responsive-table"
				pagination={false}
				loading={loading}
				title={() => {
					return (
						<>
							<div className="flex items-center justify-between w-full">
								<div className="flex items-center justify-start">
									<Popover
										open={openSearch}
										placement="rightTop"
										title="Pesquisar Serviços"
										content={
											<SearchServiceForm
												onCallBackFilter={() => setOpenSearch(false)}
												onCallBackResetFilter={() => {
													setOpenSearch(false);
													resetFilter();
												}}
											/>
										}
										trigger="click"
										onOpenChange={(open) => setOpenSearch(open)}
									>
										<Button
											onClick={() => setOpenSearch(!openSearch)}
											type="primary"
											size="middle"
											icon={<Icon icon="fluent:search-12-filled" height={16} width={16} />}
										>
											Pesquisar
										</Button>
									</Popover>
								</div>
								<div className="flex items-center justify-center">
									<span className="text-xl font-semibold text-gray-600">Serviços</span>
								</div>
								<div className="flex items-center justify-end">
									<D3Can action="create" resource="system_service">
										<Button
											type="primary"
											size="middle"
											icon={<Icon icon="ic:round-plus" height={20} width={20} />}
											onClick={() => openDrawer(<CreateServiceForm />, "right", 600, "Cadastrar Serviço")}
										>
											Novo Serviço
										</Button>
									</D3Can>
								</div>
							</div>
						</>
					);
				}}
				rowSelection={{
					hideSelectAll: false,
					type: "checkbox",
					selectedRowKeys: selectedRows.cuids,
					onChange: (_, selectRows: RowServiceType[]) => {
						onMultiSelectedRowChange(selectRows);
					},
				}}
				onChange={(a, b, sorter: any) => {
					if (sorter) {
						setSort({
							sort_by: sorter.field,
							sort_direction: sorter.order,
						});
					}
				}}
				footer={() => {
					return (
						<>
							<div className="flex gap-4">
								<D3Can action="lock" resource="system_service">
									<Badge count={selectedRows.cuids.length > 0 && selectedRows.active === true ? selectedRows.cuids.length : undefined}>
										<Popconfirm
											disabled={!(selectedRows.cuids.length > 0 && selectedRows.active === true)}
											title={`Atenção!`}
											description={`Confirma bloqueio ${selectedRows.cuids.length} ${selectedRows.cuids.length > 1 ? "itens" : "item"}?`}
											onConfirm={async () => {
												await lockService(selectedRows.cuids);
											}}
											okText="Sim"
											cancelText="Não"
											placement="top"
										>
											<Button
												type="primary"
												danger
												disabled={!(selectedRows.cuids.length > 0 && selectedRows.active === true)}
												size="middle"
												icon={<Icon icon="basil:lock-solid" height={16} width={16} />}
											>
												Bloquear
											</Button>
										</Popconfirm>
									</Badge>
								</D3Can>
								<D3Can action="unlock" resource="system_service">
									<Badge color="green" count={selectedRows.cuids.length > 0 && selectedRows.active === false ? selectedRows.cuids.length : undefined}>
										<Popconfirm
											disabled={!(selectedRows.cuids.length > 0 && selectedRows.active === false)}
											title={`Atenção!`}
											description={`Confirma desbloqueio ${selectedRows.cuids.length} ${selectedRows.cuids.length > 1 ? "itens" : "item"}?`}
											onConfirm={async () => {
												await unLockService(selectedRows.cuids);
											}}
											okText="Sim"
											cancelText="Não"
											placement="top"
										>
											<Button
												className=""
												disabled={!(selectedRows.cuids.length > 0 && selectedRows.active === false)}
												size="middle"
												icon={<Icon icon="basil:unlock-solid" height={16} width={16} />}
											>
												Desbloquear
											</Button>
										</Popconfirm>
									</Badge>
								</D3Can>
							</div>

							<Space>
								<Pagination
									showLessItems
									showTotal={(total, range) => `Exibindo de ${range[0]} a ${range[1]} do total ${total} ${total > 1 ? "registros" : "registro"}`}
									showSizeChanger={true}
									current={pagination.page}
									onChange={setPagination}
									pageSize={pagination.pageSize}
									total={apiResult.total}
								/>
							</Space>
						</>
					);
				}}
			>
				<Table.Column<RowServiceType>
					showSorterTooltip={false}
					defaultSortOrder="ascend"
					key="description"
					title="Descrição"
					dataIndex="description"
					sorter
				/>
				<Table.Column<RowServiceType>
					showSorterTooltip={false}
					defaultSortOrder="ascend"
					key="isRequestableInCustomerSituation"
					title={<Tooltip title="É solicitável na situação do cliente?">Cliente</Tooltip>}
					dataIndex="isRequestableInCustomerSituation"
					render={(value, service, index) => (service.is_requestable_in_customer_situation ? <span>Sim</span> : <span>Não</span>)}
				/>
				<Table.Column<RowServiceType>
					showSorterTooltip={false}
					defaultSortOrder="ascend"
					key="isRequestableInStoredSituation"
					title={<Tooltip title="É solicitável na situação de armazenada?">Armazenado</Tooltip>}
					dataIndex="isRequestableInStoredSituation"
					render={(value, service, index) => (service.is_requestable_in_stored_situation ? <span>Sim</span> : <span>Não</span>)}
				/>
				<Table.Column<RowServiceType>
					showSorterTooltip={false}
					defaultSortOrder="ascend"
					key="isRequestableInRegisteredSituation"
					title={<Tooltip title="É solicitável na situação cadastrada?">Cadastrado</Tooltip>}
					dataIndex="isRequestableInRegisteredSituation"
					render={(value, service, index) => (service.is_requestable_in_registered_situation ? <span>Sim</span> : <span>Não</span>)}
				/>
				<Table.Column<RowServiceType>
					showSorterTooltip={false}
					defaultSortOrder="ascend"
					key="isSpecialService"
					title={<Tooltip title="É serviço especial?">Especial</Tooltip>}
					dataIndex="isSpecialService"
					render={(value, service) => (service.is_special_service ? <span>Sim</span> : <span>Não</span>)}
				/>

				<Table.Column<RowServiceType>
					showSorterTooltip={false}
					key="active"
					title="Status"
					dataIndex="active"
					sorter
					render={(value, service) => <D3Status status={service.active} />}
				/>
				<Table.Column<RowServiceType>
					key="action"
					title="Ações"
					width={140}
					render={(value, service) => {
						return (
							<>
								<Space>
									<D3Can action="update" resource="system_service">
										<Tooltip title="Editar" destroyTooltipOnHide mouseLeaveDelay={0} zIndex={11}>
											<Button
												type="text"
												shape="default"
												icon={
													<Icon
														icon="iconamoon:edit-fill"
														height={18}
														width={18}
														className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
													/>
												}
												size={"middle"}
												onClick={() => openDrawer(<UpdateServiceForm cuid={service.cuid} />, "right", 600, "Editar Serviço")}
											/>
										</Tooltip>
									</D3Can>
									<D3Can action="view" resource="system_service">
										<Tooltip title="Visualizar" destroyTooltipOnHide mouseLeaveDelay={0} zIndex={11}>
											<Button
												className="peer"
												type="text"
												shape="default"
												icon={
													<Icon
														icon="carbon:document-view"
														height={18}
														width={18}
														className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
													/>
												}
												size={"middle"}
												onClick={() => openDrawer(<ViewServiceForm cuid={service.cuid} />, "right", 600, "Visualizar Serviço")}
											/>
										</Tooltip>
									</D3Can>
									<D3Can action="lock" resource="system_service">
										<ToggleLockAction
											description="Confirma bloqueio?"
											title="Atenção!"
											isVisible={service.active}
											tooltip="Bloquear"
											paramsToAction={[service.cuid]}
											action={async (params: string[]) => {
												await lockService(params);
											}}
										/>
									</D3Can>
									<D3Can action="unlock" resource="system_service">
										<ToggleLockAction
											title="Atenção!"
											description="Confirma desbloqueio?"
											isVisible={!service.active}
											tooltip="Desbloquear"
											paramsToAction={[service.cuid]}
											action={async (params: string[]) => {
												await unLockService(params);
											}}
										/>
									</D3Can>
								</Space>
							</>
						);
					}}
				/>
			</Table>
		</div>
	);
};

export default ServicesView;
