import { notification } from "antd";
import { BadRequest, StandardError, Success } from "./clientHttp";

type PayloadType = BadRequest | StandardError | Success<any>;

const showMessage = (payload: PayloadType, standardMessage?: string, duration: number = 3) => {
  if ((payload.code === 200 || payload.code === 201) && payload.status === "success") {
    notification.success({
      duration: duration,
      message: "Notificação",
      description: standardMessage ?? "Operação realizada com sucesso",
      placement: "topRight",
    });
  } else if (payload.status === "warning") {
    notification.warning({
      duration: duration,
      message: "Notificação",
      description: standardMessage ?? "Operação pode ter sido realizada parcialmente",
      placement: "topRight",
    });
  }else if (payload.status === "error") {
    let description = "";
    if (
      payload.code === 400 ||
      payload.code === 401 ||
      payload.code === 403 ||
      payload.code === 404 ||
      payload.code === 409 ||
      payload.code === 410 ||
      payload.code === 422 ||
      payload.code === 423 ||
      payload.code === 500
    ) {
      let resultError = payload as StandardError;
      description =
        standardMessage ?? resultError?.body?.message ?? "Ocorreu um erro desconhecido ao realizar a operação";
    } else if (payload.code === 400) {
      let resultError: BadRequest = payload as BadRequest;
      description = resultError?.body?.data
        ? JSON.stringify(resultError.body.data)
        : "Ocorreu um erro desconhecido ao realizar a operação";
    }

    if (description !== "") {
      notification.error({
        message: "Notificação",
        description: description,
        placement: "topRight",
      });
    }
  }
};

export default showMessage;
