import { Badge, Button, Dropdown } from "antd";
import useNavigateStore from "./store";
import { Icon } from "@iconify/react";
import MoveToTrashForm from "../trash/MoveToTrashForm";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { useContext } from "react";

const NavigateFooter = () => {
  const { content, selected, viewType } = useNavigateStore();
  const { openDrawer } = useContext(D3DrawerContext);
  return (
    <div className="z-50 flex items-center justify-between w-full p-2 border-t border-gray-200 shadow-t min-h-12">
      <div>
        {viewType === "list" && (
          <Badge size="small" count={selected.cuids.length} showZero>
            <Dropdown
              menu={{
                items: [
                  {
                    key: "1",
                    label: "Mover para lixeira",
                    onClick: () => openDrawer(<MoveToTrashForm />, "right", 600, "Mover para lixeira"),
                    danger: true,
                    icon: <Icon height={16} icon="ic:baseline-auto-delete" />,
                  },
                ],
              }}
            >
              <Button type="dashed" size="small">
                {selected.cuids.length > 1 ? "Selecionados" : "Selecionado"}
              </Button>
            </Dropdown>
          </Badge>
        )}
      </div>
      <span className="text-sm text-gray-600 whitespace-nowrap">
        Exibindo <strong>{content.children.length}</strong> do total de <strong>{content.total_children}</strong>{" "}
        registros.
      </span>
    </div>
  );
};

export default NavigateFooter;
