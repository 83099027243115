import { Button, DatePicker, Form, Input, Space } from "antd";
import { useForm } from "antd/es/form/Form";
import { CreateRescissionType } from "../types";
import { useContext } from "react";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { useContractStore } from "../store";
import dayjs from "dayjs";

type CreateRescissionFormProps = {
  contractCuid: string;
};

function CreateRescissionForm({ contractCuid }: CreateRescissionFormProps) {
  const [form] = useForm<CreateRescissionType>();
  const { createRescission, loadingCreateRescission } = useContractStore();
  const { onClose } = useContext(D3DrawerContext);

  return (
    <Form
      form={form}
      layout="vertical"
      className="p-4 select-none"
      onFinish={async (values: CreateRescissionType) => {
        const isSuccess = await createRescission(contractCuid, values);
        if (isSuccess) {
          onClose();
        }
      }}
    >
      <Form.Item<CreateRescissionType>
        name="description"
        label="Descrição"
        rules={[
          {
            required: true,
            message: "Por favor, preencha a descrição.",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item<CreateRescissionType>
        className="w-48"
        name="date"
        label="Data"
        rules={[
          {
            required: true,
            message: "Por favor, selecione a data.",
          },
        ]}
        getValueProps={(value) => value}
        normalize={(value) => (value ? dayjs(value).format("YYYY-MM-DD") : "")}
      >
        <DatePicker format="DD/MM/YYYY" />
      </Form.Item>

      <Form.Item>
        <div className="text-right">
          <Space size="small">
            <Button type="default" danger onClick={() => onClose()}>
              Cancelar
            </Button>

            <Button loading={loadingCreateRescission} type="primary" htmlType="submit">
              Cadastrar
            </Button>
          </Space>
        </div>
      </Form.Item>
    </Form>
  );
}

export default CreateRescissionForm;
