import { Spin } from "antd";

type SpinLoadingType = {
  size?: "small" | "default" | "large";
};

function SpinLoading({ size = "small" }: SpinLoadingType) {
  return (
    <div className="flex items-center justify-center w-full h-full">
      <Spin size={size} />
    </div>
  );
}

export default SpinLoading;
