import { Input, Form, Radio, Row, Spin, Divider } from "antd";
import { useCustomerStore } from "../../store";
import { useEffect, useState } from "react";
import { PatternFormat } from "react-number-format";
import { ViewCustomerType } from "../../types";
import showMessage from "@utils/showMessage";
import { D3Date } from "@utils/date";

function TabData() {
  const [typeDocument, setTypeDocument] = useState("CNPJ");
  const [loading, setLoading] = useState(true);

  const [form] = Form.useForm<ViewCustomerType>();

  const { getCustomer } = useCustomerStore();

  useEffect(() => {
    (async () => {
      const result = await getCustomer();
      if (result.status === "success") {
        const viewCustomer = result.body as ViewCustomerType;
        form.setFieldsValue({
          ...viewCustomer,
          created_at: D3Date.FormatValueApiToPtBR(viewCustomer.created_at),
          updated_at: D3Date.FormatValueApiToPtBR(viewCustomer.updated_at),
        });
        setTypeDocument(viewCustomer.document_type)
      } else {
        showMessage(result);
      }
      setLoading(false);
    })();
  }, [getCustomer, form]);

  return (
    <div className="p-2">
      <Spin spinning={loading}>
        <h2 className="ml-1 text-lg font-semibold text-gray-600">
          Dados do Cliente
        </h2>
        <Divider className="mt-1 mb-6 " />
        <Form
          className="w-[650px] select-none"
          form={form}
          layout="vertical"
          autoComplete="off"
        >
          <Form.Item<ViewCustomerType>
            label="Nome fantasia"
            name="fantasy_name"
          >
            <Input disabled />
          </Form.Item>
          <Form.Item<ViewCustomerType> label="Razão social" name="name">
            <Input disabled />
          </Form.Item>
          <Form.Item<ViewCustomerType> label="Pessoa" name="document_type">
            <Radio.Group
              disabled
              options={[
                { label: "Física", value: "CPF" },
                { label: "Jurídica", value: "CNPJ" },
              ]}
              onChange={(e) => {
                setTypeDocument(e.target.value);
                form.setFieldsValue({ document_type: e.target.value });
              }}
              optionType="button"
              buttonStyle="solid"
            />
          </Form.Item>
          {typeDocument === "CPF" ? (
            <Form.Item<ViewCustomerType>
              label="CPF"
              name="document"
            >
              <PatternFormat
                disabled
                format="###.###.###-##"
                customInput={Input}
              />
            </Form.Item>
          ) : (
            <Row className="flex items-center justify-between">
              <Form.Item<ViewCustomerType>
                label="CNPJ"
                className="w-[312px]"
                name="document"
              >
                <PatternFormat
                  disabled
                  format="##.###.###/####-##"
                  customInput={Input}
                />
              </Form.Item>

              <Form.Item<ViewCustomerType>
                label="Inscrição estadual"
                className="w-[312px]"
                name="state_inscription"
              >
                <Input disabled />
              </Form.Item>
            </Row>
          )}

          <Form.Item<ViewCustomerType>
            label="Responsável pelo contrato"
            name="contract_manager"
          >
            <Input disabled />
          </Form.Item>

          <Row className="flex items-center justify-between">
            <Form.Item<ViewCustomerType>
              className="w-[380px]"
              name={["customer_group", "description"]}
              label="Grupo"
            >
              <Input disabled />
            </Form.Item>
            <Form.Item<ViewCustomerType> name="active" label="Status">
              <Radio.Group
                disabled
                options={[
                  { label: "Desbloqueado", value: true },
                  { label: "Bloqueado", value: false },
                ]}
              />
            </Form.Item>
          </Row>

          <Row className="flex items-center justify-between">
            <Form.Item<ViewCustomerType>
              className="w-[315px]"
              name="created_at"
              label="Criado em"
            >
              <Input disabled />
            </Form.Item>
            <Form.Item<ViewCustomerType>
              className="w-[315px]"
              name="updated_at"
              label="Atualizado em"
            >
              <Input disabled />
            </Form.Item>
          </Row>
        </Form>
      </Spin>
    </div>
  );
}

export default TabData;
