import { Form, Spin, Table, Tag } from "antd";
import { ViewCustomerUserType } from "../../types";
import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";

type TabDepartmentsProps = {
  data: ViewCustomerUserType | undefined;
};

function TabDepartments({ data }: TabDepartmentsProps) {
  const [form] = Form.useForm<ViewCustomerUserType>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (data !== undefined) {
      form.setFieldsValue(data);
    }
    setLoading(false);
  }, [data, form]);

  return (
    <>
      <Spin spinning={loading}>
        <Form layout="vertical" form={form}>
          <Form.Item<ViewCustomerUserType>
            label="Departamentos do usuário"
            name="departments"
          >
            <Table<ViewCustomerUserType>
              rowKey={(record) => record.cuid}
              size="small"
              pagination={false}
              dataSource={form.getFieldValue("departments")}
              bordered
            >
              <Table.Column<ViewCustomerUserType>
                showSorterTooltip={false}
                defaultSortOrder="ascend"
                key="description"
                title="Nome"
                dataIndex="description"
              />
                <Table.Column<ViewCustomerUserType>
                showSorterTooltip={false}
                defaultSortOrder="ascend"
                key="abbreviation"
                title="Abreviação"
                dataIndex="abbreviation"
              />
              <Table.Column<ViewCustomerUserType>
                showSorterTooltip={false}
                key="active"
                title="Status"
                dataIndex="active"
                render={(value, permissiongroup, index) =>
                  permissiongroup.active ? (
                    <Tag
                      className="flex items-center justify-start gap-1 max-w-max"
                      icon={
                        <Icon
                          icon="basil:unlock-solid"
                          height={14}
                          width={14}
                        />
                      }
                      color="success"
                    >
                      Desbloqueado
                    </Tag>
                  ) : (
                    <Tag
                      className="flex items-center justify-start gap-1 max-w-max"
                      icon={
                        <Icon icon="basil:lock-solid" height={14} width={14} />
                      }
                      color="error"
                    >
                      Bloqueado
                    </Tag>
                  )
                }
              />
            </Table>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
}

export default TabDepartments;
