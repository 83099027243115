import { create } from "zustand";
import {
  CreateCustomerGroupUserType,
  ListCustomerGroupUserType,
  LockOrUnlockPayloadType,
  RowCustomerGroupUserType,
  SearchCustomerGroupUserType,
  UpdateCustomerGroupUserType,
  ViewCustomerGroupUserType,
} from "./types";

import showMessage from "@utils/showMessage";

import D3QSGenerator, { FilterDefinition } from "@utils/D3QSGenerator";
import { BadRequest, ClientHttp, StandardError, Success } from "@utils/clientHttp";
import IsValidMultiSelectedRow from "@utils/table/IsValidMultiSelectedRow";
import { SetSort, SortType } from "@utils/table/SetSort";

type CustomerGroupUserStoreState = {
  apiResult: ListCustomerGroupUserType;
  selectedRows: { cuids: string[]; active: boolean };
  filter: SearchCustomerGroupUserType;
  pagination: { page: number; pageSize: number };
  sort: { sort_by: string; sort_direction: string };
  loading: boolean;
  setFilter: (filter: SearchCustomerGroupUserType) => void;
  setPagination: (page: number, pageSize: number) => void;
  setSort: (newSort: SortType) => void;
  loadCustomerGroupUsers: () => Promise<Success<ListCustomerGroupUserType> | BadRequest | StandardError>;
  getCustomerGroupUser: (cuid: string) => Promise<Success<ViewCustomerGroupUserType> | StandardError>;
  createCustomerGroupUser: (
    customerGroupUser: CreateCustomerGroupUserType
  ) => Promise<Success<{ data: { cuid: string } }> | BadRequest | StandardError>;
  updateCustomerGroupUser: (cuid: string, customerGroupUser: UpdateCustomerGroupUserType) => Promise<boolean>;
  loadingUpdate: boolean;
  lockCustomerGroupUser: (cuids: string[]) => Promise<void>;
  unLockCustomerGroupUser: (cuids: string[]) => Promise<void>;
  onMultiSelectedRowChange: (selectedRows: RowCustomerGroupUserType[]) => void;
  generatePassword: (userCuid: string) => Promise<void>;
  resetSelectedRows: () => void;
  resetFilter: () => void;
  resetState: () => void;
};

const defaultSort = {
  sort_by: "user_name",
  sort_direction: "asc",
};

const operationsFilter: FilterDefinition = {
  user_name: { op: "lk", type: "string" },
  user_email: { op: "lk", type: "string" },
  user_username: { op: "lk", type: "string" },
  user_active: { op: "eq", type: "active" },
};

const defaultState = {
  apiResult: {
    total: 0,
    data: [],
  },
  selectedRows: {
    cuids: [],
    active: false,
  },
  filter: {
    user_name: "",
    user_email: "",
    user_username: "",
    user_active: "on",
  } as SearchCustomerGroupUserType,
  pagination: {
    page: 1,
    pageSize: 10,
  },
  sort: defaultSort,
  loading: false,
  loadingUpdate: false,
};

export const useCustomerGroupUsersStore = create<CustomerGroupUserStoreState>((set, get) => ({
  ...defaultState,
  resetState: () => set(defaultState),
  setFilter: (filter: SearchCustomerGroupUserType) => {
    set({
      filter,
      selectedRows: { cuids: [], active: false },
      pagination: { ...get().pagination, page: 1 },
    });
    get().loadCustomerGroupUsers();
  },
  onMultiSelectedRowChange: (selectedRows: RowCustomerGroupUserType[]): void =>
    IsValidMultiSelectedRow(selectedRows, get, set),
  resetSelectedRows: () => {
    set({ selectedRows: { cuids: [], active: false } });
  },
  resetFilter: () => {
    set({
      filter: {
        user_name: "",
        user_email: "",
        user_username: "",
        user_active: "on",
      },
    });
    get().loadCustomerGroupUsers();
  },
  setPagination: (page: number, pageSize: number) => {
    if (pageSize !== get().pagination.pageSize) {
      page = 1;
    }
    set({ pagination: { page, pageSize } });
    get().resetSelectedRows();
    get().loadCustomerGroupUsers();
  },
  setSort: (newSort: SortType) =>
    SetSort(
      newSort,
      get().sort,
      defaultSort,
      new Map<string, string>([
        ["name", "user_name"], // Column name from table and column name from api
        ["username", "user_username"],
        ["email", "user_email"],
        ["active", "user_active"],
      ]),
      set,
      get().loadCustomerGroupUsers
    ),
  loadCustomerGroupUsers: async (): Promise<Success<ListCustomerGroupUserType> | BadRequest | StandardError> => {
    set({ loading: true });

    let qs = D3QSGenerator(get().filter, operationsFilter, get().pagination, get().sort);

    return await new ClientHttp().get<Success<ListCustomerGroupUserType>, BadRequest | StandardError>(
      `/api/v1/customer-group/users?${qs}`,
      (result: Success<ListCustomerGroupUserType>) => {
        let newPage = get().pagination.page;
        const { body } = result;
        if (body.total > 0 && body.data.length === 0) {
          newPage = newPage - 1;
          set({
            apiResult: body,
            loading: false,
            pagination: { ...get().pagination, page: newPage },
          });
          get().loadCustomerGroupUsers();
        } else {
          set({ apiResult: body, loading: false });
        }
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
  },
  getCustomerGroupUser: async (cuid: string): Promise<Success<ViewCustomerGroupUserType> | StandardError> => {
    return await new ClientHttp().get<Success<ViewCustomerGroupUserType>, StandardError>(
      `/api/v1/customer-group/users/${cuid}`
    );
  },
  createCustomerGroupUser: async (
    customerGroupUser: CreateCustomerGroupUserType
  ): Promise<Success<{ data: { cuid: string } }> | BadRequest | StandardError> => {
    return await new ClientHttp().post<
      CreateCustomerGroupUserType,
      Success<{ data: { cuid: string } }>,
      BadRequest | StandardError
    >("/api/v1/customer-group/users", customerGroupUser, (result: Success<{ data: { cuid: string } }>) => {
      get().loadCustomerGroupUsers();
    });
  },
  updateCustomerGroupUser: async (cuid: string, customerGroupUser: UpdateCustomerGroupUserType): Promise<boolean> => {
    set({ loadingUpdate: true });
    const result = await new ClientHttp().put<UpdateCustomerGroupUserType, Success<void>, BadRequest | StandardError>(
      `/api/v1/customer-group/users/${cuid}`,
      customerGroupUser,
      (result: Success<void>) => {
        showMessage(result, "Usuário atualizado com sucesso.");
        get().loadCustomerGroupUsers();
        set({ loadingUpdate: false });
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
        set({ loadingUpdate: false });
      }
    );
    return result.status === "success";
  },
  lockCustomerGroupUser: async (cuids: string[]): Promise<void> => {
    await new ClientHttp().patch<
      LockOrUnlockPayloadType,
      Success<{ rows_affected: number; status: string }>,
      BadRequest | StandardError
    >(
      `/api/v1/customer-group/users/lock`,
      { cuids: cuids },
      (result: Success<{ rows_affected: number; status: string }>) => {
        get().resetSelectedRows();
        get().loadCustomerGroupUsers();
        if (result.body.rows_affected > 1) {
          showMessage(result, "Itens bloqueados com sucesso.");
        } else {
          showMessage(result, "Item bloqueado com sucesso.");
        }
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
  },
  unLockCustomerGroupUser: async (cuids: string[]): Promise<void> => {
    await new ClientHttp().patch<
      LockOrUnlockPayloadType,
      Success<{ rows_affected: number; status: string }>,
      BadRequest | StandardError
    >(
      `/api/v1/customer-group/users/unlock`,
      { cuids: cuids },
      (result: Success<{ rows_affected: number; status: string }>) => {
        get().resetSelectedRows();
        get().loadCustomerGroupUsers();
        if (result.body.rows_affected > 1) {
          showMessage(result, "Itens desbloqueados com sucesso.");
        } else {
          showMessage(result, "Item desbloqueado com sucesso.");
        }
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
  },
  generatePassword: async (userCuid: string): Promise<void> => {
    await new ClientHttp().patch<null, Success<void>, BadRequest | StandardError>(
      `/api/v1/customer-group/users/${userCuid}/generate-password`,
      null,
      (result: Success<void>) => {
        showMessage(result, "Nova senha enviada com sucesso.");
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
  },
}));
