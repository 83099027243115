import { create } from "zustand";
import { ClientHttp, StandardError, Success } from "@utils/clientHttp";
import showMessage from "@utils/showMessage";
import {
  ItemDocumentTypesType,
  ItemIndexerType,
  SimpleListDocumentTypesType,
  SimpleListIndexesType,
} from "./types";

type DeleteDocumentTypeIndexersStoreState = {
  simpleListDocumentType: () => Promise<ItemDocumentTypesType[]>;
  simpleListIndexes: (documentTypeCuid: string) => Promise<ItemIndexerType[]>;
  deleteIndexers: (documentTypeCuid: string, documentTypeIndexID: string) => Promise<Success<void> | StandardError>
};

export const useDeleteDocumentTypeIndexers =
  create<DeleteDocumentTypeIndexersStoreState>((set, get) => ({
    simpleListDocumentType: async (): Promise<ItemDocumentTypesType[]> => {
      return await new ClientHttp().getItensForSelect<
        Success<SimpleListDocumentTypesType>,
        StandardError,
        ItemDocumentTypesType[]
      >(
        "/api/v1/customer/document-types/simple-list",
        (
          result: Success<SimpleListDocumentTypesType>
        ): ItemDocumentTypesType[] => {
          return result.body.data as ItemDocumentTypesType[];
        },
        (error: StandardError): ItemDocumentTypesType[] => {
          showMessage(error, "Erro ao carregar lista de tipos de documentos");
          return [] as ItemDocumentTypesType[];
        }
      );
    },
    simpleListIndexes: async (
      documentTypeCuid: string
    ): Promise<ItemIndexerType[]> => {
      return await new ClientHttp().getItensForSelect<
        Success<SimpleListIndexesType>,
        StandardError,
        ItemIndexerType[]
      >(
        `/api/v1/customer/document-types/${documentTypeCuid}/document-type-indexes`,
        (result: Success<SimpleListIndexesType>): ItemIndexerType[] => {
          return result.body.data as ItemIndexerType[];
        },
        (error: StandardError): ItemIndexerType[] => {
          showMessage(error, "Erro ao carregar lista de tipos de documentos");
          return [] as ItemIndexerType[];
        }
      );
    },
    deleteIndexers: async (
      documentTypeCuid: string,
      documentTypeIndexID: string
    ): Promise<Success<void> | StandardError> => {
      return await new ClientHttp().delete<Success<void>, StandardError>(
        `/api/v1/admin-tool/customer/document-types/${documentTypeCuid}/document-type-indexes/${documentTypeIndexID}`,
        (result: Success<void>) => {
          showMessage(result, "Indexador excluído com sucesso.");
        },
        (result: StandardError) => {
          showMessage(result);
        }
      );
    },
  }));
