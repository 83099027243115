import { Icon } from "@iconify/react";
import { Button, Popover, Space, Tooltip } from "antd";
import Table from "antd/es/table";
import D3Can from "@components/shared/D3Can";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { useContactTypesStore } from "./store";
import { ItemListContactTypesType } from "./types";
import { useContext, useEffect, useState } from "react";
import { SearchContactTypeForm } from "./components/SearchContactTypeForm";
import { CreateContactTypeForm } from "./components/CreateContactTypeForm";
import { UpdateContactTypeForm } from "./components/UpdateContactTypeForm";
import { ProfileList, profileList } from "./components/listProfile";

export default function ContactTypesView() {
  const [openSearch, setOpenSearch] = useState(false);
  const { openDrawer } = useContext(D3DrawerContext);
  const { apiResult, loadContactTypes, loading, resetFilter, resetState } = useContactTypesStore();

  useEffect(() => {
    (async () => {
      await loadContactTypes();
    })();
    return () => resetState();
  }, [loadContactTypes]);

  return (
    <div className="flex flex-col h-full mb-1 mr-1 overflow-auto bg-white rounded shadow">
      <Table<ItemListContactTypesType>
        size="small"
        rowKey="id"
        dataSource={apiResult.data}
        bordered
        className="responsive-table"
        pagination={false}
        loading={loading}
        title={() => {
          return (
            <>
              <div className="flex items-center justify-between w-full">
                <div className="flex items-center justify-start">
                  <Popover
                    open={openSearch}
                    placement="rightTop"
                    title="Pesquisar tipo de contato"
                    content={
                      <SearchContactTypeForm
                        onCallBackFilter={() => setOpenSearch(false)}
                        onCallBackResetFilter={() => {
                          setOpenSearch(false);
                          resetFilter();
                        }}
                      />
                    }
                    trigger="click"
                    onOpenChange={(open) => setOpenSearch(open)}
                  >
                    <Button
                      onClick={() => setOpenSearch(!openSearch)}
                      type="primary"
                      size="middle"
                      icon={<Icon icon="fluent:search-12-filled" height={16} width={16} />}
                    >
                      Pesquisar
                    </Button>
                  </Popover>
                </div>
                <div className="flex items-center justify-center">
                  <span className="text-xl font-semibold text-gray-600">Tipos de contato</span>
                </div>
                <div className="flex items-center justify-end">
                  <D3Can action="manager" resource="all">
                    <Button
                      type="primary"
                      size="middle"
                      icon={<Icon icon="ic:round-plus" height={20} width={20} />}
                      onClick={() => openDrawer(<CreateContactTypeForm />, "right", 600, "Cadastrar tipo de contato")}
                    >
                      Novo tipo de contato
                    </Button>
                  </D3Can>
                </div>
              </div>
            </>
          );
        }}
      >
        <Table.Column<ItemListContactTypesType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="description"
          title="Descrição"
          dataIndex="description"
        />
        <Table.Column<ItemListContactTypesType>
          showSorterTooltip={false}
          key="profile"
          title="Perfil"
          dataIndex="profile"
          render={(value: ProfileList) => <>{profileList[value]}</>}
        />
        <Table.Column<ItemListContactTypesType>
          key="action"
          title="Ações"
          align="center"
          width={80}
          render={(value, record) => {
            return (
              <>
                <Space>
                  <D3Can action="manager" resource="all">
                    <Tooltip title="Editar" destroyTooltipOnHide mouseLeaveDelay={0} zIndex={11}>
                      <Button
                        type="text"
                        shape="default"
                        icon={
                          <Icon
                            icon="iconamoon:edit-fill"
                            height={18}
                            width={18}
                            className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
                          />
                        }
                        size={"middle"}
                        onClick={() =>
                          openDrawer(
                            <UpdateContactTypeForm
                              id={record.id}
                              description={record.description}
                              profile={record.profile}
                            />,
                            "right",
                            600,
                            "Editar tipo de contato"
                          )
                        }
                      />
                    </Tooltip>
                  </D3Can>
                </Space>
              </>
            );
          }}
        />
      </Table>
    </div>
  );
}
