import { useState } from "react";
import { Form, Input } from "antd";
import { NamePath } from "antd/es/form/interface";
import { FormInstance } from "antd/lib";

const formatToBRL = (value: number) => {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
  }).format(value);
};

const parseInputValue = (value: string) => {
  return parseFloat(value.replace(/\./g, "").replace(/,/g, "."));
};

interface AppProps {
  numberType?: "positive" | "negative" | "both";
  name: NamePath;
  label: string;
  required?: boolean;
  form: FormInstance<any>;
}

const InputMoney: React.FC<AppProps> = ({ numberType = "both", name, label, required = false, form }) => {
  const [inputValue, setInputValue] = useState<string>("");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let rawValue = e.target.value.replace(/[^\d+-]/g, "");
    const hasNegativeSign = rawValue.includes("-");
    const hasPositiveSign = rawValue.includes("+");
    rawValue = rawValue.replace(/[-+]/g, "");

    if (numberType === "positive" && hasNegativeSign) {
      rawValue = rawValue;
    } else if (numberType === "negative" && !hasNegativeSign) {
      rawValue = "-" + rawValue;
    } else if (numberType === "both" && hasPositiveSign && hasNegativeSign) {
      rawValue = rawValue;
    }

    const floatValue = rawValue ? parseInputValue(rawValue) : 0;
    const finalValue =
      hasNegativeSign && numberType !== "positive" && !hasPositiveSign ? -floatValue / 100 : floatValue / 100;

    setInputValue(hasNegativeSign && numberType !== "positive" && !hasPositiveSign ? `-${rawValue}` : rawValue);
    form.setFieldValue(name, finalValue);
  };

  const normalizeValue = (value: string) => {
    let rawValue = value.replace(/[^\d+-]/g, "");
    const hasNegativeSign = rawValue.includes("-");
    const hasPositiveSign = rawValue.includes("+");
    rawValue = rawValue.replace(/[-+]/g, "");

    if (numberType === "positive" && hasNegativeSign) {
      rawValue = rawValue;
    } else if (numberType === "negative" && !hasNegativeSign) {
      rawValue = "-" + rawValue;
    } else if (numberType === "both" && hasPositiveSign && hasNegativeSign) {
      rawValue = rawValue;
    }

    return rawValue
      ? (hasNegativeSign && numberType !== "positive" && !hasPositiveSign
          ? -parseInputValue(rawValue)
          : parseInputValue(rawValue)) / 100
      : 0;
  };

  const getValueProps = (value: number) => {
    return {
      value: value !== undefined ? (value < 0 ? `-${formatToBRL(-value)}` : formatToBRL(value)) : "",
    };
  };

  return (
    <Form.Item
      name={name}
      label={label}
      normalize={normalizeValue}
      getValueProps={getValueProps}
      rules={[
        {
          required: required,
          message: `Por favor, insira o ${label.toLowerCase()}.`,
        },
      ]}
    >
      <Input placeholder="R$ 0,00" className="w-32" value={inputValue} onChange={handleInputChange} />
    </Form.Item>
  );
};
export default InputMoney;
