import { Form } from "antd";
import { MaskedInput } from "antd-mask-input";
import { NamePath } from "antd/es/form/interface";

type FormItemPhoneNumberProps = {
  required?: boolean;
  name: NamePath;
  label?: string;
  type?: "phone" | "landline";
  value?: string;
  disabled?: boolean;
};

const DynamicPhone = (props: any) => {
  const cellphoneMask = "(00) 0 0000-0000";
  const phoneMask = "(00) 0000-0000";

  return (
    <MaskedInput
      {...props}
      mask={props.type === "phone" ? cellphoneMask : phoneMask}
      maskOptions={{
        dispatch: props.value
          ? undefined
          : function (_, dynamicMasked) {
              const isCellPhone = dynamicMasked.unmaskedValue[2] === "9";
              return isCellPhone ? cellphoneMask : phoneMask;
            },
      }}
    />
  );
};

function FormItemPhoneNumber({
  required = false,
  name,
  label = "Telefone",
  type,
  value,
  disabled = false,
}: FormItemPhoneNumberProps) {
  return (
    <Form.Item
      label={label}
      name={name}
      rules={[{ required: required, message: " telefone." }]}
      getValueProps={(value) => ({ value })}
      normalize={(value) => value.replace(/[^\d]/g, "")}
    >
      <DynamicPhone disabled={disabled} value={value} type={type} />
    </Form.Item>
  );
}

export default FormItemPhoneNumber;
