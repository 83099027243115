import { D3DrawerContext } from "@provider/D3DrawerContext";
import { Button, Form, Tabs, Tooltip } from "antd";
import { useContext, useEffect } from "react";
import SearchCriterionForm from "./SearchCriterionForm";
import SearchDocumentForm from "./SearchDocumentForm";
import SearchRepositoryForm from "./SearchRepositoryForm";
import SearchSortByForm from "./SearchSortByForm ";
import { SearchType } from "./types";
import SearchMarkersForm from "./SearchMarkersForm";
import { Icon } from "@iconify/react";
import useSearchStore from "./store";
import useNavigateStore from "../../store";
import { useNavigate } from "react-router-dom";

type SearchOperationFormProps = {
  onCallBackResetFilter: () => void;
};

export const SearchNavigateForm = ({ onCallBackResetFilter }: SearchOperationFormProps) => {
  const navigate = useNavigate();
  const [form] = Form.useForm<SearchType>();
  const { filter, setFilter, resetPartFilter, diffFilter } = useSearchStore();
  const { onClose } = useContext(D3DrawerContext);

  useEffect(() => {
    (() => {
      form.setFieldsValue(filter);
    })();
  }, [form, filter]);
  return (
    <div className="flex flex-col w-full h-full overflow-hidden ">
      <Form
        className="flex h-full overflow-hidden grow "
        layout="vertical"
        form={form}
        onFinish={async (values) => {
          if (diffFilter("", values)) {
            setFilter(values);
            useNavigateStore.setState({ isFilter: true });
            onClose();
          } else {
            onCallBackResetFilter();
            useNavigateStore.setState({ isFilter: false });
            navigate("/app/customer/spaces/work");
            onClose();
          }
        }}
        autoComplete="off"
      >
        <Tabs
          className="w-full select-none tab-filter-work"
          items={[
            {
              key: "filter_document_type",
              label: (
                <div className="items-center">
                  {diffFilter("filter_document_type") ? (
                    <div className="flex items-center justify-start ">
                      Critério
                      <Tooltip title="Limpar filtro">
                        <Button
                          className=" max-h-[22px]"
                          danger
                          type="link"
                          icon={<Icon icon="mdi:filter-off" />}
                          onClick={() => resetPartFilter("filter_document_type" as keyof SearchType)}
                        />
                      </Tooltip>
                    </div>
                  ) : (
                    <div className="flex items-center justify-start">Critério</div>
                  )}
                </div>
              ),
              children: <SearchCriterionForm form={form} />,
            },
            {
              key: "filter_document",
              label: (
                <div className="items-center">
                  {diffFilter("filter_document") ? (
                    <div className="flex items-center justify-start ">
                      Documento
                      <Tooltip title="Limpar filtro">
                        <Button
                          className=" max-h-[22px]"
                          danger
                          type="link"
                          icon={<Icon icon="mdi:filter-off" />}
                          onClick={() => resetPartFilter("filter_document" as keyof SearchType)}
                        />
                      </Tooltip>
                    </div>
                  ) : (
                    <div className="flex items-center justify-start">Documento</div>
                  )}
                </div>
              ),
              children: <SearchDocumentForm form={form} />,
            },
            {
              key: "filter_repository",
              label: (
                <div className="items-center">
                  {diffFilter("filter_repository") ? (
                    <div className="flex items-center justify-start ">
                      Repositório
                      <Tooltip title="Limpar filtro">
                        <Button
                          className=" max-h-[22px]"
                          danger
                          type="link"
                          icon={<Icon icon="mdi:filter-off" />}
                          onClick={() => resetPartFilter("filter_repository" as keyof SearchType)}
                        />
                      </Tooltip>
                    </div>
                  ) : (
                    <div className="flex items-center justify-start">Repositório</div>
                  )}
                </div>
              ),
              children: <SearchRepositoryForm form={form} />,
            },
            {
              key: "filter_marker",
              label: (
                <div className="items-center">
                  {diffFilter("filter_marker") ? (
                    <div className="flex items-center justify-start ">
                      Marcadores
                      <Tooltip title="Limpar filtro">
                        <Button
                          className=" max-h-[22px]"
                          danger
                          type="link"
                          icon={<Icon icon="mdi:filter-off" />}
                          onClick={() => resetPartFilter("filter_marker" as keyof SearchType)}
                        />
                      </Tooltip>
                    </div>
                  ) : (
                    <div className="flex items-center justify-start">Marcadores</div>
                  )}
                </div>
              ),
              children: <SearchMarkersForm form={form} />,
            },
            {
              key: "config",
              label: (
                <div className="items-center">
                  {diffFilter("config.sort") ? (
                    <div className="flex items-center justify-start ">
                      Ordenação
                      <Tooltip title="Limpar filtro">
                        <Button
                          className=" max-h-[22px]"
                          danger
                          type="link"
                          icon={<Icon icon="mdi:filter-off" />}
                          onClick={() => resetPartFilter("config" as keyof SearchType)}
                        />
                      </Tooltip>
                    </div>
                  ) : (
                    <div className="flex items-center justify-start">Ordenação</div>
                  )}
                </div>
              ),
              children: <SearchSortByForm />,
            },
          ]}
        />
      </Form>
      <div className="flex gap-3 border-t-[1px] border-gray-100  items-center justify-end px-2 min-h-12 ">
        <Button
          type="default"
          danger
          onClick={() => {
            onCallBackResetFilter();
            useNavigateStore.setState({ isFilter: false });
            navigate("/app/customer/spaces/work");
            onClose();
          }}
        >
          Limpar Filtro
        </Button>
        <Button type="primary" onClick={() => form.submit()}>
          Filtrar
        </Button>
      </div>
    </div>
  );
};
