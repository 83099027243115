import D3Can from "@components/shared/D3Can";
import D3DateHourSorter from "@utils/D3DateHourSorter";
import { Button, Form, Input, Space } from "antd";
import { useEffect } from "react";
import { useDefaultPricesStore } from "./store";
import { DefaultPricesType } from "./types";
import FormItemInputMoney from "@components/shared/FormItemInputMoney";

function DefaultPrice() {
  const { getDefaultPrices, updateDefaultPrices } = useDefaultPricesStore();
  const [form] = Form.useForm<DefaultPricesType>();

  useEffect(() => {
    (async () => {
      const result = await getDefaultPrices();
      if (result.status === "success") {
        const data = result.body as DefaultPricesType;
        form.setFieldsValue({
          ...data,
          updated_at: D3DateHourSorter(data.updated_at),
        });
      }
    })();
  }, [getDefaultPrices]);

  return (
    <div className="flex flex-col h-full p-2 overflow-hidden w-80">
      <Form
        className="form-item-for-table"
        form={form}
        layout="vertical"
        onFinish={async (payload: DefaultPricesType) => {
          await updateDefaultPrices(payload);
        }}
      >
        <div className="h-full pr-2 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100">
          <FormItemInputMoney
            form={form}
            numberType="positive"
            name="repository_registration"
            label="Cadastro de repositório"
            required
          />
          <FormItemInputMoney
            form={form}
            numberType="positive"
            name="document_registration"
            label="Cadastro de documento"
            required
          />
          <FormItemInputMoney
            form={form}
            numberType="positive"
            name="digitalized_page"
            label="Digitalização"
            required
          />
          <FormItemInputMoney
            form={form}
            numberType="positive"
            name="repository_deployment"
            label="Implantação de repositório"
            required
          />
          <FormItemInputMoney
            form={form}
            numberType="positive"
            name="suspended_folder_deployment"
            label="Implantação pasta suspensa"
            required
          />
          <FormItemInputMoney
            form={form}
            numberType="positive"
            name="gigabyte_storage"
            label="Armazenamento de imagem em Gigabyte"
            required
          />
          <Form.Item name="updated_by" label="Atualizado por">
            <Input disabled />
          </Form.Item>
          <Form.Item name="updated_at" label="Atualizado em">
            <Input disabled />
          </Form.Item>
        </div>
      </Form>
      <div className="h-8 pr-4 my-2">
        <Form.Item>
          <div className="text-right">
            <Space size="small">
              <D3Can action="view" resource="company">
                <Button type="primary" htmlType="submit" onClick={() => form.submit()}>
                  Atualizar
                </Button>
              </D3Can>
            </Space>
          </div>
        </Form.Item>
      </div>
    </div>
  );
}

export default DefaultPrice;
