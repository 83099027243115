import { create } from "zustand";
import { BreadCrumbItemTypeDto } from "../../types";

type BreadCrumbStoreType = {
  breadCrumb: BreadCrumbItemTypeDto[];
  setBreadCrumb: (breadCrumb: BreadCrumbItemTypeDto[]) => void;
};

const defaultState = {
  breadCrumb: [{ cuid: "", name: "", type: "" }],
};

const useBreadCrumbStore = create<BreadCrumbStoreType>((set, get) => ({
  ...defaultState,
  setBreadCrumb: (breadCrumb: BreadCrumbItemTypeDto[]) => {
    set({
      breadCrumb,
    });
  },
}));

export default useBreadCrumbStore;
