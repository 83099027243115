import { Icon } from "@iconify/react";
import { Button, Checkbox, Col, ColorPicker, Form, Input, Row, Switch, Table } from "antd";
import { useEffect } from "react";
import { CurrencyInput } from "react-currency-mask";
import "../style.css";
import { useCustomerGroupMaterialStore } from "./store";
import { ListItemType } from "./types";

function ListMaterial() {
  const {
    savePriceSettings,
    alteredMaterials,
    loadItemsList,
    apiResult,
    loading,
    loadingSave,
    editApiResult,
    resetState,
  } = useCustomerGroupMaterialStore();

  useEffect(() => {
    (async () => {
      await loadItemsList();
    })();
    return () => {
      resetState();
    };
  }, [loadItemsList]);

  const columns = [
    {
      title: "Ativo?",
      dataIndex: "active",
      key: "active",
      align: "center" as const,
      width: 100,
      render: (_: any, record: ListItemType) => (
        <Switch
          disabled={!record.is_allowed_active_or_inactive}
          checkedChildren={
            record.is_allowed_active_or_inactive ? (
              "Sim"
            ) : (
              <Icon icon="material-symbols:lock" height={14} className="mt-1 ml-2 text-white" />
            )
          }
          unCheckedChildren={
            record.is_allowed_active_or_inactive ? (
              "Não"
            ) : (
              <Icon icon="material-symbols:lock" height={14} className="mt-2 mr-2 text-gray-500" />
            )
          }
          defaultChecked={record.active}
          onChange={(isActive) => {
            editApiResult([
              {
                material_cuid: record.material_cuid,
                field: "active",
                value: isActive,
              },
            ]);
          }}
        />
      ),
    },
    {
      title: "Descrição",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Preço padrão",
      dataIndex: "price_default",
      key: "price_default",
      render: (_: any, record: ListItemType) => (
        <span>
          {record.price_default.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          })}
        </span>
      ),
    },
    {
      title: "Aplicar preço padrão?",
      dataIndex: "apply_default_price",
      key: "apply_default_price",
      align: "center" as const,
      render: (_: any, record: ListItemType) => (
        <Checkbox
          disabled={!record.active || !record.is_allowed_active_or_inactive}
          checked={record.apply_default_price}
          onChange={(e) => {
            editApiResult([
              {
                material_cuid: record.material_cuid,
                field: "apply_default_price",
                value: e.target.checked,
              },
            ]);
            if (e.target.checked) {
              editApiResult([
                {
                  material_cuid: record.material_cuid,
                  field: "price",
                  value: null,
                },
              ]);
            }
          }}
        />
      ),
    },
    {
      title: "Preço definido",
      dataIndex: "price",
      key: "price",
      render: (_: any, record: ListItemType) => (
        <Form.Item
          className="p-0 m-0"
          validateStatus={
            record.apply_default_price === false && (record.price === null || record.price === undefined) ? "error" : ""
          }
        >
          <CurrencyInput
            locale="pt-BR"
            currency="BRL"
            value={record.price == null ? "" : record.price === 0 ? "0,00" : record.price.toFixed(2).replace(".", ",")}
            onChangeValue={(_, value) => {
              editApiResult([
                {
                  material_cuid: record.material_cuid,
                  field: "price",
                  value: parseFloat(value.toString().replace(",", ".")),
                },
              ]);
            }}
            InputElement={
              <Input
                disabled={!record.active || record.apply_default_price || !record.is_allowed_active_or_inactive}
                className="w-3/5"
              />
            }
          />
        </Form.Item>
      ),
    },
  ];
  return (
    <div className="flex flex-col w-full h-full pb-1 overflow-hidden select-none new-table">
      <Table
        rowClassName={(record) => (alteredMaterials.includes(record.material_cuid) ? "change-row" : "")}
        size="small"
        dataSource={apiResult.data}
        columns={columns}
        pagination={false}
        bordered
        loading={loading}
        sticky
        rowKey={(record) => record.material_cuid}
        title={() => (
          <>
            <Row className="w-full">
              <Col span={8} className="flex items-center justify-start">
                <span className="text-sm font-normal text-gray-600">
                  Configurações de materiais que podem ser solicitados pelo grupo de clientes.
                </span>
              </Col>
              <Col span={8} className="text-center">
                <span className="text-xl font-semibold text-gray-600">Preço de Materiais</span>
              </Col>
              <Col span={8} className="flex items-center justify-end"></Col>
            </Row>
          </>
        )}
        footer={() => (
          <>
            <Row className="w-full">
              <Col span={8} className="flex items-center justify-start gap-4">
                <div className="flex items-center justify-center gap-2">
                  <ColorPicker value="#dbeafe" size="small" disabled /> Material alterado
                </div>
              </Col>
              <Col span={8} className="text-center"></Col>
              <Col span={8} className="flex items-center justify-end">
                <Button
                  loading={loadingSave}
                  disabled={alteredMaterials.length === 0}
                  type="primary"
                  size="middle"
                  icon={<Icon icon="ant-design:save-filled" height={20} width={20} />}
                  onClick={() => savePriceSettings()}
                >
                  Salvar {alteredMaterials.length > 1 ? "alterações" : "alteração"}
                </Button>
              </Col>
            </Row>
          </>
        )}
      />
    </div>
  );
}

export default ListMaterial;
