import { Button, Tag } from "antd";
import cellStore from "../store";
import { Icon } from "@iconify/react";
import { useContext } from "react";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import CellsConfiguratorForm from "./CellsConfiguratorForm";
import { useNavigate } from "react-router-dom";
import D3Can from "@components/shared/D3Can";

const Title: React.FC = () => {
  const { selected } = cellStore();
  const { openDrawer } = useContext(D3DrawerContext);
  const navigate = useNavigate();
  return (
    <div className="flex flex-row items-center justify-between w-full ">
      <div className="flex flex-row items-center gap-2 font-semibold">
        <Button
          className="flex items-center justify-center"
          icon={<Icon height={18} icon="ion:arrow-back-outline" />}
          type="dashed"
          onClick={() => navigate(-1)}
        >
          Voltar
        </Button>
        {selected.total.length > 0 ? (
          <>
            <div>
              {selected.total.length <= 1 ? (
                <div className="flex gap-2">
                  <Tag color="#108ee9">{selected.total.length}</Tag>
                  Vão/Prateleira foi selecionado para alteração.
                </div>
              ) : (
                <div className="flex gap-2">
                  <Tag color="#108ee9">{selected.total.length}</Tag>
                  Vãos/Prateleiras foram selecionados para alteração.
                </div>
              )}
            </div>
          </>
        ) : (
          <>
            <div className="flex gap-2">
              <Tag color="#108ee9">0</Tag>
              Item selecionado.
            </div>
          </>
        )}
      </div>

     <D3Can action="update" resource="company_storage">
     <Button
        type="primary"
        className="flex items-center"
        disabled={
          !(
            selected.total.length > 0 &&
            selected.totalActive.length === selected.total.length &&
            selected.totalInactive.length === 0
          )
        }
        onClick={() => openDrawer(<CellsConfiguratorForm />, "right", 600, "Configurar vãos/prateleiras selecionados")}
        icon={<Icon icon={"material-symbols:rule-settings"} width={16} height={16} />}
      >
        Configurar {selected.total.length <= 1 ? "Vão/Prateleira" : "Vãos/Prateleiras"}
      </Button>
     </D3Can>
    </div>
  );
};

export default Title;
