import { Can } from "@context/CASLContext"

type D3CanProps = {
    action: string;
    resource: string;
    children: React.ReactNode;   
}

const D3Can = ({action, resource, children}: D3CanProps) => {
    return (
       <Can I={action} a={resource}>
        {children}
       </Can>
    );    
};

export default D3Can;
