import { create } from "zustand";
import { BadRequest, ClientHttp, StandardError, Success } from "@utils/clientHttp";
import showMessage from "@utils/showMessage";
import { CloseIncidentType, ListNoPayments, RegisterIncidentType, IncidentsType } from "./types";

type IncidentsStoreState = {
  listNonPayments: IncidentsType[];
  loading: boolean;
  loadNonPayments: () => Promise<void>;
  registerIncident: (reason: RegisterIncidentType) => Promise<boolean>;
  closeIncident: (payload: CloseIncidentType) => Promise<boolean>;
  loadingMutation: boolean;
  resetState: () => void;
};

const defaultState = {
  listNonPayments: [],
  loading: false,
  loadingMutation: false,
};

export const useIncidentsStore = create<IncidentsStoreState>((set, get) => ({
  ...defaultState,
  resetState: () => set(defaultState),
  loadNonPayments: async (): Promise<void> => {
    set({ loading: true });
    return await new ClientHttp().getItensForSelect<Success<ListNoPayments>, StandardError, void>(
      `/api/v1/customer-group/non-payments`,
      (result: Success<ListNoPayments>): void => {
        set({
          listNonPayments: result.body.data,
          loading: false,
        });
      },
      (error: StandardError): void => {
        showMessage(error);
        set({ loading: false });
      }
    );
  },
  registerIncident: async (reason: RegisterIncidentType): Promise<boolean> => {
    set({ loadingMutation: true });
    const result = await new ClientHttp().post<RegisterIncidentType, Success<void>, BadRequest | StandardError>(
      "/api/v1/customer-group/non-payments",
      reason,
      (result: Success<void>): void => {
        showMessage(result, "Ocorrência registrada com sucesso.");
        set({ loadingMutation: false });
        get().loadNonPayments();
      },
      (error: BadRequest | StandardError): void => {
        showMessage(error);
        set({ loadingMutation: false });
      }
    );
    return result.status === "success";
  },
  closeIncident: async (reason: CloseIncidentType): Promise<boolean> => {
    set({ loadingMutation: true });
    const result = await new ClientHttp().patch<CloseIncidentType, Success<null>, BadRequest | StandardError>(
      `/api/v1/customer-group/non-payments`,
      reason,
      (result: Success<null>) => {
        showMessage(result, "Ocorrência encerrada com sucesso.");
        set({ loadingMutation: false });
        get().loadNonPayments();
      },
      (error: BadRequest | StandardError): void => {
        showMessage(error);
        set({ loadingMutation: false });
      }
    );
    return result.status === "success";
  },
}));

/*   const isInactive = !active;
            return {
              color: isInactive ? "green" : "red",
              status: active,
              children: (
                <div className="flex flex-col">
                  {isInactive && (
                    <>
                      <span>
                        <strong>Encerrada</strong>
                      </span>
                      <span>
                        <strong>Motivo:</strong>
                        {` ${removing_non_payment_reason}`}
                      </span>
                      <span>
                        <strong>Autor:</strong> {name_user_removed}
                      </span>
                      <span>
                        <strong>Data:</strong> {D3DateHourSorter(removed_in)}
                      </span>
                      <Divider className="my-1" />
                    </>
                  )}
                  <span>
                    <strong>Registrada</strong>
                  </span>
                  <span>
                    <strong>Motivo:</strong>
                    {` ${non_payment_reason}`}
                  </span>
                  <span>
                    <strong>Autor:</strong> {name_user_added}
                  </span>
                  <span>
                    <strong>Data:</strong> {D3DateHourSorter(added_in)}
                  </span>
                </div>
              ),
            } as itemTimeline; */
