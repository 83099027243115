import { create } from "zustand";
import { ListContactTypesType, MutationContactTypeType, ResponseSuccess, SearchContactTypeType } from "./types";
import { BadRequest, ClientHttp, StandardError, Success } from "@utils/clientHttp";
import showMessage from "@utils/showMessage";
import D3QSGenerator, { FilterDefinition } from "@utils/D3QSGenerator";

type ContactTypeStoreState = {
  apiResult: ListContactTypesType;
  filter: SearchContactTypeType;

  setFilter: (filter: SearchContactTypeType) => void;
  loadContactTypes: () => Promise<void>;
  loading: boolean;
  createContactType: (payload: MutationContactTypeType) => Promise<boolean>;
  loadingCreated: boolean;
  updateContactType: (cuidContactType: number, payload: MutationContactTypeType) => Promise<boolean>;
  loadingUpdated: boolean;
  resetFilter: () => void;
  resetState: () => void;
};

const contactTypesFilter: FilterDefinition = {
  description: { op: "lk", type: "string" },
  profile: { op: "eq", type: "string" },
};

const defaultState = {
  apiResult: {
    total: 0,
    data: [],
  },
  filter: {
    description: "",
    profile: "",
  } as SearchContactTypeType,
  loading: false,
  loadingCreated: false,
  loadingUpdated: false,
};

export const useContactTypesStore = create<ContactTypeStoreState>((set, get) => ({
  ...defaultState,
  resetState: () => set(defaultState),
  setFilter: (filter: SearchContactTypeType) => {
    set({
      filter,
    });
    get().loadContactTypes();
  },
  resetFilter: () => {
    set({ filter: defaultState.filter });
    get().loadContactTypes();
  },

  loadContactTypes: async (): Promise<void> => {
    set({ loading: true });
    let qs = D3QSGenerator(get().filter, contactTypesFilter);
    await new ClientHttp().get<Success<ListContactTypesType>, BadRequest | StandardError>(
      `/api/v1/system/contact-types?${qs}`,
      (result: Success<ListContactTypesType>) => {
        set({
          apiResult: result.body,
          loading: false,
        });
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
  },
  createContactType: async (payload: MutationContactTypeType): Promise<boolean> => {
    set({ loadingCreated: true });
    const result = await new ClientHttp().post<
      MutationContactTypeType,
      Success<ResponseSuccess>,
      BadRequest | StandardError
    >(
      "/api/v1/system/contact-types",
      payload,
      (result: Success<ResponseSuccess>) => {
        set({ loadingCreated: false });
        showMessage(result, "Tipo de contato criado com sucesso.");
        get().loadContactTypes();
      },
      (error: BadRequest | StandardError) => {
        set({ loadingCreated: false });
        showMessage(error);
      }
    );
    return result.status === "success";
  },
  updateContactType: async (cuidContactType: number, payload: MutationContactTypeType): Promise<boolean> => {
    set({ loadingUpdated: true });
    const result = await new ClientHttp().put<MutationContactTypeType, Success<void>, BadRequest | StandardError>(
      `/api/v1/system/contact-types/${cuidContactType}`,
      payload,
      (result: Success<void>) => {
        set({ loadingUpdated: false });
        showMessage(result, "Tipo de contato atualizado com sucesso.");
        get().loadContactTypes();
      },
      (error: BadRequest | StandardError) => {
        set({ loadingUpdated: false });
        showMessage(error);
      }
    );
    return result.status === "success";
  },
}));
