import { Button, Form } from "antd";
import InputMoney from "./InputMoney";

function FormView() {
  const [form] = Form.useForm();
  return (
    <div className="flex h-full p-2 mb-1 mr-1 bg-white rounded shadow-md">
      <Form form={form} layout="vertical" onFinish={(payload) => console.log(payload)}>
              <InputMoney form={form} label="Preço" name="price" required />
        <Form.Item>
          <Button htmlType="submit">Enviar</Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default FormView;
