import { Button, Col, Form, Input, InputNumber, Radio, Row, Space, Spin } from "antd";
import { useRepositoryTypesStore } from "../store";
import { useContext, useEffect, useState } from "react";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { UpdateRepositoryTypeForm } from "./UpdateRepositoryTypeForm";
import { CurrencyInput } from "react-currency-mask";
import { D3Date } from "@utils/date";
import { ViewRepositoryTypeType } from "../types";
import showMessage from "@utils/showMessage";
import D3Can from "@components/shared/D3Can";

type ViewRepositoryTypeFormProps = {
  cuid: string;
};

export const ViewRepositoryTypeForm = ({ cuid }: ViewRepositoryTypeFormProps) => {
  const { getRepositoryType } = useRepositoryTypesStore();
  const { openDrawer } = useContext(D3DrawerContext);
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm<ViewRepositoryTypeType>();

  useEffect(() => {
    (async () => {
      const result = await getRepositoryType(cuid);
      if (result.status === "success") {
        const viewRepositoryType = result.body as ViewRepositoryTypeType;
        form.setFieldsValue({
          ...result.body,
          created_at: D3Date.FormatValueApiToPtBR(viewRepositoryType.created_at),
          updated_at: D3Date.FormatValueApiToPtBR(viewRepositoryType.updated_at),
        });
        setLoading(false);
      } else {
        showMessage(result);
      }
    })();
  }, [cuid, form, getRepositoryType]);

  const { onClose } = useContext(D3DrawerContext);
  return (
    <>
      <Spin spinning={loading}>
        <Form className="select-none" layout="vertical" form={form} autoComplete="off">
          <Form.Item<ViewRepositoryTypeType> name="description" label="Descrição">
            <Input disabled />
          </Form.Item>
          <Form.Item<ViewRepositoryTypeType> name={["material", "description"]} label="Material">
            <Input disabled />
          </Form.Item>

          <Row className="gap-4">
            <Col>
              <Form.Item<ViewRepositoryTypeType> name="equivalence" label="Equivalência">
                <Input addonAfter="%" disabled className="w-24" />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item<ViewRepositoryTypeType> name="price" label="Preço">
                <CurrencyInput
                  value={form.getFieldValue("price")}
                  locale="pt-BR"
                  currency="BRL"
                  onChangeValue={() => {}}
                  InputElement={<InputNumber className="min-w-max" disabled />}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item<ViewRepositoryTypeType> name="active" label="Status">
            <Radio.Group
              disabled
              options={[
                { label: "Desbloqueado", value: true },
                { label: "Bloqueado", value: false },
              ]}
            />
          </Form.Item>
          <Form.Item<ViewRepositoryTypeType> name="created_at" label="Criado em">
            <Input disabled />
          </Form.Item>
          <Form.Item<ViewRepositoryTypeType> name="updated_at" label="Atualizado em">
            <Input disabled />
          </Form.Item>
          <Form.Item>
            <div className="text-right">
              <Space size="small">
                <Button type="default" danger onClick={() => onClose()}>
                  Cancelar
                </Button>
                <D3Can action="update" resource="system_repository_type">
                  <Button
                    type="primary"
                    onClick={() =>
                      openDrawer(<UpdateRepositoryTypeForm cuid={cuid} />, "right", 600, "Editar Tipo de Repositório")
                    }
                  >
                    Editar
                  </Button>
                </D3Can>
              </Space>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};
