import { create } from "zustand";
import {
  CreateDocumentTypeType,
  ListDocumentTypeType,
  ListInputsType,
  ListPreviewType,
  ListTypesType,
  LockOrUnlockPayloadType,
  PayloadType,
  RowDocumentTypeType,
  SearchDocumentTypeType,
  UpdateDocumentTypeType,
  ViewDocumentTypeType,
} from "./types";

import showMessage from "@utils/showMessage";

import D3QSGenerator, {
  FilterDefinition,
} from "@utils/D3QSGenerator";
import {
  BadRequest,
  ClientHttp,
  StandardError,
  Success,
} from "@utils/clientHttp";
import IsValidMultiSelectedRow from "@utils/table/IsValidMultiSelectedRow";
import { SetSort, SortType } from "@utils/table/SetSort";

type DocumentTypeStoreState = {
  apiResult: ListDocumentTypeType;
  selectedRows: { cuids: string[]; active: boolean };
  filter: SearchDocumentTypeType;
  pagination: { page: number; pageSize: number };
  sort: { sort_by: string; sort_direction: string };
  loading: boolean;
  activeTab: string;
  setActiveTab: (tab: string) => void;
  getActiveTab: () => string;
  setFilter: (filter: SearchDocumentTypeType) => void;
  setPagination: (page: number, pageSize: number) => void;
  setSort: (newSort: SortType) => void;
  loadDocumentTypes: () => Promise<
    Success<ListDocumentTypeType> | BadRequest | StandardError
  >;
  getDocumentType: (
    cuid: string
  ) => Promise<Success<ViewDocumentTypeType> | StandardError>;
  createDocumentType: (
    documentType: CreateDocumentTypeType
  ) => Promise<Success<{ cuid: string }> | BadRequest | StandardError>;
  updateDocumentType: (
    cuid: string,
    documentType: UpdateDocumentTypeType
  ) => Promise<Success<void> | BadRequest | StandardError>;
  lockDocumentType: (cuids: string[]) => Promise<void>;
  unLockDocumentType: (cuids: string[]) => Promise<void>;
  onMultiSelectedRowChange: (selectedRows: RowDocumentTypeType[]) => void;
  resetSelectedRows: () => void;
  resetFilter: () => void;
  resetState: () => void;
  getIndexTypesForList: () => Promise<ListTypesType[]>;
  indexadores: ListPreviewType[];
  payload: PayloadType[];
  getIndexadores: () => ListPreviewType[];
  setIndexadores: (newIndexadores: ListPreviewType[]) => void;
  resetStateDrawer: () => void;
};



const defaultSort = {
  sort_by: "dt_description",
  sort_direction: "asc",
};

const operationsFilter: FilterDefinition = {
  dt_description: { op: "lk", type: "string" },
  dt_active: { op: "eq", type: "active" },
};

const defaultState = {
  apiResult: {
    total: 0,
    data: [],
  },
  selectedRows: {
    cuids: [],
    active: false,
  },
  filter: {
    dt_description: "",
    dt_active: "on",
  } as SearchDocumentTypeType,
  pagination: {
    page: 1,
    pageSize: 10,
  },
  sort: defaultSort,
  loading: false,
};

const defaultStateDrawer = {
  activeTab: "1",
  indexadores: [],
  payload: [],
};

export const useDocumentTypesStore = create<DocumentTypeStoreState>(
  (set, get) => ({
    ...defaultState,
    ...defaultStateDrawer,
    resetState: () => set(defaultState),
    resetStateDrawer: () => set(defaultStateDrawer),
    setActiveTab: (tab: string) => {
      set({ activeTab: tab });
    },
    getActiveTab: () => {
      return get().activeTab;
    },
    setFilter: (filter: SearchDocumentTypeType) => {
      set({
        filter,
        selectedRows: { cuids: [], active: false },
        pagination: { ...get().pagination, page: 1 },
      });
      get().loadDocumentTypes();
    },
    onMultiSelectedRowChange: (selectedRows: RowDocumentTypeType[]): void =>
      IsValidMultiSelectedRow(selectedRows, get, set),
    resetSelectedRows: () => {
      set({ selectedRows: { cuids: [], active: false } });
    },
    resetFilter: () => {
      set({ filter: { dt_description: "", dt_active: "on" } });
      get().loadDocumentTypes();
    },
    setPagination: (page: number, pageSize: number) => {
      if (pageSize !== get().pagination.pageSize) {
        page = 1;
      }
      set({ pagination: { page, pageSize } });
      get().resetSelectedRows();
      get().loadDocumentTypes();
    },
    setSort: (newSort: SortType) =>
      SetSort(
        newSort,
        get().sort,
        defaultSort,
        new Map<string, string>([
          ["description", "dt_description"],
          ["active", "dt_active"],
        ]),
        set,
        get().loadDocumentTypes
      ),
    loadDocumentTypes: async (): Promise<
      Success<ListDocumentTypeType> | BadRequest | StandardError
    > => {
      set({ loading: true });
      let qs = D3QSGenerator(
        get().filter,
        operationsFilter,
        get().pagination,
        get().sort
      );

      return await new ClientHttp().get<
        Success<ListDocumentTypeType>,
        BadRequest | StandardError
      >(
        `/api/v1/customer/document-types?${qs}`,
        (result: Success<ListDocumentTypeType>) => {
          let newPage = get().pagination.page;
          const { body } = result;
          if (body.total > 0 && body.data.length === 0) {
            newPage = newPage - 1;
            set({
              apiResult: body,
              loading: false,
              pagination: { ...get().pagination, page: newPage },
            });
            get().loadDocumentTypes();
          } else {
            set({ apiResult: body, loading: false });
          }
        },
        (error: BadRequest | StandardError) => {
          showMessage(error);
        }
      );
    },
    getDocumentType: async (
      cuid: string
    ): Promise<Success<ViewDocumentTypeType> | StandardError> => {
      return await new ClientHttp().get<
        Success<ViewDocumentTypeType>,
        StandardError
      >(`/api/v1/customer/document-types/${cuid}`);
    },
    createDocumentType: async (
      documentType: CreateDocumentTypeType
    ): Promise<Success<{ cuid: string }> | BadRequest | StandardError> => {
      return await new ClientHttp().post<
        CreateDocumentTypeType,
        Success<{ cuid: string }>,
        BadRequest | StandardError
      >(
        "/api/v1/customer/document-types",
        documentType,
        (result: Success<{ cuid: string }>) => {
          get().loadDocumentTypes();
          set({
            activeTab: "1",
            indexadores: [],
            payload: [],
          });
        }
      );
    },
    updateDocumentType: async (
      cuid: string,
      documentType: UpdateDocumentTypeType
    ): Promise<Success<void> | BadRequest | StandardError> => {
      return await new ClientHttp().put<
        UpdateDocumentTypeType,
        Success<void>,
        BadRequest | StandardError
      >(
        `/api/v1/customer/document-types/${cuid}`,
        documentType,
        (result: Success<void>) => {
          get().loadDocumentTypes();
        }
      );
    },
    lockDocumentType: async (cuids: string[]): Promise<void> => {
      await new ClientHttp().patch<
        LockOrUnlockPayloadType,
        Success<{ rows_affected: number }>,
        BadRequest | StandardError
      >(
        `/api/v1/customer/document-types/lock`,
        { cuids: cuids },
        (result: Success<{ rows_affected: number }>) => {
          get().resetSelectedRows();
          get().loadDocumentTypes();
          if (result.body.rows_affected > 1) {
            showMessage(result, "Itens bloqueados com sucesso.");
          } else {
            showMessage(result, "Item bloqueado com sucesso.");
          }
        },
        (error: BadRequest | StandardError) => {
          showMessage(error);
        }
      );
    },
    unLockDocumentType: async (cuids: string[]): Promise<void> => {
      await new ClientHttp().patch<
        LockOrUnlockPayloadType,
        Success<{ rows_affected: number }>,
        BadRequest | StandardError
      >(
        `/api/v1/customer/document-types/unlock`,
        { cuids: cuids },
        (result: Success<{ rows_affected: number }>) => {
          get().resetSelectedRows();
          get().loadDocumentTypes();
          if (result.body.rows_affected > 1) {
            showMessage(result, "Itens desbloqueados com sucesso.");
          } else {
            showMessage(result, "Item desbloqueado com sucesso.");
          }
        },
        (error: BadRequest | StandardError) => {
          showMessage(error);
        }
      );
    },
    getIndexadores: () => {
      return get().indexadores;
    },
    setIndexadores: (newIndexadores: ListPreviewType[]) => {
      set({
        indexadores: newIndexadores,
        payload: newIndexadores.map(({ default_input, ...rest }) => rest),
      });
    },
    getIndexTypesForList: async (): Promise<ListTypesType[]> => {
      return await new ClientHttp().getItensForSelect<
        Success<ListInputsType>,
        StandardError,
        ListTypesType[]
      >(
        "/api/v1/system/index_types",
        (result: Success<ListInputsType>): ListTypesType[] => {
          return result.body.data;
        },
        (error: StandardError): ListTypesType[] => {
          showMessage(error);
          return [] as ListTypesType[];
        }
      );
    },
  })
);
