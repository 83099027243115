import { green, red } from "@ant-design/colors";
import { InboxOutlined } from "@ant-design/icons";
import { Icon } from "@iconify/react";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import type { UploadProps } from "antd";
import { Button, Form, Input, Progress, Radio, Select, Space, Table, Tooltip, Upload, notification } from "antd";
import { RcFile } from "antd/lib/upload";
import mime from "mime";
import { useContext, useEffect, useState } from "react";
import { useFormUploadWorkStore } from "./store";
import { ItemDepartmentType, PayloadFileType, UploadFileType } from "./types";
import useNavigateStore from "../../work/components/navigate/store";
import formatBytes from "@utils/formatBytes";

const { Dragger } = Upload;

type UploadFormPropsType = {
  cuid: string;
  onFiles?: (files: UploadFileType[]) => void;
  validateFiles?: (isValid: boolean) => void;
};

function FormUploadDrawer({ cuid, validateFiles, onFiles }: UploadFormPropsType) {
  const {
    content: { require_departments_to_upload_files_here },
  } = useNavigateStore();

  const { simpleListDepartments, createFiles } = useFormUploadWorkStore();

  const { onClose } = useContext(D3DrawerContext);

  const [form] = Form.useForm<PayloadFileType>();

  const [departments, setDepartments] = useState<ItemDepartmentType[]>([]);

  const [fileList, setFileList] = useState<UploadFileType[]>([]);

  const total = Math.ceil(
    (fileList.reduce((accumulator, currentFile) => {
      return accumulator + currentFile.size;
    }, 0) /
      1000000) *
      100
  );

  useEffect(() => {
    if (require_departments_to_upload_files_here) {
      (async () => {
        setDepartments(await simpleListDepartments());
      })();
    }
    if (fileList && onFiles && validateFiles) {
      const isDuplicates: boolean = checkDuplicates(fileList);
      validateFiles(!isDuplicates);
      if (isDuplicates) {
        notification.error({
          message: "Atenção",
          description: "Corrija os nomes duplicados, que estão destacados em vermelho.",
        });
      } else {
        const files: UploadFileType[] = fileList.map((file) => {
          return {
            ...file,
            name: `${file.name}.${file.extension}` as string,
          };
        });
        onFiles(files);
      }
    }
  }, [simpleListDepartments, fileList, require_departments_to_upload_files_here]);

  function removeFile(fileUid: string) {
    setFileList(fileList.filter(({ uid }) => uid !== fileUid));
  }

  function editFileName(fileUid: string, newName: string) {
    setFileList(
      fileList.map((file) => {
        if (file.uid === fileUid) {
          file.name = newName;
        }
        return file;
      })
    );
  }

  function checkDuplicates(files: UploadFileType[]): boolean {
    const map = new Map<string, string>();
    for (const file of files) {
      const key = `${file.name}.${file.extension}`;
      if (map.has(key)) {
        if (map.get(key) !== file.uid) {
          return true;
        }
      } else {
        map.set(key, file.uid);
      }
    }
    return false;
  }

  return (
    <div className="flex flex-col w-full h-full gap-2 px-2 py-1 overflow-hidden ">
      <div className="flex min-h-44 h-44">
        <Dragger
          {...({
            disabled: total > 100,
            multiple: true,
            showUploadList: false,
            beforeUpload: async (file: RcFile) => {
              const type = file.type ? mime.getExtension(file.type) : undefined;
              const preUpload: UploadFileType = {
                extension: type ? type : "Desconhecida",
                name: file.name.replace(`.${type}` as string, ""),
                file: file as RcFile,
                size: file.size,
                uid: file.uid,
                status_upload: "W",
                progress: 0,
              };
              setFileList((prevFileList) => [...prevFileList, preUpload]);
              return false;
            },
          } as UploadProps)}
          className="w-full h-full p-0 m-0 rounded d3-upload-dragger "
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Clique ou arraste o arquivo para esta área para fazer upload</p>
          <p className="ant-upload-hint">
            Suporte para upload único ou em massa. Estritamente proibido de upload de dados da empresa ou outros
            arquivos proibidos.
          </p>
        </Dragger>
      </div>
      <div className="flex h-full overflow-hidden grow">
        <Form
          form={form}
          className="w-full h-full overflow-hidden"
          onFinish={(payload) => {
            payload.parent_cuid = cuid;
            payload.billable_department_cuid = require_departments_to_upload_files_here
              ? payload.billable_department_cuid
              : "";
            payload.global_visibility = require_departments_to_upload_files_here ? payload.global_visibility : false;
            payload.files = fileList.map((file) => {
              return {
                ...file,
                name: `${file.name}.${file.extension}`,
              };
            });
            createFiles(payload);
            onClose();
          }}
        >
          <div className="flex flex-col h-full">
            <div className="overflow-hidden grow">
              <div className="h-full overflow-hidden">
                <Table
                  className="d3-table-h-full"
                  rowClassName={(record) =>
                    fileList.filter(
                      (file) =>
                        file.uid !== record.uid && file.name === record.name && file.extension === record.extension
                    ).length > 0
                      ? "d3-is-duplicate"
                      : ""
                  }
                  title={() =>
                    require_departments_to_upload_files_here ? (
                      <div className="flex items-center justify-start w-full gap-4 ">
                        <Form.Item
                          className="p-0 m-0"
                          name="global_visibility"
                          initialValue={false}
                          label="Visibilidade global?"
                        >
                          <Radio.Group
                            options={[
                              { label: "Sim", value: true },
                              { label: "Não", value: false },
                            ]}
                            optionType="button"
                            buttonStyle="solid"
                          />
                        </Form.Item>
                        <Form.Item
                          className="p-0 m-0 w-96 h-[32px]"
                          label="Departamentos"
                          name="billable_department_cuid"
                          rules={[
                            {
                              required: true,
                              message: "Por favor, selecione o departamento.",
                            },
                          ]}
                        >
                          <Select
                            listItemHeight={10}
                            allowClear
                            removeIcon={
                              <Icon
                                icon="iconamoon:close-duotone"
                                className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
                              />
                            }
                            menuItemSelectedIcon={
                              <Icon icon="eva:checkmark-outline" className="w-5 h-5 text-blue-500" />
                            }
                            optionLabelProp="dataLabel"
                            showSearch
                            maxTagCount="responsive"
                            size="middle"
                            className="w-full truncate select-none"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option?.dataFilter.toLowerCase().includes(input.toLowerCase())
                            }
                          >
                            {departments.map(({ abbreviation, active, cuid, description }) => (
                              <Select.Option
                                disabled={!active}
                                key={cuid}
                                value={cuid}
                                dataFilter={`${abbreviation} - ${description}`}
                                dataLabel={`${abbreviation} - ${description}`}
                              >
                                <div className="flex items-center justify-between ">
                                  <div className="flex flex-col truncate">
                                    <span className="truncate">{`${abbreviation} - ${description}`}</span>
                                  </div>
                                  <span>
                                    {active ? (
                                      <Tooltip trigger="hover" title="Departamento desbloqueado" placement="right">
                                        <Icon
                                          icon="basil:unlock-solid"
                                          className="text-green-500"
                                          height={16}
                                          width={16}
                                        />
                                      </Tooltip>
                                    ) : (
                                      <Tooltip trigger="hover" title="Departamento bloqueado" placement="right">
                                        <Icon icon="basil:lock-solid" className="text-red-500" height={16} width={16} />
                                      </Tooltip>
                                    )}
                                  </span>
                                </div>
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <div></div>
                      </div>
                    ) : (
                      <div className="flex items-center justify-center w-full ">
                        <span className="text-base font-semibold text-gray-600">Lista de arquivos</span>
                      </div>
                    )
                  }
                  footer={() => (
                    <div className="flex items-center justify-between w-full ">
                      <Button danger size="small" onClick={() => setFileList([])} type="dashed">
                        {`Excluir todos ${fileList.length} arquivos`}
                      </Button>
                      <Progress
                        steps={10}
                        type="line"
                        percent={total}
                        strokeColor={[
                          green[1],
                          green[2],
                          green[3],
                          green[4],
                          green[5],
                          red[2],
                          red[3],
                          red[4],
                          red[5],
                          red[6],
                        ]}
                      />
                    </div>
                  )}
                  rowKey="uid"
                  bordered
                  size="small"
                  pagination={false}
                  sticky
                  dataSource={fileList}
                >
                  <Table.Column<UploadFileType>
                    showSorterTooltip={false}
                    key="name"
                    title="Nome"
                    dataIndex="name"
                    render={(value, record) => (
                      <Form.Item
                        validateStatus={`${
                          fileList.filter(
                            (file) =>
                              file.uid !== record.uid &&
                              file.name === record.name &&
                              file.extension === record.extension
                          ).length > 0
                            ? "error"
                            : "success"
                        }`}
                        className="p-0 m-0"
                      >
                        <Input defaultValue={value} onBlur={(e) => editFileName(record.uid, e.target.value)} />
                      </Form.Item>
                    )}
                  />
                  <Table.Column<UploadFileType>
                    showSorterTooltip={false}
                    key="size"
                    title="Tamanho"
                    dataIndex="size"
                    render={(value, record) => {
                      return formatBytes(value);
                    }}
                  />

                  <Table.Column<UploadFileType>
                    showSorterTooltip={false}
                    key="extension"
                    title="Extensão"
                    dataIndex="extension"
                  />

                  <Table.Column<UploadFileType>
                    key="action"
                    title="Ações"
                    width={80}
                    align="center"
                    render={(value, record) => {
                      return (
                        <>
                          <Space>
                            <Tooltip
                              trigger="hover"
                              title="Excluir"
                              destroyTooltipOnHide
                              mouseLeaveDelay={0}
                              zIndex={11}
                            >
                              <Button
                                className="peer"
                                type="text"
                                shape="circle"
                                icon={
                                  <Icon
                                    icon="material-symbols:delete"
                                    height={18}
                                    width={18}
                                    className="text-red-400 transition-all duration-100 ease-in hover:text-red-500"
                                  />
                                }
                                size={"middle"}
                                onClick={() => removeFile(record.uid)}
                              />
                            </Tooltip>
                          </Space>
                        </>
                      );
                    }}
                  />
                </Table>
              </div>
            </div>
            <div className="flex w-full py-2 h-14">
              <Form.Item className="w-full">
                <Button
                  disabled={fileList.length === 0}
                  className="w-full font-semibold"
                  type="primary"
                  size="large"
                  htmlType="submit"
                >
                  Enviar Arquivos
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default FormUploadDrawer;
