import useAuthStore, { InputLoginType, OutputLoginType } from "@provider/AuthProvider";
import showMessage from "@utils/showMessage";
import { useChangePassword } from "@views/auth/change-password/store";
import { Button, Form, Input } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function LoginView() {
	const navigate = useNavigate();
	const { setUserData } = useChangePassword();
	const { login } = useAuthStore();
	const { t } = useTranslation();
	const [loading, setLoading] = useState<boolean>(false);

	const onFinish = async (payload: InputLoginType) => {
		setLoading(true);
		const loginResult = await login(payload);
		if (loginResult.status === "success") {
			const bodySuccess = loginResult.body as OutputLoginType;
			if (bodySuccess.change_password_next_login === true) {
				setUserData(payload);
				navigate("/auth/change-password");
			} else {
				navigate("/app");
			}
		} else {
			showMessage(loginResult);
		}
		setLoading(false);
	};

	return (
		<div className="flex flex-col items-center justify-center h-full overflow-y-auto bg-white scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100 au:w-full">
			<span className="mt-8 text-2xl font-extrabold text-center text-gray-700">Bem-vindo(a) ao GED da CelulaTech!</span>
			<span className="flex py-8 font-bold text-gray-600">Informe suas credenciais para acessar sua conta</span>
			<div className="w-[350px]">
				<Form layout="vertical" onFinish={onFinish} autoComplete="nope">
					<Form.Item
						label="Informe seu login"
						initialValue=""
						name="login"
						rules={[
							{
								required: true,
								message: t("shared.login.msg_username"),
							},
						]}
					>
						<Input size="large" variant="filled" autoComplete="new-login" />
					</Form.Item>
					<Form.Item
						label="Informe sua senha"
						name="password"
						rules={[
							{
								required: true,
								message: t("shared.login.msg_password"),
							},
							{
								min: 6,
								message: "A senha deve ter no mínimo 6 caracteres.",
							},
						]}
					>
						<Input.Password size="large" variant="filled" autoComplete="new-password" />
					</Form.Item>
					<Form.Item>
						<Button loading={loading} htmlType="submit" type="primary" className="mt-3 text-base font-semibold text-gray-50" block size="large">
							Acessar minha conta
						</Button>
					</Form.Item>
				</Form>
			</div>

			<div className="flex items-center justify-center">
				<Button onClick={() => navigate("/auth/forgot-password")} className="text-sm font-semibold" type="link">
					Esqueci minha senha
				</Button>
			</div>
		</div>
	);
}

export default LoginView;
