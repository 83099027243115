import {
  Button,
  Form,
  Input,
  Radio,
  Space,
  Spin,
  Table,
  Tabs,
  Tag,
} from "antd";
import { useDepartmentsStore } from "../store";
import { useContext, useEffect, useState } from "react";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { UpdateDepartmentForm } from "./UpdateDepartmentForm";

import { D3Date } from "@utils/date";
import { ViewDepartmentType } from "../types";
import showMessage from "@utils/showMessage";
import { Icon } from "@iconify/react";
import D3Can from "@components/shared/D3Can";

type ViewDepartmentFormProps = {
  cuid: string;
};

export const ViewDepartmentForm = ({ cuid }: ViewDepartmentFormProps) => {
  const { getDepartment } = useDepartmentsStore();
  const { openDrawer } = useContext(D3DrawerContext);
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm<ViewDepartmentType>();

  useEffect(() => {
    (async () => {
      const result = await getDepartment(cuid);
      if (result.status === "success") {
        const viewDepartment = result.body as ViewDepartmentType;
        form.setFieldsValue({
          ...result.body,
          created_at: D3Date.FormatValueApiToPtBR(viewDepartment.created_at),
          updated_at: D3Date.FormatValueApiToPtBR(viewDepartment.updated_at),
        });
        setLoading(false);
      } else {
        showMessage(result);
      }
    })();
  }, [cuid, getDepartment, form]);

  const { onClose } = useContext(D3DrawerContext);

  return (
    <>
      <Spin spinning={loading}>
        <Form
          className="select-none"
          layout="vertical"
          form={form}
          autoComplete="off"
        >
          <Tabs
            defaultActiveKey="1"
            className="select-none"
            items={[
              {
                key: "1",
                label: (
                  <span className="flex items-center justify-center gap-2 ">
                    <Icon icon="fluent:form-24-filled" className="w-5 h-5" />
                    Dados
                  </span>
                ),
                children: (
                  <>
                    <Form.Item<ViewDepartmentType>
                      name="description"
                      label="Descrição"
                    >
                      <Input disabled />
                    </Form.Item>
                    <Form.Item<ViewDepartmentType>
                      name="abbreviation"
                      label="Abreviação"
                    >
                      <Input disabled />
                    </Form.Item>
                    <Form.Item<ViewDepartmentType> name="active" label="Status">
                      <Radio.Group
                        disabled
                        options={[
                          { label: "Desbloqueado", value: true },
                          { label: "Bloqueado", value: false },
                        ]}
                      />
                    </Form.Item>
                    <Form.Item<ViewDepartmentType>
                      name="created_at"
                      label="Criado em"
                    >
                      <Input disabled />
                    </Form.Item>
                    <Form.Item<ViewDepartmentType>
                      name="updated_at"
                      label="Atualizado em"
                    >
                      <Input disabled />
                    </Form.Item>
                  </>
                ),
              },
              {
                key: "2",
                label: (
                  <span className="flex items-center justify-center gap-2 ">
                    <Icon icon="fa:users" className="w-4 h-4" />
                    Usuários
                  </span>
                ),
                children: (
                  <>
                    <Form.Item<ViewDepartmentType>
                      label="Usuários do departamento"
                      name="users"
                    >
                      <Table
                        rowKey={(record) => record.cuid}
                        size="small"
                        pagination={false}
                        dataSource={form.getFieldValue("users")}
                        bordered
                      >
                        <Table.Column<ViewDepartmentType>
                          showSorterTooltip={false}
                          defaultSortOrder="ascend"
                          key="username"
                          title="Usuário"
                          dataIndex="username"
                        />
                        <Table.Column<ViewDepartmentType>
                          showSorterTooltip={false}
                          defaultSortOrder="ascend"
                          key="email"
                          title="E-mail"
                          dataIndex="email"
                        />
                        <Table.Column<ViewDepartmentType>
                          showSorterTooltip={false}
                          key="active"
                          title="Status"
                          dataIndex="active"
                          render={(value, customerUser, index) =>
                            customerUser.active ? (
                              <Tag
                                className="flex items-center justify-start gap-1 max-w-max"
                                icon={
                                  <Icon
                                    icon="basil:unlock-solid"
                                    height={14}
                                    width={14}
                                  />
                                }
                                color="success"
                              >
                                Desbloqueado
                              </Tag>
                            ) : (
                              <Tag
                                className="flex items-center justify-start gap-1 max-w-max"
                                icon={
                                  <Icon
                                    icon="basil:lock-solid"
                                    height={14}
                                    width={14}
                                  />
                                }
                                color="error"
                              >
                                Bloqueado
                              </Tag>
                            )
                          }
                        />
                      </Table>
                    </Form.Item>
                  </>
                ),
              },
            ]}
          />
          <div className="mt-3 text-right">
            <Space size="small">
              <Button type="default" danger onClick={() => onClose()}>
                Cancelar
              </Button>
              <D3Can action="update" resource="customer_department">
                <Button
                  type="primary"
                  onClick={() =>
                    openDrawer(
                      <UpdateDepartmentForm cuid={cuid} />,
                      "right",
                      600,
                      "Editar Serviço"
                    )
                  }
                >
                  Editar
                </Button>
              </D3Can>
            </Space>
          </div>
        </Form>
      </Spin>
    </>
  );
};
