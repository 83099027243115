import { useForm } from "antd/es/form/Form";
import { ItemListTrashType } from "../types";
import { Button, DatePicker, Form, Input, InputNumber, Radio, Space } from "antd";
import { FileType } from "..";
import dayjs from "dayjs";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { useContext } from "react";

const { TextArea } = Input;

type ViewTrashFormProps = {
  item: ItemListTrashType;
};
function ViewTrashForm({ item }: ViewTrashFormProps) {
  const [form] = useForm<ItemListTrashType>();
  form.setFieldsValue(item);
  const { onClose } = useContext(D3DrawerContext);
  return (
    <Form disabled layout="vertical" form={form} className="m-2 form-view">
      <Form.Item<ItemListTrashType> label="Descrição" name="fs_name">
        <Input />
      </Form.Item>
      <Form.Item<ItemListTrashType> label="Tipo">
        <Input className="w-44" value={FileType[item.fs_type as keyof typeof FileType]} />
      </Form.Item>
      <div
        className={`flex ${
          item.fs_type === "SDC" ? "justify-between" : item.fs_type === "FLD" ? "gap-4" : "justify-start"
        }`}
      >
        <Form.Item<ItemListTrashType> hidden={item.fs_type !== "SDC"} label="Físico ?" name="fs_is_physical">
          <Radio.Group
            options={[
              { label: "Sim", value: true },
              { label: "Não", value: false },
            ]}
          />
        </Form.Item>
        <Form.Item<ItemListTrashType>
          hidden={item.fs_type === "FLE"}
          label="Quantidade de itens"
          name="fs_number_of_childrens"
        >
          <InputNumber />
        </Form.Item>
        <Form.Item<ItemListTrashType> label="Dias restantes" name="trash_days_remaining">
          <InputNumber />
        </Form.Item>
      </div>
      <Form.Item<ItemListTrashType> label="Data agendada para exclusão">
        <DatePicker value={dayjs(item.trash_scheduled_deletion_date)} format="DD/MM/YYYY" />
      </Form.Item>
      <Form.Item<ItemListTrashType> label="Excluído por" name="trash_deleted_user_name">
        <Input />
      </Form.Item>
      <Form.Item<ItemListTrashType> label="Motivo" name="deletion_reason">
        <TextArea rows={4} />
      </Form.Item>

      <Form.Item>
        <div className="mt-2 text-right">
          <Space size="small">
            <Button disabled={false} type="default" danger onClick={() => onClose()}>
              Fechar
            </Button>
          </Space>
        </div>
      </Form.Item>
    </Form>
  );
}

export default ViewTrashForm;
