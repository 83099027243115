import { useContext, useEffect, useState } from "react";

import { Button, Form, Input, Space, Spin} from "antd";

import { D3DrawerContext } from "@provider/D3DrawerContext";
import { useStoragesStore } from "../store";
import {UpdateStorageType, ViewStorageType} from "../types";
import showMessage from "@utils/showMessage";
import D3Can from "@components/shared/D3Can";

type UpdateStorageProps = {
  cuid: string;
};

export const UpdateStorageForm = ({ cuid }: UpdateStorageProps) => {
  const { updateStorage, getStorage} = useStoragesStore();
  const { onClose } = useContext(D3DrawerContext);
  const [form] = Form.useForm<UpdateStorageType>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const resultGetStorage = await getStorage(cuid);
      if (resultGetStorage.status === "success") {
        const { description } = resultGetStorage.body as ViewStorageType;
        form.setFieldsValue({description});
        setLoading(false);
      } else {
        showMessage(resultGetStorage);
      }
    })();
  }, [cuid, form, getStorage]);

  return (
    <>
      <Spin spinning={loading}>
        <Form
          className="select-none"
          layout="vertical"
          form={form}
          onFinish={async (payload: UpdateStorageType) => {
            setLoading(true);
            const result = await updateStorage(cuid, payload);
            if (result.code === 200) {
              showMessage(result, "Galpão atualizado com sucesso.");
              onClose();
            } else {
              showMessage(result);
            }
            setLoading(false);
          }}
          autoComplete="off"
        >
          <Form.Item<UpdateStorageType>
            name="description"
            label="Descrição"
            rules={[
              { required: true, message: "Por favor, insira descrição." },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <div className="text-right">
              <Space size="small">
                <Button type="default" danger onClick={() => onClose()}>
                  Cancelar
                </Button>
                <D3Can action="update" resource="company_storage">
                  <Button type="primary" htmlType="submit">
                    Atualizar
                  </Button>
                </D3Can>
              </Space>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};
