import D3QSGenerator, { FilterDefinition } from "@utils/D3QSGenerator";
import { BadRequest, ClientHttp, StandardError, Success } from "@utils/clientHttp";
import showMessage from "@utils/showMessage";
import { SetSort, SortType } from "@utils/table/SetSort";
import { create } from "zustand";
import {
	CreateEventType,
	ItemCustomerSystemType,
	ItemDepartmentsCustomerCuid,
	ListEventType,
	SearchEventType,
	SimpleListCustomerSystemType,
	SimpleListDepartmentsByCustomerCuid,
	UpdateEventType,
	ViewEventType,
} from "./types";

type EventStoreState = {
	apiResult: ListEventType;
	selectedRows: { cuids: string[] };
	filter: SearchEventType;
	pagination: { page: number; pageSize: number };
	sort: { sort_by: string; sort_direction: string };
	loading: boolean;
	setFilter: (filter: SearchEventType) => void;
	setPagination: (page: number, pageSize: number) => void;
	setSort: (newSort: SortType) => void;
	loadEvents: () => Promise<void>;
	simpleListCustomerSystem: () => Promise<ItemCustomerSystemType[]>;
	simpleListDepartmentsByCustomerCuid: (customerCuid: string) => Promise<ItemDepartmentsCustomerCuid[]>;
	getEvent: (eventCuid: string) => Promise<Success<ViewEventType> | StandardError>;
	createEvent: (eventCuid: CreateEventType) => Promise<Success<{ cuid: string }> | BadRequest | StandardError>;
	updateEvent: (eventCuid: string, payload: UpdateEventType) => Promise<Success<void> | BadRequest | StandardError>;
	deleteEvent: (eventCuid: string) => Promise<Success<void> | StandardError>;
	resetFilter: () => void;
	resetState: () => void;
};

const defaultSort = {
	sort_by: "event_date",
	sort_direction: "desc",
};

const operationsFilter: FilterDefinition = {
	description: { op: "lk", type: "string" },
	c_cuid: { op: "eq", type: "string" },
	invoice_id: { op: "eq", type: "string" },
	event_date: { op: "eq", type: "string" },
};

const defaultState = {
	apiResult: {
		total: 0,
		data: [],
	},
	selectedRows: {
		cuids: [],
	},
	filter: {
		c_cuid: "",
		description: "",
		event_date: "",
		invoice_id: "",
	} as SearchEventType,
	pagination: {
		page: 1,
		pageSize: 10,
	},
	sort: defaultSort,
	loading: false,
};

export const useEventsStore = create<EventStoreState>((set, get) => ({
	...defaultState,
	resetState: () => set(defaultState),
	setFilter: (filter: SearchEventType) => {
		set({
			filter,
			selectedRows: { cuids: [] },
			pagination: { ...get().pagination, page: 1 },
		});
		get().loadEvents();
	},
	resetSelectedRows: () => {
		set({ selectedRows: { cuids: [] } });
	},
	resetFilter: () => {
		set({ filter: defaultState.filter });
		get().loadEvents();
	},
	setPagination: (page: number, pageSize: number) => {
		if (pageSize !== get().pagination.pageSize) {
			page = 1;
		}
		set({ pagination: { page, pageSize } });
		get().loadEvents();
	},
	setSort: (newSort: SortType) =>
		SetSort(newSort, get().sort, defaultSort, new Map<string, string>([["event_date", "event_date"]]), set, get().loadEvents),
	loadEvents: async (): Promise<void> => {
		set({ loading: true });

		let qs = D3QSGenerator(get().filter, operationsFilter, get().pagination, get().sort);

		await new ClientHttp().get<Success<ListEventType>, BadRequest | StandardError>(
			`/api/v1/company/billing/events?${qs}`,
			(result: Success<ListEventType>) => {
				let newPage = get().pagination.page;
				const { body } = result;
				if (body.total > 0 && body.data.length === 0) {
					newPage = newPage - 1;
					set({
						apiResult: body,
						loading: false,
						pagination: { ...get().pagination, page: newPage },
					});
					get().loadEvents();
				} else {
					set({ apiResult: body, loading: false });
				}
			},
			(error: BadRequest | StandardError) => {
				showMessage(error);
			}
		);
	},
	getEvent: async (eventCuid: string): Promise<Success<ViewEventType> | StandardError> => {
		return await new ClientHttp().get<Success<ViewEventType>, StandardError>(
			`/api/v1/company/billing/events/${eventCuid}`,
			(result: Success<{ cuid: string }>) => {
				return result.body;
			},
			(error: StandardError): void => {
				showMessage(error);
			}
		);
	},
	createEvent: async (operation: CreateEventType): Promise<Success<{ cuid: string }> | BadRequest | StandardError> => {
		return await new ClientHttp().post<CreateEventType, Success<{ cuid: string }>, BadRequest | StandardError>(
			"/api/v1/company/billing/events",
			operation,
			(result: Success<{ cuid: string }>) => {
				showMessage(result, "Evento cadastrado com sucesso.");
				get().loadEvents();
			},
			(error: BadRequest | StandardError): void => {
				showMessage(error);
			}
		);
	},
	updateEvent: async (eventCuid: string, payload: UpdateEventType): Promise<Success<void> | BadRequest | StandardError> => {
		return await new ClientHttp().put<UpdateEventType, Success<void>, BadRequest | StandardError>(
			`/api/v1/company/billing/events/${eventCuid}`,
			payload,
			(result: Success<void>) => {
				showMessage(result, "Evento atualizado com sucesso.");
				get().loadEvents();
			},
			(error: BadRequest | StandardError): void => {
				showMessage(error);
			}
		);
	},
	simpleListCustomerSystem: async (): Promise<ItemCustomerSystemType[]> => {
		return await new ClientHttp().getItensForSelect<Success<SimpleListCustomerSystemType>, StandardError, ItemCustomerSystemType[]>(
			"/api/v1/system/customers/simple-list",
			(result: Success<SimpleListCustomerSystemType>): ItemCustomerSystemType[] => {
				return result.body.data as ItemCustomerSystemType[];
			},
			(error: StandardError): ItemCustomerSystemType[] => {
				showMessage(error, "Erro ao carregar lista de clientes.");
				return [] as ItemCustomerSystemType[];
			}
		);
	},
	simpleListDepartmentsByCustomerCuid: async (customerCuid: string): Promise<ItemDepartmentsCustomerCuid[]> => {
		return await new ClientHttp().getItensForSelect<Success<SimpleListDepartmentsByCustomerCuid>, StandardError, ItemDepartmentsCustomerCuid[]>(
			`/api/v1/company/customers/${customerCuid}/departments/simple-list`,
			(result: Success<SimpleListDepartmentsByCustomerCuid>): ItemDepartmentsCustomerCuid[] => {
				return result.body.data as ItemDepartmentsCustomerCuid[];
			},
			(error: StandardError): ItemDepartmentsCustomerCuid[] => {
				showMessage(error, "Erro ao carregar lista de clientes.");
				return [] as ItemDepartmentsCustomerCuid[];
			}
		);
	},
	deleteEvent: async (eventCuid: string): Promise<Success<void> | StandardError> => {
		return await new ClientHttp().delete<Success<void>, StandardError>(
			`/api/v1/company/billing/events/${eventCuid}`,
			(result: Success<void>) => {
				showMessage(result, "Evento excluído com sucesso.");
				get().loadEvents();
			},
			(result: StandardError) => {
				showMessage(result);
			}
		);
	},
}));
