import D3Can from "@components/shared/D3Can";
import { Icon } from "@iconify/react";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import D3DateHourSorter from "@utils/D3DateHourSorter";
import { Button, Pagination, Popconfirm, Popover, Space, Tooltip } from "antd";
import Table from "antd/es/table";
import { useContext, useEffect, useState } from "react";
import { CreatePhysicalTagForm } from "./components/CreatePhysicalTagForm";
import { SearchPhysicalTagForm } from "./components/SearchPhysicalTagForm";
import { ViewPhysicalTagForm } from "./components/ViewPhysicalTagForm";
import { usePhysicalTagsStore } from "./store";
import { RowPhysicalTagType } from "./types";

const PhysicalTagsView: React.FC = () => {
  const { openDrawer } = useContext(D3DrawerContext);
  const {
    apiResult,
    pagination,
    loading,
    setPagination,
    setSort,
    loadPhysicalTags,
    deletePhysicalTag,
    resetFilter,
    resetState,
  } = usePhysicalTagsStore();
  const [openSearch, setOpenSearch] = useState(false);

  useEffect(() => {
    (async () => {
      await loadPhysicalTags();
    })();
    return () => {
      resetState();
    };
  }, [loadPhysicalTags]);

  return (
    <div className="flex flex-col h-full mb-1 mr-1 overflow-auto bg-white rounded shadow-md">
      <Table<RowPhysicalTagType>
        size="small"
        rowKey="cuid"
        dataSource={apiResult.data as RowPhysicalTagType[]}
        bordered
        className="responsive-table"
        pagination={false}
        loading={loading}
        title={() => {
          return (
            <>
              <div className="flex items-center justify-between w-full">
                <div className="flex items-center justify-start">
                  <Popover
                    open={openSearch}
                    placement="rightTop"
                    title="Pesquisar Lotes de Etiquetas"
                    content={
                      <SearchPhysicalTagForm
                        onCallBackFilter={() => setOpenSearch(false)}
                        onCallBackResetFilter={() => {
                          setOpenSearch(false);
                          resetFilter();
                        }}
                      />
                    }
                    trigger="click"
                    onOpenChange={(open) => setOpenSearch(open)}
                  >
                    <Button
                      onClick={() => setOpenSearch(!openSearch)}
                      type="primary"
                      size="middle"
                      icon={<Icon icon="fluent:search-12-filled" height={16} width={16} />}
                    >
                      Pesquisar
                    </Button>
                  </Popover>
                </div>
                <div className="flex items-center justify-center">
                  <span className="text-xl font-semibold text-gray-600">Lotes de Etiquetas</span>
                </div>
                <div className="flex items-center justify-end">
                  <D3Can action="create" resource="company_physical_tag">
                    <Button
                      type="primary"
                      size="middle"
                      icon={<Icon icon="ic:round-plus" height={20} width={20} />}
                      onClick={() => openDrawer(<CreatePhysicalTagForm />, "right", 600, "Cadastrar Lote de Etiquetas")}
                    >
                      Novo Lote de Etiquetas
                    </Button>
                  </D3Can>
                </div>
              </div>
            </>
          );
        }}
        onChange={(a, b, sorter: any) => {
          if (sorter) {
            setSort({
              sort_by: sorter.field,
              sort_direction: sorter.order,
            });
          }
        }}
        footer={() => {
          return (
            <>
              <div className="flex gap-4"></div>

              <Space>
                <Pagination
                  showLessItems
                  showTotal={(total, range) =>
                    `Exibindo de ${range[0]} a ${range[1]} do total ${total} ${total > 1 ? "registros" : "registro"}`
                  }
                  showSizeChanger={true}
                  current={pagination.page}
                  onChange={setPagination}
                  pageSize={pagination.pageSize}
                  total={apiResult.total}
                />
              </Space>
            </>
          );
        }}
      >
        <Table.Column<RowPhysicalTagType>
          showSorterTooltip={false}
          key="description"
          title="Descrição"
          dataIndex="description"
          sorter
        />
        <Table.Column<RowPhysicalTagType>
          showSorterTooltip={false}
          key="type"
          title="Tipo de etiquetas"
          dataIndex="type"
          sorter
          render={(value, physicalTag) => (physicalTag.type === "D" ? "Documento" : "Repositório")}
        />

        <Table.Column<RowPhysicalTagType>
          showSorterTooltip={false}
          key="generated_by.name"
          title="Gerado por"
          dataIndex={["generated_by", "name"]}
        />
        <Table.Column<RowPhysicalTagType>
          showSorterTooltip={false}
          key="generated_at"
          title="Data de geração"
          dataIndex="generated_at"
          render={(value, physicalTag) => D3DateHourSorter(physicalTag.generated_at as Date)}
          sorter
        />

        <Table.Column<RowPhysicalTagType>
          showSorterTooltip={false}
          key="printed_by.name"
          title="Impresso por"
          dataIndex={["printed_by", "name"]}
        />
        <Table.Column<RowPhysicalTagType>
          showSorterTooltip={false}
          key="generprinted_atated_at"
          title="Data de impressão"
          dataIndex="printed_at"
          render={(value, physicalTag) => D3DateHourSorter(physicalTag.printed_at as Date)}
          sorter
        />

        <Table.Column<RowPhysicalTagType>
          showSorterTooltip={false}
          key="number_of_tags"
          title="Qtd. de etiquetas"
          dataIndex="number_of_tags"
        />

        <Table.Column<RowPhysicalTagType>
          key="action"
          title="Ações"
          width={100}
          render={(value, physicalTag, index) => {
            return (
              <>
                <Space>
                  <D3Can action="view" resource="company_physical_tag">
                    <Tooltip title="Visualizar" destroyTooltipOnHide mouseLeaveDelay={0} zIndex={11}>
                      <Button
                        onClick={() =>
                          openDrawer(
                            <ViewPhysicalTagForm physicalTagBatchCuid={physicalTag.cuid} />,
                            "right",
                            700,
                            `Visualizar etiquetas ${physicalTag.description ? `- ${physicalTag.description}` : ""}`
                          )
                        }
                        className="peer"
                        type="text"
                        shape="default"
                        icon={
                          <Icon
                            icon="carbon:document-view"
                            height={18}
                            width={18}
                            className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
                          />
                        }
                        size={"middle"}
                      />
                    </Tooltip>
                  </D3Can>
                  <D3Can action="delete" resource="company_physical_tag">
                    <Popconfirm
                      title="Atenção!"
                      description="Confirmar exclusão de lote etiquetas?"
                      onConfirm={() => deletePhysicalTag(physicalTag.cuid)}
                      okText="Sim"
                      cancelText="Não"
                    >
                      <Tooltip title="Excluir" destroyTooltipOnHide mouseLeaveDelay={0} zIndex={11}>
                        <Button
                          className="peer"
                          type="text"
                          shape="default"
                          icon={
                            <Icon
                              icon="material-symbols:delete"
                              height={18}
                              width={18}
                              className="text-red-400 transition-all duration-100 ease-in hover:text-red-500"
                            />
                          }
                          size={"middle"}
                        />
                      </Tooltip>
                    </Popconfirm>
                  </D3Can>
                </Space>
              </>
            );
          }}
        />
      </Table>
    </div>
  );
};

export default PhysicalTagsView;
