import { Icon } from "@iconify/react";
import type { DescriptionsProps } from "antd";
import { Descriptions, Tag, Tooltip } from "antd";
import dayjs from "dayjs";
import { DepartmentType, MarkerItemType, MutationByType, ViewFileType } from "../../types";
import formatBytes from "@utils/formatBytes";

type FileInfoProps = {
  info: ViewFileType;
};

enum UserType {
  SYS = "Usuário sistema",
  CPN = "Usuário empresa",
  CTM = "Usuário cliente",
  CGR = "Usuário grupo de cliente",
}

function FileInfo({ info }: FileInfoProps) {
  const department = ({ abbreviation, active, description }: DepartmentType) => {
    const isGlobal = description === "DEPARTAMENTO GLOBAL";
    return (
      <div className="flex justify-between">
        <div>{`${abbreviation} - ${description}`}</div>
        {!isGlobal && (
          <Tooltip trigger="hover" title={active ? "Departamento desbloqueado" : "Departamento Bloqueado"}>
            <div>
              <Icon
                height={16}
                icon={active ? "basil:unlock-solid" : "basil:lock-solid"}
                className={active ? "text-green-500" : "text-red-500"}
              />
            </div>
          </Tooltip>
        )}
      </div>
    );
  };

  const userBy = ({ type, active, name }: MutationByType) => {
    return (
      <div className="flex items-center justify-between">
        <span>{`${name} - ${UserType[type as keyof typeof UserType]}`}</span>
        <Tooltip trigger="hover" title={active ? "Usuário desbloqueado" : "Usuário Bloqueado"}>
          <div>
            <Icon
              height={16}
              icon={active ? "basil:unlock-solid" : "basil:lock-solid"}
              className={active ? "text-green-500" : "text-red-500"}
            />
          </div>
        </Tooltip>
      </div>
    );
  };

  const formatDate = (date: Date, sort?: boolean) => {
    if (date) {
      if (sort) {
        return dayjs(date).format("DD/MM/YYYY");
      } else {
        return dayjs(date).format("DD/MM/YYYY - HH:MM");
      }
    } else {
      return "Data não definida.";
    }
  };

  const deletedInfo = info.deleted_at
    ? [
        {
          key: "15",
          label: "Deletado por",
          children: info.deleted_by ? userBy(info.deleted_by) : <></>,
          span: 1,
        },
        {
          key: "16",
          label: "Deletado em",
          children: info.deleted_at ? formatDate(info.deleted_at) : <></>,
          span: 1,
        },
      ]
    : [];

  const items: DescriptionsProps["items"] = [
    {
      key: "1",
      label: "Nome",
      children: info.name,
      span: 1,
    },
    {
      key: "2",
      label: "Extensão",
      children: (
        <div className="flex items-center gap-2">
          {info.extension.key} <Icon height={22} icon={info.extension.icon} />
        </div>
      ),
      span: 1,
    },
    {
      key: "3",
      label: "Tamanho",
      children: formatBytes(info.size),
      span: 1,
    },
    {
      key: "4",
      label: "Editável",
      children: info.editable ? "Sim" : "Não",
      span: 1,
    },
    {
      key: "5",
      label: "Criado por",
      children: userBy(info.created_by),
      span: 1,
    },
    {
      key: "6",
      label: "Criado em",
      children: formatDate(info.created_at),
      span: 1,
    },
    {
      key: "7",
      label: "Atualizado por",
      children: userBy(info.updated_by),
      span: 1,
    },
    {
      key: "8",
      label: "Atualizado em",
      children: formatDate(info.updated_at),
      span: 1,
    },
    {
      key: "9",
      label: "Data de origem",
      children: formatDate(info.original_date, true),
      span: 1,
    },
    {
      key: "10",
      label: "Previsão de expurgo",
      children: formatDate(info.expected_purge_date, true),
      span: 1,
    },
    {
      key: "11",
      label: "Upload completo",
      children: info.file_upload_completed ? "Sim" : "Não",
      span: 1,
    },
    {
      key: "12",
      label: "Visibilidade global",
      children: info.global_visibility ? "Sim" : "Não",
      span: 1,
    },
    {
      key: "13",
      label: "Departamento",
      children: department(info.billable_department),
      span: 1,
    },
    {
      key: "14",
      label: "Marcadores",
      children: (
        <div className="flex flex-wrap gap-1">
          {info.markers.map(({ color, cuid, key }: MarkerItemType) => (
            <Tag color={color} key={cuid}>
              {key}
            </Tag>
          ))}
        </div>
      ),
      span: 1,
    },
    {
      key: "15",
      label: "Hash",
      children: info.etag,
      span: 1,
    },
    ...deletedInfo,
  ];

  return <Descriptions column={1} className="p-3" size="small" bordered items={items} />;
}

export default FileInfo;
