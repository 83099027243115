export enum ProfileList {
  CPN = "CPN",
  CTM = "CTM",
  CGR = "CGR",
}

export const profileOptions = (): { value: ProfileList; label: string }[] => {
  return [
    { label: "Empresa", value: ProfileList.CPN },
    { label: "Cliente", value: ProfileList.CTM },
    { label: "Grupo de cliente", value: ProfileList.CGR },
  ];
};

export const profileList = profileOptions().reduce((map, option) => {
  map[option.value] = option.label;
  return map;
}, {} as Record<ProfileList, string>);
