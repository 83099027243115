import { Button, Result } from "antd";
import { useD3SelectEntityStore } from "../Layout/D3HeaderBar/components/store";
import { ReactElement } from "react";

type D3CustomerRequiredProps = {
    children: ReactElement;
}

function D3CustomerRequired({children}: D3CustomerRequiredProps) {
    const { setIsOpen, getCustomerCuid} = useD3SelectEntityStore();
    return ( 
        getCustomerCuid() === undefined ?
    <Result
    status="404"
    title="Selecione um cliente"
    subTitle="O acesso a tela solicitada só é permitida com cliente selecionado."
    extra={<Button type="primary" onClick={() => setIsOpen(true) }>Selecionar cliente</Button>}
  /> : children
     );
}

export default D3CustomerRequired;