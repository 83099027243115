import { useContext, useEffect } from "react";
import { Button, Form, Input, Select, Space } from "antd";
import { useContactTypesStore } from "../store";
import { MutationContactTypeType } from "../types";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import D3Can from "@components/shared/D3Can";
import { profileOptions } from "./listProfile";

type UpdateContactTypeFormProps = {
  id: number;
  description: string;
  profile: string;
};

export const UpdateContactTypeForm = ({ description, id, profile }: UpdateContactTypeFormProps) => {
  const { updateContactType, loadingUpdated } = useContactTypesStore();
  const { onClose } = useContext(D3DrawerContext);
  const [form] = Form.useForm<MutationContactTypeType>();

  useEffect(() => {
    form.setFieldsValue({ description, profile });
  }, [id]);

  return (
    <Form
      className="select-none"
      form={form}
      layout="vertical"
      onFinish={async (payload: MutationContactTypeType) => {
        const isCreated = await updateContactType(id, payload);
        if (isCreated) {
          onClose();
        }
      }}
      autoComplete="off"
    >
      <Form.Item<MutationContactTypeType>
        name="description"
        label="Descrição"
        rules={[{ required: true, message: "Por favor, insira descrição." }]}
      >
        <Input />
      </Form.Item>

      <Form.Item<MutationContactTypeType>
        name="profile"
        label="Perfil"
        rules={[{ required: true, message: "Por favor, selecione um perfil." }]}
      >
        <Select options={profileOptions()} />
      </Form.Item>

      <Form.Item>
        <div className="text-right">
          <Space size="small">
            <Button disabled={loadingUpdated} type="default" danger onClick={() => onClose()}>
              Cancelar
            </Button>
            <D3Can action="manager" resource="all">
              <Button loading={loadingUpdated} type="primary" htmlType="submit">
                Cadastrar
              </Button>
            </D3Can>
          </Space>
        </div>
      </Form.Item>
    </Form>
  );
};
