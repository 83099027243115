import { Icon } from "@iconify/react";
import { Tabs } from "antd";
import { useCompaniesStore } from "./store";
import TabData from "./components/TabPersonalInformation";
import TabAddress from "./components/TabPrivacy";
import TabLogs from "./components/TabLogs";

const UserView: React.FC = () => {
  const { getActiveTab, setActiveTab } = useCompaniesStore();

  return (
    <>
      <div className="flex-col flex-1 h-full p-3 mb-1 mr-1 bg-white rounded-lg shadow ">
        <Tabs
          tabPosition="left"
          defaultActiveKey="1"
          activeKey={getActiveTab()}
          onTabClick={(tab) => {
            setActiveTab(tab);
          }}
          className="h-full select-none"
          items={[
            {
              key: "1",
              label: (
                <span className="flex items-center justify-center gap-2">
                  <Icon icon="ri:profile-line" height={20} width={20} />
                  Informações Pessoais
                </span>
              ),
              children: <TabData />,
            },
            {
              key: "2",
              label: (
                <span className="flex items-center justify-center gap-2">
                  <Icon icon="fluent:person-key-20-filled" height={20} width={20} />
                  Segurança
                </span>
              ),
              children: <TabAddress />,
            },
            {
              key: "3",
              label: (
                <span className="flex items-center justify-center gap-2">
                  <Icon icon="fluent:document-key-20-filled" height={20} width={20} />
                  Logs
                </span>
              ),
              children: <TabLogs />,
            },
          ]}
        />
      </div>
    </>
  );
};

export default UserView;
