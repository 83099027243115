export type SortType = {
  sort_by: string;
  sort_direction: string;
};

export const SetSort = (
  newSort: SortType,
  oldSort: SortType,
  defaultSort: SortType,
  apiToDbMapper: Map<string, string>,
  set: any,
  callback?: () => void
) => {
  let sort_by = apiToDbMapper.get(newSort.sort_by);
  let sort_direction = newSort.sort_direction;
  if (
    sort_by === undefined ||
    sort_by === "" ||
    sort_direction === undefined ||
    sort_direction === ""
  ) {
    set({ sort: defaultSort });
  } else {
    sort_direction = sort_direction === "ascend" ? "asc" : "desc";
    if (
      sort_by === oldSort.sort_by &&
      sort_direction === oldSort.sort_direction
    ) {
      return;
    }
    set({ sort: { sort_by, sort_direction } });
  }
  if (callback) {
    callback();
  }
};
