import { Divider, Button, Tooltip, Popconfirm, Table, Space, Tag } from "antd";
import { Icon } from "@iconify/react";
import { useContext, useEffect } from "react";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { useContactStore } from "../contacts/store";
import { ItemListContactType } from "../contacts/type";
import D3Can from "@components/shared/D3Can";
import { CreateContactForm } from "../contacts/CreateContactForm";
import SpinLoading from "@components/shared/SpinLoading";
import { UpdateContactForm } from "../contacts/UpdateContactForm ";
import { ViewContactForm } from "../contacts/ViewContactForm";

function TabContacts() {
  const { openDrawer } = useContext(D3DrawerContext);
  const { loadContacts, loading, listContacts, deleteContact } = useContactStore();

  useEffect(() => {
    (async () => {
      await loadContacts();
    })();
  }, [loadContacts]);

  return (
    <div className="flex flex-col h-full p-2 overflow-hidden">
      <div className="flex items-center max-h-[30px] justify-between">
        <h2 className="ml-1 text-lg font-semibold text-gray-600">Contatos do Cliente</h2>
        <Button
          className="flex items-center justify-start"
          type="primary"
          size="middle"
          icon={<Icon icon="ic:round-plus" height={20} width={20} />}
          onClick={() => openDrawer(<CreateContactForm />, "right", 600, "Cadastrar contato")}
        >
          Novo contato
        </Button>
      </div>
      <Divider className="mt-1 mb-6 " />

      <div className="flex flex-col h-full overflow-auto bg-white ">
        {loading ? (
          <SpinLoading />
        ) : (
          <Table<ItemListContactType>
            size="small"
            rowKey="cuid"
            dataSource={listContacts}
            bordered
            className="responsive-table tabs-table"
            pagination={false}
            loading={loading}
            footer={() => (
              <>
                Total de {listContacts.length} {listContacts.length > 1 ? "registros." : "registro."}
              </>
            )}
          >
            <Table.Column<ItemListContactType>
              showSorterTooltip={false}
              key="description"
              title="Descrição"
              dataIndex="description"
            />
            <Table.Column<ItemListContactType> showSorterTooltip={false} key="name" title="Nome" dataIndex="name" />

            <Table.Column<ItemListContactType>
              showSorterTooltip={false}
              key="contact_type"
              title="Tipo de contato"
              dataIndex={["contact_type", "description"]}
            />

            <Table.Column
              showSorterTooltip={false}
              key="contacts"
              title="Contatos"
              render={(_, record: ItemListContactType) => {
                return (
                  <div className="flex gap-2 min-w-max">
                    {record.email && <Tag color="blue">Email</Tag>}
                    {record.landline && <Tag color="red">Telefone Fixo</Tag>}
                    {record.phone && <Tag color="green">Celular</Tag>}
                  </div>
                );
              }}
            />

            <Table.Column<ItemListContactType>
              key="action"
              title="Ações"
              width={80}
              align="center"
              render={(value, record) => {
                return (
                  <>
                    <Space>
                      <D3Can action="manager" resource="all">
                        <Tooltip
                          trigger="hover"
                          title="Visualizar"
                          destroyTooltipOnHide
                          mouseLeaveDelay={0}
                          zIndex={11}
                        >
                          <Button
                            className="peer"
                            type="text"
                            shape="default"
                            icon={
                              <Icon
                                icon="carbon:document-view"
                                height={18}
                                width={18}
                                className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
                              />
                            }
                            size={"middle"}
                            onClick={() =>
                              openDrawer(<ViewContactForm contact={record} />, "right", 600, "Visualizar contato")
                            }
                          />
                        </Tooltip>
                      </D3Can>
                      <D3Can action="manager" resource="all">
                        <Tooltip title="Editar" destroyTooltipOnHide mouseLeaveDelay={0} zIndex={11}>
                          <Button
                            type="text"
                            shape="default"
                            icon={
                              <Icon
                                icon="iconamoon:edit-fill"
                                height={18}
                                width={18}
                                className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
                              />
                            }
                            size={"middle"}
                            onClick={() =>
                              openDrawer(<UpdateContactForm contact={record} />, "right", 600, "Editar contato")
                            }
                          />
                        </Tooltip>
                      </D3Can>
                      <D3Can action="manager" resource="all">
                        <Popconfirm
                          title="Atenção!"
                          description="Confirmar a exclusão do contato?"
                          onConfirm={() => deleteContact(record.cuid)}
                          okText="Sim"
                          cancelText="Não"
                        >
                          <Tooltip title="Excluir contato" destroyTooltipOnHide mouseLeaveDelay={0} zIndex={11}>
                            <Button
                              className="peer"
                              type="text"
                              shape="default"
                              icon={
                                <Icon
                                  icon="material-symbols:delete"
                                  height={18}
                                  width={18}
                                  className="text-red-400 transition-all duration-100 ease-in hover:text-red-500"
                                />
                              }
                              size={"middle"}
                            />
                          </Tooltip>
                        </Popconfirm>
                      </D3Can>
                    </Space>
                  </>
                );
              }}
            />
          </Table>
        )}
      </div>
    </div>
  );
}

export default TabContacts;
