import { Form, Input } from "antd";
import { IndexItemType } from "../physical/types";

type ItemEMAPropsType = {
  item: IndexItemType;
};

function ItemEMA({ item }: ItemEMAPropsType) {
  const { id, label, required } = item;
  return (
    <Form.Item
      name={[id, "EMA", "value"]}
      label={label}
      rules={[
        {
          required: required,
          message: `Por favor, preencha o campo ${label.toLowerCase()}`,
        },
        {
          type: "email",
          message: "Por favor, insira um endereço de e-mail válido!",
        },
      ]}
    >
      <Input />
    </Form.Item>
  );
}

export default ItemEMA;
