import D3PDFView from "@components/shared/D3PDF/D3PDFView";
import showMessage from "@utils/showMessage";
import { Spin } from "antd";
import { useEffect, useState } from "react";
import { useSpecialOrdersStore } from "../store";
import { UrlType } from "../types";

type PrintSpecialOrderFormPropsType = {
  cuid: string;
  fileName: string;
  printed: boolean;
};

function PrintSpecialOrderForm({ cuid, fileName, printed }: PrintSpecialOrderFormPropsType) {
  const { getUrl, loadSpecialOrders } = useSpecialOrdersStore();
  const [loading, setLoading] = useState<boolean>(true);
  const [base, setBase] = useState<string>("");

  useEffect(() => {
    (async () => {
      const response = await getUrl(cuid);
      if (response.status === "success") {
        const viewSpecial = response.body as UrlType;
        setBase(viewSpecial.url);
        setLoading(false);
      } else {
        showMessage(response);
      }
    })();

    return () => {
      if (!printed) {
        loadSpecialOrders();
      }
    };
  }, [cuid, getUrl]);

  return (
    <>
      {loading ? (
        <div className="flex items-center justify-center h-full ">
          <Spin spinning />
        </div>
      ) : (
        <D3PDFView url={base} fileName={fileName} />
      )}
    </>
  );
}

export default PrintSpecialOrderForm;
