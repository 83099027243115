import { Col, Divider, Form, Input, Row, Spin, Table, Tabs } from "antd";
import { useEffect, useState } from "react";
import { useDeploymentOrdersStore } from "../store";

import { Icon } from "@iconify/react";
import D3Copy from "@utils/D3Copy";
import D3DateHourSorter from "@utils/D3DateHourSorter";
import showMessage from "@utils/showMessage";
import { ShowTag, StatusOrders, TypeOfRequest } from "@views/orders/Utils";
import { AddressType, ViewDeploymentOrderType } from "../types";

type ViewDeploymentOrderFormProps = {
  cuid: string;
};
const { TextArea } = Input;

export const ViewDeploymentOrderForm = ({ cuid }: ViewDeploymentOrderFormProps) => {
  const { getDeploymentOrder } = useDeploymentOrdersStore();
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm<ViewDeploymentOrderType>();

  useEffect(() => {
    (async () => {
      const result = await getDeploymentOrder(cuid);
      if (result.status === "success") {
        const viewDeploymentOrder = result.body as ViewDeploymentOrderType;
        const datesTransformed = {
          canceled_at: D3DateHourSorter(viewDeploymentOrder.canceled_at),
          created_at: D3DateHourSorter(viewDeploymentOrder.created_at),
          printed_at: D3DateHourSorter(viewDeploymentOrder.printed_at),
          served_at: D3DateHourSorter(viewDeploymentOrder.served_at),
        };

        form.setFieldsValue({
          ...viewDeploymentOrder,
          ...datesTransformed,
        });

        setLoading(false);
      } else {
        showMessage(result);
      }
    })();
  }, [cuid, form, getDeploymentOrder]);

  const customerAddress: AddressType = form.getFieldValue("customer_address") || null;
  const storageAddress: AddressType = form.getFieldValue("storage_address") || null;

  return (
    <>
      <Spin spinning={loading}>
        <Form className="select-none form-view" layout="vertical" form={form} autoComplete="off">
          <Tabs
            defaultActiveKey="1"
            className="select-none"
            items={[
              {
                key: "1",
                label: (
                  <span className="flex items-center justify-center gap-2 text-gray-600">
                    <Icon icon="fluent:form-24-filled" className="w-5 h-5" />
                    Dados
                  </span>
                ),
                children: (
                  <>
                    <Row gutter={12}>
                      <Col span={12}>
                        <Form.Item<ViewDeploymentOrderType> label="Número" name="id">
                          <Input
                            disabled={false}
                            readOnly
                            addonAfter={
                              <Icon
                                onClick={() =>
                                  D3Copy({
                                    text: form.getFieldValue("id"),
                                    success: "Número copiado!",
                                  })
                                }
                                className="text-blue-400 transition-all duration-300 ease-in-out cursor-pointer hover:scale-110 hover:text-blue-500 "
                                icon="fluent:copy-32-filled"
                              />
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item<ViewDeploymentOrderType> name="customer_name" label="Cliente">
                          <Input disabled />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={12}>
                      <Col span={12}>
                        <Form.Item<ViewDeploymentOrderType> name="created_by" label="Criado por">
                          <Input disabled />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item<ViewDeploymentOrderType> name="created_at" label="Data da criação">
                          <Input disabled />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={12}>
                      <Col span={12}>
                        <Form.Item<ViewDeploymentOrderType>
                          name="repository_type_description"
                          label="Tipo de repositório"
                        >
                          <Input disabled />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item<ViewDeploymentOrderType> name="department_description" label="Departamento">
                          <Input disabled />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={12}>
                      <Col span={12}>
                        <Form.Item<ViewDeploymentOrderType> label="Status">
                          {ShowTag(StatusOrders, form.getFieldValue("status"))}
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item<ViewDeploymentOrderType> label="Tipo de requisição">
                          {ShowTag(TypeOfRequest, form.getFieldValue("request_type"))}
                        </Form.Item>
                      </Col>
                    </Row>

                    <Form.Item<ViewDeploymentOrderType> hidden={customerAddress === null} label="Endereço de coleta">
                      <TextArea
                        value={
                          customerAddress
                            ? `${customerAddress.street_address}, ${customerAddress.street_number}${
                                customerAddress.complement ? ` - ${customerAddress.complement}` : ""
                              }, ${customerAddress.neighborhood}, ${customerAddress.city},  ${
                                customerAddress.state
                              } - CEP ${customerAddress.zip_code}`
                            : "requisição sem transporte"
                        }
                        autoSize
                        disabled
                      />
                    </Form.Item>

                    <Form.Item<ViewDeploymentOrderType>
                      label="Endereço de implantação"
                      hidden={storageAddress === null}
                    >
                      <TextArea
                        value={
                          storageAddress
                            ? `${storageAddress.street_address}, ${storageAddress.street_number}${
                                storageAddress.complement ? ` - ${storageAddress.complement}` : ""
                              }, ${storageAddress.neighborhood}, ${storageAddress.city},  ${
                                storageAddress.state
                              } - CEP ${storageAddress.zip_code}`
                            : "requisição sem transporte"
                        }
                        autoSize
                        disabled
                      />
                    </Form.Item>

                    {form.getFieldValue("printed") && (
                      <>
                        <Divider plain>Dados da impressão</Divider>
                        <Row gutter={12}>
                          <Col span={12}>
                            <Form.Item<ViewDeploymentOrderType> name="printed_by" label="Impresso por">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item<ViewDeploymentOrderType> name="printed_at" label="Data da impressão">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                        </Row>
                      </>
                    )}

                    {form.getFieldValue("served") && (
                      <>
                        <Divider plain>Dados do atendimento</Divider>
                        <Row gutter={12}>
                          <Col span={12}>
                            <Form.Item<ViewDeploymentOrderType> name="served_by" label="Atendido por">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item<ViewDeploymentOrderType> name="served_at" label="Data do atendimento">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                        </Row>
                      </>
                    )}

                    {form.getFieldValue("canceled") && (
                      <>
                        <Divider plain>Dados do cancelamento</Divider>
                        <Row gutter={12}>
                          <Col span={12}>
                            <Form.Item<ViewDeploymentOrderType> name="canceled_by" label="Cancelado por">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item<ViewDeploymentOrderType> name="canceled_at" label="Data do cancelamento">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Form.Item<ViewDeploymentOrderType> name="cancel_reason" label="Motivo do cancelamento">
                          <TextArea autoSize={{ minRows: 3 }} disabled />
                        </Form.Item>
                      </>
                    )}
                  </>
                ),
              },
              {
                key: "2",
                label: (
                  <span className="flex items-center justify-center gap-2 text-gray-600">
                    <Icon icon="fluent:form-24-filled" className="w-5 h-5" />
                    Repositórios
                  </span>
                ),
                children: (
                  <Table
                    className="mt-2"
                    key="repository_physical_tag_id"
                    size="small"
                    bordered
                    rowKey="repository_physical_tag_id"
                    pagination={false}
                    dataSource={form.getFieldValue("repositories")}
                    columns={[
                      {
                        title: "Código de etiqueta do repositório",
                        dataIndex: "repository_physical_tag_id",
                        key: "repository_physical_tag_id",
                      },
                      {
                        title: "Posição",
                        dataIndex: "position_id",
                        key: "position_id",
                      },
                    ]}
                  />
                ),
              },
            ]}
          />
        </Form>
      </Spin>
    </>
  );
};
