import { create } from "zustand";

import axios from "axios";
import {
  BadRequest,
  ClientHttp,
  StandardError,
  Success,
} from "@utils/clientHttp";
import showMessage from "@utils/showMessage";
import {
  CreateExtensionType,
  IconDataType,
  ListExtensionType,
  UpdateExtensionType,
  ViewExtension,
} from "./types";
type CustomerStoreState = {
  apiResult: ListExtensionType;
  loadExtensions: () => Promise<void>;
  getExtension: (id: number) => Promise<Success<ViewExtension> | StandardError>;
  createExtension: (address: CreateExtensionType) => Promise<
    | Success<{
        cuid: string;
      }>
    | BadRequest
    | StandardError
  >;
  updateExtension: (
    id: number,
    address: UpdateExtensionType
  ) => Promise<
    | StandardError
    | BadRequest
    | Success<{
        cuid: string;
      }>
  >;
  getIconsForSelect: (search: string, page: number) => Promise<IconDataType[]>;
  resetState: () => void;
};

const defaultState = {
  apiResult: {
    total: 0,
    data: [],
  },
};

export const useExtensionsStore = create<CustomerStoreState>((set, get) => ({
  ...defaultState,

  resetState: () => set(defaultState),

  loadExtensions: async (): Promise<void> => {
    await new ClientHttp().get<
      Success<ListExtensionType>,
      BadRequest | StandardError
    >(
      `/api/v1/system/extensions`,
      (result: Success<ListExtensionType>) => {
        const { body } = result;
        if (body.total > 0 && body.data.length === 0) {
          set({
            apiResult: body,
          });
          get().loadExtensions();
        } else {
          set({ apiResult: body });
        }
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
  },

  getExtension: async (
    id: number
  ): Promise<Success<ViewExtension> | StandardError> => {
    return await new ClientHttp().get<Success<ViewExtension>, StandardError>(
      `/api/v1/system/extensions/${id}`
    );
  },

  createExtension: async (
    extension: CreateExtensionType
  ): Promise<Success<{ cuid: string }> | BadRequest | StandardError> => {
    return await new ClientHttp().post<
      CreateExtensionType,
      Success<{ cuid: string }>,
      BadRequest | StandardError
    >(
      "/api/v1/system/extensions",
      extension,
      (result: Success<{ cuid: string }>) => {
        get().loadExtensions();
      }
    );
  },
  updateExtension: async (
    id: number,
    extension: UpdateExtensionType
  ): Promise<Success<{ cuid: string }> | BadRequest | StandardError> => {
    return await new ClientHttp().put<
      UpdateExtensionType,
      Success<{ cuid: string }>,
      BadRequest | StandardError
    >(
      `/api/v1/system/extensions/${id}`,
      extension,
      (result: Success<{ cuid: string }>) => {
        get().loadExtensions();
      }
    );
  },
  getIconsForSelect: async (
    search: string,
    page: number
  ): Promise<IconDataType[]> => {
    try {
      const response = await axios.get(
        `https://api.iconify.design/search?query=${search}&page=${page}`
      );
      const newIcons = response.data.icons.map((iconId: string) => ({
        id: iconId,
        url: `https://api.iconify.design/${iconId}.svg`,
      }));
      return newIcons;
    } catch (error) {
      return [];
    }
  },
}));
