export const enumSituation: Map<string, string> = new Map<string, string>([
  ["DTF", "Tipo de documento"],
  ["SDC", "Documento Estruturado"],
  ["FLD", "Pasta"],
  ["FLE", "Arquivo"],
]);

function FileTypes(key: string): string | undefined {
  return enumSituation.get(key);
}

export default FileTypes;
