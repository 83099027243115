import { create } from "zustand";
import {
  ListCustomerType,
  CreateCustomerType,
  RowCustomerType,
  LockOrUnlockPayloadType,
  SearchCustomerType,
  UpdateCustomerType,
  ViewCustomerType,
} from "./types";

import showMessage from "@utils/showMessage";

import {
  ClientHttp,
  BadRequest,
  StandardError,
  Success,
} from "@utils/clientHttp";
import IsValidMultiSelectedRow from "@utils/table/IsValidMultiSelectedRow";
import { SetSort, SortType } from "@utils/table/SetSort";
import D3QSGenerator, {
  FilterDefinition,
} from "@utils/D3QSGenerator";
import { useD3SelectEntityStore } from "@components/Layout/D3HeaderBar/components/store";

type CustomerStoreState = {
  apiResult: ListCustomerType;
  selectedRows: { cuids: string[]; active: boolean };
  filter: SearchCustomerType;
  pagination: { page: number; pageSize: number };
  sort: { sort_by: string; sort_direction: string };
  loading: boolean;
  setFilter: (filter: SearchCustomerType) => void;
  setPagination: (page: number, pageSize: number) => void;
  setSort: (newSort: SortType) => void;
  loadCustomers: () => Promise<
    Success<ListCustomerType> | BadRequest | StandardError
  >;
  getCustomer: (
    cuid: string
  ) => Promise<Success<ViewCustomerType> | StandardError>;
  createCustomer: (
    customer: CreateCustomerType
  ) => Promise<Success<{ cuid: string }> | BadRequest | StandardError>;
  updateCustomer: (
    cuid: string,
    customer: UpdateCustomerType
  ) => Promise<Success<void> | BadRequest | StandardError>;
  lockCustomer: (cuids: string[]) => Promise<void>;
  unLockCustomer: (cuids: string[]) => Promise<void>;
  onMultiSelectedRowChange: (selectedRows: RowCustomerType[]) => void;
  resetSelectedRows: () => void;
  resetFilter: () => void;
  resetState: () => void;
};

const defaultSort = {
  sort_by: "name",
  sort_direction: "asc",
};

const operationsFilter: FilterDefinition = {
  cus_id: { op: "eq", type: "string" },
  cus_fantasy_name: { op: "lk", type: "string" },
  cus_name: { op: "lk", type: "string" },
  cus_active: { op: "eq", type: "active" },
};

const defaultState = {
  apiResult: {
    total: 0,
    data: [],
  },
  selectedRows: {
    cuids: [],
    active: false,
  },
  filter: {
    cus_id: "",
    cus_fantasy_name: "",
    cus_name: "",
    cus_active: "on",
  } as SearchCustomerType,
  pagination: {
    page: 1,
    pageSize: 10,
  },
  sort: defaultSort,
  loading: false,
};

export const useCustomersStore = create<CustomerStoreState>((set, get) => ({
  ...defaultState,
  resetState: () => set(defaultState),
  setFilter: (filter: SearchCustomerType) => {
    set({
      filter,
      selectedRows: { cuids: [], active: false },
      pagination: { ...get().pagination, page: 1 },
    });
    get().loadCustomers();
  },
  onMultiSelectedRowChange: (selectedRows: RowCustomerType[]): void =>
    IsValidMultiSelectedRow(selectedRows, get, set),
  resetSelectedRows: () => {
    set({ selectedRows: { cuids: [], active: false } });
  },
  resetFilter: () => {
    set({
      filter: {
        cus_id: "",
        cus_fantasy_name: "",
        cus_name: "",
        cus_active: "on",
      },
    });
    get().loadCustomers();
  },
  setPagination: (page: number, pageSize: number) => {
    if (pageSize !== get().pagination.pageSize) {
      page = 1;
    }
    set({ pagination: { page, pageSize } });
    get().resetSelectedRows();
    get().loadCustomers();
  },
  setSort: (newSort: SortType) =>
    SetSort(
      newSort,
      get().sort,
      defaultSort,
      new Map<string, string>([
        ["id", "cus_id"], // Column name from table and column name from api
        ["name", "cus_name"],
        ["fantasy_name", "cus_fantasy_name"],
        ["active", "cus_active"],
      ]),
      set,
      get().loadCustomers
    ),
  loadCustomers: async (): Promise<
    Success<ListCustomerType> | BadRequest | StandardError
  > => {
    set({ loading: true });

    let qs = D3QSGenerator(
      get().filter,
      operationsFilter,
      get().pagination,
      get().sort
    );
    return await new ClientHttp().get<
      Success<ListCustomerType>,
      BadRequest | StandardError
    >(
      `/api/v1/company/customers?${qs}`,
      (result: Success<ListCustomerType>) => {
        let newPage = get().pagination.page;
        const { body } = result;
        if (body.total > 0 && body.data.length === 0) {
          newPage = newPage - 1;
          set({
            apiResult: body,
            loading: false,
            pagination: { ...get().pagination, page: newPage },
          });
          get().loadCustomers();
        } else {
          set({ apiResult: body, loading: false });
        }
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
  },
  getCustomer: async (
    cuid: string
  ): Promise<Success<ViewCustomerType> | StandardError> => {
    return await new ClientHttp().get<Success<ViewCustomerType>, StandardError>(
      `/api/v1/company/customers/${cuid}`
    );
  },
  createCustomer: async (
    customer: CreateCustomerType
  ): Promise<Success<{ cuid: string }> | BadRequest | StandardError> => {
    return await new ClientHttp().post<
      CreateCustomerType,
      Success<{ cuid: string }>,
      BadRequest | StandardError
    >(
      "/api/v1/company/customers",
      customer,
      (result: Success<{ cuid: string }>) => {
        get().loadCustomers();
        useD3SelectEntityStore.getState().loadSimpleListEntity();
      }
    );
  },
  updateCustomer: async (
    cuid: string,
    customer: UpdateCustomerType
  ): Promise<Success<void> | BadRequest | StandardError> => {
    return await new ClientHttp().put<
      UpdateCustomerType,
      Success<void>,
      BadRequest | StandardError
    >(
      `/api/v1/company/customers/${cuid}`,
      customer,
      (result: Success<void>) => {
        get().loadCustomers();
        useD3SelectEntityStore.getState().loadSimpleListEntity();
      }
    );
  },
  lockCustomer: async (cuids: string[]): Promise<void> => {
    await new ClientHttp().patch<
      LockOrUnlockPayloadType,
      Success<{ rows_affected: number }>,
      BadRequest | StandardError
    >(
      `/api/v1/company/customers/lock`,
      { cuids: cuids },
      (result: Success<{ rows_affected: number }>) => {
        get().resetSelectedRows();
        get().loadCustomers();
        if (result.body.rows_affected > 1) {
          showMessage(result, "Itens bloqueados com sucesso.");
        } else {
          showMessage(result, "Item bloqueado com sucesso.");
        }
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
  },
  unLockCustomer: async (cuids: string[]): Promise<void> => {
    await new ClientHttp().patch<
      LockOrUnlockPayloadType,
      Success<{ rows_affected: number }>,
      BadRequest | StandardError
    >(
      `/api/v1/company/customers/unlock`,
      { cuids: cuids },
      (result: Success<{ rows_affected: number }>) => {
        get().resetSelectedRows();
        get().loadCustomers();
        if (result.body.rows_affected > 1) {
          showMessage(result, "Itens desbloqueados com sucesso.");
        } else {
          showMessage(result, "Item desbloqueado com sucesso.");
        }
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
  },
}));
