import { Col, Divider, Form, Input, Row, Spin, Table, Tabs } from "antd";
import { ReactNode, useEffect } from "react";
import { useRelocationStore } from "../store";
import { Icon } from "@iconify/react";
import { ViewRelocationType } from "../types";
import D3DateHourSorter from "@utils/D3DateHourSorter";
import D3Copy from "@utils/D3Copy";
import { StatusOrders } from "@views/orders/Utils";

type ViewRelocationFormProps = {
  cuid: string;
};
const { TextArea } = Input;

export default function ViewRelocationForm({ cuid }: ViewRelocationFormProps) {
  const { ViewRelocation, loadingView, viewRelocation } = useRelocationStore();
  const [form] = Form.useForm<ViewRelocationType>();

  useEffect(() => {
    (async () => {
      if (viewRelocation.cuid === cuid) {
        const datesTransformed = {
          canceled_at: D3DateHourSorter(viewRelocation.canceled_at),
          created_at: D3DateHourSorter(viewRelocation.created_at),
          printed_at: D3DateHourSorter(viewRelocation.printed_at),
          served_at: D3DateHourSorter(viewRelocation.served_at),
        };
        form.setFieldsValue({
          ...viewRelocation,
          ...datesTransformed,
          status: StatusOrders.find((item) => item.status === viewRelocation.status)?.label,
          action_type: viewRelocation.action_type
            ? viewRelocation.action_type === "M"
              ? "Migração"
              : "Deslocamento"
            : undefined,
        });
      } else {
        await ViewRelocation(cuid);
      }
    })();
  }, [cuid, form, ViewRelocation, viewRelocation]);

  const renderTable = (): ReactNode => {
    const isMigration = viewRelocation.served && viewRelocation.action_type === "M";
    return (
      <Table
        className="mt-2"
        key="repository_physical_tag_id"
        size="small"
        bordered
        rowKey="repository_physical_tag_id"
        pagination={false}
        dataSource={viewRelocation.repositories}
        columns={[
          {
            title: "Código de etiqueta do repositório",
            dataIndex: "repository_physical_tag_id",
            key: "repository_physical_tag_id",
          },
          {
            title: `${isMigration ? "Posição antiga" : "Posição"}`,
            dataIndex: "old_position_id",
            key: "old_position_id",
          },
          {
            title: "Nova posição",
            dataIndex: "new_position_id",
            key: "new_position_id",
            hidden: !isMigration,
          },
        ]}
      />
    );
  };

  return (
    <>
      <Spin spinning={loadingView}>
        <Form className="select-none form-view" layout="vertical" form={form} autoComplete="off">
          <Tabs
            defaultActiveKey="1"
            className="select-none"
            items={[
              {
                key: "1",
                label: (
                  <span className="flex items-center justify-center gap-2 text-gray-600">
                    <Icon icon="fluent:form-24-filled" className="w-5 h-5" />
                    Dados
                  </span>
                ),
                children: (
                  <>
                    <Row gutter={12}>
                      <Col span={12}>
                        <Form.Item<ViewRelocationType> label="Número" name="id">
                          <Input
                            disabled={false}
                            readOnly
                            addonAfter={
                              <Icon
                                onClick={() =>
                                  D3Copy({
                                    text: form.getFieldValue("id"),
                                    success: "Número copiado!",
                                  })
                                }
                                className="text-blue-400 transition-all duration-300 ease-in-out cursor-pointer hover:scale-110 hover:text-blue-500 "
                                icon="fluent:copy-32-filled"
                              />
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item<ViewRelocationType> name="status" label="Status">
                          <Input disabled />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={12}>
                      <Col span={12}>
                        <Form.Item<ViewRelocationType> name="created_by" label="Criado por">
                          <Input disabled />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item<ViewRelocationType> name="created_at" label="Data da criação">
                          <Input disabled />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={12}>
                      <Col span={12}>
                        <Form.Item<ViewRelocationType> name="repository_type_description" label="Tipo de repositório">
                          <Input disabled />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item<ViewRelocationType>
                          hidden={viewRelocation.action_type ? false : true}
                          name="action_type"
                          label="Tipo de ação"
                        >
                          <Input disabled />
                        </Form.Item>
                      </Col>
                    </Row>

                    {viewRelocation.printed && (
                      <>
                        <Divider plain>Dados da impressão</Divider>
                        <Row gutter={12}>
                          <Col span={12}>
                            <Form.Item<ViewRelocationType> name="printed_by" label="Impresso por">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item<ViewRelocationType> name="printed_at" label="Data da impressão">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                        </Row>
                      </>
                    )}

                    {viewRelocation.served && (
                      <>
                        <Divider plain>Dados do atendimento</Divider>
                        <Row gutter={12}>
                          <Col span={12}>
                            <Form.Item<ViewRelocationType> name="served_by" label="Atendido por">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item<ViewRelocationType> name="served_at" label="Data do atendimento">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                        </Row>
                      </>
                    )}

                    {viewRelocation.canceled && (
                      <>
                        <Divider plain>Dados do cancelamento</Divider>
                        <Row gutter={12}>
                          <Col span={12}>
                            <Form.Item<ViewRelocationType> name="canceled_by" label="Cancelado por">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item<ViewRelocationType> name="canceled_at" label="Data do cancelamento">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Form.Item<ViewRelocationType> name="cancel_reason" label="Motivo do cancelamento">
                          <TextArea autoSize={{ minRows: 3 }} disabled />
                        </Form.Item>
                      </>
                    )}
                  </>
                ),
              },
              {
                key: "2",
                label: (
                  <span className="flex items-center justify-center gap-2 text-gray-600">
                    <Icon icon="fluent:form-24-filled" className="w-5 h-5" />
                    Repositórios
                  </span>
                ),
                children: renderTable(),
              },
            ]}
          />
        </Form>
      </Spin>
    </>
  );
}
