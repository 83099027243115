import { Button, DatePicker, Form, Input, Segmented, Space } from "antd";
import dayjs from "dayjs";
import { useEffect } from "react";
import { SearchNoteType } from "../types";
import { useNotesStore } from "../store";

const { TextArea } = Input;

export const SearchNoteForm = () => {
  const [form] = Form.useForm<SearchNoteType>();
  const { filter, setFilter } = useNotesStore();

  useEffect(() => {
    form.setFieldsValue(filter);
  }, [form, filter]);

  const normalize = (value: any) => {
    return dayjs(value as Date).format("YYYY-MM-DD");
  };
  const getValueProps = (value: any) => {
    return { value: value ? dayjs(value) : "" };
  };

  return (
    <>
      <Form
        className="select-none w-80"
        layout="vertical"
        form={form}
        onFinish={async (values) => {
          if (form.isFieldsTouched(true)) {
            setFilter(values);
          }
          useNotesStore.setState({
            openSearch: false,
          });
        }}
        autoComplete="off"
      >
        <Form.Item<SearchNoteType> name="note_title" label="Descrição">
          <Input />
        </Form.Item>
        <Form.Item<SearchNoteType> name="note_content" label="Nota de observação">
          <TextArea rows={3} />
        </Form.Item>
        <Form.Item<SearchNoteType> name="created_by_name" label="Criado por">
          <Input />
        </Form.Item>
        <Form.Item<SearchNoteType>
          name="note_created_at"
          label="Data de criação"
          className="w-56"
          normalize={normalize}
          getValueProps={getValueProps}
        >
          <DatePicker format="DD/MM/YYYY" />
        </Form.Item>
        <Form.Item<SearchNoteType> name="note_active" label="Status">
          <Segmented
            options={[
              { label: "Desbloqueado", value: "on" },
              { label: "Bloqueado", value: "off" },
              { label: "Todos", value: "all" },
            ]}
            value={filter.note_active}
          />
        </Form.Item>
        <Form.Item>
          <div className="text-right">
            <Space size="small">
              <Button
                type="default"
                danger
                onClick={() => {
                  useNotesStore.getState().resetFilter();
                  useNotesStore.setState({
                    openSearch: false,
                  });
                }}
              >
                Limpar Filtro
              </Button>
              <Button type="primary" htmlType="submit">
                Filtrar
              </Button>
            </Space>
          </div>
        </Form.Item>
      </Form>
    </>
  );
};
