import axios, { AxiosInstance } from "axios"
import apiClient from "../apiClient"

export default class ClientHttp {

    httpClient: AxiosInstance

    constructor() { this.httpClient = apiClient }

    async post<TPayload, TSuccess, TError>(
        url: string,
        data: TPayload,
        callbackSuccess?: (result: TSuccess) => void ,
        callbackError?: (result: TError) => void,
    ): Promise<TSuccess | TError> {

        let result = {} as TSuccess | TError

        try {
            let response = await this.httpClient.post<TSuccess>(url, data)

            result = {
                code: response.status,
                status: "success",
                body: response.data
            } as TSuccess

            if (callbackSuccess) { callbackSuccess(result) }

        } catch (error) {
            if (axios.isAxiosError(error)) {
               result = {
                    code: error?.response?.status ?? 500,
                    status: "error",
                    body: error?.response?.data
                } as TError;
            } else {
                result = {
                    code: 500,
                    status: "error",
                    body: {
                        message: "Ops! Ocorreu um erro desconhecido",
                    }
                } as TError
            }
            if (callbackError) { callbackError(result) }
        }

        return result;
    }

    async put<TPayload, TSuccess, TError>(
        url: string,
        data: TPayload,
        callbackSuccess?: (result: TSuccess) => void,
        callbackError?: (result: TError) => void,
    ): Promise<TSuccess | TError> {

        let result = {} as TSuccess | TError

        try {

            let response = await this.httpClient.put<TSuccess>(url, data)

            result = {
                code: response.status,
                status: "success",
                body: response.data,
            } as TSuccess

            if (callbackSuccess) { callbackSuccess(result) }

        } catch (error) {
            if (axios.isAxiosError(error)) {
                result = {
                    code: error?.response?.status  ?? 500,
                    status: "error",
                    body: error?.response?.data
                } as TError;
            } else {
                result = {
                    code: 500,
                    status: "error",
                    body: {
                        message: "Ops! Ocorreu um erro desconhecido",
                    }
                } as TError
            }

            if (callbackError) { callbackError(result) }
        }

        return result;
    }

    async patch<TPayload, TSuccess, TError>(
        url: string,
        data: TPayload,
        callbackSuccess?: (result: TSuccess) => void,
        callbackError?: (result: TError) => void,
    ): Promise<TSuccess | TError> {

        let result = {} as TSuccess | TError

        try {
            let response = await this.httpClient.patch<TSuccess>(url, data)
            result = {
                code: response.status,
                status: "success",
                body: response.data
            } as TSuccess

            if (callbackSuccess) { callbackSuccess(result) }

        } catch (error) {
            if (axios.isAxiosError(error)) {
                result = {
                    code: error?.response?.status ?? 500,
                    status: "error",
                    body: error?.response?.data
                } as TError;
            } else {
                result = {
                    code: 500,
                    status: "error",
                    body: {
                        message: "Ops! Ocorreu um erro desconhecido",
                    }
                } as TError
            }

            if (callbackError) { callbackError(result) }
        }

        return result;
    }
    
    async delete<TSuccess, TError>(
        url: string,
        callbackSuccess?: (result: TSuccess) => void,
        callbackError?: (result: TError) => void,
    ): Promise<TSuccess | TError> {
    
        let result = {} as TSuccess | TError;
    
        try {
            let response = await this.httpClient.delete<TSuccess>(url);
    
            result = {
                code: response.status,
                status: "success",
                body: response.data
            } as TSuccess;
    
            if (callbackSuccess) { callbackSuccess(result); }
    
        } catch (error) {
            if (axios.isAxiosError(error)) {
                result = {
                    code: error?.response?.status ?? 500,
                    status: "error",
                    body: error?.response?.data
                } as TError;
            } else {
                result = {
                    code: 500,
                    status: "error",
                    body: {
                        message: "Ops! Ocorreu um erro desconhecido",
                    }
                } as TError;
            }
    
            if (callbackError) { callbackError(result); }
        }
    
        return result;
    }

    async get<TSuccess, TError>(
        url: string,
        callbackSuccess?: (result: TSuccess) => void,
        callbackError?: (result: TError) => void,
    ): Promise<TSuccess | TError> {

        let result = {} as TSuccess | TError

        try {
            let response = await this.httpClient.get(url)

            result = {
                code: response.status,
                status: "success",
                body: await response.data
            } as TSuccess

            if (callbackSuccess) { callbackSuccess(result) }

        } catch (error) {

            if (axios.isAxiosError(error) && error?.response) {
                result = {
                    code: error?.response?.status  ?? 500,
                    status: "error",
                    body: error?.response?.data,
                } as TError;
            } else {
                result = {
                    code: 500,
                    status: "error",
                    body: {
                        message: "Ops! Ocorreu um erro desconhecido",
                    }
                } as TError
            }

            if (callbackError) { callbackError(result) }
        }

        return result;
    }

    async getItensForSelect<TSuccess, TError, TReturn>(
        url: string,
        callbackSuccess: (result: TSuccess) => TReturn,
        callbackError: (result: TError) => TReturn,
    ): Promise<TReturn> {

        let result = {} as TSuccess | TError

        try {
            let response = await this.httpClient.get(url)
            result = {
                code: response.status,
                status: "success",
                body: response.data
            } as TSuccess

            return callbackSuccess(result) 
        } catch (error) {

            if (axios.isAxiosError(error) && error?.response) {
                result = {
                    code: error?.response?.status  ?? 500,
                    status: "error",
                    body: error?.response?.data,
                } as TError;
            } else {
                result = {
                    code: 500,
                    status: "error",
                    body: {
                        message: "Ops! Ocorreu um erro desconhecido ao buscar a lista de itens",
                    }
                } as TError
            }

           return callbackError(result)
        }

    }
}