import { useContext, useState } from "react";

import { Button, Form, Input, InputNumber, Select, Space, Spin } from "antd";
import { usePhysicalTagsStore } from "../store";
import { CreatePhysicalTagType } from "../types";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import showMessage from "@utils/showMessage";
import D3Can from "@components/shared/D3Can";

export const CreatePhysicalTagForm = () => {
  const { createPhysicalTag } = usePhysicalTagsStore();
  const { onClose } = useContext(D3DrawerContext);
  const [form] = Form.useForm<CreatePhysicalTagType>();
  const [loading, setLoading] = useState(false);
  return (
    <>
      <Spin spinning={loading}>
        <Form
          className="select-none"
          form={form}
          layout="vertical"
          onFinish={async (payload: CreatePhysicalTagType) => {
            setLoading(true);
            const result = await createPhysicalTag(payload);
            if (result.status === "success") {
              showMessage(result, "Lote de etiquetas cadastrado com sucesso.");
              onClose();
            } else {
              showMessage(result);
            }
            setLoading(false);
          }}
          autoComplete="off"
        >
          <Form.Item<CreatePhysicalTagType>
            name="description"
            label="Descrição"
          >
            <Input />
          </Form.Item>
          <Form.Item<CreatePhysicalTagType>
            name="number_of_tags"
            label="Quantidade de etiquetas"
            rules={[
              {
                required: true,
                message: "Por favor, insira a quantidade de etiquetas.",
              },
            ]}
          >
            <InputNumber min={1} />
          </Form.Item>

          <Form.Item<CreatePhysicalTagType>
            name="type"
            label="Tipo da etiqueta"
            rules={[
              {
                required: true,
                message: "Por favor, selecione o tipo de etiqueta.",
              },
            ]}
          >
            <Select
              options={[
                { value: "D", label: "Documento" },
                { value: "R", label: "Repositório" },
              ]}
            />
          </Form.Item>
          <Form.Item>
            <div className="text-right">
              <Space size="small">
                <Button type="default" danger onClick={() => onClose()}>
                  Cancelar
                </Button>
                <D3Can action="create" resource="company_physical_tag">
                  <Button type="primary" htmlType="submit">
                    Cadastrar
                  </Button>
                </D3Can>
              </Space>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};
