import { D3Date } from "@utils/date";
import { Form, Input, InputNumber, Radio, Select, Spin, Table } from "antd";
import { useEffect, useState } from "react";
import { ColumnType, Data } from "../../data/data";
import { ViewDocumentTypeType } from "../../types";

type CreateDataType = {
  data: ViewDocumentTypeType;
};

function CreateData({ data }: CreateDataType) {
  const [form] = Form.useForm<ViewDocumentTypeType>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (data && data.created_at && data.updated_at) {
      form.setFieldsValue({
        ...data,
        created_at: D3Date.FormatValueApiToPtBR(data.created_at),
        updated_at: D3Date.FormatValueApiToPtBR(data.updated_at),
      });
    }
    setLoading(false);
  }, [form, data]);

  return (
    <Spin spinning={loading}>
      <Form form={form} layout="vertical" className="w-full" disabled>
        <Form.Item<ViewDocumentTypeType> name="description" label="Descrição">
          <Input />
        </Form.Item>
        <Form.Item<ViewDocumentTypeType> label="Configurações">
          <Table className="mb-5 table-service" key="key" size="small" bordered pagination={false} dataSource={Data}>
            <Table.Column<ColumnType>
              showSorterTooltip={false}
              defaultSortOrder="ascend"
              key="description"
              title="Descrição"
              dataIndex="description"
            />

            <Table.Column<ColumnType>
              align="center"
              className="register-service"
              showSorterTooltip={false}
              defaultSortOrder="ascend"
              key="radio"
              title="Opções"
              dataIndex="radio"
              render={(text, record) => (
                <Form.Item initialValue={record.radio} name={record.name} className="register-service">
                  {record.radio !== undefined ? (
                    <Radio.Group
                      size="small"
                      options={[
                        { label: "Sim", value: true },
                        { label: "Não", value: false },
                      ]}
                      value={record.radio}
                      optionType="button"
                      buttonStyle="solid"
                    />
                  ) : record.name === "temporality_year" || record.name === "update_frequency_period" ? (
                    <InputNumber min={0} />
                  ) : (
                    <Select
                      style={{ width: 90 }}
                      options={[
                        {
                          value: "D",
                          label: "Diário",
                        },
                        {
                          value: "M",
                          label: "Mensal",
                        },
                        {
                          value: "Y",
                          label: "Anual",
                        },
                      ]}
                    />
                  )}
                </Form.Item>
              )}
            />
          </Table>
        </Form.Item>
        <Form.Item<ViewDocumentTypeType> name="active" label="Status">
          <Radio.Group
            options={[
              { label: "Desbloqueado", value: true },
              { label: "Bloqueado", value: false },
            ]}
          />
        </Form.Item>
        <Form.Item<ViewDocumentTypeType> name={["created_by", "name"]} label="Criado por">
          <Input />
        </Form.Item>
        <Form.Item<ViewDocumentTypeType> name="created_at" label="Criado em">
          <Input />
        </Form.Item>
        <Form.Item<ViewDocumentTypeType> name={["updated_by", "name"]} label="Atualizado por">
          <Input />
        </Form.Item>
        <Form.Item<ViewDocumentTypeType> name="updated_at" label="Atualizado em">
          <Input />
        </Form.Item>
      </Form>
    </Spin>
  );
}

export default CreateData;
