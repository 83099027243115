import { Icon } from "@iconify/react";
import { AbilityType } from "@provider/AuthProvider";
import { NavigateFunction } from "react-router-dom";

export type MenuItem = {
  label: string;
  key: string;
  disable?: boolean;
  icon: React.ReactElement;
  onClick?: () => void;
  roles: string[];
  type?: string[];
  children?: MenuItem[];
};

const MenuItems = (navigate: NavigateFunction): MenuItem[] => {
  return [
    {
      label: "Cliente",
      key: "menu-customer",
      icon: <Icon icon="fa6-solid:users-gear" />,
      type: ["SYS", "CPN", "CGR", "CTM"],
      roles: [
        "customer.details",
        "customer_fs.view",
        "customer_fs_trash.view",
        "customer_department.view",
        "customer_repository.view",
        "customer_permission_group.view",
        "customer_storage.view",
        "customer_document_type.view",
        "customer_fs_document.view",
        "customer_user.view",
        "customer_marker.view",
        "customer_physical_tag.view",
      ],
      children: [
        {
          label: "Configurações",
          key: "menu-customer-details",
          icon: <Icon icon="material-symbols:subdirectory-arrow-right-rounded" />,
          onClick: () => navigate("/app/customer"),
          roles: ["customer.details"],
        },
        {
          label: "Espaço",
          key: "menu-customer-spaces",
          icon: <Icon icon="material-symbols:space-dashboard" />,
          roles: ["customer_fs.view"],
          children: [
            {
              label: "Trabalho",
              key: "menu-customer-spaces-work",
              icon: <Icon icon="mdi:monitor-dashboard" />,
              onClick: () => navigate("/app/customer/spaces/work"),
              roles: ["customer_fs.view"],
            },
            {
              label: "Lixeira",
              key: "menu-customer-spaces-trash",
              icon: <Icon icon="clarity:trash-solid" />,
              onClick: () => navigate("/app/customer/spaces/trash"),
              roles: ["customer_fs_trash.view"],
            },
          ],
        },
        {
          label: "Departamentos",
          key: "menu-customer-departments",
          icon: <Icon icon="carbon:container-services" />,
          onClick: () => navigate("/app/customer/departments"),
          roles: ["customer_department.view"],
        },
        {
          label: "Repositórios",
          key: "menu-customer-repositories",
          icon: <Icon icon="mdi:box-cog" />,
          onClick: () => navigate("/app/customer/production/repositories"),
          roles: ["customer_repository.view"],
        },
        {
          label: "Grupos Permissão",
          key: "menu-customer-permissions-groups",
          icon: <Icon icon="ic:round-group-add" />,
          onClick: () => navigate("/app/customer/permission-groups"),
          roles: ["customer_permission_group.view"],
        },
        {
          label: "Galpões",
          key: "menu-customer-storages",
          icon: <Icon icon="vaadin:storage" />,
          onClick: () => navigate("/app/customer/storages"),
          roles: ["customer_storage.view"],
        },
        {
          label: "Tipos de Documento",
          key: "menu-customer-document-types",
          icon: <Icon icon="fluent:document-table-20-filled" />,
          onClick: () => navigate("/app/customer/document-types"),
          roles: ["customer_document_type.view"],
        },
        {
          label: "Cadastro de Documento Físico",
          key: "menu-customer-register-physical-document",
          icon: <Icon icon="bxs:box" />,
          onClick: () => navigate("/app/customer/register-physical-document"),
          roles: ["customer_fs_document.create"],
        },
        {
          label: "Cadastro de Documento Digital",
          key: "menu-customer-register-digital-document",
          icon: <Icon icon="streamline:web-solid" />,
          onClick: () => navigate("/app/customer/register-digital-document"),
          roles: ["customer_fs_document.create"],
        },
        {
          label: "Usuários Cliente",
          key: "menu-customer-users",
          icon: <Icon icon="fa6-solid:users-gear" />,
          onClick: () => navigate("/app/customer/users"),
          roles: ["customer_user.view"],
        },
        {
          label: "Marcadores",
          key: "menu-customer-markers",
          icon: <Icon icon="mdi:tag-multiple" />,
          onClick: () => navigate("/app/customer/markers"),
          roles: ["customer_marker.view"],
        },
        {
          label: "Lotes de Etiquetas",
          key: "menu-customer-physical-tag",
          icon: <Icon icon="dashicons:tickets-alt" />,
          onClick: () => navigate("/app/customer/physical-tags"),
          roles: ["customer_physical_tag.view"],
        },
      ],
    },
    {
      label: "Grupo de Clientes",
      type: ["SYS", "CPN", "CGR"],
      key: "menu-customer-group",
      icon: <Icon icon="material-symbols:groups-3" />,
      roles: ["customer_group.details", "customer_group_user.view", "customer.view"],
      children: [
        {
          label: "Configurações",
          key: "menu-customer-group-details",
          icon: <Icon icon="material-symbols:subdirectory-arrow-right-rounded" />,
          onClick: () => navigate("/app/customer-group"),
          roles: ["customer_group.details"],
        },
        {
          label: "Usuários",
          key: "menu-customer-group-users",
          icon: <Icon icon="fa6-solid:users-gear" />,
          onClick: () => navigate("/app/customer-group/users"),
          roles: ["customer_group_user.view"],
        },
        {
          label: "Clientes",
          key: "menu-customer_group-customers",
          icon: <Icon icon="clarity:group-solid" />,
          onClick: () => navigate("/app/customer-group/customers"),
          roles: ["customer.view"],
        },
      ],
    },
    {
      label: "Empresa",
      key: "menu-company",
      icon: <Icon icon="bi:building-fill-gear" />,
      type: ["SYS", "CPN"],
      roles: [
        "company.details",
        "customer.view",
        "customer_group.view",
        "company_storage.view",
        "company_user.view",
        "company_permission_group.view",
        "company_physical_tag.view",
        "billing.view",
      ],
      children: [
        {
          label: "Configurações",
          key: "menu-company-this",
          icon: <Icon icon="material-symbols:subdirectory-arrow-right-rounded" />,
          onClick: () => navigate("/app/company"),
          roles: ["company.details"],
        },
        {
          label: "Faturamento",
          key: "menu-company-billing",
          icon: <Icon icon="medical-icon:billing" />,
          roles: ["billing.view"],
          children: [
            {
              label: "Notas Fiscais",
              key: "menu-company-billing-invoices",
              icon: <Icon icon="fa6-solid:file-invoice-dollar" />,
              onClick: () => navigate("/app/billing/invoices"),
              roles: ["billing.view"],
            },
            {
              label: "Eventos",
              key: "menu-company-billing-event",
              icon: <Icon icon="clarity:event-solid-badged" />,
              onClick: () => navigate("/app/billing/events"),
              roles: ["billing.view"],
            },
          ],
        },
        {
          label: "Clientes",
          key: "menu-company-customers",
          icon: <Icon icon="clarity:group-solid" />,
          onClick: () => navigate("/app/company/customers"),
          roles: ["customer.view"],
        },
        {
          label: "Grupos de Clientes",
          key: "menu-company-customer-groups",
          icon: <Icon icon="material-symbols:groups-3" />,
          onClick: () => navigate("/app/company/customer-groups"),
          roles: ["customer_group.view"],
        },
        {
          label: "Galpões",
          key: "menu-company-storages",
          icon: <Icon icon="vaadin:storage" />,
          onClick: () => navigate("/app/company/storages"),
          roles: ["company_storage.view"],
        },
        {
          label: "Usuários Empresa",
          key: "menu-company-users",
          icon: <Icon icon="mdi:user-badge" />,
          onClick: () => navigate("/app/company/users"),
          roles: ["company_user.view"],
        },
        {
          label: "Grupos de Permissão",
          key: "menu-company-permissions-groups",
          icon: <Icon icon="fluent:contact-card-group-48-regular" />,
          onClick: () => navigate("/app/company/permissions-groups"),
          roles: ["company_permission_group.view"],
        },
        {
          label: "Lotes de Etiquetas",
          key: "menu-company-physical-tag",
          icon: <Icon icon="dashicons:tickets-alt" />,
          onClick: () => navigate("/app/company/physical-tags"),
          roles: ["company_physical_tag.view"],
        },
      ],
    },
    {
      label: "Sistema",
      key: "menu-system",
      icon: <Icon icon="material-symbols:display-settings-outline-rounded" />,
      type: ["SYS", "CPN"],
      roles: [
        "system_material.view",
        "system_operation.view",
        "system_repository_type.view",
        "system_service.view",
        "manage.all",
      ],
      children: [
        {
          label: "Materiais",
          key: "menu-system-materials",
          icon: <Icon icon="tabler:wallpaper" />,
          onClick: () => navigate("/app/system/materials"),
          roles: ["system_material.view"],
        },
        {
          label: "Operações",
          key: "menu-system-operations",
          icon: <Icon icon="carbon:settings-services" />,
          onClick: () => navigate("/app/system/operations"),
          roles: ["system_operation.view"],
        },
        {
          label: "Tipos de Repositório",
          key: "menu-system-repository-types",
          icon: <Icon icon="carbon:box" />,
          onClick: () => navigate("/app/system/repository-types"),
          roles: ["system_repository_type.view"],
        },
        {
          label: "Serviços",
          key: "menu-system-services",
          icon: <Icon icon="carbon:task-settings" />,
          onClick: () => navigate("/app/system/services"),
          roles: ["system_service.view"],
        },
        {
          label: "Extensões",
          key: "menu-system-extensions",
          icon: <Icon icon="codicon:extensions" />,
          onClick: () => navigate("/app/system/extensions"),
          roles: ["manage.all"],
        },
        {
          label: "Tipos de contato",
          key: "menu-system-contact-types",
          icon: <Icon icon="material-symbols:contact-phone-rounded" />,
          onClick: () => navigate("/app/system/contact-types"),
          roles: ["manage.all"],
        },
      ],
    },
    {
      label: "Ordens",
      type: ["SYS", "CPN", "CGR", "CTM"],
      key: "menu-orders",
      icon: <Icon icon="material-symbols:orders-rounded" />,
      roles: [
        "order_protocol.view",
        "order_material.view",
        "order_relocation.view",
        "order_deployment.view",
        "order_special.view",
      ],
      children: [
        {
          label: "Implantação",
          key: "menu-orders-deployment",
          icon: <Icon icon="material-symbols:deployed-code-update-rounded" />,
          onClick: () => navigate("/app/orders/deployment"),
          roles: ["order_deployment.view"],
        },
        {
          label: "Remanejamento",
          key: "menu-orders-relocation",
          icon: <Icon icon="icon-park-solid:transport" />,
          onClick: () => navigate("/app/orders/relocation"),
          roles: ["order_relocation.view"],
        },
        {
          label: "Material",
          key: "menu-orders-material",
          icon: <Icon icon="tabler:wallpaper" />,
          onClick: () => navigate("/app/orders/material"),
          roles: ["order_material.view"],
        },
        {
          label: "Protocolo",
          key: "menu-orders-protocol",
          icon: <Icon icon="material-symbols:order-approve-rounded" />,
          onClick: () => navigate("/app/orders/protocol"),
          roles: ["order_protocol.view"],
        },
        {
          label: "Especial",
          key: "menu-orders-special",
          icon: <Icon icon="ic:round-star-border" />,
          onClick: () => navigate("/app/orders/special"),
          roles: ["order_special.view"],
        },
      ],
    },
    {
      key: "menu-tools",
      icon: <Icon icon="fluent:window-dev-tools-24-filled" />,
      label: "Ferramentas",
      disable: false,
      onClick: () => navigate("/app/system/tools"),
      type: ["SYS", "CPN", "CGR", "CTM"],
      roles: ["admin_tool.view"],
    },
  ];
};
function filterMenuItems(menuItems: MenuItem[], ability: AbilityType): MenuItem[] {
  return menuItems.map((item) => {
    const hasPermission = item.roles.some((role) => {
      const [subject, action] = role.split(".");
      return ability.can(action, subject);
    });

    if (item.children) {
      item.children = filterMenuItems(item.children, ability);
    }

    item.disable = !hasPermission;
    return item;
  });
}

export const getMenuItems = (type: string, navigate: NavigateFunction, ability: AbilityType): MenuItem[] => {
  return filterMenuItems(MenuItems(navigate), ability).filter((item) => item.type?.includes(type));
};
