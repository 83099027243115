import { BadRequest, ClientHttp, StandardError, Success } from "@utils/clientHttp";
import showMessage from "@utils/showMessage";
import { diff } from "deep-diff";
import { create } from "zustand";

import { notification } from "antd";
import { DiscountRuleType, EditApiResultType, ListType, PriceSettingType, ReturnAfterCreationType, SavePriceSettingsType } from "./types";

type UseCustomerOperationStoreType = {
	apiResult: ListType;
	oldApiResult: ListType;
	loading: boolean;
	loadingSave: boolean;
	alteredOperations: string[];
	loadItemsList: () => Promise<Success<ListType> | BadRequest | StandardError>;

	savePriceSettings: () => Promise<Success<ReturnAfterCreationType> | BadRequest | StandardError | void>;

	editApiResult: (objs: EditApiResultType[]) => void;

	clearPriceSetup: (operation_cuid: string) => void;

	payloadGenerator: (alteredOperations: string[]) => SavePriceSettingsType | void;

	deletePriceSetup: (operationCuid: string, ruleDiscountCuid: string) => Promise<Success<void> | StandardError>;

	resetState: () => void;
};

const defaultState = {
	loading: false,
	loadingSave: false,
	apiResult: {
		data: [],
	},
	oldApiResult: {
		data: [],
	},
	alteredOperations: [],
};

const ruleFieldMap: { [key: string]: Array<keyof DiscountRuleType> } = {
	Q_IT: ["discount_percent", "number_of_items_limit"],
	Q_IN: ["discount_percent", "number_of_invoices_limit"],
	Q_IT_PER_IN: ["discount_percent", "number_of_invoices_limit", "number_of_items_limit"],
};

const validateRule = (rule_discount: DiscountRuleType, rule: string) => {
	const fields = ruleFieldMap[rule] || [];
	for (const field of fields) {
		if (rule_discount[field] === null || rule_discount[field] === undefined) {
			return false;
		}
	}
	return true;
};

export const useCustomerOperationStore = create<UseCustomerOperationStoreType>((set, get) => ({
	...defaultState,

	resetState: () => set(defaultState),

	loadItemsList: async (): Promise<Success<ListType> | BadRequest | StandardError> => {
		set({ loading: true });
		return await new ClientHttp().get<Success<ListType>, BadRequest | StandardError>(
			"/api/v1/customer/services/operations",
			(result: Success<ListType>) => {
				const { status, body } = result;
				if (status !== "success") {
					set({
						apiResult: body,
						loading: false,
					});
					get().loadItemsList();
				} else {
					set({
						apiResult: body,
						oldApiResult: body,
						loading: false,
					});
				}
			},
			(error: BadRequest | StandardError) => {
				showMessage(error);
			}
		);
	},

	editApiResult: (objs: EditApiResultType[]) => {
		set({
			apiResult: {
				data: get().apiResult.data.map((item) => {
					const objToUpdate = objs.find((obj) => obj.operation_cuid === item.operation_cuid);
					if (objToUpdate) {
						if (objToUpdate.field in item) {
							return { ...item, [objToUpdate.field]: objToUpdate.value };
						} else {
							return {
								...item,
								rule_discount: {
									...item.rule_discount,
									[objToUpdate.field]: objToUpdate.value,
								},
							};
						}
					}
					return item;
				}),
			},
		});

		const oldData = get().oldApiResult.data;
		const newData = get().apiResult.data;
		const listCuids = get().alteredOperations;
		newData.forEach((newItem, index) => {
			const differences = diff(oldData[index], newItem);
			if (differences && !listCuids.includes(newItem.operation_cuid)) {
				set({
					alteredOperations: [...listCuids, newItem.operation_cuid],
				});
			} else if (!differences && listCuids.includes(newItem.operation_cuid)) {
				set({
					alteredOperations: listCuids.filter((item) => item !== newItem.operation_cuid),
				});
			}
		});
	},

	clearPriceSetup: (operation_cuid) => {
		const updateItem = (item: any) =>
			item.operation_cuid === operation_cuid
				? {
						...item,
						rule_discount: {
							cuid: null,
							discount_percent: null,
							number_of_invoices_limit: null,
							number_of_items_limit: null,
							rule: null,
						},
				  }
				: item;

		set({
			apiResult: {
				data: get().apiResult.data.map(updateItem),
			},
			oldApiResult: {
				data: get().oldApiResult.data.map(updateItem),
			},
		});
	},

	payloadGenerator: (alteredOperations: string[]): SavePriceSettingsType | void => {
		const data = get().apiResult.data;
		let hasValidationError = false;

		const payloads = alteredOperations.map((operationCuid) => {
			const rowData = data.find((item) => item.operation_cuid === operationCuid);

			if (!rowData || !rowData.rule_discount) {
				hasValidationError = true;
				return null;
			}

			const { apply_default_price, price, rule_discount } = rowData;
			if (apply_default_price === false && (price === null || price === undefined)) {
				hasValidationError = true;
				return null;
			}

			if (!validateRule(rule_discount, rule_discount.rule)) {
				hasValidationError = true;
				return null;
			}

			return {
				operation_cuid: rowData.operation_cuid,
				apply_default_price: rowData.apply_default_price,
				price: rowData.price,
				discount_setup: {
					discount_cuid: rowData.rule_discount.cuid,
					discount_percentage: rowData.rule_discount.discount_percent,
					number_of_invoices_limit: rowData.rule_discount.number_of_invoices_limit,
					number_of_items_limit: rowData.rule_discount.number_of_items_limit,
					rule: rowData.rule_discount.rule,
				},
			};
		});

		if (hasValidationError) {
			set({
				loadingSave: false,
			});
			return notification.error({
				message: "Erro de validação!",
				description: "Por favor, preencha os campos destacados em vermelho.",
				placement: "topRight",
				duration: 5,
			});
		}

		const result = payloads.filter((payload): payload is PriceSettingType => payload !== null);

		return {
			input: result,
		};
	},

	savePriceSettings: async (): Promise<Success<ReturnAfterCreationType> | BadRequest | StandardError | void> => {
		set({ loadingSave: true });
		const alteredOperations = get().alteredOperations;
		const payload = get().payloadGenerator(alteredOperations);
		if (payload && payload.input.length > 0) {
			await new ClientHttp().post<SavePriceSettingsType, Success<ReturnAfterCreationType>, BadRequest | StandardError>(
				`/api/v1/customer/services/operations/price-setup`,
				payload,
				(result: Success<ReturnAfterCreationType>) => {
					let api = get().apiResult.data;
					const rest = result.body.output;
					rest.forEach((rest) => {
						const index = api.findIndex((api) => api.operation_cuid === rest.operation_cuid);
						if (index !== -1) {
							api[index].rule_discount.cuid = rest.rule_discount_cuid;
						}
						set({
							apiResult: {
								data: api,
							},
						});
					});
					showMessage(result, `${alteredOperations.length > 1 ? "Alterações salvas" : "Alteração salva"} com sucesso.`);
					set({
						oldApiResult: {
							data: api,
						},
						alteredOperations: [],
						loadingSave: false,
					});
				},
				(error: StandardError | BadRequest) => {
					showMessage(error);
					set({ loadingSave: false });
				}
			);
		}
	},
	deletePriceSetup: async (operationCuid: string, ruleDiscountCuid: string): Promise<Success<void> | StandardError> => {
		return await new ClientHttp().delete<Success<void>, StandardError>(
			`/api/v1/customer/services/operations/rule-disconts/${ruleDiscountCuid}`,
			(result: Success<void>) => {
				get().clearPriceSetup(operationCuid);
				showMessage(result, "Regra de desconto excluída com sucesso.");
			},
			(result: StandardError) => {
				showMessage(result);
			}
		);
	},
}));
