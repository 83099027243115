import { Form, Input } from "antd";

type ItemEMAProps = {
  name: number;
};

function ItemEMA({ name, ...props }: ItemEMAProps) {
  return (
    <div className="flex w-full gap-3 ">
      <Form.Item
        {...props}
        className="w-full p-0 m-0"
        name={[name, "value"]}
        rules={[{ type: "email", message: "Por favor, insira um endereço de e-mail válido!" }]}
      >
        <Input />
      </Form.Item>
    </div>
  );
}

export default ItemEMA;
