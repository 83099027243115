import { useContext, useEffect, useState } from "react";

import { Button, Form, Input, Select, Space, Spin, Tooltip } from "antd";

import { D3DrawerContext } from "@provider/D3DrawerContext";
import { useDepartmentsStore } from "../store";
import {
  SelectCustomerUserItemType,
  UpdateDepartmentType,
  ViewDepartmentType,
} from "../types";
import showMessage from "@utils/showMessage";
import { Icon } from "@iconify/react";
import D3Can from "@components/shared/D3Can";
import { StandardError } from "@utils/clientHttp";

type UpdateDepartmentProps = {
  cuid: string;
};

export const UpdateDepartmentForm = ({ cuid }: UpdateDepartmentProps) => {
  const { updateDepartment, getDepartment, getCustomerUserForSelect } =
    useDepartmentsStore();
  const { onClose } = useContext(D3DrawerContext);
  const [form] = Form.useForm<UpdateDepartmentType>();
  const [loading, setLoading] = useState(true);
  const [customerUsers, setcustomerUsers] = useState<
    SelectCustomerUserItemType[]
  >([]);

  useEffect(() => {
    (async () => {
      const [getDepartmentResult, getCustomerUserForSelectResult] =
        await Promise.all([getDepartment(cuid), getCustomerUserForSelect()]);
      if (getDepartmentResult.status === "success") {
        const viewDepartment = getDepartmentResult.body as ViewDepartmentType;
        form.setFieldsValue({
          description: viewDepartment.description,
          abbreviation: viewDepartment.abbreviation,
          customer_users_cuids: viewDepartment.users.map((group) => group.cuid),
        });
        setcustomerUsers(getCustomerUserForSelectResult);
        setLoading(false);
      } else {
        showMessage(getDepartmentResult);
      }
    })();
  }, [cuid, form, getCustomerUserForSelect, getDepartment]);

  return (
    <>
      <Spin spinning={loading}>
        <Form
          className="select-none"
          layout="vertical"
          form={form}
          onFinish={async (payload: UpdateDepartmentType) => {
            setLoading(true);
            const result = await updateDepartment(cuid, payload);
            if (result.code === 200) {
              showMessage(result, "Departamento atualizado com sucesso.");
              onClose();
            } else {
              showMessage(result as StandardError);
            }
            setLoading(false);
          }}
          autoComplete="off"
        >
          <Form.Item<UpdateDepartmentType>
            name="description"
            label="Descrição"
            rules={[
              { required: true, message: "Por favor, insira descrição." },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item<UpdateDepartmentType>
            name="abbreviation"
            label="Abreviação"
            rules={[
              { required: true, message: "Por favor, insira abreviação." },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item<UpdateDepartmentType>
            label="Usuários Cliente"
            name="customer_users_cuids"
          >
            <Select
              listItemHeight={10}
              listHeight={250}
              allowClear
              removeIcon={
                <Icon
                  icon="iconamoon:close-duotone"
                  className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
                />
              }
              menuItemSelectedIcon={
                <Icon
                  icon="eva:checkmark-outline"
                  className="w-5 h-5 text-blue-500"
                />
              }
              optionLabelProp="dataLabel"
              showSearch
              maxTagCount="responsive"
              mode="multiple"
              size="middle"
              className="w-full truncate select-none"
              optionFilterProp="children"
              loading={loading}
              filterOption={(input, option) =>
                option?.dataFilter.toLowerCase().includes(input.toLowerCase())
              }
            >
              {customerUsers.map(({ value, active, name, label }) => (
                <Select.Option
                  key={value}
                  value={value}
                  dataFilter={`${label} ${name}`}
                  dataLabel={`${label}`}
                >
                  <div className="flex items-center justify-between ">
                    <div className="flex flex-col truncate">
                      <span className="truncate">{label}</span>
                      <span className="text-[10px] truncate">{name}</span>
                    </div>
                    <span>
                      {active ? (
                        <Tooltip title="Cliente desbloqueado" placement="right">
                          <Icon
                            icon="basil:unlock-solid"
                            className="text-green-500"
                            height={16}
                            width={16}
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip title="Cliente bloqueado" placement="right">
                          <Icon
                            icon="basil:lock-solid"
                            className="text-red-500"
                            height={16}
                            width={16}
                          />
                        </Tooltip>
                      )}
                    </span>
                  </div>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <div className="text-right">
              <Space size="small">
                <Button type="default" danger onClick={() => onClose()}>
                  Cancelar
                </Button>
                <D3Can action="update" resource="customer_department">
                  <Button type="primary" htmlType="submit">
                    Atualizar
                  </Button>
                </D3Can>
              </Space>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};
