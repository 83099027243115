import { create } from "zustand";
import { BadRequest, ClientHttp, StandardError, Success } from "@utils/clientHttp";
import showMessage from "@utils/showMessage";
import { EditFileType, MarkerItemType, SimpleListMarkersType, ViewFileType } from "./types";
import useNavigateStore from "../navigate/store";

type useFileStoreType = {
  viewFile: (fs_file_cuid: string) => Promise<Success<ViewFileType> | StandardError>;
  editFile: (fileCuid: string, filePayload: EditFileType) => Promise<Success<void> | BadRequest | StandardError>;
  simpleListMarkers: () => Promise<MarkerItemType[]>;
};

const useFileStore = create<useFileStoreType>((set, get) => ({
  viewFile: async (fs_file_cuid: string): Promise<Success<ViewFileType> | StandardError> => {
    return await new ClientHttp().get<Success<ViewFileType>, StandardError>(
      `/api/v1/customer/fs/file/${fs_file_cuid}`,
      (result: Success<ViewFileType>) => {
        return result.body as ViewFileType;
      },
      (error: StandardError) => {
        showMessage(error);
        return {} as ViewFileType;
      }
    );
  },
  editFile: async (
    fileCuid: string,
    filePayload: EditFileType
  ): Promise<Success<void> | BadRequest | StandardError> => {
    return await new ClientHttp().patch<EditFileType, Success<void>, BadRequest | StandardError>(
      `/api/v1/customer/fs/file/${fileCuid}`,
      filePayload,
      async (result: Success<void>) => {
        const store = useNavigateStore.getState();
        const childrens = store.content.children;
        for (var i = 0; i < childrens.length; i++) {
          if (childrens[i].cuid === fileCuid && childrens[i].name !== filePayload.new_file_name) {
            childrens[i].name = filePayload.new_file_name;
            useNavigateStore.setState({
              content: {
                ...store.content,
                children: childrens,
              },
            });
            break;
          }
        }
        showMessage(result, "Arquivo atualizado com sucesso.");
      }
    );
  },

  simpleListMarkers: async (): Promise<MarkerItemType[]> => {
    return await new ClientHttp().getItensForSelect<Success<SimpleListMarkersType>, StandardError, MarkerItemType[]>(
      "/api/v1/customer/markers/simple-list",
      (result: Success<SimpleListMarkersType>): MarkerItemType[] => {
        return result.body.data as MarkerItemType[];
      },
      (error: StandardError): MarkerItemType[] => {
        showMessage(error);
        return [] as MarkerItemType[];
      }
    );
  },
}));

export default useFileStore;
