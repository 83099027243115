import { BadRequest, ClientHttp, StandardError, Success } from "@utils/clientHttp";
import { create } from "zustand";
import { ListPermissionType, PermissionType, profileType } from "./type";
import showMessage from "@utils/showMessage";

type useTablePermissionsType = {
  apiResult: ListPermissionType;
  loading: boolean;
  listPermissions: (profile: profileType) => Promise<void>;
};

const defaultState = {
  apiResult: {
    data: [],
  },
  loading: false,
};

export const useTablePermissions = create<useTablePermissionsType>((set, get) => ({
  ...defaultState,
  listPermissions: async (profile: profileType): Promise<void> => {
    set({
      loading: true,
    });
    await new ClientHttp().get<Success<ListPermissionType>, StandardError>(
      `/api/v1/system/permissions/${profile}`,
      (result: Success<ListPermissionType>) => {
        set({
          apiResult: {
            data: result.body.data as PermissionType[],
          },
          loading: false,
        });
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
        set({
          loading: false,
        });
      }
    );
  },
}));
