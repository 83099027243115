import { Form } from "antd";
import { useEffect, useState } from "react";
import { DocumentTypeIndex } from "../../types";
import { listView } from "../ListInputs";

type ViewIndexPropsType = {
  indexes: DocumentTypeIndex[];
};

function ViewIndex({ indexes }: ViewIndexPropsType) {
  const [itens, setItens] = useState<DocumentTypeIndex[]>([]);
  useEffect(() => {
    setItens(indexes);
  }, [indexes]);

  return (
    <div className="flex select-none">
      <Form layout="vertical" disabled className="w-full">
        <ul>
          {itens.map((item) => (
            <li key={item.id}>{listView(item)}</li>
          ))}
        </ul>
      </Form>
    </div>
  );
}

export default ViewIndex;
