import { Button, Form, Input, Radio, Space, Spin, Table, Tabs } from "antd";

import { useContext, useEffect, useState } from "react";

import cellStore from "../store";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { ViewCellType } from "../types";
import showMessage from "@utils/showMessage";

import { useParams } from "react-router-dom";
import { D3Date } from "@utils/date";
import { Icon } from "@iconify/react";
import D3Status from "@components/shared/D3Status";


type ViewCellFormProps = {
  cuid: string;
};

const columns = [
  {
    title: "Posição",
    dataIndex: "number",
    align: "center" as const,
  },
  {
    title: "Código",
    dataIndex: "id",
  },

  {
    title: "Etiqueta do repositório",
    dataIndex: "repository_physical_tag_id",
  },
  {
    title: "Cliente",
    dataIndex: "customer_name",
  },
  {
    title: "Ativo",
    dataIndex: "active",
    render: (value: boolean) => <D3Status status={value} />,
  },
];

export const ViewCellForm = ({ cuid }: ViewCellFormProps) => {
  let { shelfCuid } = useParams();
  const { getCell } = cellStore();
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm<ViewCellType>();

  useEffect(() => {
    (async () => {
      const result = await getCell(cuid, shelfCuid || "");
      if (result.status === "success") {
        const viewCell = result.body as ViewCellType;
        form.setFieldsValue({
          ...viewCell,
          created_at: D3Date.FormatValueApiToPtBR(viewCell.created_at),
          updated_at: D3Date.FormatValueApiToPtBR(viewCell.updated_at),
        });
        setLoading(false);
      } else {
        showMessage(result);
      }
    })();
  }, [cuid, form, getCell]);

  const { onClose } = useContext(D3DrawerContext);

  return (
    <>
      <Spin spinning={loading}>
        <Form
          className="select-none"
          layout="vertical"
          form={form}
          autoComplete="off"
          disabled
        >
          <Tabs
            defaultActiveKey="1"
            className="select-none"
            items={[
              {
                key: "1",
                label: (
                  <span className="flex items-center justify-center gap-2 ">
                    <Icon icon="fluent:form-24-filled" className="w-5 h-5" />
                    Dados
                  </span>
                ),
                children: (
                  <>
                    <Form.Item<ViewCellType> label="Vão / Prateleira" name="id">
                      <Input />
                    </Form.Item>
                    <div className="grid grid-cols-2 gap-4">
                      <Form.Item<ViewCellType> label="Vão" name="column">
                        <Input />
                      </Form.Item>
                      <Form.Item<ViewCellType> label="Prateleira" name="row">
                        <Input />
                      </Form.Item>
                    </div>
                    <Form.Item<ViewCellType>
                      label="Tipo de repositório"
                      name={["repository_type", "description"]}
                    >
                      <Input />
                    </Form.Item>
                    <div className="grid grid-cols-2 gap-4">
                      <Form.Item<ViewCellType>
                        label="Qtd. de posições"
                        name="number_of_positions"
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item<ViewCellType>
                        label="Qtd. de repositório"
                        name="number_of_repositories"
                      >
                        <Input />
                      </Form.Item>
                    </div>
                    <Form.Item<ViewCellType> name="active" label="Status">
                      <Radio.Group
                        options={[
                          { label: "Desbloqueado", value: true },
                          { label: "Bloqueado", value: false },
                        ]}
                      />
                    </Form.Item>
                    <Form.Item<ViewCellType>
                      name="created_at"
                      label="Criado em"
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item<ViewCellType>
                      name="updated_at"
                      label="Atualizado em"
                    >
                      <Input />
                    </Form.Item>
                  </>
                ),
              },
              {
                key: "2",
                label: (
                  <span className="flex items-center justify-center gap-2">
                    <Icon
                      icon="fluent:slide-grid-24-filled"
                      className="w-[20px] h-[20px]"
                    />
                    Posições
                  </span>
                ),
                children: (
                  <>
                    <Form.Item<ViewCellType>
                      name="positions"
                      label="Posições do Vão / Prateleira"
                    >
                      <Table
                        columns={columns}
                        pagination={false}
                        dataSource={form.getFieldValue("positions")}
                        bordered
                        size="small"
                      />
                    </Form.Item>
                  </>
                ),
              },
            ]}
          />
          <Form.Item>
            <div className="text-right">
              <Space size="small">
                <Button
                  disabled={false}
                  type="default"
                  danger
                  onClick={() => onClose()}
                >
                  Fechar
                </Button>
              </Space>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};
