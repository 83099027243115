import { Form, Radio } from "antd";

type ItemBOOProps = {
  name: number;
};

function ItemBOO({ name, ...props }: ItemBOOProps) {
  return (
    <Form.Item
      {...props}
      className="w-full p-0 m-0"
      name={[name, "value"]}
      getValueProps={(value: string) => {
        return { value: value !== undefined ? value : "" };
      }}
      normalize={(value: string) => {
        if (value === "true" || value === "false" || value === "") {
          return value;
        }
        return "";
      }}
    >
      <Radio.Group
        optionType="button"
        buttonStyle="solid"
        options={[
          { label: "Sim", value: "true" },
          { label: "Não", value: "false" },
          { label: "Todos", value: "" },
        ]}
        className="select-none"
      />
    </Form.Item>
  );
}

export default ItemBOO;
