import { Icon } from "@iconify/react";
import { StatusOrders } from "@views/orders/Utils";
import { Button, Col, Form, Input, Row, Segmented, Select, Space, Tag } from "antd";
import { useEffect } from "react";
import { SearchRelocationType } from "../types";
import { useRelocationStore } from "../store";

type SearchRelocationFormProps = {
  onCallBackFilter: () => void;
  onCallBackResetFilter: () => void;
};

export const SearchRelocationForm = ({ onCallBackFilter, onCallBackResetFilter }: SearchRelocationFormProps) => {
  const [form] = Form.useForm<SearchRelocationType>();
  const { filter, setFilter } = useRelocationStore();

  useEffect(() => {
    form.setFieldsValue(filter);
  }, [form, filter]);

  return (
    <>
      <Form
        className="select-none w-[700px]"
        layout="vertical"
        form={form}
        onFinish={async (values: SearchRelocationType) => {
          setFilter(values);
          onCallBackFilter();
        }}
        autoComplete="off"
      >
        <Row gutter={20}>
          <Col span={12}>
            <Form.Item<SearchRelocationType> name="ro_id" label="Número">
              <Input className="w-full" />
            </Form.Item>

            <Form.Item<SearchRelocationType> label="Etiqueta do repositório" name="r_repository_physical_tag_id">
              <Input />
            </Form.Item>
            <Form.Item<SearchRelocationType> name="ro_action_type" label="Tipo de ação">
              <Select
                allowClear
                options={[
                  { value: "M", label: "Migração" },
                  { value: "D", label: "Desalocação" },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item<SearchRelocationType> label="Status" name="ro_status" className="max-h-[58px]">
              <Select
                listItemHeight={10}
                listHeight={250}
                allowClear
                removeIcon={
                  <Icon
                    icon="iconamoon:close-duotone"
                    className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
                  />
                }
                menuItemSelectedIcon={<Icon icon="eva:checkmark-outline" className="w-5 h-5 text-blue-500" />}
                optionLabelProp="dataLabel"
                showSearch
                maxTagCount="responsive"
                size="middle"
                className="w-full truncate select-none"
                optionFilterProp="children"
                filterOption={(input, option) => option?.dataFilter.toLowerCase().includes(input.toLowerCase())}
              >
                {StatusOrders.map(({ color, icon, label, status }) => (
                  <Select.Option key={status} value={status} dataFilter={`${status} - ${label}`} dataLabel={`${label}`}>
                    <div className="flex items-center justify-between ">
                      <div className="flex flex-col truncate">
                        <span className="truncate">{label}</span>
                      </div>
                      <span>
                        <Tag
                          className="flex items-center gap-2 py-1 max-w-max"
                          color={color}
                          icon={<Icon icon={icon} />}
                        ></Tag>
                      </span>
                    </div>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item<SearchRelocationType> label="Criado por" name="uc_name" className="max-h-[58px]">
              <Input />
            </Form.Item>
            <Form.Item<SearchRelocationType> name="ro_has_order_resent" label="Possui ordem digitalizada?">
              <Segmented
                options={[
                  { label: "Sim", value: "on" },
                  { label: "Não", value: "off" },
                  { label: "Todos", value: "all" },
                ]}
              />
            </Form.Item>
          </Col>

          <div className="flex justify-end w-full px-3">
            <Space size="small">
              <Button type="default" danger onClick={onCallBackResetFilter}>
                Limpar Filtro
              </Button>
              <Button type="primary" htmlType="submit">
                Filtrar
              </Button>
            </Space>
          </div>
        </Row>
      </Form>
    </>
  );
};
