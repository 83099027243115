import { create } from "zustand";

type D3LayoutStoreProps = {
  collapsed: boolean;
  setIsOpen: (isOpen: boolean) => void;
  getCompany: () => string;
};

export const useD3LayoutStore = create<D3LayoutStoreProps>((set, get) => ({
  // Início State para abrir e fechar o menu
  collapsed: true,
  setIsOpen: (collapsed: boolean) => set({ collapsed }),
  // Fim - State para abrir e fechar o menu

  getCompany: () => {
    return "clmouyeiv0001l2i0w3ep5iah";
  }
}));
