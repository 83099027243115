import D3Can from "@components/shared/D3Can";
import D3CustomerRequired from "@components/shared/D3CustomerRequired";
import D3Status from "@components/shared/D3Status";
import { Icon } from "@iconify/react";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { ToggleLockAction } from "@utils/ToggleLockAction";
import { Badge, Button, Pagination, Popconfirm, Popover, Space, Tooltip } from "antd";
import Table from "antd/es/table";
import { useContext, useEffect, useState } from "react";
import { CreateCustomerUserForm } from "./components/CreateCustomerUserForm";
import { SearchCustomerUserForm } from "./components/SearchCustomerUserForm";
import { UpdateCustomerUserForm } from "./components/UpdateCustomerUserForm";
import { ViewCustomerUserForm } from "./components/view/ViewCustomerUserForm";
import { useCustomerUsersStore } from "./store";
import { CustomerUserListItemType } from "./types";

const CustomerUsersView: React.FC = () => {
  const { openDrawer } = useContext(D3DrawerContext);
  const {
    apiResult,
    pagination,
    loading,
    selectedRows,
    setPagination,
    setSort,
    loadCustomerUsers,
    lockCustomerUser,
    unLockCustomerUser,
    onMultiSelectedRowChange,
    resetFilter,
    resetState,
    generatePassword,
  } = useCustomerUsersStore();
  const [openSearch, setOpenSearch] = useState(false);

  useEffect(() => {
    (async () => {
      await loadCustomerUsers();
    })();
    return () => {
      resetState();
    };
  }, [loadCustomerUsers]);

  return (
    <D3CustomerRequired>
      <div className="flex flex-col h-full mb-1 mr-1 overflow-auto bg-white rounded shadow-md">
        <Table<CustomerUserListItemType>
          size="small"
          rowKey="cuid"
          dataSource={apiResult.data}
          bordered
          className="responsive-table"
          pagination={false}
          loading={loading}
          title={() => {
            return (
              <>
                <div className="flex items-center justify-between w-full">
                  <div className="flex items-center justify-start">
                    <Popover
                      open={openSearch}
                      placement="rightTop"
                      title="Pesquisar Usuários Cliente"
                      content={
                        <SearchCustomerUserForm
                          onCallBackFilter={() => setOpenSearch(false)}
                          onCallBackResetFilter={() => {
                            setOpenSearch(false);
                            resetFilter();
                          }}
                        />
                      }
                      trigger="click"
                      onOpenChange={(open) => setOpenSearch(open)}
                    >
                      <Button
                        onClick={() => setOpenSearch(!openSearch)}
                        type="primary"
                        size="middle"
                        icon={<Icon icon="fluent:search-12-filled" height={16} width={16} />}
                      >
                        Pesquisar
                      </Button>
                    </Popover>
                  </div>
                  <div className="flex items-center justify-center">
                    <span className="text-xl font-semibold text-gray-600">Usuários Cliente</span>
                  </div>
                  <div className="flex items-center justify-end">
                    <D3Can action="create" resource="customer_user">
                      <Button
                        type="primary"
                        size="middle"
                        icon={<Icon icon="ic:round-plus" height={20} width={20} />}
                        onClick={() =>
                          openDrawer(<CreateCustomerUserForm />, "right", 600, "Cadastrar Usuário Cliente")
                        }
                      >
                        Novo Usuário Cliente
                      </Button>
                    </D3Can>
                  </div>
                </div>
              </>
            );
          }}
          rowSelection={{
            hideSelectAll: false,
            type: "checkbox",
            selectedRowKeys: selectedRows.cuids,
            onChange: (_, selectRows: CustomerUserListItemType[]) => {
              onMultiSelectedRowChange(selectRows);
            },
          }}
          onChange={(a, b, sorter: any) => {
            if (sorter) {
              setSort({
                sort_by: sorter.field,
                sort_direction: sorter.order,
              });
            }
          }}
          footer={() => {
            return (
              <>
                <div className="flex gap-4">
                  <D3Can action="lock" resource="customer_user">
                    <Badge
                      count={
                        selectedRows.cuids.length > 0 && selectedRows.active === true
                          ? selectedRows.cuids.length
                          : undefined
                      }
                    >
                      <Popconfirm
                        disabled={!(selectedRows.cuids.length > 0 && selectedRows.active === true)}
                        title={`Atenção!`}
                        description={`Confirma bloqueio ${selectedRows.cuids.length} ${
                          selectedRows.cuids.length > 1 ? "itens" : "item"
                        }?`}
                        onConfirm={async () => {
                          await lockCustomerUser(selectedRows.cuids);
                        }}
                        okText="Sim"
                        cancelText="Não"
                        placement="top"
                      >
                        <Button
                          type="primary"
                          danger
                          disabled={!(selectedRows.cuids.length > 0 && selectedRows.active === true)}
                          size="middle"
                          icon={<Icon icon="basil:lock-solid" height={16} width={16} />}
                        >
                          Bloquear
                        </Button>
                      </Popconfirm>
                    </Badge>
                  </D3Can>
                  <D3Can action="unlock" resource="customer_user">
                    <Badge
                      color="green"
                      count={
                        selectedRows.cuids.length > 0 && selectedRows.active === false
                          ? selectedRows.cuids.length
                          : undefined
                      }
                    >
                      <Popconfirm
                        disabled={!(selectedRows.cuids.length > 0 && selectedRows.active === false)}
                        title={`Atenção!`}
                        description={`Confirma desbloqueio ${selectedRows.cuids.length} ${
                          selectedRows.cuids.length > 1 ? "itens" : "item"
                        }?`}
                        onConfirm={async () => {
                          await unLockCustomerUser(selectedRows.cuids);
                        }}
                        okText="Sim"
                        cancelText="Não"
                        placement="top"
                      >
                        <Button
                          className=""
                          disabled={!(selectedRows.cuids.length > 0 && selectedRows.active === false)}
                          size="middle"
                          icon={<Icon icon="basil:unlock-solid" height={16} width={16} />}
                        >
                          Desbloquear
                        </Button>
                      </Popconfirm>
                    </Badge>
                  </D3Can>
                </div>

                <Space>
                  <Pagination
                    showLessItems
                    showTotal={(total, range) =>
                      `Exibindo de ${range[0]} a ${range[1]} do total ${total} ${total > 1 ? "registros" : "registro"}`
                    }
                    showSizeChanger={true}
                    current={pagination.page}
                    onChange={setPagination}
                    pageSize={pagination.pageSize}
                    total={apiResult.total}
                  />
                </Space>
              </>
            );
          }}
        >
          <Table.Column<CustomerUserListItemType>
            defaultSortOrder="ascend"
            showSorterTooltip={false}
            key="name"
            title="Nome"
            dataIndex="name"
            sorter
          />
          <Table.Column<CustomerUserListItemType>
            showSorterTooltip={false}
            key="username"
            title="Usuário"
            dataIndex="username"
            sorter
          />
          <Table.Column<CustomerUserListItemType>
            showSorterTooltip={false}
            key="login"
            title="Login"
            dataIndex="login"
          />
          <Table.Column<CustomerUserListItemType>
            showSorterTooltip={false}
            key="email"
            title="E-mail"
            dataIndex="email"
            sorter
          />
          <Table.Column<CustomerUserListItemType>
            showSorterTooltip={false}
            key="change_password_next_login"
            title="Trocar senha no próximo login?"
            dataIndex="change_password_next_login"
            render={(value, customerUser, index) =>
              customerUser.change_password_next_login ? (
                <Badge status="error" text="Sim" />
              ) : (
                <Badge status="success" text="Não" />
              )
            }
          />
          <Table.Column<CustomerUserListItemType>
            showSorterTooltip={false}
            key="active"
            title="Status"
            dataIndex="active"
            sorter
            render={(value, customerUser) => <D3Status status={customerUser.active} />}
          />
          <Table.Column<CustomerUserListItemType>
            key="action"
            title="Ações"
            width={170}
            render={(value, customerUser, index) => {
              return (
                <>
                  <Space>
                    <D3Can action="update" resource="customer_user">
                      <Tooltip title="Editar" destroyTooltipOnHide mouseLeaveDelay={0} zIndex={11}>
                        <Button
                          type="text"
                          shape="default"
                          icon={
                            <Icon
                              icon="iconamoon:edit-fill"
                              height={18}
                              width={18}
                              className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
                            />
                          }
                          size={"middle"}
                          onClick={() =>
                            openDrawer(
                              <UpdateCustomerUserForm cuid={customerUser.cuid} />,
                              "right",
                              800,
                              "Editar Usuário Cliente"
                            )
                          }
                        />
                      </Tooltip>
                    </D3Can>
                    <D3Can action="view" resource="customer_user">
                      <Tooltip title="Visualizar" destroyTooltipOnHide mouseLeaveDelay={0} zIndex={11}>
                        <Button
                          className="peer"
                          type="text"
                          shape="default"
                          icon={
                            <Icon
                              icon="carbon:document-view"
                              height={18}
                              width={18}
                              className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
                            />
                          }
                          size={"middle"}
                          onClick={() =>
                            openDrawer(
                              <ViewCustomerUserForm cuid={customerUser.cuid} />,
                              "right",
                              800,
                              "Visualizar Usuário Cliente"
                            )
                          }
                        />
                      </Tooltip>
                    </D3Can>
                    <D3Can action="lock" resource="customer_user">
                      <ToggleLockAction
                        description="Confirma bloqueio?"
                        title="Atenção!"
                        isVisible={customerUser.active}
                        tooltip="Bloquear"
                        paramsToAction={[customerUser.cuid]}
                        action={async (params: string[]) => {
                          await lockCustomerUser(params);
                        }}
                      />
                    </D3Can>
                    <D3Can action="unlock" resource="customer_user">
                      <ToggleLockAction
                        title="Atenção!"
                        description="Confirma desbloqueio?"
                        isVisible={!customerUser.active}
                        tooltip="Desbloquear"
                        paramsToAction={[customerUser.cuid]}
                        action={async (params: string[]) => {
                          await unLockCustomerUser(params);
                        }}
                      />
                    </D3Can>
                    <D3Can action="generate_password" resource="customer_user">
                      <Tooltip title="Gerar senha" destroyTooltipOnHide mouseLeaveDelay={0} zIndex={11}>
                        <Popconfirm
                          title="Atenção !"
                          description={
                            <div className="text-justify w-80">
                              Você está prestes a gerar uma nova senha que será enviada automaticamente para o e-mail do
                              usuário. Deseja prosseguir com esta ação?
                            </div>
                          }
                          okText="Sim"
                          cancelText="Não"
                          onConfirm={() => generatePassword(customerUser.cuid)}
                        >
                          <Button
                            className="peer"
                            type="text"
                            shape="default"
                            icon={
                              <Icon
                                icon="ic:outline-lock-reset"
                                height={20}
                                width={20}
                                className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
                              />
                            }
                            size={"middle"}
                          />
                        </Popconfirm>
                      </Tooltip>
                    </D3Can>
                  </Space>
                </>
              );
            }}
          />
        </Table>
      </div>
    </D3CustomerRequired>
  );
};

export default CustomerUsersView;
