import { ReactElement } from "react";
import { useAbility } from "@casl/react";
import { Button, Result } from "antd";

import { useD3SelectEntityStore } from "../Layout/D3HeaderBar/components/store";
import useAuthStore from "@provider/AuthProvider";
import { AbilityContext } from "@context/CASLContext";


type D3ProtectPageProps = {
  targetPage: ReactElement;
  isRequiredCustomerGroup: boolean;
  isRequiredCustomer: boolean;
  resource: string;
  action: string;
};

const D3ProtectPage = ({
  targetPage,
  isRequiredCustomerGroup,
  isRequiredCustomer,
  resource,
  action,
}: D3ProtectPageProps) => {
  const ability = useAbility(AbilityContext);

  const userInfo = useAuthStore.getState().getUserInfo();

  const { setIsOpen } = useD3SelectEntityStore();

  if (
    isRequiredCustomerGroup &&
    (userInfo?.CustomerGroupCuid === undefined ||
      userInfo?.CustomerGroupCuid === "")
  ) {
    return (
      <div className="flex flex-col items-center justify-center h-full mb-1 mr-1 bg-white rounded shadow-md">
        <Result
          status="404"
          title="Selecione um cliente que pertença a um grupo de clientes"
          subTitle="O acesso a tela solicitada só é permitida com grupo de clientes selecionado."
          extra={
            <Button type="primary" onClick={() => setIsOpen(true)}>
              Selecionar grupo de clientes
            </Button>
          }
        />
      </div>
    );
  }

  if (
    isRequiredCustomer &&
    (userInfo?.CustomerCuid === undefined || userInfo?.CustomerCuid === "")
  ) {
    return (
      <div className="flex flex-col items-center justify-center h-full mb-1 mr-1 bg-white rounded shadow-md">
        <Result
          status="404"
          title="Selecione um cliente"
          subTitle="O acesso a tela solicitada só é permitida com cliente selecionado."
          extra={
            <Button type="primary" onClick={() => setIsOpen(true)}>
              Selecionar cliente
            </Button>
          }
        />
      </div>
    );
  }

  if (ability.can(action, resource)) {
    return targetPage;
  } else {
    return (
      <div className="flex flex-col items-center justify-center h-full mb-1 mr-1 bg-white rounded shadow-md">
        <Result
          className="h-full"
          status="403"
          title="Acesso negado"
          subTitle="Você não tem permissão para acessar esta tela."
        />
      </div>
    );
  }
};

export default D3ProtectPage;
