import { D3DrawerContext } from "@provider/D3DrawerContext";
import { Button, Form, Tabs } from "antd";
import { useContext, useEffect } from "react";
import { useSpecialsStore } from "../store";
import { SearchPhysicalDocumentType } from "../types";
import SearchCriterionForm from "./SearchCriterionForm";
import SearchDocumentForm from "./SearchDocumentForm";
import SearchOrdersForm from "./SearchOrdersForm ";
import SearchRepositoryForm from "./SearchRepositoryForm";
import SearchSortByForm from "./SearchSortByForm ";

type SearchOperationFormProps = {
  onCallBackResetFilter: () => void;
};

export const SearchSpecialForm = ({ onCallBackResetFilter }: SearchOperationFormProps) => {
  const [form] = Form.useForm<SearchPhysicalDocumentType>();
  const { filter, setFilter, setPagination } = useSpecialsStore();
  const { onClose } = useContext(D3DrawerContext);

  useEffect(() => {
    (() => {
      form.setFieldsValue(filter);
    })();
  }, [form, filter]);

  return (
    <div className="flex flex-col w-full h-full overflow-hidden ">
      <Form
        className="flex h-full overflow-hidden grow "
        layout="vertical"
        form={form}
        onFinish={async (values) => {
          setFilter(values);
          onClose();
        }}
        autoComplete="off"
      >
        <Tabs
          className="w-full select-none"
          items={[
            {
              key: "1",
              label: "Critério",
              children: <SearchCriterionForm form={form} />,
            },
            {
              key: "2",
              label: "Documento",
              children: <SearchDocumentForm form={form} />,
            },
            {
              key: "3",
              label: "Repositório",
              children: <SearchRepositoryForm form={form} />,
            },
            {
              key: "4",
              label: "Ordens",
              children: <SearchOrdersForm form={form} />,
            },
            {
              key: "5",
              label: "Ordenação",
              children: <SearchSortByForm form={form} />,
            },
          ]}
        />
      </Form>
      <div className="flex gap-3 border-t-[1px] border-gray-100  items-center justify-end px-2 min-h-12 ">
        <Button
          type="default"
          danger
          onClick={() => {
            onCallBackResetFilter();
            onClose();
          }}
        >
          Limpar Filtro
        </Button>
        <Button type="primary" onClick={() => form.submit()}>
          Filtrar
        </Button>
      </div>
    </div>
  );
};
