import { useContext } from "react";
import { Button, Form, Input, Space } from "antd";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import D3Can from "@components/shared/D3Can";
import { useNotesStore } from "../store";
import { CreateNoteType } from "../types";

const { TextArea } = Input;

export const CreateNoteForm = () => {
  const { createNote, loadingCreated } = useNotesStore();
  const { onClose } = useContext(D3DrawerContext);
  const [form] = Form.useForm<CreateNoteType>();

  return (
    <Form
      className="select-none"
      form={form}
      layout="vertical"
      onFinish={async (payload: CreateNoteType) => {
        const isCreated = await createNote(payload);
        if (isCreated) {
          onClose();
        }
      }}
      autoComplete="off"
    >
      <Form.Item<CreateNoteType>
        name="title"
        label="Descrição"
        rules={[{ required: true, message: "Por favor, insira a descrição." }]}
      >
        <Input />
      </Form.Item>

      <Form.Item<CreateNoteType>
        name="content"
        label="Nota de observação"
        rules={[{ required: true, message: "Por favor, insira a nota de observação." }]}
      >
        <TextArea rows={6} />
      </Form.Item>

      <Form.Item>
        <div className="text-right">
          <Space size="small">
            <Button disabled={loadingCreated} type="default" danger onClick={() => onClose()}>
              Cancelar
            </Button>
            <D3Can action="customer_note" resource="create">
              <Button loading={loadingCreated} type="primary" htmlType="submit">
                Cadastrar
              </Button>
            </D3Can>
          </Space>
        </div>
      </Form.Item>
    </Form>
  );
};
