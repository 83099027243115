import { Icon } from "@iconify/react";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { Button, Progress, Tooltip } from "antd";
import { useContext } from "react";
import { useUploadManagerStore } from "./store";
import { ListItemType } from "./types";
import EditFile from "../../work/components/file/edit-file/EditFile";
import ViewFile from "../../work/components/file/view-file/ViewFile";

type ListItemProps = {
  item: ListItemType;
  progress: any;
  sourceAxios: any;
  icon: string;
};

function ListItem({ item: { status_upload, name, fs_cuid }, progress, sourceAxios, icon }: ListItemProps) {
  const { openDrawer } = useContext(D3DrawerContext);

  return (
    <div className="relative mr-2">
      {status_upload === "C" && <div className="absolute z-50 w-full border border-red-400 rounded-lg" />}
      <div className="flex justify-between w-full gap-2 ">
        <div className="flex items-center gap-2 grow">
          <div className="flex">
            <Icon height={24} className="drop-shadow" icon={icon} />
          </div>
          <div className="flex items-center justify-between grow">
            <div className="truncate max-w-64">{name}</div>
            <div className="w-24">
              {status_upload !== "C" && (
                <Progress
                  percent={progress}
                  size="small"
                  status={`${
                    status_upload === "W"
                      ? "normal"
                      : status_upload === "P"
                      ? "active"
                      : status_upload === "C"
                      ? "exception"
                      : "success"
                  }`}
                />
              )}
            </div>
          </div>
        </div>
        {status_upload !== "C" && (
          <div className="flex items-center justify-start gap-1 text-gray-500 ">
            <Tooltip title="Editar arquivo" trigger="hover" mouseLeaveDelay={0}>
              <Button
                className="flex items-center justify-center "
                type="link"
                size="small"
                icon={
                  <Icon
                    className="text-blue-400 transition-all duration-200 ease-in hover:scale-110"
                    height={16}
                    icon="iconamoon:edit-fill"
                  />
                }
                onClick={() => {
                  useUploadManagerStore.setState({ open: false });
                  openDrawer(<EditFile fileCuid={fs_cuid ? fs_cuid : ""} />, "right", 600, "Editar arquivo");
                }}
              />
            </Tooltip>

            <Tooltip
              title={progress === 100 ? "Visualizar arquivo" : "Aguarde finalizar o upload."}
              trigger="hover"
              mouseLeaveDelay={0}
            >
              <Button
                disabled={status_upload !== "S"}
                className="flex items-center justify-center"
                type="link"
                size="small"
                icon={
                  <Icon
                    className="text-blue-400 transition-all duration-200 ease-in hover:scale-110"
                    height={20}
                    icon="lets-icons:view"
                  />
                }
                onClick={() => {
                  useUploadManagerStore.setState({ open: false });
                  openDrawer(<ViewFile fsFileCuid={fs_cuid ? fs_cuid : ""} />, "right", 800, "Visualizar arquivo");
                }}
              />
            </Tooltip>

            <Tooltip
              title={
                progress > 85 && progress < 99
                  ? "Impossível cancelar, processo maior que 85%."
                  : progress === 100
                  ? "Impossível cancelar, upload concluído."
                  : "Cancelar upload"
              }
              trigger="hover"
              mouseLeaveDelay={0}
            >
              <Button
                disabled={progress > 85}
                className="flex items-center justify-center "
                type="link"
                danger
                size="small"
                icon={
                  <Icon
                    className="text-red-400 transition-all duration-200 ease-in hover:scale-110"
                    height={16}
                    icon="mingcute:close-fill"
                  />
                }
                onClick={() => sourceAxios?.cancel("Cancelado pelo usuário.")}
              />
            </Tooltip>
          </div>
        )}
      </div>
    </div>
  );
}

export default ListItem;
