import { Button, Tabs, Space, Spin } from "antd";
import { useCustomerUsersStore } from "../../store";
import { useContext, useEffect, useState } from "react";
import { D3DrawerContext } from "@provider/D3DrawerContext";

import { ViewCustomerUserType } from "../../types";
import showMessage from "@utils/showMessage";
import { Icon } from "@iconify/react";
import TabForm from "./TabForm";
import TabPermissions from "./TabPermissions";
import { UpdateCustomerUserForm } from "../UpdateCustomerUserForm";
import TabDepartments from "./TabDepartments";
import TabPermissionsGroups from "./TabPermissionsGroups";
import D3Can from "@components/shared/D3Can";

type ViewCustomerUserFormProps = {
  cuid: string;
};

export const ViewCustomerUserForm = ({ cuid }: ViewCustomerUserFormProps) => {
  const { getCustomerUser } = useCustomerUsersStore();
  const { openDrawer } = useContext(D3DrawerContext);
  const [loading, setLoading] = useState(true);
  const [tab, setTab] = useState<string>("1");
  const [customerUser, setCustomerUser] = useState<ViewCustomerUserType | undefined>();

  useEffect(() => {
    (async () => {
      const result = await getCustomerUser(cuid);
      if (result.status === "success") {
        const viewCustomerUser = result.body as ViewCustomerUserType;
        setCustomerUser(viewCustomerUser);
        setLoading(false);
      } else {
        showMessage(result);
      }
    })();
  }, [cuid, getCustomerUser]);

  const { onClose } = useContext(D3DrawerContext);

  return (
    <>
      <Spin spinning={loading}>
        <Tabs
          defaultActiveKey="1"
          className="select-none"
          onTabClick={(tab) => setTab(tab)}
          items={[
            {
              key: "1",
              label: (
                <span className="flex items-center justify-center gap-2 ">
                  <Icon icon="fluent:form-24-filled" className="w-5 h-5" />
                  Dados
                </span>
              ),
              children: <TabForm data={customerUser} />,
            },
            {
              key: "2",
              label: (
                <span className="flex items-center justify-center gap-2 ">
                  <Icon icon="fa:users" className="w-4 h-4" />
                  Grupos Permissões
                </span>
              ),
              children: <TabPermissionsGroups data={customerUser} />,
            },
            {
              key: "3",
              label: (
                <span className="flex items-center justify-center gap-2 ">
                  <Icon icon="carbon:container-services" className="w-[18px] h-[18px]" />
                  Departamentos
                </span>
              ),
              children: <TabDepartments data={customerUser} />,
            },
            {
              key: "4",
              label: (
                <span className="flex items-center justify-center gap-2 ">
                  <Icon icon="icon-park-outline:permissions" className="w-[18px] h-[18px]" />
                  Permissões
                </span>
              ),
              children: tab === "4" && <TabPermissions data={customerUser} />,
            },
          ]}
        />
        <div className="m-2 text-right">
          <Space size="small">
            <Button type="default" danger onClick={() => onClose()}>
              Cancelar
            </Button>
            <D3Can action="update" resource="customer_user">
              <Button
                type="primary"
                onClick={() =>
                  openDrawer(<UpdateCustomerUserForm cuid={cuid} />, "right", 800, "Editar Usuário Cliente")
                }
              >
                Editar
              </Button>
            </D3Can>
          </Space>
        </div>
      </Spin>
    </>
  );
};
