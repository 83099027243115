import { Alert, Button, Form, Input } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useChangePassword } from "./store";

function ChangePasswordView() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [form] = Form.useForm();
  const { user, changePasswordAndGenerateNewToken } = useChangePassword();

  useEffect(() => {
    if (user) {
      form.setFieldsValue({
        login: user.login,
        password: user.password,
      });
    } else {
      navigate("/auth/login");
    }
    setIsLoading(false);
  }, [user, form]);

  return (
    <>
      <div className="flex flex-col items-center justify-center h-full overflow-y-auto bg-white scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100 au:w-full">
        <span className="mt-4 text-2xl font-extrabold text-center text-gray-700">
          Bem-vindo(a) ao GED da CelulaTech!
        </span>
        <div className="flex w-4/5 py-3 font-medium text-gray-600">
          <Alert
            className="p-3"
            showIcon
            description="Detectamos o uso de uma senha padrão em sua conta. Para garantir a sua segurança, é essencial estabelecer uma nova senha pessoal e única."
            type="info"
          />
        </div>
        <div className="w-[350px]">
          <Form
            form={form}
            layout="vertical"
            onFinish={async (values: any) => {
              setIsLoading(true);
              const isSuccess = await changePasswordAndGenerateNewToken({
                login: values.login,
                password: values.password,
                new_password: values.new_password,
                repeated_new_password: values.repeated_new_password,
              });
              setIsLoading(false);
              if (isSuccess === true) {
                navigate("/app");
              }
            }}
            autoComplete="off"
          >
            <Form.Item label="Seu login" name="login">
              <Input size="large" disabled variant="filled" autoComplete="off" />
            </Form.Item>

            <Form.Item label="Sua senha padrão" name="password">
              <Input.Password size="large" disabled variant="filled" autoComplete="off" />
            </Form.Item>

            <Form.Item
              label="Informe sua nova senha"
              name="new_password"
              rules={[
                {
                  required: true,
                  message: "Por favor, informe sua nova senha.",
                },
                {
                  min: 6,
                  message: "A nova senha deve ter no mínimo 6 caracteres.",
                },
              ]}
              hasFeedback
            >
              <Input.Password size="large" variant="filled" autoComplete="new-password" />
            </Form.Item>

            <Form.Item
              label="Repita sua nova senha"
              name="repeated_new_password"
              dependencies={["new_password"]}
              rules={[
                {
                  required: true,
                  message: "Por favor, repita sua nova senha.",
                },
                {
                  min: 6,
                  message: "A nova senha deve ter no mínimo 6 caracteres.",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const oldPassword = getFieldValue("password");
                    const newPassword = getFieldValue("new_password");

                    if (oldPassword === newPassword) {
                      return Promise.reject(new Error("A nova senha não pode ser igual a senha atual."));
                    } else if (!value || newPassword === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(new Error("As senhas não são iguais."));
                  },
                }),
              ]}
              hasFeedback
            >
              <Input.Password size="large" variant="filled" autoComplete="repeat-new-password" />
            </Form.Item>

            <Form.Item>
              <Button
                loading={isLoading}
                htmlType="submit"
                type="primary"
                className="mt-3 text-base font-semibold text-gray-50"
                block
                size="large"
              >
                Alterar senha
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
}

export default ChangePasswordView;
