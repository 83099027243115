import { Dispatch, ReactNode, SetStateAction, createContext, useState } from "react";

interface IDrawerContext {
  isOpen: boolean;
  content: ReactNode;
  placement: "top" | "right" | "bottom" | "left";
  size: number;
  title: string;
  withOutBorder?: boolean;
  openDrawer: (
    content: ReactNode,
    placement: "top" | "right" | "bottom" | "left",
    size: number,
    title: string,
    withOutBorder?: boolean
  ) => void;
  onClose: () => void;
  setSize: Dispatch<SetStateAction<number>>;
}

const D3DrawerContext = createContext({} as IDrawerContext);

interface IDrawerProviderProps {
  children: ReactNode;
}

const D3DrawerProvider = ({ children }: IDrawerProviderProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [content, setContent] = useState<ReactNode | null>(null);
  const [placement, setPlacement] = useState<"top" | "right" | "bottom" | "left">("right");
  const [size, setSize] = useState<number>(800);
  const [title, setTitle] = useState<string>("");
  const [withOutBorder, setWithOutBorder] = useState<boolean>(false);

  const openDrawer = (
    content: ReactNode,
    placement: "top" | "right" | "bottom" | "left",
    size: number,
    title: string,
    withOutBorder?: boolean
  ) => {
    setContent(content);
    setIsOpen(true);
    setPlacement(placement);
    setSize(size);
    setTitle(title);
    setWithOutBorder(withOutBorder || false);
  };

  const onClose = () => {
    setContent(null);
    setIsOpen(false);
  };

  return (
    <D3DrawerContext.Provider
      value={{ isOpen, content, placement, size, title, withOutBorder, openDrawer, onClose, setSize }}
    >
      {children}
    </D3DrawerContext.Provider>
  );
};
export { D3DrawerContext, D3DrawerProvider };
