import { Icon } from "@iconify/react";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { LocalizationContext, LocalizationMap, Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { RenderDownloadProps, getFilePlugin } from "@react-pdf-viewer/get-file";
import { RenderPrintProps, printPlugin } from "@react-pdf-viewer/print";
import "@react-pdf-viewer/print/lib/styles/index.css";
import { searchPlugin } from "@react-pdf-viewer/search";
import { ToolbarSlot, toolbarPlugin } from "@react-pdf-viewer/toolbar";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";
import "@react-pdf-viewer/zoom/lib/styles/index.css";

import { Button, Tooltip } from "antd";
import { useContext, useState } from "react";
import pt_BR from "./pt_BR.json";
import EditFile from "@views/customer/spaces/work/components/file/edit-file/EditFile";
type D3PDFViewPropsType = {
	url: string;
	keyword?: string;
	fileName: string;
	info?: boolean;
	edit?: string;
	showInfo?: () => void;
};

function D3PDFView({ keyword, fileName, url, info, edit, showInfo }: D3PDFViewPropsType) {

	const {openDrawer} = useContext(D3DrawerContext);

	//language
	const [l10n, setL10n] = useState(pt_BR as any as LocalizationMap);
	const localizationContext = { l10n, setL10n };

	//Toolbar
	const toolbarPluginInstance = toolbarPlugin();
	const { Toolbar } = toolbarPluginInstance;

	//Search
	const searchPluginInstance = searchPlugin({
		keyword: keyword,
	});
	const { ShowSearchPopoverButton } = searchPluginInstance;

	//Download
	const getFilePluginInstance = getFilePlugin({
		fileNameGenerator: () => {
			return `${fileName}`;
		},
	});
	const { DownloadButton, Download } = getFilePluginInstance;

	//Print
	const printPluginInstance = printPlugin();
	const { PrintButton } = printPluginInstance;
	return (
		<div className="h-full overflow-hidden">
			<Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.js">
				<LocalizationContext.Provider value={localizationContext}>
					<div className="flex items-center justify-between p-1">
						<Toolbar>
							{(props: ToolbarSlot) => {
								const {
									CurrentPageInput,
									EnterFullScreen,
									GoToNextPage,
									GoToPreviousPage,
									NumberOfPages,
									Print,
									GoToFirstPage,
									GoToLastPage,
									ZoomIn,
									ZoomOut,
								} = props;
								return (
									<>
										<div className="flex items-center gap-2 px-3 ">
											<ShowSearchPopoverButton />
											<ZoomOut />
											<ZoomIn />
										</div>
										<div className="flex items-center gap-2">
											<GoToFirstPage />
											<GoToPreviousPage /> <CurrentPageInput />/ <NumberOfPages />
											<GoToNextPage />
											<GoToLastPage />
										</div>
										<div className="flex items-center gap-2 mr-3">
										{edit && (
												<Tooltip title="Editar arquivo">
													<Button
														className="flex items-center justify-center"
														type="text"
														icon={<Icon height={22} className="text-gray-500" icon="raphael:edit" />}
														onClick={() => openDrawer(<EditFile fileCuid={edit} />, "right", 600, "Editar arquivo")}
													/>
												</Tooltip>
											)}
											{info && (
												<Tooltip title="Detalhes do arquivo">
												<Button
													className="flex items-center justify-center"
													type="text"
													icon={<Icon height={24} icon="flat-color-icons:info" />}
													onClick={showInfo}
												/>
												</Tooltip>
											)}
											<EnterFullScreen />
											<Download>
												{(props: RenderDownloadProps) => (
													<div
														onClick={(e) => {
															props.onClick();
														}}
													>
														<DownloadButton />
													</div>
												)}
											</Download>
											<Print>
												{(props: RenderPrintProps) => (
													<div
														onClick={(e) => {
															props.onClick();
														}}
													>
														<PrintButton />
													</div>
												)}
											</Print>
										</div>
									</>
								);
							}}
						</Toolbar>
					</div>
					<div className="h-full overflow-hidden" style={{ maxHeight: "calc(100vh - 6rem)" }}>
						<Viewer plugins={[toolbarPluginInstance, searchPluginInstance, getFilePluginInstance]} fileUrl={url} />
					</div>
				</LocalizationContext.Provider>
			</Worker>
		</div>
	);
}

export default D3PDFView;
