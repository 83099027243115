import { BadRequest, ClientHttp, StandardError, Success } from "@utils/clientHttp";
import showMessage from "@utils/showMessage";
import { create } from "zustand";
import { DefaultPricesType, ResultRequestType, UpdateDefaultPricesType } from "./types";

type useDefaultPricesStoreType = {
	getDefaultPrices: () => Promise<Success<DefaultPricesType> | StandardError>;
	updateDefaultPrices: (payload: UpdateDefaultPricesType) => Promise<boolean>;
};

export const useDefaultPricesStore = create<useDefaultPricesStoreType>((set, get) => ({
	getDefaultPrices: async (): Promise<Success<DefaultPricesType> | StandardError> => {
		return await new ClientHttp().get<Success<DefaultPricesType>, StandardError>(`/api/v1/customer/table-prices`);
	},
	updateDefaultPrices: async (payload: UpdateDefaultPricesType): Promise<boolean> => {
		const result = await new ClientHttp().put<UpdateDefaultPricesType, Success<ResultRequestType>, BadRequest | StandardError>(
			"/api/v1/customer/table-prices",
			payload,
			(result: Success<ResultRequestType>) => {
				showMessage(result, "Preços padrão atualizado com sucesso. ");
			},
			(error: BadRequest | StandardError) => {
				showMessage(error);
			}
		);
		return result.status === "success";
	},
}));
