import axios from "axios";
import useAuthStore from "@provider/AuthProvider";

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  timeout: 60000, //revisar timeout, no momento está alto, digital ocean está lento
  headers: {
    "Content-Type": "application/json",
    "Accept-Language": "pt-BR",
  },
});

apiClient.interceptors.request.use(
  (config) => {
    const {isAuthenticated, token } = useAuthStore.getState();
    if (token !== undefined && isAuthenticated === true) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    /** 
     * Envia o usuário para a tela de login caso o token esteja expirado
     *   401 - InvalidToken
     *   401 - Unauthorized
     */
    if ( error.response.status === 401) {
      useAuthStore.getState().logout(); 
    }
    return Promise.reject(error);
  }
);

export default apiClient;
