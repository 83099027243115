import { DatePicker, Form, Input, InputNumber, Radio, Select } from "antd";
import { DocumentTypeIndex, ListPreviewType, ListTypesType } from "../types";

enum FormItemType {
  TXT = "Campo utilizado para texto em geral.",
  INT = "Campo utilizado para números inteiros. Ex: 1, 13, 45, 112...",
  IFT = "Campo utilizado para números inteiros ou decimais. Ex: 1, 3, 0.5, ou 2.76.",
  BOO = "Campo utilizado para condicional, sim ou não.",
  CAC = "Campo utilizado para CPF ou CNPJ.",
  SEL = "Campo utilizado para seleção de opções.",
  DAT = "Campo utilizado para data. Ex: 12-09-2021",
  DTI = "Campo utilizado para intervalo de datas. Ex: 12-09-2021 a 01-02-2023",
  NMI = "Campo utilizado para intervalo de números. Ex: 12 a 128",
  EMA = "Campo utilizado para emails. Ex: fulano@gmail.com",
}

const renderFormItem = (item: any, customClass = "") => {
  const keyType =
    item.key || item.default_input?.key || item.system_index_type?.key;

  switch (keyType) {
    case "TXT":
      return <Input type="text" className={`w-full ${customClass}`} />;
    case "INT":
    case "IFT":
      return <InputNumber className={customClass} />;
    case "BOO":
      return (
        <Radio.Group
          optionType="button"
          buttonStyle="solid"
          options={[
            { label: "Sim", value: true },
            { label: "Não", value: false },
          ]}
          className={customClass}
        />
      );
    case "CAC":
      return <Input className={customClass} />;
    case "SEL":
      return <Select className={`w-72 ${customClass}`} />;
    case "DAT":
      return <DatePicker className={customClass} />;
    case "DTI":
      return <DatePicker.RangePicker className={customClass} />;
    case "NMI":
      return (
        <div className={`flex justify-between gap-4 ${customClass}`}>
          <InputNumber placeholder="início" />
          <InputNumber placeholder="fim" />
        </div>
      );
    case "EMA":
      return <Input className={customClass} />;
    default:
      return null;
  }
};

export const listTypes = (item: ListTypesType) => {
  const tooltip = FormItemType[item.key as keyof typeof FormItemType];
  return (
    <Form.Item className="m-0" label={item.label} tooltip={tooltip}>
      {renderFormItem(item)}
    </Form.Item>
  );
};

export const listPreview = (item: ListPreviewType) => (
  <Form.Item className="m-0" label={item.label}>
    {renderFormItem(item.default_input)}
  </Form.Item>
);

export const listView = (item: DocumentTypeIndex) => (
  <Form.Item label={item.label}>
    {renderFormItem(item.system_index_type)}
  </Form.Item>
);
