import { Icon } from "@iconify/react";
import { Tabs } from "antd";
import TabAddress from "./components/tabs/TabAddress";
import TabAdmins from "./components/tabs/TabAdmins";
import TabData from "./components/tabs/TabData";
import TabIncident from "./components/tabs/TabIncident";
import { useCustomerStore } from "./store";
import TabPrices from "./components/tabs/TabPrices";
import TabNotifications from "./components/tabs/TabNotifications";
import TabContracts from "./components/tabs/TabContracts";
import TabContacts from "./components/tabs/TabContacts";
import TabNotes from "./components/tabs/TabNotes";

function CustomerView() {
  const { getActiveTab, setActiveTab } = useCustomerStore();
  return (
    <div className="flex flex-col h-full p-1 mb-1 mr-1 overflow-hidden bg-white rounded shadow-md">
      <Tabs
        tabPosition="left"
        defaultActiveKey="1"
        activeKey={getActiveTab()}
        onTabClick={(tab) => {
          setActiveTab(tab);
        }}
        className="flex h-full overflow-hidden select-none"
        items={[
          {
            key: "1",
            label: (
              <span className="flex items-center justify-center gap-2">
                <Icon icon="ri:profile-line" />
                Dados
              </span>
            ),
            children: <TabData />,
          },
          {
            key: "2",
            label: (
              <span className="flex items-center justify-center gap-2">
                <Icon icon="material-symbols:contact-phone-rounded" />
                Contatos
              </span>
            ),
            children: <TabContacts />,
          },
          {
            key: "3",
            label: (
              <span className="flex items-center justify-center gap-2">
                <Icon icon="material-symbols:contact-phone-rounded" />
                Notas de observações
              </span>
            ),
            children: <TabNotes />,
          },
          {
            key: "4",
            label: (
              <span className="flex items-center justify-center gap-2">
                <Icon icon="mdi:address-marker" />
                Endereços
              </span>
            ),
            children: <TabAddress />,
          },
          {
            key: "5",
            label: (
              <span className="flex items-center justify-center gap-2">
                <Icon icon="material-symbols:price-change" />
                Preço
              </span>
            ),
            children: <TabPrices />,
          },
          {
            key: "6",
            label: (
              <span className="flex items-center justify-center gap-2">
                <Icon icon="clarity:group-solid" />
                Administradores
              </span>
            ),
            children: <TabAdmins />,
          },
          {
            key: "7",
            label: (
              <span className="flex items-center justify-center gap-2">
                <Icon height={16} icon="ri:contract-fill" />
                Contratos
              </span>
            ),
            children: <TabContracts />,
          },
          {
            key: "8",
            label: (
              <span className="flex items-center justify-center gap-2">
                <Icon icon="mingcute:notification-fill" />
                Notificações
              </span>
            ),
            children: <TabNotifications />,
          },
          {
            key: "9",
            label: (
              <span className="flex items-center justify-center gap-2">
                <Icon icon="ic:round-money-off" width={18} height={18} />
                Registros
              </span>
            ),
            children: <TabIncident />,
          },
        ]}
      />
    </div>
  );
}

export default CustomerView;
