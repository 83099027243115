import { Icon } from "@iconify/react";
import { Button, Popconfirm, Popover, Tooltip } from "antd";
import { useContext, useEffect, useState } from "react";
import { FixedSizeList as VirtualList } from "react-window";
import { D3UploadContext } from "../../../../../../context/D3Upload/D3UploadContext";
import ListItem from "./ListItem";
import { useUploadManagerStore } from "./store";
import { UploadFileType } from "./types";
import axios, { CancelTokenSource } from "axios";

function UploadManager() {
  const { uploadFiles, completed, open } = useContext(D3UploadContext);
  const totalFiles = uploadFiles.length;
  const downloadFinished = totalFiles === completed;
  const [progress, setProgress] = useState<number>(0);
  const [sourceAxios, setSourceAxios] = useState<CancelTokenSource | null>(null);
  const { setStatusUpload, cancelUpload } = useUploadManagerStore();

  const handleFileUpload = async (dataFile: UploadFileType) => {
    const { fs_cuid, url, status_upload, file } = dataFile;
    if (url && status_upload === "W" && fs_cuid) {
      const sourceAxiosTemp = axios.CancelToken.source();

      try {
        const config = {
          headers: {
            "Content-Type": "application/octet-stream",
          },
          onUploadProgress: (progressEvent: any) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setProgress(percentCompleted);
          },
          cancelToken: sourceAxiosTemp.token,
        };

        setStatusUpload(fs_cuid, "P");
        setSourceAxios(sourceAxiosTemp);
        const response = await axios.put(url, file, config);

        if (response.statusText === "OK") {
          setStatusUpload(fs_cuid, "S");
        } else {
          throw new Error("Erro ao fazer upload do arquivo.");
        }
      } catch (error) {
        setStatusUpload(fs_cuid, "C");
        cancelUpload(fs_cuid);
      }
    }
  };

  useEffect(() => {
    uploadFiles.forEach((file) => {
      if (file.status_upload === "W") {
        handleFileUpload(file);
      }
    });
  }, [uploadFiles]);

  const renderRow = ({ index, style }: { index: any; style: any }) => (
    <div style={style}>
      <ListItem
        progress={progress}
        sourceAxios={sourceAxios}
        key={uploadFiles[index].fs_cuid}
        item={uploadFiles[index]}
        icon={uploadFiles[index].icon ?? "ooui:article-not-found-ltr"}
      />
    </div>
  );

  return totalFiles > 0 ? (
    <Popover
      open={open}
      trigger="click"
      placement="bottomRight"
      title={
        <div className="flex items-center justify-between select-none ">
          <span>{`${completed}/${totalFiles} uploads concluídos.`}</span>
          <div className="flex items-center justify-end">
            <Tooltip title="Minimizar gerenciador">
              <Button size="small" type="link" onClick={() => useUploadManagerStore.setState({ open: false })}>
                <Icon
                  className="text-blue-400 transition-all duration-200 ease-in hover:text-blue-500 hover:scale-110"
                  icon="flowbite:minimize-outline"
                  height={20}
                />
              </Button>
            </Tooltip>
            {downloadFinished && (
              <Popconfirm
                onConfirm={() => {
                  if (downloadFinished) {
                    useUploadManagerStore.setState({
                      uploadFiles: [],
                      completed: 0,
                      open: true,
                    });
                  }
                }}
                okText="Encerrar"
                placement="leftTop"
                title="Atenção !"
                description="Essa ação encerrará o gerenciador de uploads."
              >
                <Button size="small" type="link">
                  <Icon
                    className="text-red-400 transition-all duration-200 ease-in hover:text-red-500 hover:scale-110"
                    icon="ep:close-bold"
                    height={18}
                  />
                </Button>
              </Popconfirm>
            )}
          </div>
        </div>
      }
      content={
        <div className="w-[500px] overflow-hidden">
          <VirtualList
            className="custom-scroll"
            height={300}
            itemCount={uploadFiles.length}
            itemSize={50}
            width={"100%"}
          >
            {renderRow}
          </VirtualList>
        </div>
      }
    >
      {
        <Button
          onClick={() => useUploadManagerStore.setState({ open: !open })}
          className="flex flex-row items-center justify-center gap-2"
          shape="default"
          type="dashed"
        >
          <Icon
            className={`${downloadFinished ? "text-green-500" : "text-blue-500"}`}
            height={downloadFinished ? 16 : 20}
            icon={downloadFinished ? "grommet-icons:upload-option" : "line-md:uploading-loop"}
          />
          <span className="font-semibold tracking-widest text-gray-600">{`${completed}/${totalFiles}`}</span>
        </Button>
      }
    </Popover>
  ) : (
    <></>
  );
}

export default UploadManager;
