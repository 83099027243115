import { Icon } from "@iconify/react";
import { Tabs } from "antd";
import TabAdmins from "./components/tabs/TabAdmins";
import TabData from "./components/tabs/TabData";
import TabIncident from "./components/tabs/TabIncident";
import { useCustomerGroupStore } from "./store";
import TabPrices from "./components/tabs/TabPrices";

function CustomerGroupView() {
  const { getActiveTab, setActiveTab } = useCustomerGroupStore();
  return (
    <div className="flex flex-col h-full p-1 mb-1 mr-1 overflow-hidden bg-white rounded shadow-md">
      <Tabs
        tabPosition="left"
        defaultActiveKey="1"
        activeKey={getActiveTab()}
        onTabClick={(tab) => {
          setActiveTab(tab);
        }}
        className="flex h-full overflow-hidden select-none"
        items={[
          {
            key: "1",
            label: (
              <span className="flex items-center justify-center gap-2">
                <Icon icon="ri:profile-line" />
                Dados
              </span>
            ),
            children: <TabData />,
          },
          {
            key: "2",
            label: (
              <span className="flex items-center justify-center gap-2">
                <Icon icon="material-symbols:price-change" />
                Preço
              </span>
            ),
            children: <TabPrices />,
          },

          {
            key: "3",
            label: (
              <span className="flex items-center justify-center gap-2">
                <Icon icon="clarity:group-solid" />
                Administradores
              </span>
            ),
            children: <TabAdmins />,
          },
          {
            key: "7",
            label: (
              <span className="flex items-center justify-center gap-2">
                <Icon icon="ic:round-money-off" width={18} height={18} />
                Registros
              </span>
            ),
            children: <TabIncident />,
          },
        ]}
      />
    </div>
  );
}

export default CustomerGroupView;
