import { useEffect, useState } from "react";
import { Button, DatePicker, Form, Input, Select, Space, Spin, Tooltip } from "antd";
import dayjs from "dayjs";
import { usePhysicalTagsStore } from "../store";
import { SearchPhysicalTagType, SelectCompanyUserItemType } from "../types";
import { Icon } from "@iconify/react";
import "dayjs/locale/pt-br";
import { formatDatePicker } from "@utils/DatePickerFormat";

type SearchPhysicalTagFormProps = {
  onCallBackFilter: () => void;
  onCallBackResetFilter: () => void;
};

export const SearchPhysicalTagForm = ({ onCallBackFilter, onCallBackResetFilter }: SearchPhysicalTagFormProps) => {
  const [form] = Form.useForm<SearchPhysicalTagType>();
  const { filter, setFilter, getCompanyUsersForSelect } = usePhysicalTagsStore();
  const [companyUsers, setCompanyUsers] = useState<SelectCompanyUserItemType[]>([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const result = await getCompanyUsersForSelect();
      setCompanyUsers(result);
      const generated = filter.pt_generated_at ? dayjs(filter.pt_generated_at) : "";
      const printed = filter.pt_printed_at ? dayjs(filter.pt_printed_at) : "";
      form.setFieldsValue({
        ...filter,
        pt_generated_at: generated,
        pt_printed_at: printed,
      });
      setLoading(false);
    })();
  }, [getCompanyUsersForSelect, filter, form]);

  return (
    <Spin spinning={loading}>
      <Form
        className="select-none w-80"
        layout="vertical"
        form={form}
        onFinish={async (payload) => {
          payload.pt_generated_at = payload.pt_generated_at ? formatDatePicker(payload.pt_generated_at) : "";
          payload.pt_printed_at = payload.pt_printed_at ? formatDatePicker(payload.pt_printed_at) : "";
          setFilter(payload);
          onCallBackFilter();
        }}
        autoComplete="off"
      >
        <Form.Item<SearchPhysicalTagType> name="pt_description" label="Descrição">
          <Input />
        </Form.Item>

        <Form.Item<SearchPhysicalTagType> name="pt_type" label="Tipo da etiqueta">
          <Select
            allowClear
            onClear={() => {
              form.setFieldValue("up_cuid", "");
            }}
            options={[
              { value: "D", label: "Documento" },
              { value: "R", label: "Repositório" },
            ]}
          />
        </Form.Item>

        <Form.Item<SearchPhysicalTagType> label="Gerador por" name="ug_cuid">
          <Select
            listItemHeight={10}
            listHeight={250}
            allowClear
            removeIcon={
              <Icon
                icon="iconamoon:close-duotone"
                className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
              />
            }
            menuItemSelectedIcon={<Icon icon="eva:checkmark-outline" className="w-5 h-5 text-blue-500" />}
            optionLabelProp="dataLabel"
            showSearch
            size="middle"
            className="w-full truncate select-none"
            optionFilterProp="children"
            filterOption={(input, option) => option?.dataFilter.toLowerCase().includes(input.toLowerCase())}
            onClear={() => {
              form.setFieldValue("ug_cuid", "");
            }}
          >
            {companyUsers.map(({ value, active, username, label }) => (
              <Select.Option key={value} value={value} dataFilter={`${label} ${username}`} dataLabel={`${label}`}>
                <div className="flex items-center justify-between ">
                  <div className="flex flex-col truncate">
                    <span className="truncate">{label}</span>
                    <span className="text-[10px] truncate">{username}</span>
                  </div>
                  <span>
                    {active ? (
                      <Tooltip title="Usuário desbloqueado" placement="right">
                        <Icon icon="basil:unlock-solid" className="text-green-500" height={16} width={16} />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Usuário bloqueado" placement="right">
                        <Icon icon="basil:lock-solid" className="text-red-500" height={16} width={16} />
                      </Tooltip>
                    )}
                  </span>
                </div>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item<SearchPhysicalTagType> name="pt_generated_at" label="Data de geração">
          <DatePicker format="DD/MM/YYYY" maxDate={dayjs()} />
        </Form.Item>

        <Form.Item<SearchPhysicalTagType> label="Impresso por" name="up_cuid">
          <Select
            listItemHeight={10}
            listHeight={250}
            allowClear
            removeIcon={
              <Icon
                icon="iconamoon:close-duotone"
                className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
              />
            }
            menuItemSelectedIcon={<Icon icon="eva:checkmark-outline" className="w-5 h-5 text-blue-500" />}
            optionLabelProp="dataLabel"
            showSearch
            size="middle"
            className="w-full truncate select-none"
            optionFilterProp="children"
            filterOption={(input, option) => option?.dataFilter.toLowerCase().includes(input.toLowerCase())}
            onClear={() => {
              form.setFieldValue("up_cuid", "");
            }}
          >
            {companyUsers.map(({ value, active, username, label }) => (
              <Select.Option key={value} value={value} dataFilter={`${label} ${username}`} dataLabel={`${label}`}>
                <div className="flex items-center justify-between ">
                  <div className="flex flex-col truncate">
                    <span className="truncate">{label}</span>
                    <span className="text-[10px] truncate">{username}</span>
                  </div>
                  <span>
                    {active ? (
                      <Tooltip title="Usuário desbloqueado" placement="right">
                        <Icon icon="basil:unlock-solid" className="text-green-500" height={16} width={16} />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Usuário bloqueado" placement="right">
                        <Icon icon="basil:lock-solid" className="text-red-500" height={16} width={16} />
                      </Tooltip>
                    )}
                  </span>
                </div>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item<SearchPhysicalTagType> name="pt_printed_at" label="Data de impressão">
          <DatePicker format="DD/MM/YYYY" maxDate={dayjs()} />
        </Form.Item>

        <Form.Item>
          <div className="text-right">
            <Space size="small">
              <Button type="default" danger onClick={onCallBackResetFilter}>
                Limpar Filtro
              </Button>
              <Button type="primary" htmlType="submit">
                Filtrar
              </Button>
            </Space>
          </div>
        </Form.Item>
      </Form>
    </Spin>
  );
};
