import { Icon } from "@iconify/react";
import D3DateHourSorter from "@utils/D3DateHourSorter";
import { Button, Col, Form, Input, Row, Table, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { CurrencyInput } from "react-currency-mask";
import "../style.css";
import { usePriceTransportStore } from "./store";
import { AddressType, FormType, ItemTransportType } from "./types";

function Transport() {
  const { updatePricesTransport, loading, formValues, loadPricesTransport, apiResult } = usePriceTransportStore();
  const [id, setId] = useState<number>(0);
  const [form] = Form.useForm<FormType>();

  useEffect(() => {
    (async () => {
      await loadPricesTransport();
    })();
  }, [loadPricesTransport]);

  useEffect(() => {
    (async () => {
      form.setFieldsValue(formValues);
    })();
  }, [formValues]);

  const columns = [
    {
      title: "Endereço da empresa",
      dataIndex: "company_address",
      key: "company_address",
      render: ({ city, complement, neighborhood, state, street_address, street_number, zip_code }: AddressType) =>
        `${street_address}, nº ${street_number ? street_number : "s/n"} ${
          complement ? `, ${complement}` : ""
        }, ${neighborhood}, ${city} - ${state}, CEP: ${zip_code} `,
    },
    {
      title: "Endereço do cliente",
      dataIndex: "customer_address",
      key: "customer_address",
      render: ({ city, complement, neighborhood, state, street_address, street_number, zip_code }: AddressType) =>
        `${street_address}, nº ${street_number ? street_number : "s/n"} ${
          complement ? `, ${complement}` : ""
        }, ${neighborhood}, ${city} - ${state}, CEP: ${zip_code} `,
    },
    {
      title: "Preço transporte padrão",
      dataIndex: "transport_price",
      key: "transport_price",
      align: "center" as const,
      render: (_: any, record: ItemTransportType) => (
        <Form.Item
          name={[record.id, "transport_price"]}
          className="p-0 m-0"
          validateStatus={record.transport_price === null || record.transport_price === undefined ? "error" : ""}
        >
          <CurrencyInput
            locale="pt-BR"
            currency="BRL"
            value={record.transport_price == null ? "" : record.transport_price.toFixed(2).replace(".", ",")}
            onChangeValue={(_, value) => {
              form.setFieldValue([record.id, "transport_price"], value);
            }}
            InputElement={<Input className="w-32" />}
          />
        </Form.Item>
      ),
    },
    {
      title: "Preço transporte emergencial",
      dataIndex: "transport_emergency_price",
      key: "transport_emergency_price",
      align: "center" as const,
      render: (_: any, record: ItemTransportType) => (
        <Form.Item
          name={[record.id, "transport_emergency_price"]}
          className="p-0 m-0"
          validateStatus={
            record.transport_emergency_price === null || record.transport_emergency_price === undefined ? "error" : ""
          }
        >
          <CurrencyInput
            locale="pt-BR"
            currency="BRL"
            value={
              record.transport_emergency_price == null
                ? ""
                : record.transport_emergency_price.toFixed(2).replace(".", ",")
            }
            onChangeValue={(_, value) => {
              form.setFieldValue([record.id, "transport_emergency_price"], value);
            }}
            InputElement={<Input className="w-32" />}
          />
        </Form.Item>
      ),
    },
    {
      title: "Atualizado por",
      dataIndex: "updated_by",
      key: "updated_by",
    },
    {
      title: "Atualizado em",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (value: Date, record: ItemTransportType) => D3DateHourSorter(value),
    },
    {
      title: "Ações",
      dataIndex: "action",
      key: "action",
      width: 80,
      align: "center" as const,
      render: (value: Date, record: ItemTransportType) => (
        <div className="flex justify-center">
          <Tooltip title="Salvar" trigger="hover">
            <Button
              onClick={() => setId(record.id)}
              className="flex items-center justify-center"
              type="link"
              size="small"
              htmlType="submit"
              icon={
                <Icon
                  height={20}
                  className="text-blue-400 transition-all duration-200 ease-in hover:text-blue-600"
                  icon="fluent:save-16-filled"
                />
              }
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <Form
      form={form}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          event.preventDefault();
        }
      }}
      onFinish={async (payload: FormType) => {
        const { transport_emergency_price, transport_price } = payload[id];
        if (transport_price && transport_emergency_price) {
          await updatePricesTransport(id.toString(), { transport_price, transport_emergency_price });
        }
      }}
      className="flex flex-col w-full h-full pb-1 overflow-hidden select-none new-table"
    >
      <Table<ItemTransportType>
        size="small"
        rowKey="id"
        dataSource={apiResult.data}
        columns={columns}
        pagination={false}
        bordered
        loading={loading}
        sticky
        title={() => (
          <>
            <Row className="w-full">
              <Col span={8} className="flex items-center justify-start">
                <span className="text-sm font-semibold text-gray-600">
                  Configurações de preços de transporte que podem ser solicitados pelo cliente.
                </span>
              </Col>
              <Col span={8} className="text-center">
                <span className="text-xl font-semibold text-gray-600">Preço de Transportes</span>
              </Col>
              <Col span={8} className="flex items-center justify-end"></Col>
            </Row>
          </>
        )}
      />
    </Form>
  );
}

export default Transport;
