import { message } from "antd";

type D3CopyProps = {
  success?: string;
  error?: string;
  text: string;
};

function D3Copy({
  success = "Texto copiado!",
  error = "Falha ao copiar texto!",
  text,
}: D3CopyProps) {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      message.success(success);
    })
    .catch((err) => {
      message.error(error, err);
    });
}

export default D3Copy;
