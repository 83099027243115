import { useContext, useEffect, useState } from "react";
import { Button, Form, Input, Space, Spin } from "antd";
import { useOperationsStore } from "../store";
import { UpdateOperationType, ViewOperationType } from "../types";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import showMessage from "@utils/showMessage";
import D3Can from "@components/shared/D3Can";
import FormItemInputMoney from "@components/shared/FormItemInputMoney";

type UpdateOperationProps = {
  cuid: string;
};

export const UpdateOperationForm = ({ cuid }: UpdateOperationProps) => {
  const { updateOperation, getOperation } = useOperationsStore();
  const [form] = Form.useForm<UpdateOperationType>();
  const [loading, setLoading] = useState(true);

  const { onClose } = useContext(D3DrawerContext);

  useEffect(() => {
    (async () => {
      const result = await getOperation(cuid);
      if (result.status === "success") {
        const viewOperation = result.body as ViewOperationType;
        form.setFieldsValue(viewOperation);
        setLoading(false);
      } else {
        showMessage(result);
      }
    })();
  }, [cuid, form, getOperation]);

  return (
    <>
      <Spin spinning={loading}>
        <Form
          className="select-none"
          layout="vertical"
          form={form}
          onFinish={async (payload: UpdateOperationType) => {
            setLoading(true);
            const result = await updateOperation(cuid, payload);
            if (result.code === 200) {
              showMessage(result, "Operação atualizada com sucesso.");
              onClose();
            } else {
              showMessage(result);
            }
            setLoading(false);
          }}
          autoComplete="off"
        >
          <Form.Item<UpdateOperationType>
            name="description"
            label="Descrição"
            rules={[{ required: true, message: "Por favor, insira descrição." }]}
          >
            <Input />
          </Form.Item>
          <FormItemInputMoney form={form} name="price" label="Preço" numberType="positive" />
          <Form.Item>
            <div className="text-right">
              <Space size="small">
                <Button type="default" danger onClick={() => onClose()}>
                  Cancelar
                </Button>
                <D3Can action="update" resource="system_operation">
                  <Button type="primary" htmlType="submit">
                    Atualizar
                  </Button>
                </D3Can>
              </Space>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};
