import { createContext } from "react";

import { useUploadManagerStore } from "../../views/app/customer/spaces/components/upload-manager/store";
import { UploadFileType } from "./types";

interface IUploadContext {
  uploadFiles: UploadFileType[];
  completed: number;
  open: boolean;
}

const D3UploadContext = createContext({} as IUploadContext);

interface IUploadProviderProps {
  children: JSX.Element;
}

const D3UploadProvider = ({ children }: IUploadProviderProps) => {
  const { uploadFiles, completed, open } = useUploadManagerStore();

  return (
    <D3UploadContext.Provider value={{ uploadFiles, completed, open }}>
      {children}
    </D3UploadContext.Provider>
  );
};
export { D3UploadContext, D3UploadProvider };
