import { Form, InputNumber } from "antd";
type ItemNMIProps = {
  name: number;
};
function ItemNMI({ name, ...props }: ItemNMIProps) {
  return (
    <div className="flex w-full gap-3 ">
      <div className="flex items-center">
        <Form.Item
          {...props}
          className="w-32 p-0 m-0"
          name={[name, "start_value"]}
          getValueProps={(value: number) => {
            return { value: value !== undefined ? value.toString() : "" };
          }}
          normalize={(value: string) => {
            const numericValue = Number(value);
            return !isNaN(numericValue) ? numericValue.toString() : value;
          }}
        >
          <InputNumber type="number" className="w-32" />
        </Form.Item>
        <div className="flex items-center h-full px-2">ao</div>
        <Form.Item
          {...props}
          className="w-32 p-0 m-0"
          name={[name, "end_value"]}
          getValueProps={(value: number) => {
            return { value: value !== undefined ? value.toString() : "" };
          }}
          normalize={(value: string) => {
            const numericValue = Number(value);
            return !isNaN(numericValue) ? numericValue.toString() : value;
          }}
        >
          <InputNumber type="number" className="w-32" />
        </Form.Item>
      </div>
    </div>
  );
}

export default ItemNMI;
