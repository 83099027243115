import { Divider, Button, Table, Space, Tooltip, Popover, Pagination } from "antd";
import { Icon } from "@iconify/react";
import { useContext, useEffect } from "react";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { CreateNoteForm } from "../notes/components/CreateNoteForm";
import { ItemListNotesType } from "../notes/types";
import { useNotesStore } from "../notes/store";
import D3Status from "@components/shared/D3Status";
import dayjs from "dayjs";
import D3Can from "@components/shared/D3Can";
import { ViewNoteForm } from "../notes/components/ViewNoteForm";
import { UpdateNoteForm } from "../notes/components/UpdateNoteForm ";
import { SearchNoteForm } from "../notes/components/SearchNoteForm";

function TabNotes() {
  const { openDrawer } = useContext(D3DrawerContext);
  const { loadNotes, loading, dataNotes, openSearch, pagination, setPagination, setSort } = useNotesStore();

  useEffect(() => {
    (async () => {
      await loadNotes();
    })();
  }, [loadNotes]);

  return (
    <div className="flex flex-col h-full p-2 overflow-hidden">
      <div className="flex items-center max-h-[30px] justify-between">
        <div className="flex items-center gap-5">
          <h2 className="ml-1 text-lg font-semibold text-gray-600">Notas do Cliente</h2>
          <Popover
            open={openSearch}
            placement="rightTop"
            title="Pesquisar notas de observação"
            content={<SearchNoteForm />}
            trigger="click"
            onOpenChange={(open) => useNotesStore.setState({ openSearch: open })}
          >
            <Button
              onClick={() => useNotesStore.setState({ openSearch: !openSearch })}
              type="primary"
              size="middle"
              icon={<Icon icon="fluent:search-12-filled" height={16} width={16} />}
            >
              Pesquisar
            </Button>
          </Popover>
        </div>
        <D3Can action="customer_note" resource="create">
          <Button
            className="flex items-center justify-start"
            type="primary"
            size="middle"
            icon={<Icon icon="ic:round-plus" height={20} width={20} />}
            onClick={() => openDrawer(<CreateNoteForm />, "right", 600, "Cadastrar Nota")}
          >
            Nova nota
          </Button>
        </D3Can>
      </div>
      <Divider className="mt-1 mb-6 " />

      <div className="flex flex-col h-full overflow-auto bg-white ">
        <Table<ItemListNotesType>
          size="small"
          rowKey="cuid"
          dataSource={dataNotes.data}
          bordered
          className="responsive-table tabs-table"
          pagination={false}
          loading={loading}
          footer={() => (
            <div className="flex items-center justify-end min-w-full">
              <Pagination
                showLessItems
                showTotal={(total, range) =>
                  `Exibindo de ${range[0]} a ${range[1]} do total ${total} ${total > 1 ? "registros" : "registro"}`
                }
                showSizeChanger={true}
                current={pagination.page}
                onChange={setPagination}
                pageSize={pagination.pageSize}
                total={dataNotes.total}
              />
            </div>
          )}
          onChange={(a, b, sorter: any) => {
            if (sorter) {
              setSort({
                sort_by: sorter.field,
                sort_direction: sorter.order,
              });
            }
          }}
        >
          <Table.Column<ItemListNotesType>
            showSorterTooltip={false}
            key="title"
            title="Descrição"
            dataIndex="title"
            sorter
          />
          <Table.Column<ItemListNotesType>
            showSorterTooltip={false}
            key="created_by"
            title="Criado por"
            dataIndex="created_by"
            sorter
          />
          <Table.Column<ItemListNotesType>
            showSorterTooltip={false}
            key="created_at"
            title="Criado em"
            dataIndex="created_at"
            render={(value, record) => dayjs(record.created_at).format("DD/MM/YYYY [às] HH:mm")}
            sorter
          />
          <Table.Column<ItemListNotesType>
            showSorterTooltip={false}
            key="updated_at"
            title="Atualizado em"
            dataIndex="updated_at"
            render={(value, record) => dayjs(record.updated_at).format("DD/MM/YYYY [às] HH:mm")}
          />

          <Table.Column<ItemListNotesType>
            showSorterTooltip={false}
            key="active"
            title="Status"
            dataIndex="active"
            render={(value, record) => <D3Status status={record.active} />}
            sorter
          />

          <Table.Column<ItemListNotesType>
            key="action"
            title="Ações"
            width={80}
            align="center"
            render={(value, record) => {
              return (
                <>
                  <Space>
                    <D3Can action="customer_note" resource="view">
                      <Tooltip trigger="hover" title="Visualizar" destroyTooltipOnHide mouseLeaveDelay={0} zIndex={11}>
                        <Button
                          className="peer"
                          type="text"
                          shape="default"
                          icon={
                            <Icon
                              icon="carbon:document-view"
                              height={18}
                              width={18}
                              className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
                            />
                          }
                          size={"middle"}
                          onClick={() =>
                            openDrawer(
                              <ViewNoteForm noteCuid={record.cuid} />,
                              "right",
                              600,
                              "Visualizar nota de observação"
                            )
                          }
                        />
                      </Tooltip>
                    </D3Can>
                    <D3Can action="customer_note" resource="update">
                      <Tooltip title="Editar" destroyTooltipOnHide mouseLeaveDelay={0} zIndex={11}>
                        <Button
                          type="text"
                          shape="default"
                          icon={
                            <Icon
                              icon="iconamoon:edit-fill"
                              height={18}
                              width={18}
                              className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
                            />
                          }
                          size={"middle"}
                          onClick={() =>
                            openDrawer(
                              <UpdateNoteForm noteCuid={record.cuid} />,
                              "right",
                              600,
                              "Editar nota de observação"
                            )
                          }
                        />
                      </Tooltip>
                    </D3Can>
                  </Space>
                </>
              );
            }}
          />
        </Table>
      </div>
    </div>
  );
}

export default TabNotes;
