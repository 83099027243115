import { Icon } from "@iconify/react";
import { FloatButton, Spin } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import LogoGedCelula from "../../../assets/images/newlogoged.png";
function AuthView() {
	const { i18n } = useTranslation();
	const [isLoading, setIsLoading] = useState<boolean>(true);

	useEffect(() => {
		setIsLoading(false);
	}, []);

	return (
		<div className="flex items-center justify-center w-full h-full p-5 overflow-hidden">
			{isLoading ? (
				<Spin size="large" />
			) : (
				<div className="flex relative flex-col overflow-hidden  au:flex-row au:max-h-[660px]  w-full max-w-[1024px] h-full bg-white rounded shadow-md">
					<div className="flex overflow-hidden  justify-center items-center min-h-[30vh] au:flex-1 au:h-full bg-blue-500 au:min-w-[400px]">
						<img src={LogoGedCelula} className="w-4/6" alt="Logo Ged Célula" />
					</div>
					<div className="flex flex-col items-center justify-center h-full overflow-y-auto bg-white scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100 au:w-full">
						<Outlet />
					</div>
					<div className="absolute bottom-0 right-0 bg-red-500">
						<FloatButton.Group
							trigger="click"
							type="default"
							style={{ right: 30 }}
							icon={<Icon height={18} icon={`${i18n.language === "pt" ? "twemoji:flag-brazil" : "twemoji:flag-united-states"}`} />}
						>
							{i18n.language === "pt" ? (
								<FloatButton onClick={() => i18n.changeLanguage("en")} icon={<Icon height={18} icon="twemoji:flag-united-states" />} />
							) : (
								<FloatButton onClick={() => i18n.changeLanguage("pt")} icon={<Icon height={18} icon="twemoji:flag-brazil" />} />
							)}
						</FloatButton.Group>
					</div>
				</div>
			)}
		</div>
	);
}

export default AuthView;
