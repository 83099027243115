import { ItemBOO, ItemCAC, ItemDAT, ItemDTI, ItemEMA, ItemIFT, ItemINT, ItemNMI, ItemSEL, ItemTXT } from ".";
import { IndexItemType } from "../../types";

type RenderFormItemProps = {
  name: number;
  item: IndexItemType;
};
function Items({ name, item, ...props }: RenderFormItemProps) {
  const {
    list_of_key_and_value,
    system_index_type: { key, search_options },
  } = item;
  switch (key) {
    case "EMA":
      return <ItemEMA {...props} name={name} options={search_options} />;
    case "CAC":
      return <ItemCAC {...props} name={name} options={search_options} />;
    case "DAT":
      return <ItemDAT {...props} name={name} options={search_options} />;
    case "DTI":
      return <ItemDTI {...props} name={name} />;
    case "BOO":
      return <ItemBOO {...props} name={name} />;
    case "NMI":
      return <ItemNMI {...props} name={name} />;
    case "TXT":
      return <ItemTXT {...props} name={name} options={search_options} />;
    case "INT":
      return <ItemINT {...props} name={name} options={search_options} />;
    case "IFT":
      return <ItemIFT {...props} name={name} options={search_options} />;
    case "SEL":
      return <ItemSEL {...props} name={name} options={search_options} values={list_of_key_and_value} />;
    default:
      return null;
  }
}

export default Items;
