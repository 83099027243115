import { useEffect } from "react";
import { Button, Form, Input, Radio, Space } from "antd";

import { usePermissionGroupsStore } from "../store";
import { SearchPermissionGroupType } from "../types";

type SearchPermissionGroupFormProps = {
  onCallBackFilter: () => void;
  onCallBackResetFilter: () => void;
};

export const SearchPermissionGroupForm = ({
  onCallBackFilter,
  onCallBackResetFilter,
}: SearchPermissionGroupFormProps) => {
  const [form] = Form.useForm<SearchPermissionGroupType>();
  const { filter, setFilter } = usePermissionGroupsStore();

  useEffect(() => {
    form.setFieldsValue(filter);
  }, [form, filter]);
  return (
    <>
      <Form
        className="select-none"
        layout="vertical"
        form={form}
        onFinish={async (values) => {
          if (form.isFieldsTouched(true)) {
            setFilter(values);
          }
          onCallBackFilter();
        }}
        autoComplete="off"
      >
        <Form.Item<SearchPermissionGroupType> name="pg_name" label="Nome">
          <Input />
        </Form.Item>
        <Form.Item<SearchPermissionGroupType> name="pg_active" label="Status">
          <Radio.Group value={filter.pg_active}>
            <Radio value={"off"}>Bloqueados</Radio>
            <Radio value={"on"}>Desbloqueados</Radio>
            <Radio value={"all"}>Todos</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item>
          <div className="text-right">
            <Space size="small">
              <Button type="default" danger onClick={onCallBackResetFilter}>
                Limpar Filtro
              </Button>
              <Button type="primary" htmlType="submit">
                Filtrar
              </Button>
            </Space>
          </div>
        </Form.Item>
      </Form>
    </>
  );
};
