import useAuthStore, { InputLoginType } from "@provider/AuthProvider";
import { BadRequest, ClientHttp, StandardError, Success } from "@utils/clientHttp";
import showMessage from "@utils/showMessage";
import { create } from "zustand";
import { ChangePasswordType, PayloadType } from "./types";

type ChangePasswordState = {
  user?: InputLoginType;
  setUserData: (payload: InputLoginType) => void;
  clearUserData: () => void;
  changePasswordAndGenerateNewToken: (payload: PayloadType) => Promise<boolean>;
  loadingChange: boolean;
  isLoggedIn: boolean;
};

export const useChangePassword = create<ChangePasswordState>((set, get) => ({
  User: undefined,
  loadingChange: false,
  isLoggedIn: false,
  setUserData: (payload: InputLoginType) => {
    set({ user: payload });
  },
  clearUserData: () => {
    set({ user: undefined });
  },
  changePasswordAndGenerateNewToken: async ({
    new_password,
    login,
    password: old_password,
    repeated_new_password,
  }: PayloadType): Promise<boolean> => {
    set({ loadingChange: true });
    const result = await new ClientHttp().patch<ChangePasswordType, Success<void>, BadRequest | StandardError>(
      "/api/v1/system/user",
      {
        new_password,
        old_password,
        repeated_new_password,
      },
      (result: Success<void>) => {
        (async () => {
          const resultLogin = await useAuthStore.getState().login({
            login,
            password: new_password,
          });
          if (resultLogin.status === "success") {
            get().clearUserData();
            return true;
          } else {
            showMessage(resultLogin);
          }
        })();
      },
      (result: BadRequest | StandardError): void => {
        showMessage(result);
        set({ loadingChange: false });
      }
    );
    return result.status === "success";
  },
}));
