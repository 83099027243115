import { useEffect } from "react";
import { Button, Form, Input, Select, Space } from "antd";
import { useContactTypesStore } from "../store";
import { SearchContactTypeType } from "../types";
import { profileOptions } from "./listProfile";

type SearchContactTypeFormProps = {
  onCallBackFilter: () => void;
  onCallBackResetFilter: () => void;
};

export const SearchContactTypeForm = ({ onCallBackFilter, onCallBackResetFilter }: SearchContactTypeFormProps) => {
  const [form] = Form.useForm<SearchContactTypeType>();
  const { filter, setFilter } = useContactTypesStore();

  useEffect(() => {
    form.setFieldsValue(filter);
  }, [form, filter]);

  return (
    <>
      <Form
        className="select-none min-w-80"
        layout="vertical"
        form={form}
        onFinish={async (values) => {
          setFilter(values);
          onCallBackFilter();
        }}
        autoComplete="off"
      >
        <Form.Item<SearchContactTypeType> name="description" label="Descrição">
          <Input />
        </Form.Item>
        <Form.Item<SearchContactTypeType> name="profile" label="Perfil">
          <Select options={profileOptions()} />
        </Form.Item>
        <Form.Item>
          <div className="text-right">
            <Space size="small">
              <Button type="default" danger onClick={onCallBackResetFilter}>
                Limpar Filtro
              </Button>
              <Button type="primary" htmlType="submit">
                Filtrar
              </Button>
            </Space>
          </div>
        </Form.Item>
      </Form>
    </>
  );
};
