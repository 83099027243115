import { create } from "zustand";
import { BadRequest, ClientHttp, StandardError, Success } from "@utils/clientHttp";
import { ChildrenType, CreateFolderType, RenameFolderType } from "./types";
import useNavigateStore from "../navigate/store";
import showMessage from "@utils/showMessage";

type FolderStoreType = {
  openModal: boolean;
  createFolder: (payload: CreateFolderType) => Promise<boolean>;
  renameFolder: (folderCuid: string, payload: RenameFolderType) => Promise<boolean>;
};

const defaultState = {
  openModal: false,
};

const useFolderStore = create<FolderStoreType>((set, get) => ({
  ...defaultState,
  renameFolder: async (folderCuid: string, payload: RenameFolderType): Promise<boolean> => {
    const response = await new ClientHttp().patch<RenameFolderType, Success<void>, BadRequest | StandardError>(
      `/api/v1/customer/fs/folder/${folderCuid}/rename`,
      payload,
      (result: Success<void>) => {
        showMessage(result, "Pasta renomeada com sucesso");
        useNavigateStore.getState().renameFolder(folderCuid, payload.new_folder_name )
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
    return response.status === "success";
  },
  createFolder: async (payload: CreateFolderType): Promise<boolean> => {
    const response = await new ClientHttp().post<CreateFolderType, Success<ChildrenType>, BadRequest | StandardError>(
      "/api/v1/customer/fs/folder",
      payload,
      (result: Success<ChildrenType>) => {
        showMessage(result, "Pasta criada com sucesso");
        useNavigateStore.getState().addChildren(result.body);
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
    return response.status === "success";
  },
}));

export default useFolderStore;
