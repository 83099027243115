import { BadRequest, ClientHttp, StandardError, Success } from "@utils/clientHttp";
import showMessage from "@utils/showMessage";
import { create } from "zustand";
import { ListTransportType, ResultRequestType, UpdateTransportType } from "./types";

type usePriceTransportStoreType = {
	loading: boolean;
	apiResult: ListTransportType;
	formValues: Record<number, {
		transport_emergency_price: number;
		transport_price?: number | undefined;
	}>;
	loadPricesTransport: () => Promise<Success<ListTransportType> | BadRequest | StandardError>;
	updatePricesTransport: (customerTransportId: string, payload: UpdateTransportType) => Promise<boolean>;
};

const defaultState = {
	loading: false,
	apiResult: {
		data: [],
	},
	formValues: {}
};

export const usePriceTransportStore = create<usePriceTransportStoreType>((set, get) => ({
	...defaultState,
	loadPricesTransport: async (): Promise<Success<ListTransportType> | BadRequest | StandardError> => {
		set({ loading: true });
		return await new ClientHttp().get<Success<ListTransportType>, BadRequest | StandardError>(
			"/api/v1/customer/transport-prices",
			(result: Success<ListTransportType>) => {
				const body = result.body.data;
			const formValues: Record<number, { transport_emergency_price: number; transport_price?: number }> = body.reduce((acc, item) => {
				acc[item.id] = {
					transport_emergency_price: item.transport_emergency_price,
					transport_price: item.transport_price,
				};
				return acc;
			}, {} as Record<number, { transport_emergency_price: number; transport_price?: number }>);
				set({
					apiResult: result.body,
					loading: false,
					formValues
				});
			},
			(error: BadRequest | StandardError) => {
				showMessage(error);
				set({
					loading: false,
				});
			}
		);
	},
	updatePricesTransport: async (customerTransportId: string, payload: UpdateTransportType): Promise<boolean> => {
		const result = await new ClientHttp().patch<UpdateTransportType, Success<ResultRequestType>, BadRequest | StandardError>(
			`/api/v1/customer/transport-prices/${customerTransportId}`,
			payload,
			(result: Success<ResultRequestType>) => {
				showMessage(result, "Preços de transporte atualizado com sucesso. ");
				get().loadPricesTransport();
			},
			(error: BadRequest | StandardError) => {
				showMessage(error);
			}
		);
		return result.status === "success";
	},
}));
