import { BadRequest, ClientHttp, StandardError, Success } from "@utils/clientHttp";
import showMessage from "@utils/showMessage";
import { notification } from "antd";
import { diff } from "deep-diff";
import { create } from "zustand";
import { EditApiResultType, ListType, ServiceItemType, ServiceUpdateType } from "./types";

type UseCustomerMaterialStoreType = {
	apiResult: ListType;
	oldApiResult: ListType;
	loading: boolean;
	loadingSave: boolean;
	alteredMaterials: string[];
	loadItemsList: () => Promise<Success<ListType> | BadRequest | StandardError>;

	serviceUpdate: () => Promise<Success<void> | BadRequest | StandardError | void>;

	editApiResult: (objs: EditApiResultType[]) => void;

	payloadGenerator: (alteredMaterials: string[]) => ServiceUpdateType | void;

	resetState: () => void;
};

const defaultState = {
	loading: false,
	loadingSave: false,
	apiResult: {
		data: [],
	},
	oldApiResult: {
		data: [],
	},
	alteredMaterials: [],
};

export const useCustomerMaterialStore = create<UseCustomerMaterialStoreType>((set, get) => ({
	...defaultState,

	resetState: () => set(defaultState),

	loadItemsList: async (): Promise<Success<ListType> | BadRequest | StandardError> => {
		set({ loading: true });
		return await new ClientHttp().get<Success<ListType>, BadRequest | StandardError>(
			"/api/v1/customer/services",
			(result: Success<ListType>) => {
				const { status, body } = result;
				if (status !== "success") {
					set({
						apiResult: body,
						loading: false,
					});
					get().loadItemsList();
				} else {
					set({
						apiResult: body,
						oldApiResult: body,
						loading: false,
					});
				}
			},
			(error: BadRequest | StandardError) => {
				showMessage(error);
			}
		);
	},

	editApiResult: (objs: EditApiResultType[]) => {
		set({
			apiResult: {
				data: get().apiResult.data.map((item) => {
					const objsToUpdate = objs.filter((obj) => obj.service_cuid === item.service_cuid);
					if (objsToUpdate.length > 0) {
						let updatedItem = { ...item };
						objsToUpdate.forEach((objToUpdate) => {
							if (objToUpdate.field in updatedItem) {
								updatedItem = { ...updatedItem, [objToUpdate.field]: objToUpdate.value };
							}
						});
						return updatedItem;
					}
					return item;
				}),
			},
		});

		const oldData = get().oldApiResult.data;
		const newData = get().apiResult.data;
		const listCuids = get().alteredMaterials;
		newData.forEach((newItem, index) => {
			const differences = diff(oldData[index], newItem);
			if (differences && !listCuids.includes(newItem.service_cuid)) {
				set({
					alteredMaterials: [...listCuids, newItem.service_cuid],
				});
			} else if (!differences && listCuids.includes(newItem.service_cuid)) {
				set({
					alteredMaterials: listCuids.filter((item) => item !== newItem.service_cuid),
				});
			}
		});
	},

	payloadGenerator: (alteredMaterials: string[]): ServiceUpdateType | void => {
		const data = get().apiResult.data;
		let hasValidationError = false;

		const payloads = alteredMaterials.map((materialCuid) => {
			const rowData = data.find((item) => item.service_cuid === materialCuid);

			if (!rowData) {
				hasValidationError = true;
				return null;
			}
			if (rowData.fixed_monthly === true && rowData.amount === null ? true : false) {
				hasValidationError = true;
				return null;
			}
			return {
				active: rowData.active,
				amount: rowData.amount,
				fixed_monthly: rowData.fixed_monthly,
				service_cuid: rowData.service_cuid,
			};
		});

		if (hasValidationError) {
			set({
				loadingSave: false,
			});
			return notification.error({
				message: "Erro de validação!",
				description: "Por favor, preencha os campos destacados em vermelho.",
				placement: "topRight",
				duration: 5,
			});
		}

		const result = payloads.filter((payload): payload is ServiceItemType => payload !== null);

		return {
			items: result,
		};
	},

	serviceUpdate: async (): Promise<Success<void> | BadRequest | StandardError | void> => {
		set({ loadingSave: true });
		const alteredMaterials = get().alteredMaterials;
		const payload = get().payloadGenerator(alteredMaterials);
		if (payload && payload.items.length > 0) {
			await new ClientHttp().put<ServiceUpdateType, Success<void>, BadRequest | StandardError>(
				`/api/v1/customer/services/setup`,
				payload,
				(result: Success<void>) => {
					let api = get().apiResult.data;
					set({
						apiResult: {
							data: api,
						},
						oldApiResult: {
							data: api,
						},
						alteredMaterials: [],
						loadingSave: false,
					});
					showMessage(result, `${alteredMaterials.length > 1 ? "Alterações salvas" : "Alteração salva"} com sucesso.`);
				},
				(error: StandardError | BadRequest) => {
					showMessage(error);
					set({ loadingSave: false });
				}
			);
		}
	},
}));
