import { create } from "zustand";
import {
  CreateRepositoryTypeType,
  ListRepositoryTypeType,
  LockOrUnlockPayloadType,
  RepositoryTypeListItemType,
  SearchRepositoryTypeType,
  SelectMaterialItemType,
  SimpleListMaterialType,
  UpdateRepositoryTypeType,
  ViewRepositoryTypeType,
} from "./types";

import IsValidMultiSelectedRow from "@utils/table/IsValidMultiSelectedRow";
import { BadRequest, ClientHttp, StandardError, Success } from "@utils/clientHttp";
import showMessage from "@utils/showMessage";
import { SetSort, SortType } from "@utils/table/SetSort";
import D3QSGenerator, { FilterDefinition } from "@utils/D3QSGenerator";

type RepositoryTypeStoreState = {
  apiResult: ListRepositoryTypeType;
  selectedRows: { cuids: string[]; active: boolean };
  filter: SearchRepositoryTypeType;
  pagination: { page: number; pageSize: number };
  sort: { sort_by: string; sort_direction: string };
  loading: boolean;
  setFilter: (filter: SearchRepositoryTypeType) => void;
  setPagination: (page: number, pageSize: number) => void;
  setSort: (newSort: SortType) => void;
  loadRepositoryTypes: () => Promise<void>;
  getRepositoryType: (cuid: string) => Promise<Success<ViewRepositoryTypeType> | StandardError>;
  getMaterialForSelect: () => Promise<SelectMaterialItemType[]>;
  createRepositoryType: (
    repositoryType: CreateRepositoryTypeType
  ) => Promise<Success<{ data: { cuid: string } }> | BadRequest | StandardError>;
  updateRepositoryType: (
    cuid: string,
    repositoryType: UpdateRepositoryTypeType
  ) => Promise<Success<{ status: string }> | BadRequest | StandardError>;
  lockRepositoryType: (cuids: string[]) => Promise<void>;
  unLockRepositoryType: (cuids: string[]) => Promise<void>;
  onMultiSelectedRowChange: (selectedRows: RepositoryTypeListItemType[]) => void;
  resetSelectedRows: () => void;
  resetFilter: () => void;
  resetState: () => void;
};

const defaultSort = {
  sort_by: "rt_description",
  sort_direction: "asc",
};

const operationsFilter: FilterDefinition = {
  rt_description: { op: "lk", type: "string" },
  rt_active: { op: "eq", type: "active" },
};

const defaultState = {
  apiResult: {
    total: 0,
    data: [],
  },
  selectedRows: {
    cuids: [],
    active: false,
  },
  filter: {
    rt_description: "",
    rt_active: "on",
  } as SearchRepositoryTypeType,
  pagination: {
    page: 1,
    pageSize: 10,
  },
  sort: defaultSort,
  loading: false,
};

export const useRepositoryTypesStore = create<RepositoryTypeStoreState>((set, get) => ({
  ...defaultState,
  resetState: () => set(defaultState),
  setFilter: (filter: SearchRepositoryTypeType) => {
    set({
      filter,
      selectedRows: { cuids: [], active: false },
      pagination: { ...get().pagination, page: 1 },
    });
    get().loadRepositoryTypes();
  },
  onMultiSelectedRowChange: (selectedRows: RepositoryTypeListItemType[]): void => {
    IsValidMultiSelectedRow(selectedRows, get, set);
  },
  resetSelectedRows: () => {
    set({ selectedRows: { cuids: [], active: false } });
  },
  resetFilter: () => {
    set({ filter: { rt_description: "", rt_active: "on" } });
    get().loadRepositoryTypes();
  },
  setPagination: (page: number, pageSize: number) => {
    if (pageSize !== get().pagination.pageSize) {
      page = 1;
    }
    set({ pagination: { page, pageSize } });
    get().resetSelectedRows();
    get().loadRepositoryTypes();
  },
  setSort: (newSort: SortType) =>
    SetSort(
      newSort,
      get().sort,
      defaultSort,
      new Map<string, string>([
        ["description", "rt_description"], // Column name from table and column name from api
        ["price", "rt_price"],
        ["active", "rt_active"],
      ]),
      set,
      get().loadRepositoryTypes
    ),
  loadRepositoryTypes: async (): Promise<void> => {
    set({ loading: true });
    let qs = D3QSGenerator(get().filter, operationsFilter, get().pagination, get().sort);

    await new ClientHttp().get<Success<ListRepositoryTypeType>, BadRequest | StandardError>(
      `/api/v1/system/repository-types?${qs}`,
      (result: Success<ListRepositoryTypeType>) => {
        let newPage = get().pagination.page;
        const { body } = result;
        if (body.total > 0 && body.data.length === 0) {
          newPage = newPage - 1;
          set({
            apiResult: body,
            loading: false,
            pagination: { ...get().pagination, page: newPage },
          });
          get().loadRepositoryTypes();
        } else {
          set({ apiResult: body, loading: false });
        }
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
  },
  getRepositoryType: async (cuid: string): Promise<Success<ViewRepositoryTypeType> | StandardError> => {
    return await new ClientHttp().get<Success<ViewRepositoryTypeType>, StandardError>(
      `/api/v1/system/repository-types/${cuid}`
    );
  },
  getMaterialForSelect: async (): Promise<SelectMaterialItemType[]> => {
    return await new ClientHttp().getItensForSelect<
      Success<SimpleListMaterialType>,
      StandardError,
      SelectMaterialItemType[]
    >(
      "/api/v1/system/materials/simple-list",
      (result: Success<SimpleListMaterialType>): SelectMaterialItemType[] => {
        return result.body.data.map((item) => {
          return {
            value: item.cuid,
            label: item.description,
            active: item.active,
          } as SelectMaterialItemType;
        });
      },
      (error: StandardError): SelectMaterialItemType[] => {
        showMessage(error);
        return [] as SelectMaterialItemType[];
      }
    );
  },
  createRepositoryType: async (
    repositoryType: CreateRepositoryTypeType
  ): Promise<Success<{ data: { cuid: string } }> | BadRequest | StandardError> => {
    return await new ClientHttp().post<
      CreateRepositoryTypeType,
      Success<{ data: { cuid: string } }>,
      BadRequest | StandardError
    >("/api/v1/system/repository-types", repositoryType, (result: Success<{ data: { cuid: string } }>) => {
      get().loadRepositoryTypes();
    });
  },
  updateRepositoryType: async (
    cuid: string,
    repositoryType: UpdateRepositoryTypeType
  ): Promise<Success<{ status: string }> | BadRequest | StandardError> => {
    return await new ClientHttp().put<
      UpdateRepositoryTypeType,
      Success<{ status: string }>,
      BadRequest | StandardError
    >(`/api/v1/system/repository-types/${cuid}`, repositoryType, (result: Success<{ status: string }>) => {
      get().loadRepositoryTypes();
    });
  },
  lockRepositoryType: async (cuids: string[]): Promise<void> => {
    await new ClientHttp().patch<
      LockOrUnlockPayloadType,
      Success<{ rows_affected: number; status: string }>,
      BadRequest | StandardError
    >(
      `/api/v1/system/repository-types/lock`,
      { cuids: cuids },
      (result: Success<{ rows_affected: number; status: string }>) => {
        get().resetSelectedRows();
        get().loadRepositoryTypes();
        if (result.body.rows_affected > 1) {
          showMessage(result, "Itens bloqueados com sucesso.");
        } else {
          showMessage(result, "Item bloqueado com sucesso.");
        }
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
  },
  unLockRepositoryType: async (cuids: string[]): Promise<void> => {
    await new ClientHttp().patch<
      LockOrUnlockPayloadType,
      Success<{ rows_affected: number; status: string }>,
      BadRequest | StandardError
    >(
      `/api/v1/system/repository-types/unlock`,
      { cuids: cuids },
      (result: Success<{ rows_affected: number; status: string }>) => {
        get().resetSelectedRows();
        get().loadRepositoryTypes();
        if (result.body.rows_affected > 1) {
          showMessage(result, "Itens desbloqueados com sucesso.");
        } else {
          showMessage(result, "Item desbloqueado com sucesso.");
        }
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
  },
}));
