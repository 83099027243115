import { Form, InputNumber } from "antd";
import { IndexItemType } from "../physical/types";

type ItemIFTPropsType = {
  item: IndexItemType;
};

function ItemIFT({ item }: ItemIFTPropsType) {
  const { id, label, required } = item;
  return (
    <Form.Item
      name={[id, "IFT", "value"]}
      label={label}
      rules={[
        {
          required: required,
          message: `Por favor, preencha o campo ${label.toLowerCase()}`,
        },
      ]}
    >
      <InputNumber type="number" className="w-32" />
    </Form.Item>
  );
}

export default ItemIFT;
