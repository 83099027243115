import D3PDFView from "@components/shared/D3PDF/D3PDFView";
import { Spin } from "antd";
import { useEffect } from "react";
import { useRelocationStore } from "../store";

type PrintRelocationFormPropsType = {
  cuid: string;
  fileName: string;
  printed: boolean;
};

function PrintRelocationForm({ cuid, fileName, printed }: PrintRelocationFormPropsType) {
  const { getUrlPrint, urlPrint, loadingUrlPrint, loadRelocations } = useRelocationStore();

  useEffect(() => {
    (async () => {
      await getUrlPrint(cuid);
    })();
    return () => {
      if (!printed) {
        loadRelocations();
      }
    };
  }, [cuid, getUrlPrint]);

  return (
    <>
      {loadingUrlPrint ? (
        <div className="flex items-center justify-center h-full ">
          <Spin spinning />
        </div>
      ) : (
        <D3PDFView url={urlPrint} fileName={fileName} />
      )}
    </>
  );
}

export default PrintRelocationForm;
