import { useEffect } from "react";
import { Button, Form, Input, Radio, Space } from "antd";

import { useServicesStore } from "../store";
import { SearchServiceType } from "../types";

type SearchServiceFormProps = {
  onCallBackFilter: () => void;
  onCallBackResetFilter: () => void;
};

export const SearchServiceForm = ({ onCallBackFilter, onCallBackResetFilter }: SearchServiceFormProps) => {
  const [form] = Form.useForm<SearchServiceType>();
  const { filter, setFilter } = useServicesStore();

  useEffect(() => {
    form.setFieldsValue(filter);
  }, [form, filter]);

  return (
    <>
      <Form
        className="select-none"
        layout="vertical"
        form={form}
        onFinish={async (values) => {
          if (form.isFieldsTouched(true)) {
            setFilter(values);
          }
          onCallBackFilter();
        }}
        autoComplete="off"
      >
        <Form.Item<SearchServiceType> name="srv_description" label="Descrição">
          <Input />
        </Form.Item>
        <Form.Item<SearchServiceType> name="srv_active" label="Status">
          <Radio.Group value={filter.srv_active}>
            <Radio value={"off"}>Bloqueados</Radio>
            <Radio value={"on"}>Desbloqueados</Radio>
            <Radio value={"all"}>Todos</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item>
          <div className="text-right">
            <Space size="small">
              <Button type="default" danger onClick={onCallBackResetFilter}>
                Limpar Filtro
              </Button>
              <Button type="primary" htmlType="submit">
                Filtrar
              </Button>
            </Space>
          </div>
        </Form.Item>
      </Form>
    </>
  );
};
