import { Form, Input } from "antd";
import { IndexItemType } from "../physical/types";

type ItemTXTPropsType = {
  item: IndexItemType;
};

function ItemTXT({ item }: ItemTXTPropsType) {
  const { id, label, required, size } = item;
  return (
    <Form.Item
      name={[id, "TXT", "value"]}
      label={label}
      rules={[
        {
          required: required,
          message: `Por favor, preencha o campo ${label.toLowerCase()}`,
        },
      ]}
    >
      <Input showCount={size > 0} maxLength={size > 0 ? size : undefined} />
    </Form.Item>
  );
}

export default ItemTXT;
