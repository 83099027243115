import { Form, Input, InputNumber, Radio, Select, Table } from "antd";
import { ColumnType, Data } from "../../data/data";
import { UpdateDocumentTypeType } from "../../types";

function UpdateData() {
  const [form] = Form.useForm<UpdateDocumentTypeType>();
  const updateFrequencyType = Form.useWatch(
    "temporality_permanent_guard",
    form
  );
  return (
    <>
      <Form.Item<UpdateDocumentTypeType>
        name="description"
        label="Descrição"
        rules={[{ required: true, message: "Por favor, insira descrição." }]}
      >
        <Input />
      </Form.Item>
      <Form.Item<UpdateDocumentTypeType> label="Configurações">
        <Table
          className="mb-5 table-service"
          key="key"
          size="small"
          bordered
          pagination={false}
          dataSource={Data}
        >
          <Table.Column<ColumnType>
            showSorterTooltip={false}
            defaultSortOrder="ascend"
            key="description"
            title="Descrição"
            dataIndex="description"
          />

          <Table.Column<ColumnType>
            align="center"
            className="register-service"
            showSorterTooltip={false}
            defaultSortOrder="ascend"
            key="radio"
            title="Opções"
            dataIndex="radio"
            render={(text, record) => (
              <Form.Item
                initialValue={record.radio}
                name={record.name}
                className="register-service"
              >
                {record.radio !== undefined ? (
                  <Radio.Group
                    disabled={
                      record.name === "is_temporality_mandatory" &&
                      updateFrequencyType
                    }
                    size="small"
                    options={[
                      { label: "Sim", value: true },
                      { label: "Não", value: false },
                    ]}
                    value={record.radio}
                    optionType="button"
                    buttonStyle="solid"
                  />
                ) : record.name === "temporality_year" ||
                  record.name === "update_frequency_period" ? (
                  <InputNumber
                    min={0}
                    disabled={
                      record.name === "temporality_year" && updateFrequencyType
                    }
                  />
                ) : (
                  <Select
                    style={{ width: 90 }}
                    options={[
                      {
                        value: "D",
                        label: "Diário",
                      },
                      {
                        value: "M",
                        label: "Mensal",
                      },
                      {
                        value: "Y",
                        label: "Anual",
                      },
                    ]}
                  />
                )}
              </Form.Item>
            )}
          />
        </Table>
      </Form.Item>
    </>
  );
}

export default UpdateData;
