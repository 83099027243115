import { Button, Tabs, Space, Spin } from "antd";
import { usePermissionGroupsStore } from "../../store";
import { useContext, useEffect, useState } from "react";
import { D3DrawerContext } from "@provider/D3DrawerContext";

import { ViewPermissionGroupType } from "../../types";
import showMessage from "@utils/showMessage";
import { Icon } from "@iconify/react";
import TabData from "./TabData";
import TabPermissions from "./TabPermissions";
import { UpdatePermissionGroupForm } from "../UpdatePermissionGroupForm";
import TabCompanyUsers from "./TabCompanyUsers";
import D3Can from "@components/shared/D3Can";

type ViewPermissionGroupFormProps = {
  cuid: string;
};

export const ViewPermissionGroupForm = ({ cuid }: ViewPermissionGroupFormProps) => {
  const { getPermissionGroup } = usePermissionGroupsStore();
  const { openDrawer } = useContext(D3DrawerContext);
  const [loading, setLoading] = useState(true);
  const [tab, setTab] = useState<string>("1");
  const [permissionGroup, setPermissionGroup] = useState<ViewPermissionGroupType | undefined>();

  useEffect(() => {
    (async () => {
      const result = await getPermissionGroup(cuid);
      if (result.status === "success") {
        const viewPermissionGroup = result.body as ViewPermissionGroupType;
        setPermissionGroup(viewPermissionGroup);
        setLoading(false);
      } else {
        showMessage(result);
      }
    })();
  }, [cuid, getPermissionGroup]);

  const { onClose } = useContext(D3DrawerContext);

  return (
    <>
      <Spin spinning={loading}>
        <Tabs
          defaultActiveKey="1"
          className="select-none"
          onTabClick={(tab) => setTab(tab)}
          items={[
            {
              key: "1",
              label: (
                <span className="flex items-center justify-center gap-2 ">
                  <Icon icon="fluent:form-24-filled" className="w-5 h-5" />
                  Dados
                </span>
              ),
              children: <TabData data={permissionGroup} />,
            },
            {
              key: "2",
              label: (
                <span className="flex items-center justify-center gap-2 ">
                  <Icon icon="clarity:group-solid" className="w-[18px] h-[18px]" />
                  Usuários
                </span>
              ),
              children: <TabCompanyUsers data={permissionGroup} />,
            },
            {
              key: "3",
              label: (
                <span className="flex items-center justify-center gap-2 ">
                  <Icon icon="icon-park-outline:permissions" className="w-[18px] h-[18px]" />
                  Permissões
                </span>
              ),
              children: tab === "3" && <TabPermissions data={permissionGroup} />,
            },
          ]}
        />
        <div className="m-2 text-right">
          <Space size="small">
            <Button type="default" danger onClick={() => onClose()}>
              Cancelar
            </Button>
            <D3Can action="update" resource="company_permission_group">
              <Button
                type="primary"
                onClick={() =>
                  openDrawer(<UpdatePermissionGroupForm cuid={cuid} />, "right", 800, "Editar Grupo Permissões")
                }
              >
                Editar
              </Button>
            </D3Can>
          </Space>
        </div>
      </Spin>
    </>
  );
};
