import React, { useEffect, useState } from "react";
import { Table, Checkbox } from "antd";
import { Icon } from "@iconify/react";
import { profileType } from "./type";
import { useTablePermissions } from "./store";

type Column = {
  title: React.ReactNode;
  key: string;
  align?: string;
  dataIndex?: string;
  width?: string | number;
  render?: (text: string, record: any) => React.ReactNode;
};

type D3PermCheckTableProps = {
  profile: profileType;
  value?: string[];
  onChange?: (selectedActions: string[]) => void;
};

const D3PermCheckTable: React.FC<D3PermCheckTableProps> = ({ profile, value, onChange }) => {
  const { apiResult, listPermissions, loading } = useTablePermissions();
  const [selectedActions, setSelectedActions] = useState<string[]>([]);

  useEffect(() => {
    (async () => {
      await listPermissions(profile);
    })();
  }, [profile, listPermissions]);

  useEffect(() => {
    if (value !== undefined && value?.length > 0 && JSON.stringify(value) !== JSON.stringify(selectedActions)) {
      setSelectedActions(value);
    }
  }, [value]);

  useEffect(() => {
    if (onChange) {
      onChange(selectedActions);
    }
  }, [selectedActions]);

  const allPossibleActions = Array.from(
    new Set(apiResult.data.flatMap((item) => item.permissions.map((action) => action.action_group)))
  );

  const entityActionsByName = (entityName: string) =>
    apiResult.data.find((e) => e.key === entityName)?.permissions.map((a) => a.key) || [];

  const isActionChecked = (actionName: string) => selectedActions.includes(actionName);

  const isAllEntityActionsSelected = (entityName: string) => {
    const actions = entityActionsByName(entityName);
    return actions.every((actionName) => isActionChecked(actionName));
  };

  const isSomeEntityActionsSelected = (entityName: string) => {
    const actions = entityActionsByName(entityName);
    return actions.some((actionName) => isActionChecked(actionName)) && !isAllEntityActionsSelected(entityName);
  };

  const isAllEntitiesForActionSelected = (actionLabel: string) => {
    return apiResult.data.every((entity) => {
      const action = entity.permissions.find((a) => a.action_group === actionLabel);
      return !action || isActionChecked(action.key);
    });
  };

  const isSomeEntitiesForActionSelected = (actionLabel: string) => {
    return apiResult.data.some((entity) => {
      const action = entity.permissions.find((a) => a.action_group === actionLabel);
      return action && isActionChecked(action.key);
    });
  };

  const toggleEntityAction = (entityName: string, actionName: string, isChecked: boolean) => {
    setSelectedActions((prev) => {
      if (isChecked) {
        return prev.includes(actionName) ? prev : [...prev, actionName];
      } else {
        return prev.filter((a) => a !== actionName);
      }
    });
  };

  const toggleEntity = (entityName: string, isChecked: boolean) => {
    const actions = entityActionsByName(entityName);
    if (isChecked) {
      setSelectedActions((prev) => [...new Set([...prev, ...actions])]);
    } else {
      setSelectedActions((prev) => prev.filter((a) => !actions.includes(a)));
    }
  };

  const toggleAll = (isChecked: boolean) => {
    if (isChecked) {
      const allActions = apiResult.data.flatMap((entity) => entity.permissions.map((action) => action.key));
      setSelectedActions(allActions);
    } else {
      setSelectedActions([]);
    }
  };

  const columns: Column[] = [
    {
      title: (
        <Checkbox
          checked={apiResult.data.every((e) => isAllEntityActionsSelected(e.key))}
          indeterminate={
            apiResult.data.some((e) => isSomeEntityActionsSelected(e.key)) &&
            !apiResult.data.every((e) => isAllEntityActionsSelected(e.key))
          }
          onChange={(e) => toggleAll(e.target.checked)}
        />
      ),
      dataIndex: "select",
      key: "select",
      render: (_: string, record: { key: string }) => (
        <Checkbox
          checked={isAllEntityActionsSelected(record.key)}
          indeterminate={isSomeEntityActionsSelected(record.key)}
          onChange={(e) => toggleEntity(record.key, e.target.checked)}
        />
      ),
    },
    {
      title: "Telas",
      dataIndex: "permission_group",
      key: "permission_group",
      render: (_, record) => <div className="whitespace-nowrap">{record.permission_group}</div>,
    },

    ...allPossibleActions.map((actionLabel) => ({
      title: (
        <div>
          {actionLabel}
          <br />
          <Checkbox
            checked={isAllEntitiesForActionSelected(actionLabel)}
            indeterminate={!isAllEntitiesForActionSelected(actionLabel) && isSomeEntitiesForActionSelected(actionLabel)}
            onChange={(e) => {
              apiResult.data.forEach((entity) => {
                const action = entity.permissions.find((a) => a.action_group === actionLabel);
                if (action) toggleEntityAction(entity.key, action.key, e.target.checked);
              });
            }}
          />
        </div>
      ),
      key: actionLabel,
      align: "center",
      render: (_: any, record: any) => {
        const action = record.permissions.find((a: any) => a.action_group === actionLabel);
        if (!action)
          return (
            <div className="flex items-center justify-center">
              <Icon icon="gg:block" className="w-6 h-6 text-red-500" />
            </div>
          );
        return (
          <Checkbox
            checked={isActionChecked(action.key)}
            onChange={(e) => toggleEntityAction(record.key, action.key, e.target.checked)}
          />
        );
      },
    })),
  ];
  return (
    <Table
      loading={loading}
      size="small"
      bordered
      dataSource={apiResult.data}
      columns={columns as any}
      pagination={false}
      rowKey="key"
    />
  );
};

export default D3PermCheckTable;
