import D3Can from "@components/shared/D3Can";
import { Icon } from "@iconify/react";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { Button, Col, DatePicker, Form, Row, Select, Space, Spin, Tooltip } from "antd";
import dayjs from "dayjs";
import { useContext, useEffect } from "react";
import { CreateInvoiceType } from "../types";
import { useInvoiceStore } from "../store";

export const CreateInvoiceForm = () => {
  const { createInvoice, loadingCreate, simpleListCustomersSystem, listCustomer, loadingListCustomer } =
    useInvoiceStore();
  const { onClose } = useContext(D3DrawerContext);
  const [form] = Form.useForm<CreateInvoiceType>();

  useEffect(() => {
    (async () => {
      await simpleListCustomersSystem({active: true});
    })();
  }, [simpleListCustomersSystem]);

  const normalize = (value: any) => {
    return dayjs(value as Date).format("YYYY-MM-DD");
  };
  const getValueProps = (value: any) => {
    return { value: value ? dayjs(value) : "" };
  };

  const startDate = Form.useWatch("start_date", form);
  const releaseDate = Form.useWatch("release_date", form);

  return (
    <Spin spinning={loadingListCustomer}>
      <Form
        className="select-none"
        form={form}
        layout="vertical"
        onFinish={async (payload: CreateInvoiceType) => {
          const isCreated = await createInvoice(payload);
          if (isCreated) {
            onClose();
          }
        }}
        autoComplete="off"
      >
        <Form.Item<CreateInvoiceType>
          name="customer_cuids"
          label="Clientes"
          rules={[{ required: true, message: "Por favor, selecione um ou mais clientes." }]}
        >
          <Select
            loading={loadingListCustomer}
            disabled={listCustomer.length === 0 || loadingListCustomer}
            listItemHeight={10}
            allowClear
            removeIcon={
              <Icon
                icon="iconamoon:close-duotone"
                className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
              />
            }
            menuItemSelectedIcon={<Icon icon="eva:checkmark-outline" className="w-5 h-5 text-blue-500" />}
            optionLabelProp="dataSelected"
            showSearch
            mode="multiple"
            maxTagCount="responsive"
            size="middle"
            className="w-full truncate select-none"
            optionFilterProp="children"
            filterOption={(input, option) => option?.dataFilter.toLowerCase().includes(input.toLowerCase())}
          >
            {listCustomer.map(({ active, cuid, fantasy_name, id, name, document }) => (
              <Select.Option
                disabled={!active}
                key={cuid}
                value={cuid}
                dataFilter={`${fantasy_name} ${name}  ${id} ${document}`}
                dataSelected={`${fantasy_name}`}
              >
                <div className="flex items-center justify-between ">
                  <div className="flex items-center gap-2">
                    <Icon icon="fa6-solid:user" height={12} width={12} />
                    <div className="flex flex-col truncate">
                      <span className="truncate">{fantasy_name}</span>
                      <span className="text-[10px] truncate">{name}</span>
                    </div>
                  </div>
                  <span>
                    {active ? (
                      <Tooltip title="Cliente desbloqueado" placement="right">
                        <Icon icon="basil:unlock-solid" className="text-green-500" height={16} width={16} />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Cliente bloqueado" placement="right">
                        <Icon icon="basil:lock-solid" className="text-red-500" height={16} width={16} />
                      </Tooltip>
                    )}
                  </span>
                </div>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Row>
          <Col>
            <Form.Item<CreateInvoiceType>
              name="start_date"
              label="Data inicial"
              className="w-56"
              rules={[
                {
                  required: true,
                  message: "Por favor, insira data inicial.",
                },
              ]}
              normalize={normalize}
              getValueProps={getValueProps}
            >
              <DatePicker format="DD/MM/YYYY" />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item<CreateInvoiceType>
              name="end_date"
              label="Data final"
              rules={[
                {
                  required: true,
                  message: "Por favor, insira data final.",
                },
              ]}
              normalize={normalize}
              getValueProps={getValueProps}
            >
              <DatePicker disabled={!startDate} minDate={dayjs(startDate)} format="DD/MM/YYYY" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Item<CreateInvoiceType>
              name="release_date"
              label="Data de emissão"
              className="w-56"
              rules={[
                {
                  required: true,
                  message: "Por favor, insira data de emissão.",
                },
              ]}
              normalize={normalize}
              getValueProps={getValueProps}
            >
              <DatePicker format="DD/MM/YYYY" />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item<CreateInvoiceType>
              name="due_date"
              label="Data de vencimento"
              rules={[
                {
                  required: true,
                  message: "Por favor, insira data de vencimento.",
                },
              ]}
              normalize={normalize}
              getValueProps={getValueProps}
            >
              <DatePicker disabled={!releaseDate} minDate={dayjs(releaseDate)} format="DD/MM/YYYY" />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <div className="text-right">
            <Space size="small">
              <Button type="default" danger onClick={() => onClose()}>
                Cancelar
              </Button>
              <D3Can action="create" resource="billing">
                <Button loading={loadingCreate} type="primary" htmlType="submit">
                  Cadastrar
                </Button>
              </D3Can>
            </Space>
          </div>
        </Form.Item>
      </Form>
    </Spin>
  );
};
