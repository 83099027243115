import { Form, Input, Radio } from "antd";

import { useEffect } from "react";
import { D3Date } from "@utils/date";
import { ViewCustomerGroupUserType } from "../../types";

type TabFormProps = {
  data: ViewCustomerGroupUserType | undefined;
};

function TabData({ data }: TabFormProps) {
  const [form] = Form.useForm<ViewCustomerGroupUserType>();

  useEffect(() => {
    if (data !== undefined) {
      form.setFieldsValue({
        ...data,
        created_at: D3Date.FormatValueApiToPtBR(data.created_at),
        updated_at: D3Date.FormatValueApiToPtBR(data.updated_at),
      });
    }
  }, [data, form]);

  return (
    <>
      <Form
        className="select-none"
        layout="vertical"
        form={form}
        autoComplete="off"
        disabled
      >
        <Form.Item<ViewCustomerGroupUserType> label="Nome" name="name">
          <Input disabled />
        </Form.Item>
        <div className="grid grid-cols-2 gap-4">
          <Form.Item<ViewCustomerGroupUserType> label="Usuário" name="username">
            <Input disabled />
          </Form.Item>
          <Form.Item<ViewCustomerGroupUserType> label="Login" name="login">
            <Input disabled />
          </Form.Item>
        </div>
        <Form.Item<ViewCustomerGroupUserType> label="E-mail" name="email">
          <Input disabled />
        </Form.Item>
        <Form.Item<ViewCustomerGroupUserType>
          name="change_password_next_login"
          label="Trocar senha no próximo login?"
        >
          <Radio.Group
            disabled
            options={[
              { label: "Sim", value: true },
              { label: "Não", value: false },
            ]}
          />
        </Form.Item>

        <Form.Item<ViewCustomerGroupUserType> name="active" label="Status">
          <Radio.Group
            disabled
            options={[
              { label: "Desbloqueado", value: true },
              { label: "Bloqueado", value: false },
            ]}
          />
        </Form.Item>
        <Form.Item<ViewCustomerGroupUserType>
          label="Criado em"
          name="created_at"
        >
          <Input disabled />
        </Form.Item>
        <Form.Item<ViewCustomerGroupUserType>
          label="Atualizado em"
          name="updated_at"
        >
          <Input disabled />
        </Form.Item>
      </Form>
    </>
  );
}

export default TabData;
