import { create } from "zustand";
import {
  CreateCustomerGroupType,
  CustomerGroupListItemType,
  CustomerType,
  ItemSimpleListCustomerType,
  ListCustomerGroupType,
  LockOrUnlockPayloadType,
  ResponseMutationType,
  SearchCustomerGroupType,
  SimpleListCustomerType,
  UpdateCustomerGroupType,
  UpdateGroupType,
  ViewCustomerGroupType,
} from "./types";

import { useD3SelectEntityStore } from "@components/Layout/D3HeaderBar/components/store";
import D3QSGenerator, { FilterDefinition } from "@utils/D3QSGenerator";
import { BadRequest, ClientHttp, StandardError, Success } from "@utils/clientHttp";
import showMessage from "@utils/showMessage";
import IsValidMultiSelectedRow from "@utils/table/IsValidMultiSelectedRow";
import { SetSort, SortType } from "@utils/table/SetSort";

type CustomerGroupStoreState = {
  apiResult: ListCustomerGroupType;
  selectedRows: { cuids: string[]; active: boolean };
  filter: SearchCustomerGroupType;
  pagination: { page: number; pageSize: number };
  sort: { sort_by: string; sort_direction: string };
  loading: boolean;
  setFilter: (filter: SearchCustomerGroupType) => void;
  setPagination: (page: number, pageSize: number) => void;
  setSort: (newSort: SortType) => void;
  loadCustomerGroups: () => Promise<void>;
  simpleListSystemCustomer: () => Promise<void>;
  loadingCustomers: boolean;
  listCustomers: CustomerType[];
  create: (customerGroup: CreateCustomerGroupType) => Promise<boolean>;
  loadingCreate: boolean;
  update: (cuid: string, customerGroup: UpdateCustomerGroupType) => Promise<boolean>;
  loadingUpdate: boolean;
  updateGroup: (cuid: string, payload: UpdateGroupType) => Promise<boolean>;
  loadingUpdateGroup: boolean;
  viewCustomerGroup: (cuidCustomerGroup: string) => Promise<void>;
  loadingView: boolean;
  view: ViewCustomerGroupType;
  lockCustomerGroup: (cuids: string[]) => Promise<void>;
  unLockCustomerGroup: (cuids: string[]) => Promise<void>;
  onMultiSelectedRowChange: (selectedRows: CustomerGroupListItemType[]) => void;
  resetSelectedRows: () => void;
  resetFilter: () => void;
  resetState: () => void;
};

const defaultSort = {
  sort_by: "cg_description",
  sort_direction: "asc",
};
const operationsFilter: FilterDefinition = {
  cg_description: { op: "lk", type: "string" },
  cg_active: { op: "eq", type: "active" },
};

const defaultState = {
  apiResult: {
    total: 0,
    data: [],
  },
  selectedRows: {
    cuids: [],
    active: false,
  },
  filter: {
    cg_description: "",
    cg_active: "on",
  } as SearchCustomerGroupType,
  pagination: {
    page: 1,
    pageSize: 10,
  },
  sort: defaultSort,
  loading: false,
  loadingView: false,
  view: {} as ViewCustomerGroupType,
  loadingCreate: false,
  loadingUpdate: false,
  loadingCustomers: false,
  listCustomers: [],
  loadingUpdateGroup: false,
};

export const useCustomerGroupsStore = create<CustomerGroupStoreState>((set, get) => ({
  ...defaultState,
  resetState: () => set(defaultState),

  setFilter: (filter: SearchCustomerGroupType) => {
    set({
      filter,
      selectedRows: { cuids: [], active: false },
      pagination: { ...get().pagination, page: 1 },
    });
    get().loadCustomerGroups();
  },

  onMultiSelectedRowChange: (selectedRows: CustomerGroupListItemType[]): void => {
    IsValidMultiSelectedRow(selectedRows, get, set);
  },

  resetSelectedRows: () => {
    set({ selectedRows: { cuids: [], active: false } });
  },

  resetFilter: () => {
    set({ filter: { cg_description: "", cg_active: "on" } });
    get().loadCustomerGroups();
  },

  setPagination: (page: number, pageSize: number) => {
    if (pageSize !== get().pagination.pageSize) {
      page = 1;
    }
    set({ pagination: { page, pageSize } });
    get().resetSelectedRows();
    get().loadCustomerGroups();
  },

  setSort: (newSort: SortType) =>
    SetSort(
      newSort,
      get().sort,
      defaultSort,
      new Map<string, string>([
        ["description", "cg_description"],
        ["active", "cg_active"],
      ]),
      set,
      get().loadCustomerGroups
    ),

  loadCustomerGroups: async (): Promise<void> => {
    set({ loading: true });
    let qs = D3QSGenerator(get().filter, operationsFilter, get().pagination, get().sort);
    await new ClientHttp().get<Success<ListCustomerGroupType>, BadRequest | StandardError>(
      `/api/v1/company/customer-groups?${qs}`,
      (result: Success<ListCustomerGroupType>) => {
        const { body } = result;
        set({ apiResult: body });
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
    set({ loading: false });
  },

  simpleListSystemCustomer: async (): Promise<void> => {
    set({ loadingCustomers: true });
    await new ClientHttp().getItensForSelect<Success<SimpleListCustomerType>, StandardError, void>(
      "/api/v1/company/customers/simple-list",
      (result: Success<SimpleListCustomerType>): void => {
        set({
          listCustomers: result.body.data.map(
            ({ active, cuid, fantasy_name, name, id }: ItemSimpleListCustomerType) => {
              return {
                key: cuid,
                active,
                cuid,
                fantasy_name,
                name,
                id,
              };
            }
          ),
        });
      },
      (error: StandardError): void => {
        showMessage(error);
      }
    );
    set({ loadingCustomers: false });
  },

  viewCustomerGroup: async (cuidCustomerGroup: string): Promise<void> => {
    set({ loadingView: true });
    await new ClientHttp().get<Success<ViewCustomerGroupType>, StandardError>(
      `/api/v1/company/customer-groups/${cuidCustomerGroup}`,
      (result: Success<ViewCustomerGroupType>) => {
        set({ view: result.body, loadingView: false });
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
        set({ loadingView: false });
      }
    );
  },

  create: async (customerGroup: CreateCustomerGroupType): Promise<boolean> => {
    set({ loadingCreate: true });
    const result = await new ClientHttp().post<
      CreateCustomerGroupType,
      Success<ResponseMutationType>,
      BadRequest | StandardError
    >(
      "/api/v1/company/customer-groups",
      customerGroup,
      (result: Success<ResponseMutationType>) => {
        showMessage(result, "Grupo de cliente cadastrado com sucesso.");
        get().loadCustomerGroups();
        useD3SelectEntityStore.getState().loadSimpleListEntity();
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
    set({ loadingCreate: false });
    return result.status === "success";
  },

  update: async (cuid: string, customerGroup: UpdateCustomerGroupType): Promise<boolean> => {
    set({ loadingUpdate: true });
    const result = await new ClientHttp().put<
      UpdateCustomerGroupType,
      Success<{ status: string }>,
      BadRequest | StandardError
    >(
      `/api/v1/company/customer-groups/${cuid}`,
      customerGroup,
      (result: Success<{ status: string }>) => {
        showMessage(result, "Grupo atualizado com sucesso.");
        get().loadCustomerGroups();
        useD3SelectEntityStore.getState().loadSimpleListEntity();
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
    set({ loadingUpdate: false });
    return result.status === "success";
  },

  updateGroup: async (cuid: string, payload: UpdateGroupType): Promise<boolean> => {
    set({ loadingUpdateGroup: true });
    const result = await new ClientHttp().patch<UpdateGroupType, Success<null>, BadRequest | StandardError>(
      `/api/v1/company/customer-groups/${cuid}/transfer-customers`,
      {
        cuids_to_add: payload.cuids_to_add,
        cuids_to_remove: payload.cuids_to_remove,
      },
      (result: Success<null>) => {
        showMessage(result, "Transferência de clientes do grupo  atualizada com sucesso.");
        get().loadCustomerGroups();
        useD3SelectEntityStore.getState().loadSimpleListEntity();
        set({ loadingUpdateGroup: false });
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
        set({ loadingUpdateGroup: false });
      }
    );
    return result.status === "success";
  },

  lockCustomerGroup: async (cuids: string[]): Promise<void> => {
    await new ClientHttp().patch<
      LockOrUnlockPayloadType,
      Success<{ rows_affected: number; status: string }>,
      BadRequest | StandardError
    >(
      `/api/v1/company/customer-groups/lock`,
      { cuids: cuids },
      (result: Success<{ rows_affected: number; status: string }>) => {
        get().resetSelectedRows();
        get().loadCustomerGroups();
        if (result.body.rows_affected > 1) {
          showMessage(result, "Itens bloqueados com sucesso.");
        } else {
          showMessage(result, "Item bloqueado com sucesso.");
        }
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
  },

  unLockCustomerGroup: async (cuids: string[]): Promise<void> => {
    await new ClientHttp().patch<
      LockOrUnlockPayloadType,
      Success<{ rows_affected: number; status: string }>,
      BadRequest | StandardError
    >(
      `/api/v1/company/customer-groups/unlock`,
      { cuids: cuids },
      (result: Success<{ rows_affected: number; status: string }>) => {
        get().resetSelectedRows();
        get().loadCustomerGroups();
        if (result.body.rows_affected > 1) {
          showMessage(result, "Itens desbloqueados com sucesso.");
        } else {
          showMessage(result, "Item desbloqueado com sucesso.");
        }
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
  },
}));
