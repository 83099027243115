import { Icon } from "@iconify/react";
import { Form, FormInstance, Select, Spin, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { useSpecialsStore } from "../store";
import { ItemDocumentTypeType } from "../types";
import IndexersFilter from "./criterion/RenderIndexers";

type SearchDocumentFormProps = {
  form: FormInstance<any>;
};

function SearchCriterionForm({ form }: SearchDocumentFormProps) {
  const { simpleListDocumentType, getListIndexesByDocumentTypeCuid, listIndexers, loadingIndexes, documentsType } =
    useSpecialsStore();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      await simpleListDocumentType();
      setLoading(false);
    })();
  }, [simpleListDocumentType]);

  return (
    <div className="flex flex-col w-full h-full p-2 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100 !important">
      <Form.Item
        className="w-full p-0 my-2"
        name={["filter_document_type", "document_type_cuid"]}
        label="Tipo de Documento"
      >
        <Select
          disabled={documentsType.length === 0}
          loading={loading}
          listItemHeight={10}
          allowClear
          removeIcon={
            <Icon
              icon="iconamoon:close-duotone"
              className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
            />
          }
          menuItemSelectedIcon={<Icon icon="eva:checkmark-outline" className="w-5 h-5 text-blue-500" />}
          optionLabelProp="dataLabel"
          showSearch
          maxTagCount="responsive"
          size="middle"
          className="w-full truncate select-none"
          optionFilterProp="children"
          filterOption={(input, option) => option?.dataFilter.toLowerCase().includes(input.toLowerCase())}
          onClear={() => useSpecialsStore.setState({ listIndexers: [] })}
          onChange={async (e) => {
            if (e) {
              await getListIndexesByDocumentTypeCuid(e);
            }
          }}
        >
          {documentsType.map(({ active, cuid, description, temporality_permanent_guard, temporality_year }) => (
            <Select.Option
              disabled={!active}
              key={cuid}
              value={cuid}
              dataFilter={`${description}`}
              dataLabel={`${description} - ${
                temporality_permanent_guard === true
                  ? "Tempo indeterminado"
                  : `${temporality_year} ${temporality_year > 1 ? "Anos" : "Ano"}`
              }`}
            >
              <div className="flex items-center justify-between ">
                <div className="flex flex-col truncate">
                  <span className="truncate">{`${description} - ${
                    temporality_permanent_guard === true
                      ? "Tempo indeterminado"
                      : `${temporality_year} ${temporality_year > 1 ? "Anos" : "Ano"}`
                  }`}</span>
                </div>
                <span>
                  {active ? (
                    <Tooltip title="Tipo de documento desbloqueado" placement="right">
                      <Icon icon="basil:unlock-solid" className="text-green-500" height={16} width={16} />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Tipo de documento bloqueado" placement="right">
                      <Icon icon="basil:lock-solid" className="text-red-500 " height={16} width={16} />
                    </Tooltip>
                  )}
                </span>
              </div>
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      {loadingIndexes ? (
        <div className="flex items-center justify-center h-full">
          <Spin />
        </div>
      ) : !loadingIndexes && listIndexers.length > 0 ? (
        <IndexersFilter form={form} attribute={["filter_document_type", "indexes_values"]} />
      ) : null}
    </div>
  );
}

export default SearchCriterionForm;
