import { Button, Form, Input, Select, Space, Spin, Tooltip } from "antd";

import D3Can from "@components/shared/D3Can";
import { Icon } from "@iconify/react";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import showMessage from "@utils/showMessage";
import { useContext, useEffect, useState } from "react";
import { useProtocolOrdersStore } from "../store";
import { CreateProtocolOrderType, ItemSimpleListDepartmentType, UserDepartmentItemType } from "../types";
const { TextArea } = Input;
export const CreateProtocolOrderForm = () => {
  const { createProtocolOrder, simpleListUsersByDepartmentCuid, simpleListDepartmentCustomer } =
    useProtocolOrdersStore();
  const { onClose } = useContext(D3DrawerContext);
  const [form] = Form.useForm<CreateProtocolOrderType>();

  const [departments, setDepartments] = useState<ItemSimpleListDepartmentType[]>([]);
  const [usersDepartment, setUsersDepartment] = useState<UserDepartmentItemType[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      setDepartments(await simpleListDepartmentCustomer());
      setLoading(false);
    })();
  }, [simpleListDepartmentCustomer]);

  async function GetUsers(cuid: string) {
    setLoadingUsers(true);
    const usersDepartment = await simpleListUsersByDepartmentCuid(cuid);
    setUsersDepartment(usersDepartment);
    setLoadingUsers(false);
  }

  return (
    <>
      <Spin spinning={loading}>
        <Form
          className="select-none"
          form={form}
          layout="vertical"
          onFinish={async (payload: CreateProtocolOrderType) => {
            setLoading(true);
            payload.content = payload.content.trim();
            const result = await createProtocolOrder(payload);
            if (result.status === "success") {
              showMessage(result, "Ordem de protocolo cadastrada com sucesso.");
              onClose();
            } else {
              showMessage(result);
            }
            setLoading(false);
          }}
          autoComplete="off"
        >
          <Form.Item<CreateProtocolOrderType>
            name="department_cuid"
            label="Departamento"
            rules={[{ required: true, message: "Por favor, selecione departamento." }]}
          >
            <Select
              listItemHeight={10}
              allowClear
              removeIcon={
                <Icon
                  icon="iconamoon:close-duotone"
                  className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
                />
              }
              menuItemSelectedIcon={<Icon icon="eva:checkmark-outline" className="w-5 h-5 text-blue-500" />}
              optionLabelProp="dataLabel"
              showSearch
              maxTagCount="responsive"
              size="middle"
              className="w-full truncate select-none"
              optionFilterProp="children"
              onClear={() => {
                form.setFieldValue("requester_cuid", null);
                setUsersDepartment([]);
              }}
              onChange={(e) => (e !== undefined ? GetUsers(e) : null)}
              filterOption={(input, option) => option?.dataFilter.toLowerCase().includes(input.toLowerCase())}
            >
              {departments.map(({ abbreviation, active, cuid, description }) => (
                <Select.Option
                  disabled={!active}
                  key={cuid}
                  value={cuid}
                  dataFilter={`${abbreviation} - ${description}`}
                  dataLabel={`${abbreviation} - ${description}`}
                >
                  <div className="flex items-center justify-between ">
                    <div className="flex flex-col truncate">
                      <span className="truncate">{`${abbreviation} - ${description}`}</span>
                    </div>
                    <span>
                      {active ? (
                        <Tooltip title="Departamento desbloqueado" placement="right">
                          <Icon icon="basil:unlock-solid" className="text-green-500" height={16} width={16} />
                        </Tooltip>
                      ) : (
                        <Tooltip title="Departamento bloqueado" placement="right">
                          <Icon icon="basil:lock-solid" className="text-red-500" height={16} width={16} />
                        </Tooltip>
                      )}
                    </span>
                  </div>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item<CreateProtocolOrderType> label="Usuário requisitante" name="requester_cuid">
            <Select
              disabled={usersDepartment.length < 1}
              loading={loadingUsers}
              listItemHeight={10}
              listHeight={250}
              allowClear
              removeIcon={
                <Icon
                  icon="iconamoon:close-duotone"
                  className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
                />
              }
              menuItemSelectedIcon={<Icon icon="eva:checkmark-outline" className="w-5 h-5 text-blue-500" />}
              optionLabelProp="dataLabel"
              showSearch
              maxTagCount="responsive"
              size="middle"
              className="w-full truncate select-none"
              optionFilterProp="children"
              filterOption={(input, option) => option?.dataFilter.toLowerCase().includes(input.toLowerCase())}
            >
              {usersDepartment.map(({ active, cuid, login, name, username }) => (
                <Select.Option
                  disabled={!active}
                  key={cuid}
                  value={cuid}
                  dataFilter={`${name} ${username}`}
                  dataLabel={`${name}`}
                >
                  <div className="flex items-center justify-between ">
                    <div className="flex flex-col truncate">
                      <span className="truncate">{name}</span>
                      <span className="text-[10px] truncate">{username}</span>
                    </div>
                    <span>
                      {active ? (
                        <Tooltip title="Usuário desbloqueado" placement="right">
                          <Icon icon="basil:unlock-solid" className="text-green-500" height={16} width={16} />
                        </Tooltip>
                      ) : (
                        <Tooltip title="Usuário bloqueado" placement="right">
                          <Icon icon="basil:lock-solid" className="text-red-500" height={16} width={16} />
                        </Tooltip>
                      )}
                    </span>
                  </div>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item<CreateProtocolOrderType>
            label="Descrição"
            name="content"
            rules={[
              { required: true, message: "Por favor, insira descrição." },
              { min: 10, message: "Por favor, a descrição deve ter no mínimo 10 caracteres." },
              { max: 3100, message: "Por favor, a descrição deve ter no máximo 3100 caracteres." },
            ]}
          >
            <TextArea autoSize={{ minRows: 5 }} minLength={10} maxLength={3100} showCount />
          </Form.Item>
          <Form.Item>
            <div className="text-right">
              <Space size="small">
                <Button type="default" danger onClick={() => onClose()}>
                  Cancelar
                </Button>
                <D3Can action="create" resource="order_protocol">
                  <Button type="primary" htmlType="submit">
                    Cadastrar
                  </Button>
                </D3Can>
              </Space>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};
