import { Form, Select } from "antd";
import { IndexItemType } from "../physical/types";
import { Icon } from "@iconify/react";

type ItemSELPropsType = {
  item: IndexItemType;
};

function ItemSEL({ item }: ItemSELPropsType) {
  const { id, label, required, list_of_key_and_value } = item;
  return (
    <Form.Item
      name={[id, "SEL", "value"]}
      label={label}
      rules={[
        {
          required: required,
          message: `Por favor, preencha o campo ${label.toLowerCase()}`,
        },
      ]}
    >
      <Select
        allowClear
        showSearch
        optionFilterProp="children"
        removeIcon={
          <Icon
            icon="iconamoon:close-duotone"
            className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
          />
        }
        menuItemSelectedIcon={<Icon icon="eva:checkmark-outline" className="w-5 h-5 text-blue-500" />}
        className="w-full truncate select-none"
        filterOption={(input, option) => option?.dataFilter.toLowerCase().includes(input.toLowerCase())}
      >
        {list_of_key_and_value.map(({ key, value }) => (
          <Select.Option dataFilter={value} key={key}>
            {value}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
}

export default ItemSEL;
