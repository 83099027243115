import { useState } from "react";

type SelectColumnProps = {
  column: number;
  click: (row: number, newValue: boolean) => void;
};

const SelectColumn: React.FC<SelectColumnProps> = ({ column, click }) => {
  const [selected, setSelected] = useState<boolean>(false);
  return (
    <div
      className="p-2 border-2 hover:text-blue-500"
      onClick={() => {
        setSelected(!selected);
        click(column, !selected);
      }}
      style={{ cursor: "pointer" }}
    >{`V${column}`}</div>
  );
};

export default SelectColumn;
