import { BadRequest, ClientHttp, StandardError, Success } from "@utils/clientHttp";
import { create } from "zustand";
import {
  ItemListContactType,
  ItemSimpleListContactTypesType,
  ItemSimpleListUsersCustomerType,
  ListContactType,
  MutationContactType,
  ResponseMutationType,
  SimpleListContactTypesType,
  SimpleListUsersCustomerType,
} from "./type";
import showMessage from "@utils/showMessage";

type ContactStoreType = {
  loadContacts: () => Promise<void>;
  loading: boolean;
  listContacts: ItemListContactType[];
  createContact: (payload: MutationContactType) => Promise<boolean>;
  loadingCreated: boolean;
  updateContact: (contactCuid: string, payload: MutationContactType) => Promise<boolean>;
  loadingUpdated: boolean;
  deleteContact: (contactCuid: string) => Promise<boolean>;
  loadingDeleted: boolean;
  simpleListContactTypes: () => Promise<void>;
  listContactTypes: ItemSimpleListContactTypesType[];
  loadingContactTypes: boolean;
  simpleListUsersCustomer: () => Promise<void>;
  listUsersCustomer: ItemSimpleListUsersCustomerType[];
  loadingUsersCustomer: boolean;
};

const defaultState = {
  loading: false,
  listContacts: [],
  loadingCreated: false,
  loadingUpdated: false,
  loadingDeleted: false,
  listContactTypes: [],
  loadingContactTypes: false,
  listUsersCustomer: [],
  loadingUsersCustomer: false,
};

export const useContactStore = create<ContactStoreType>((set, get) => ({
  ...defaultState,
  loadContacts: async (): Promise<void> => {
    set({ loading: true });
    await new ClientHttp().get<Success<ListContactType>, BadRequest | StandardError>(
      "/api/v1/customer/contacts",
      (result: Success<ListContactType>) => {
        set({
          listContacts: result.body.data,
          loading: false,
        });
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
        set({ loading: false });
      }
    );
  },
  createContact: async (payload: MutationContactType): Promise<boolean> => {
    set({ loadingCreated: true });
    const result = await new ClientHttp().post<
      MutationContactType,
      Success<ResponseMutationType>,
      BadRequest | StandardError
    >(
      "/api/v1/customer/contacts",
      payload,
      (result: Success<ResponseMutationType>) => {
        showMessage(result, "Contato criado com sucesso.");
        set({ loadingCreated: false });
        get().loadContacts();
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
        set({ loadingCreated: false });
      }
    );
    return result.status === "success";
  },
  updateContact: async (contactCuid: string, payload: MutationContactType): Promise<boolean> => {
    set({ loadingUpdated: true });
    const result = await new ClientHttp().put<
      MutationContactType,
      Success<ResponseMutationType>,
      BadRequest | StandardError
    >(
      `/api/v1/customer/contacts/${contactCuid}`,
      payload,
      (result: Success<ResponseMutationType>) => {
        showMessage(result, "Contato atualizado com sucesso.");
        set({ loadingUpdated: false });
        get().loadContacts();
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
        set({ loadingUpdated: false });
      }
    );
    return result.status === "success";
  },
  deleteContact: async (contactCuid: string): Promise<boolean> => {
    set({ loadingDeleted: true });
    const result = await new ClientHttp().delete<Success<void>, StandardError>(
      `/api/v1/customer/contacts/${contactCuid}`,
      (result: Success<void>) => {
        showMessage(result, "Contato excluído com sucesso.");
        set({ loadingDeleted: false });
        get().loadContacts();
      },
      (result: StandardError) => {
        set({ loadingDeleted: false });
        showMessage(result);
      }
    );
    return result.status === "success";
  },
  simpleListContactTypes: async (): Promise<void> => {
    set({ loadingContactTypes: true });
    await new ClientHttp().get<Success<SimpleListContactTypesType>, BadRequest | StandardError>(
      "/api/v1/system/contact-types?q=profile:eq=CTM",
      (result: Success<SimpleListContactTypesType>) => {
        set({
          listContactTypes: result.body.data,
          loadingContactTypes: false,
        });
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
        set({ loadingContactTypes: false });
      }
    );
  },
  simpleListUsersCustomer: async (): Promise<void> => {
    set({ loadingUsersCustomer: true });
    await new ClientHttp().get<Success<SimpleListUsersCustomerType>, BadRequest | StandardError>(
      "/api/v1/customer/users/simple-list",
      (result: Success<SimpleListUsersCustomerType>) => {
        set({
          listUsersCustomer: result.body.data,
          loadingUsersCustomer: false,
        });
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
        set({ loadingUsersCustomer: false });
      }
    );
  },
}));
