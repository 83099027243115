import { Form, Input } from "antd";
import { useState } from "react";
import { IndexItemType } from "../physical/types";
import cpfValidation from "@utils/formInputValidation/cpfValidation";
import cnpjValidation from "@utils/formInputValidation/cnpjValidation";

type ItemCACPropsType = {
  item: IndexItemType;
};

const maskCPF = (value: any) => {
  if (!value) return value;
  return value
    .replace(/\D/g, "") // Remove todos os caracteres não numéricos
    .replace(/(\d{3})(\d)/, "$1.$2") // Adiciona o primeiro ponto
    .replace(/(\d{3})(\d)/, "$1.$2") // Adiciona o segundo ponto
    .replace(/(\d{3})(\d{1,2})$/, "$1-$2"); // Adiciona o traço
};

const maskCNPJ = (value: any) => {
  if (!value) return value;
  return value
    .replace(/\D/g, "") // Remove todos os caracteres não numéricos
    .replace(/(\d{2})(\d)/, "$1.$2") // Adiciona o primeiro ponto
    .replace(/(\d{3})(\d)/, "$1.$2") // Adiciona o segundo ponto
    .replace(/(\d{3})(\d)/, "$1/$2") // Adiciona a barra
    .replace(/(\d{4})(\d{1,2})$/, "$1-$2"); // Adiciona o traço
};

function ItemCAC({ item }: ItemCACPropsType) {
  const [count, setCount] = useState<number>(0);
  const { id, label, required } = item;

  const validateCPFOrCNPJ = (rule: any, value: any) => {
    if (!value) {
      return Promise.resolve();
    }
    const numericValue = value.replace(/\D/g, "");
    if (numericValue.length <= 11 && cpfValidation(value)) {
      return Promise.resolve();
    }
    if (numericValue.length > 11 && cnpjValidation(value)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error(numericValue.length <= 11 ? "CPF inválido." : "CNPJ inválido."));
  };

  return (
    <Form.Item
      getValueProps={(value) => ({
        value: count > 14 ? maskCNPJ(value) : maskCPF(value),
      })}
      normalize={(value) => value.replace(/[^0-9]/g, "")}
      name={[id, "CAC", "value"]}
      label={label}
      rules={[
        {
          required: required,
          message: `Campo obrigatório`,
        },
        {
          validator: validateCPFOrCNPJ,
        },
      ]}
    >
      <Input
        maxLength={18}
        onChange={(e: any) => {
          const value = e.target.value;
          setCount(value.length);
        }}
      />
    </Form.Item>
  );
}

export default ItemCAC;
