import { D3DrawerContext } from "@provider/D3DrawerContext";
import showMessage from "@utils/showMessage";
import { Button, Form, Input, Space } from "antd";
import { useContext, useEffect, useState } from "react";
import useFolderStore from "./store";
import { RenameFolderType } from "./types";

type RenameFolderFormType = {
  oldName: string;
  cuid: string;
};

function RenameFolderForm({ cuid, oldName }: RenameFolderFormType) {
  const { onClose } = useContext(D3DrawerContext);
  const { renameFolder } = useFolderStore();
  const [form] = Form.useForm<RenameFolderType>();
  const [loading, setLoading] = useState<Boolean>(false);

  useEffect(() => {
    (async () => {
      form.setFieldValue("new_folder_name", oldName);
    })();
  }, [cuid]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={async (payload: RenameFolderType) => {
        setLoading(true);
        const isSuccess = await renameFolder(cuid, payload);
        if (isSuccess) {
          onClose();
        }
        setLoading(false);
      }}
    >
      <Form.Item<RenameFolderType>
        name="new_folder_name"
        label="Nome da pasta"
        rules={[{ required: true, message: "Por favor, insira o nome da pasta." }]}
      >
        <Input />
      </Form.Item>
      <Form.Item>
        <div className="text-right">
          <Space size="small">
            <Button type="default" danger onClick={() => onClose()}>
              Cancelar
            </Button>

            <Button loading={loading as boolean} type="primary" htmlType="submit">
              Salvar
            </Button>
          </Space>
        </div>
      </Form.Item>
    </Form>
  );
}

export default RenameFolderForm;
