import { useContext } from "react";
import { Button, ColorPicker, Form, Input, Space } from "antd";
import { useMarkersStore } from "../store";
import { CreateMarkerType } from "../types";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import D3Can from "@components/shared/D3Can";
import { Color } from "antd/es/color-picker";

export const CreateMarkerForm = () => {
  const { createMarker, loadingCreate } = useMarkersStore();
  const { onClose } = useContext(D3DrawerContext);
  const [form] = Form.useForm<CreateMarkerType>();

  return (
    <Form
      className="select-none"
      form={form}
      layout="vertical"
      onFinish={async (payload: CreateMarkerType) => {
        const isCreated = await createMarker(payload);
        if (isCreated) {
          onClose();
        }
      }}
      disabled={loadingCreate}
      autoComplete="off"
    >
      <Form.Item<CreateMarkerType>
        name="key"
        label="Nome"
        rules={[{ required: true, message: "Por favor, insira descrição." }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="color"
        label="Cor"
        initialValue={"#1677FF"}
        rules={[{ required: true, message: "Por favor, selecione uma cor." }]}
        normalize={(value: Color) => (value ? value.toHexString() : "#1677FF")}
        getValueProps={(value: string) => ({ value })}
      >
        <ColorPicker defaultFormat="hex" format="hex" />
      </Form.Item>
      <Form.Item>
        <div className="text-right">
          <Space size="small">
            <Button type="default" danger onClick={() => onClose()}>
              Cancelar
            </Button>
            <D3Can action="create" resource="customer_marker">
              <Button loading={loadingCreate} type="primary" htmlType="submit">
                Cadastrar
              </Button>
            </D3Can>
          </Space>
        </div>
      </Form.Item>
    </Form>
  );
};
