import { Form } from "antd";
import { MaskedInput } from "antd-mask-input";
import { NamePath } from "antd/es/form/interface";

type FormItemPhoneNumberProps = {
  required?: boolean;
  name: NamePath;
  label?: string;
  value?: string;
  disabled?: boolean;
};

function FormItemPhone({
  required = false,
  name,
  label = "Telefone",
  value,
  disabled = false,
}: FormItemPhoneNumberProps) {
  return (
    <Form.Item
      label={label}
      name={name}
      rules={[{ required: required, message: `Por favor, insira o número do ${label.toLowerCase()}` }]}
      getValueProps={(value) => ({ value: value })}
      normalize={(value) => value.replace(/[^\d]/g, "")}
    >
      <MaskedInput mask="(00) 0 0000-0000" disabled={disabled} value={value} />
    </Form.Item>
  );
}

export default FormItemPhone;
