import { Form } from "antd";
import { NamePath } from "antd/es/form/interface";
import { FormInstance } from "antd/lib";
import { IndexItemType } from "../../types";
import RenderIndexer from "./components/Items";
import { useEffect } from "react";
import { useSpecialsStore } from "../../store";

type IndexersFilterProps = {
  form: FormInstance<any>;
  attribute: NamePath | NamePath[];
};

function RenderIndexers({ form, attribute }: IndexersFilterProps) {
  const { filter, listIndexers } = useSpecialsStore();

  useEffect(() => {
    if (listIndexers.length > 0) {
      const setIndexers = listIndexers.map((item: IndexItemType) => {
        const indexer =
          filter.filter_document_type.indexes_values.length > 0
            ? filter.filter_document_type.indexes_values.find((i) => i.id === item.id)
            : undefined;
        if (indexer) {
          return indexer;
        } else {
          return {
            id: item.id,
            key: item.system_index_type.key,
            search_option: item.default_search_option,
            start_value: "",
            value: "",
            values: [],
            end_value: "",
          };
        }
      });
      form.setFieldsValue({
        filter_document_type: {
          indexes_values: setIndexers,
        },
      });
    }
  }, [listIndexers, form, filter]);

  return (
    <Form.List  name={attribute}>
      {(fields) => {
        return (
          <>
            {fields.map(({ key, name, ...restField }) => {
              const { id } = form.getFieldValue(attribute)[key];
              const index = listIndexers.find((item) => item.id === id);
              return index ? (
                <Form.Item key={key} label={index.label} className="w-full p-0 my-2">
                  <RenderIndexer {...restField} name={name} item={index} />
                </Form.Item>
              ) : (
                <></>
              );
            })}
          </>
        );
      }}
    </Form.List>
  );
}

export default RenderIndexers;
