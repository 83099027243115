import { useContext, useEffect, useState } from "react";
import NewIndexersForm from "./components/NewIndexersForm";
import OldIndexersForm from "./components/OldIndexersForm";
import { D3DrawerContext } from "@provider/D3DrawerContext";

type EditIndexersFormProps = {
  documentCuid: string;
};

function EditIndexersForm({ documentCuid }: EditIndexersFormProps) {
  const [show, setShow] = useState<boolean>(false);
  const { setSize } = useContext(D3DrawerContext);

  useEffect(() => {
    setSize(show ? 1200 : 600);
  }, [show]);

  return (
    <div className="flex flex-col h-full overflow-hidden">
      <div className={`grid grid-cols-${show ? "2" : "1"} w-full h-full overflow-hidden divide-x`}>
        <OldIndexersForm show={show} setShow={setShow} documentCuid={documentCuid} />
        <NewIndexersForm show={show} documentCuid={documentCuid} />
      </div>
    </div>
  );
}

export default EditIndexersForm;
