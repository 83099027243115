import React, { useEffect, useId, useRef } from "react";
import { Table } from "antd";
import cellStore from "./store";
import "./components/CustomStyles.css";
import Title from "./components/Title";
import { useParams } from "react-router-dom";
import FooterCells from "./components/FooterCells";

const CustomerCellsView: React.FC = () => {
  let { storageCuid, shelfCuid } = useParams<{
    storageCuid: string;
    shelfCuid: string;
  }>();

  const { init, columns, dataSource, loading } = cellStore();
  const tableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (tableRef.current) {
      const scrollContainer = tableRef.current.querySelector(".ant-table-container");
      if (scrollContainer) {
        scrollContainer.scrollTop = scrollContainer.scrollHeight;
      }
    }
  }, [columns]);

  useEffect(() => {
    if (storageCuid && shelfCuid) {
      init(storageCuid, shelfCuid);
    }
  }, [storageCuid, shelfCuid]);

  return (
    <div className="d3-table" ref={tableRef}>
      <Table
        className="d3-table-cell"
        bordered
        title={() => <Title />}
        loading={loading}
        size="small"
        pagination={false}
        columns={columns}
        dataSource={dataSource}
        footer={() => <FooterCells />}
      />
    </div>
  );
};

export default CustomerCellsView;
