import { BadRequest, ClientHttp, StandardError, Success } from "@utils/clientHttp";
import showMessage from "@utils/showMessage";
import { create } from "zustand";
import { DefaultPricesType, ResultRequestType, UpdateDefaultPricesType } from "./types";

type useDefaultPricesStoreType = {
	getDefaultPrices: () => Promise<Success<DefaultPricesType> | StandardError>;
	updateDefaultPrices: (payload: UpdateDefaultPricesType) => Promise<boolean>;
	loading: boolean;
};

const defaultState = {
	loading: false,
};

export const useDefaultPricesStore = create<useDefaultPricesStoreType>((set, get) => ({
	...defaultState,
	convertPrice: (data: number | string): number | string => {
		return data === 0 ? "0,00" : (data as number).toFixed(2).replace(".", ",");
	},
	getDefaultPrices: async (): Promise<Success<DefaultPricesType> | StandardError> => {
		set({ loading: true });
		return await new ClientHttp().get<Success<DefaultPricesType>, StandardError>(
			`/api/v1/company/table-prices`,
			(result: Success<DefaultPricesType>) => {
				set({ loading: false });
			},
			(error: BadRequest | StandardError) => {
				showMessage(error);
				set({ loading: false });
			}
		);
	},
	updateDefaultPrices: async (payload: UpdateDefaultPricesType): Promise<boolean> => {
		const result = await new ClientHttp().put<UpdateDefaultPricesType, Success<ResultRequestType>, BadRequest | StandardError>(
			"/api/v1/company/table-prices",
			payload,
			(result: Success<ResultRequestType>) => {
				showMessage(result, "Preços padrão atualizado com sucesso. ");
			},
			(error: BadRequest | StandardError) => {
				showMessage(error);
			}
		);
		return result.status === "success";
	},
}));
