import i18n from "i18next";

const FormatValueApiToPtBR = (value?: Date): string => {
  if (value) {
    const language = i18n.language === "en" ? "en-US" : "pt-BR";
    const timeZone =
      i18n.language === "en" ? "America/New_York" : "America/Sao_Paulo";
    return new Intl.DateTimeFormat(language, {
      dateStyle: "full",
      timeStyle: "short",
      timeZone: timeZone,
    }).format(new Date(String(value)));
  }
  return "";
};

export const D3Date = {
  FormatValueApiToPtBR: FormatValueApiToPtBR,
};
