import { Button, Form, List, Radio, Space, Spin, Tag } from "antd";
import { CompanyAddressType, ItemCustomerAddressType } from "../types";
import { useMaterialOrdersStore } from "../store";
import D3Can from "@components/shared/D3Can";
import { useCallback, useContext, useEffect, useState } from "react";
import { D3DrawerContext } from "@provider/D3DrawerContext";

type ServeFormProps = {
  materialOrderCuid: string;
};

function ServeForm({ materialOrderCuid }: ServeFormProps) {
  const { onClose } = useContext(D3DrawerContext);
  const { savedMaterialOrder, simpleListAddressesCustomer, loadingServe } = useMaterialOrdersStore();
  const [form] = Form.useForm<CompanyAddressType>();
  const [customerAddress, setCustomerAddress] = useState<ItemCustomerAddressType[]>([]);
  const [loading, setLoading] = useState(true);

  const fetchCustomerAddresses = useCallback(async () => {
    setLoading(true);
    const addresses = await simpleListAddressesCustomer();
    setCustomerAddress(addresses);
    setLoading(false);
  }, [simpleListAddressesCustomer]);

  useEffect(() => {
    (async () => await fetchCustomerAddresses())();
  }, [fetchCustomerAddresses]);

  const handleFinish = useCallback(
    async (value: CompanyAddressType) => {
      await savedMaterialOrder(materialOrderCuid, value);
      onClose();
    },
    [materialOrderCuid, savedMaterialOrder, onClose]
  );

  const addressFilter = (address: Partial<ItemCustomerAddressType>) =>
    `${`${address.street_address}, nº ${address.street_number}${address.complement ? `, ${address.complement}` : ""}, ${
      address.neighborhood
    } , ${address.city} - ${address.state}, Cep ${address.zip_code}`}`;

  return (
    <Spin spinning={loading}>
      <Form form={form} layout="vertical" onFinish={handleFinish}>
        <Form.Item
          label="Endereço de retirada dos materiais"
          name="pickup_address_cuid"
          rules={[
            {
              required: true,
              message: "Por favor, selecione o endereço.",
            },
          ]}
        >
          <List bordered className="flex flex-col h-full" itemLayout="horizontal" dataSource={customerAddress}>
            <Radio.Group className="w-full" name="radiogroup">
              {customerAddress.map(({ active, description, is_default, cuid, ...rest }: ItemCustomerAddressType) => (
                <List.Item key={cuid}>
                  <List.Item.Meta
                    avatar={
                      <div className="flex min-h-[70px]">
                        <Radio value={cuid} />
                      </div>
                    }
                    title={
                      <div className="flex gap-2 ">
                        <span>{description}</span> {is_default && <Tag color="success">Padrão</Tag>}
                      </div>
                    }
                    description={addressFilter(rest)}
                  />
                </List.Item>
              ))}
            </Radio.Group>
          </List>
        </Form.Item>
        <Form.Item>
          <div className="text-right">
            <Space size="small">
              <Button type="default" danger onClick={() => onClose()}>
                Cancelar
              </Button>
              <D3Can action="serve" resource="order_material">
                <Button loading={loadingServe} type="primary" htmlType="submit">
                  Baixar
                </Button>
              </D3Can>
            </Space>
          </div>
        </Form.Item>
      </Form>
    </Spin>
  );
}

export default ServeForm;
