import { Form, Spin, Table, Tag } from "antd";
import { ViewCompanyUserType } from "../../types";
import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";

type TabUsersProps = {
  data: ViewCompanyUserType | undefined;
};

function TabUsers({ data }: TabUsersProps) {
  const [form] = Form.useForm<ViewCompanyUserType>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (data !== undefined) {
      form.setFieldsValue(data);
    }
    setLoading(false);
  }, [data, form]);

  return (
    <>
    <Spin spinning={loading}>
      <Form layout="vertical" form={form}>
        <Form.Item<ViewCompanyUserType> label="Grupos do usuário" name="company_permissions_groups">
          <Table
            rowKey={(record) => record.cuid}
            size="small"
            pagination={false}
            dataSource={form.getFieldValue("company_permissions_groups")}
            bordered
          >
            <Table.Column<ViewCompanyUserType>
              showSorterTooltip={false}
              defaultSortOrder="ascend"
              key="name"
              title="Nome"
              dataIndex="name"
            />
            <Table.Column<ViewCompanyUserType>
              showSorterTooltip={false}
              key="active"
              title="Status"
              dataIndex="active"
              render={(value, permissiongroup, index) =>
                permissiongroup.active ? (
                  <Tag
                    className="flex items-center justify-start gap-1 max-w-max"
                    icon={
                      <Icon icon="basil:unlock-solid" height={14} width={14} />
                    }
                    color="success"
                  >
                    Desbloqueado
                  </Tag>
                ) : (
                  <Tag
                    className="flex items-center justify-start gap-1 max-w-max"
                    icon={
                      <Icon icon="basil:lock-solid" height={14} width={14} />
                    }
                    color="error"
                  >
                    Bloqueado
                  </Tag>
                )
              }
            />
          </Table>
        </Form.Item>
      </Form>
      </Spin>
    </>
  );
}

export default TabUsers;
