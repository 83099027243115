import { BadRequest, ClientHttp, StandardError, Success } from "@utils/clientHttp";
import { create } from "zustand";
import showMessage from "@utils/showMessage";
import { CreateNoteType, ListNotesType, SearchNoteType, UpdateNoteType, ViewNoteType } from "./types";
import D3QSGenerator, { FilterDefinition } from "@utils/D3QSGenerator";
import { SetSort, SortType } from "@utils/table/SetSort";

type NoteStoreType = {
  loadNotes: () => Promise<void>;
  loading: boolean;
  dataNotes: ListNotesType;
  createNote: (payload: CreateNoteType) => Promise<boolean>;
  loadingCreated: boolean;
  updateNote: (noteCuid: string, payload: UpdateNoteType) => Promise<boolean>;
  loadingUpdated: boolean;
  loadView: (noteCuid: string) => Promise<void>;
  loadingView: boolean;
  view: ViewNoteType;
  setFilter: (filter: SearchNoteType) => void;
  filter: SearchNoteType;
  resetFilter: () => void;
  pagination: { page: number; pageSize: number };
  setPagination: (page: number, pageSize: number) => void;
  sort: { sort_by: string; sort_direction: string };
  setSort: (newSort: SortType) => void;
  openSearch: boolean;
};

const notesFilter: FilterDefinition = {
  note_title: { op: "lk", type: "string" },
  note_content: { op: "lk", type: "string" },
  note_active: { op: "eq", type: "active" },
  note_created_at: { op: "eq", type: "string" },
  created_by_name: { op: "lk", type: "string" },
};

const defaultSort = {
  sort_by: "note_created_at",
  sort_direction: "asc",
};

const defaultState = {
  loading: false,
  dataNotes: {
    data: [],
    total: 0,
  },
  loadingCreated: false,
  loadingUpdated: false,
  loadingView: false,
  view: {} as ViewNoteType,
  filter: {
    note_title: "",
    note_content: "",
    note_active: "all",
    note_created_at: "",
    created_by_name: "",
  } as SearchNoteType,
  openSearch: false,
  pagination: {
    page: 1,
    pageSize: 10,
  },
  sort: defaultSort,
};

export const useNotesStore = create<NoteStoreType>((set, get) => ({
  ...defaultState,
  resetState: () => set(defaultState),
  setFilter: (filter: SearchNoteType) => {
    set({
      filter,
      pagination: {
        ...defaultState.pagination,
      },
    });
    get().loadNotes();
  },
  resetFilter: () => {
    set({ filter: defaultState.filter });
    get().loadNotes();
  },
  setPagination: (page: number, pageSize: number) => {
    if (pageSize !== get().pagination.pageSize) {
      page = 1;
    }
    set({ pagination: { page, pageSize } });
    get().loadNotes();
  },
  setSort: (newSort: SortType) => {
    SetSort(
      newSort,
      get().sort,
      defaultSort,
      new Map<string, string>([
        ["title", "note_title"],
        ["active", "note_active"],
        ["created_at", "note_created_at"],
        ["create_by", "created_by_name"],
      ]),
      set,
      get().loadNotes
    );
  },
  loadNotes: async (): Promise<void> => {
    set({ loading: true });
    let qs = D3QSGenerator(get().filter, notesFilter, get().pagination, get().sort);
    await new ClientHttp().get<Success<ListNotesType>, BadRequest | StandardError>(
      `/api/v1/customer/notes?${qs}`,
      (result: Success<ListNotesType>) => {
        set({
          dataNotes: result.body,
          loading: false,
        });
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
        set({ loading: false });
      }
    );
  },
  createNote: async (payload: CreateNoteType): Promise<boolean> => {
    set({ loadingCreated: true });
    const result = await new ClientHttp().post<CreateNoteType, Success<void>, BadRequest | StandardError>(
      "/api/v1/customer/notes",
      payload,
      (result: Success<void>) => {
        showMessage(result, "Nota de observação criada com sucesso.");
        set({ loadingCreated: false });
        get().loadNotes();
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
        set({ loadingCreated: false });
      }
    );
    return result.status === "success";
  },
  updateNote: async (noteCuid: string, payload: UpdateNoteType): Promise<boolean> => {
    set({ loadingUpdated: true });
    const result = await new ClientHttp().put<UpdateNoteType, Success<void>, BadRequest | StandardError>(
      `/api/v1/customer/notes/${noteCuid}`,
      payload,
      (result: Success<void>) => {
        showMessage(result, "Nota de observação atualizada com sucesso.");
        set({
          loadingUpdated: false,
          view: {} as ViewNoteType,
        });
        get().loadNotes();
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
        set({ loadingUpdated: false });
      }
    );
    return result.status === "success";
  },
  loadView: async (noteCuid: string): Promise<void> => {
    set({ loadingView: true });
    await new ClientHttp().get<Success<ViewNoteType>, BadRequest | StandardError>(
      `/api/v1/customer/notes/${noteCuid}`,
      (result: Success<ViewNoteType>) => {
        set({
          view: result.body,
          loadingView: false,
        });
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
        set({ loadingView: false });
      }
    );
  },
}));
