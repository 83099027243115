import { Button, DatePicker, Form, Input, Radio, Space, Spin } from "antd";
import { useForm } from "antd/es/form/Form";
import { ViewContractType } from "../types";
import { useContext, useEffect } from "react";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { useContractStore } from "../store";
import dayjs from "dayjs";
import { contractType } from "../../tabs/TabContracts";
import { D3Date } from "@utils/date";
import UpdateContractForm from "./UpdateContractForm";

type ViewFormProps = {
  contractCuid: string;
};

function ViewForm({ contractCuid }: ViewFormProps) {
  const [form] = useForm<ViewContractType>();
  const { viewContract, loadingView, viewContractForm } = useContractStore();
  const { onClose, openDrawer } = useContext(D3DrawerContext);

  useEffect(() => {
    (async () => {
      await viewContract(contractCuid);
    })();
  }, [contractCuid, viewContract]);

  useEffect(() => {
    form.setFieldsValue({
      ...viewContractForm,
      created_at: D3Date.FormatValueApiToPtBR(viewContractForm.created_at),
      updated_at: D3Date.FormatValueApiToPtBR(viewContractForm.updated_at),
    });
  }, [viewContractForm, form]);

  return (
    <Spin spinning={loadingView}>
      <Form disabled form={form} layout="vertical" className="select-none form-view">
        <Form.Item<ViewContractType>
          hidden={viewContractForm.type === "C"}
          name={["customer_contract", "description"]}
          label="Contrato"
        >
          <Input />
        </Form.Item>
        <Form.Item<ViewContractType> label="Tipo de contrato">
          <Input value={contractType[viewContractForm.type]} />
        </Form.Item>
        <Form.Item<ViewContractType> name="description" label="Descrição">
          <Input />
        </Form.Item>
        <div className="flex gap-2">
          <Form.Item<ViewContractType>
            className="w-48"
            name="date"
            label="Data"
            getValueProps={(value) => ({ value: value ? dayjs(value) : "" })}
          >
            <DatePicker format="DD/MM/YYYY" />
          </Form.Item>

          <Form.Item<ViewContractType>
            name="expiration_date"
            label="Data de vencimento"
            getValueProps={(value) => ({ value: value ? dayjs(value) : "" })}
          >
            <DatePicker format="DD/MM/YYYY" />
          </Form.Item>
        </div>
        <Form.Item<ViewContractType> name="renovation" label="Renovável">
          <Radio.Group
            options={[
              { label: "Sim", value: true },
              { label: "Não", value: false },
            ]}
          />
        </Form.Item>

        <Form.Item<ViewContractType> name={["created_by", "name"]} label="Criado por">
          <Input disabled />
        </Form.Item>

        <Form.Item<ViewContractType> name="created_at" label="Criado em">
          <Input disabled />
        </Form.Item>

        <Form.Item<ViewContractType> name={["updated_by", "name"]} label="Atualizado por">
          <Input disabled />
        </Form.Item>
        <Form.Item<ViewContractType> name="updated_at" label="Atualizado em">
          <Input disabled />
        </Form.Item>

        <Form.Item>
          <div className="text-right">
            <Space size="small">
              <Button disabled={false} type="default" danger onClick={() => onClose()}>
                Cancelar
              </Button>
              <Button
                disabled={false}
                type="primary"
                onClick={() =>
                  openDrawer(
                    <UpdateContractForm contractCuid={contractCuid} />,
                    "right",
                    600,
                    `Editar ${contractType[viewContractForm.type]}`
                  )
                }
              >
                Editar
              </Button>
            </Space>
          </div>
        </Form.Item>
      </Form>
    </Spin>
  );
}

export default ViewForm;
