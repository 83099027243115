import showMessage from "@utils/showMessage";
import { Spin, notification } from "antd";
import { useEffect, useState } from "react";
import FileInfo from "../view-file/components/FileInfo";
import { ViewFileType } from "../types";
import useFileStore from "../store";

type DetailsFileProps = {
  fsFileCuid: string;
};

function DetailsFile({ fsFileCuid }: DetailsFileProps) {
  const { viewFile } = useFileStore();
  const [infos, setInfos] = useState<ViewFileType>();

  useEffect(() => {
    (async () => {
      try {
        const result = await viewFile(fsFileCuid);
        if (result.code === 200) {
          setInfos(result.body as ViewFileType);
        } else {
          showMessage(result, "Impossível carregar o arquivo.");
        }
      } catch (error) {
        notification.error({
          description: "Atenção !",
          message: "Impossível carregar o arquivo.",
        });
      }
    })();
  }, [fsFileCuid, viewFile]);

  return infos ? (
    <FileInfo info={infos} />
  ) : (
    <div className="flex items-center justify-center w-full h-full">
      <Spin spinning />
    </div>
  );
}

export default DetailsFile;
