import { Form } from "antd";
import { MaskedInput } from "antd-mask-input";
import { NamePath } from "antd/es/form/interface";

type FormItemPhoneNumberProps = {
  required?: boolean;
  name: NamePath;
  label?: string;
  defaultValue?: string;
  disabled?: boolean;
};

function FormItemLandLine({
  required = false,
  name,
  label = "Telefone",
  defaultValue,
  disabled = false,
}: FormItemPhoneNumberProps) {
  return (
    <Form.Item
      label={label}
      name={name}
      rules={[{ required: required, message: `Por favor, insira o número do ${label.toLowerCase()}` }]}
      getValueProps={(value) => ({ value: defaultValue ? defaultValue : value })}
      normalize={(value) => value.replace(/[^\d]/g, "")}
    >
      <MaskedInput disabled={disabled} value={defaultValue} mask="(00) 0000-0000" />
    </Form.Item>
  );
}

export default FormItemLandLine;
