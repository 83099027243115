type SearchOption = {
  value: string;
  label: string;
};

const searchOptionsArray: SearchOption[] = [
  { value: "EQ", label: "Igual a" },
  { value: "SW", label: "Começa com" },
  { value: "CT", label: "Contém" },
  { value: "CW", label: "Contém palavra" },
  { value: "EW", label: "Termina com" },
  { value: "MV", label: "Múltiplos valores" },
  { value: "GT", label: "Maior que" },
  { value: "LT", label: "Menor que" },
  { value: "BT", label: "Entre valores" },
];

/**
 * Extrai opções de busca com base em uma lista de valores.
 * @param {string[] | string} options - Os valores das opções a serem extraídas.
 * @returns {SearchOption[]} - Um array de objetos SearchOption correspondentes.
 * @throws {Error} - Lança um erro se uma opção inválida for fornecida.
 */
export default function extractOptions(
  options: string[] | string
): SearchOption[] {
  if (typeof options === "string") {
    options = options.split(";").map((opt) => opt.trim());
  }
  const optionsMap = new Map<string, SearchOption>(
    searchOptionsArray.map((option) => [option.value, option])
  );

  return options.map((value) => {
    const option = optionsMap.get(value);
    if (!option) {
      throw new Error(`Opção inválida: ${value}`);
    }
    return option;
  });
}
