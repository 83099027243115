import { FormInstance } from "antd";
import { useEffect } from "react";
import { useSpecialsStore } from "../store";
import { SearchPhysicalDocumentType } from "../types";
import FormItemSwitcher from "./FormItemSwitcher";

type SearchOrdersFormFormProps = {
  form: FormInstance<SearchPhysicalDocumentType>;
};

function SearchOrdersForm({ form }: SearchOrdersFormFormProps) {
  const { filter } = useSpecialsStore();
  useEffect(() => {
    (async () => {
      form.setFieldsValue({
        filter_order: filter.filter_order,
      });
    })();
  }, [form]);

  return (
    <div className="flex flex-col w-full h-full p-2 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100 !important">
      <FormItemSwitcher attribute={["filter_order", "services"]} label="Ordens de serviço" form={form} />
      <FormItemSwitcher attribute={["filter_order", "deployments"]} label="Ordens de implantação" form={form} />
    </div>
  );
}

export default SearchOrdersForm;
