import { Icon } from "@iconify/react";
import { ListOfKeyAndValueType } from "../../types";
import extractOptions from "@utils/extractOptions";
import { Form, Select } from "antd";
import { useState } from "react";

type ItemSELProps = {
  options: string;
  name: number;
  values: ListOfKeyAndValueType[];
};

function ItemSEL({ name, options, values, ...props }: ItemSELProps) {
  const [option, setOption] = useState<string>("");
  return (
    <div className="flex w-full gap-3 ">
      <Form.Item
        className="w-40 p-0 m-0"
        {...props}
        name={[name, "search_option"]}
      >
        <Select
          className="min-w-40"
          onChange={(e) => setOption(e)}
          placeholder="Opções de busca"
          options={extractOptions(options)}
        />
      </Form.Item>

      <Form.Item
        {...props}
        className="w-full p-0 m-0"
        name={[name, option === "MV" ? "values" : "value"]}
      >
        <Select
          allowClear
          showSearch
          optionFilterProp="children"
          removeIcon={
            <Icon
              icon="iconamoon:close-duotone"
              className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
            />
          }
          mode={option === "MV" ? "multiple" : undefined}
          menuItemSelectedIcon={
            <Icon
              icon="eva:checkmark-outline"
              className="w-5 h-5 text-blue-500"
            />
          }
          className="w-full truncate select-none"
          options={values.map((option) => {
            return {
              value: option.key,
              label: option.value,
            };
          })}
        />
      </Form.Item>
    </div>
  );
}

export default ItemSEL;
