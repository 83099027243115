import { Form, Input, InputNumber, Radio, Select, Table } from "antd";
import { ColumnType, Data } from "../../data/data";
import { CreateDocumentTypeType, MutationDocumentTypeType } from "../../types";
import { FormInstance } from "antd/lib";

type CreateDataProps = {
  uft: any;
  form: FormInstance<MutationDocumentTypeType>;
};

function CreateData({ uft, form }: CreateDataProps) {
  const temporalityPermanentGuard = Form.useWatch("temporality_permanent_guard", form);

  return (
    <>
      <Form.Item<CreateDocumentTypeType>
        name="description"
        label="Descrição"
        rules={[
          {
            required: true,
            message: "Por favor, insira descrição.",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item<CreateDocumentTypeType> label="Configurações">
        <Table className="mb-5 table-service" key="key" size="small" bordered pagination={false} dataSource={Data}>
          <Table.Column<ColumnType>
            showSorterTooltip={false}
            defaultSortOrder="ascend"
            key="description"
            title="Descrição"
            dataIndex="description"
          />

          <Table.Column<ColumnType>
            align="center"
            className="register-service"
            showSorterTooltip={false}
            defaultSortOrder="ascend"
            key="key"
            title="Opções"
            dataIndex="radio"
            render={(text, { radio, name }: ColumnType) => (
              <Form.Item
                initialValue={radio}
                name={name}
                className="register-service"
                hasFeedback={!temporalityPermanentGuard && name === "temporality_year"}
                rules={[
                  {
                    required: !temporalityPermanentGuard && name === "temporality_year",
                    message: "",
                  },
                ]}
              >
                {radio !== undefined ? (
                  <Radio.Group
                    disabled={name === "is_temporality_mandatory" && uft}
                    size="small"
                    options={[
                      { label: "Sim", value: true },
                      { label: "Não", value: false },
                    ]}
                    value={radio}
                    optionType="button"
                    buttonStyle="solid"
                  />
                ) : name === "temporality_year" || name === "update_frequency_period" ? (
                  <InputNumber disabled={name === "temporality_year" && uft} />
                ) : (
                  <Select
                    style={{ width: 90 }}
                    options={[
                      {
                        value: "D",
                        label: "Diário",
                      },
                      {
                        value: "M",
                        label: "Mensal",
                      },
                      {
                        value: "Y",
                        label: "Anual",
                      },
                    ]}
                  />
                )}
              </Form.Item>
            )}
          />
        </Table>
      </Form.Item>
    </>
  );
}

export default CreateData;
