import { Form, Button, Spin, Modal, Upload, Input, Radio } from "antd";
import { useCompaniesStore } from "../store";
import { useEffect, useState } from "react";
import { UpdateSystemUserType, ViewSystemUserType } from "../types";
import showMessage from "@utils/showMessage";
import { PlusOutlined } from "@ant-design/icons";
import type { UploadFile } from "antd/es/upload/interface";
import type { RcFile, UploadProps } from "antd/es/upload";
import { D3Date } from "@utils/date";

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

function TabPersonalInformation() {
  const [form] = Form.useForm<ViewSystemUserType>();

  const [isEditing, setIsEditing] = useState(false);

  const { updateSystemUser } = useCompaniesStore();

  const { getSystemUser } = useCompaniesStore();

  const [loading, setLoading] = useState<boolean>(true);

  const [previewOpen, setPreviewOpen] = useState(false);

  const [previewImage, setPreviewImage] = useState("");

  const [previewTitle, setPreviewTitle] = useState("");

  const [fileList, setFileList] = useState<UploadFile[]>([]);

  useEffect(() => {
    (async () => {
      const result = await getSystemUser();
      if (result.status === "success") {
        const viewUser = result.body as ViewSystemUserType;
        form.setFieldsValue(
          Object.assign(viewUser, {
            created_at: D3Date.FormatValueApiToPtBR(viewUser.created_at),
            updated_at: D3Date.FormatValueApiToPtBR(viewUser.updated_at),
          })
        );
      } else {
        showMessage(result);
      }
      setLoading(false);
    })();
  }, [form, getSystemUser]);

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => setFileList(newFileList);

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1));
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <>
      <Spin spinning={loading}>
        <Form
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 14 }}
          className="select-none w-[600px]"
          form={form}
          layout="horizontal"
          onFinish={async (payload: UpdateSystemUserType) => {
            setLoading(true);
            const result = await updateSystemUser(payload);
            if (result.status === "success") {
              showMessage(result, "Usuário atualizado com sucesso.");
              setIsEditing(false);
            } else {
              showMessage(result);
            }
            setLoading(false);
          }}
          autoComplete="off"
        >
          <div className="h-32  flex justify-center items-center  w-[400px]">
            <div>
              <Upload
                action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                listType="picture-circle"
                fileList={fileList}
                onPreview={handlePreview}
                onChange={handleChange}
              >
                {fileList.length >= 1 ? null : uploadButton}
              </Upload>
              <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                <img alt="example" style={{ width: "100%" }} src={previewImage} />
              </Modal>
            </div>
          </div>

          <Form.Item<ViewSystemUserType>
            label="Usuário"
            name="username"
            rules={[
              {
                required: true,
                message: "Por favor, insira o usuário.",
              },
              {
                max: 30,
                message: "O usuário deve ter menos de 30 caracteres.",
              },
              {
                validator: (_, value) =>
                  /\s/.test(value)
                    ? Promise.reject(new Error("O usuário não pode conter espaços."))
                    : Promise.resolve(),
              },
            ]}
          >
            <Input className="w-64" disabled={!isEditing} variant={isEditing ? "outlined" : "borderless"} />
          </Form.Item>
          <Form.Item<ViewSystemUserType>
            label="Nome"
            name="name"
            rules={[
              {
                required: true,
                message: "Por favor, insira o nome.",
              },
            ]}
          >
            <Input className="w-64" disabled={!isEditing} variant={isEditing ? "outlined" : "borderless"} />
          </Form.Item>
          <Form.Item<ViewSystemUserType>
            label="E-mail"
            name="email"
            rules={[
              {
                required: true,
                message: "Por favor, insira o e-mail.",
              },
              {
                type: "email",
                message: "Por favor, insira um e-mail válido.",
              },
            ]}
          >
            <Input className="w-64" disabled={!isEditing} variant={isEditing ? "outlined" : "borderless"} />
          </Form.Item>
          <Form.Item<ViewSystemUserType> label="Tipo" name="type">
            <Input className="w-64" disabled={true} variant="borderless" />
          </Form.Item>
          <Form.Item<ViewSystemUserType> label="Admin" name="admin">
            <Radio.Group
              disabled
              options={[
                { label: "Sim", value: true },
                { label: "Não", value: false },
              ]}
            />
          </Form.Item>

          <Form.Item<ViewSystemUserType> name="created_at" label="Criado em">
            <Input disabled={true} variant="borderless" />
          </Form.Item>
          <Form.Item<ViewSystemUserType> name="updated_at" label="Atualizado em">
            <Input disabled={true} variant="borderless" />
          </Form.Item>

          <div className="flex items-center justify-end w-[400px]">
            <Form.Item className={isEditing ? `` : `hidden`}>
              <Button type="primary" htmlType="submit">
                atualizar
              </Button>
            </Form.Item>
            <Form.Item className={isEditing ? `hidden` : ``}>
              <Button type="primary" onClick={() => setIsEditing(true)}>
                Editar
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Spin>
    </>
  );
}

export default TabPersonalInformation;
