import { Alert, Button, Form, Input, Space, Spin } from "antd";
import { useContext } from "react";
import D3Can from "@components/shared/D3Can";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { useIncidentsStore } from "./store";
import { CloseIncidentType, RegisterIncidentType } from "./types";

const { TextArea } = Input;

export const IncidentForm = () => {
  const { onClose } = useContext(D3DrawerContext);
  const { closeIncident, registerIncident, loadingMutation, listNonPayments } = useIncidentsStore();
  const [form] = Form.useForm<RegisterIncidentType | CloseIncidentType>();

  const isActive = listNonPayments.length > 0 ? listNonPayments[0].active : false;

  return (
    <Spin spinning={loadingMutation}>
      <Form
        className="select-none"
        form={form}
        layout="vertical"
        onFinish={async (payload: RegisterIncidentType | CloseIncidentType) => {
          const result = isActive
            ? await closeIncident(payload as CloseIncidentType)
            : await registerIncident(payload as RegisterIncidentType);
          if (result) {
            onClose();
          }
        }}
        autoComplete="off"
      >
        <Form.Item hidden={isActive}>
          <Alert
            message="Atenção"
            className="p-3 text-justify"
            showIcon
            description="Ao registrar uma ocorrência de inadimplência para o grupo de clientes selecionado, é fundamental compreender que os usuários vinculados a esse grupo de clientes ficarão temporariamente sem acesso ao sistema até que o referida ocorrência seja encerrada."
            type="info"
          />
        </Form.Item>
        <Form.Item
          name={isActive ? "removing_non_payment_reason" : "non_payment_reason"}
          label="Motivo"
          rules={[{ required: true, message: "Por favor, insira a razão." }]}
        >
          <TextArea rows={4} />
        </Form.Item>

        <Form.Item>
          <div className="text-right">
            <Space size="small">
              <Button disabled={loadingMutation} type="default" onClick={() => onClose()}>
                Cancelar
              </Button>
              <D3Can action="create" resource="system_material">
                <Button loading={loadingMutation} type="primary" danger={!isActive} htmlType="submit">
                  Confirmar
                </Button>
              </D3Can>
            </Space>
          </div>
        </Form.Item>
      </Form>
    </Spin>
  );
};
