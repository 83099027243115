import { PureAbility, defineAbility } from "@casl/ability";
import { AbilityTupleType } from "@casl/ability/dist/types/types";
import { BadRequest, ClientHttp, StandardError, Success } from "@utils/clientHttp";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import showMessage from "@utils/showMessage";

export type AbilityType = PureAbility<AbilityTupleType<string, string>, any>;

interface FSRoot {
  ID: number;
  Cuid: string;
}

interface InfoToken {
  UserId: number;
  UserCuid: string;
  CompanyId: number;
  CompanyCuid: string;
  CustomerId: number;
  CustomerCuid: string;
  CustomerGroupId: string;
  CustomerGroupCuid: string;
  Name: string;
  Email: string;
  Admin: boolean;
  Type: string;
  Departments: [];
  FSRoot: FSRoot;
  Permissions: string[];
  ExpirationToken: number;
}

export type InputLoginType = {
  login: string;
  password: string;
};

export type OutputLoginType = {
  change_password_next_login: boolean;
  token: string;
};

interface AuthState {
  isAuthenticated: boolean;
  selectedTypeAndEntityCuid: string | undefined;
  token: string;
  login: (payload: InputLoginType) => Promise<Success<OutputLoginType> | BadRequest | StandardError>;
  logout: () => void;
  getUserInfo: () => InfoToken;
  getInitialName: () => string | undefined;
  getAbility: () => AbilityType;
  getCustomerCuid: () => string | undefined;
  updateTokenByTypeAndEntityCuid: (selectedTypeAndEntityCuid: string) => Promise<void>;
  resetToken: () => void;
  refreshToken: () => Promise<void>;
}

const useAuthStore = create(
  persist<AuthState>(
    (set, get) => ({
      selectedTypeAndEntityCuid: undefined,
      isAuthenticated: false,
      token: "",
      login: async (payload: InputLoginType): Promise<Success<OutputLoginType> | BadRequest | StandardError> => {
        const loginResult = await new ClientHttp().post<
          InputLoginType,
          Success<OutputLoginType>,
          BadRequest | StandardError
        >("/api/v1/auth/login", payload, (result: Success<OutputLoginType>) => {
          if (result.body.token === undefined || result.body.token === "") {
            throw new Error("Token não encontrado");
          }

          const body = JSON.parse(atob(result.body.token.split(".")[1]));
          let userInfo = body.info as InfoToken;

          let selectedTypeAndEntityCuid = undefined;

          if (userInfo.Type === "CTM") {
            selectedTypeAndEntityCuid = `CTM:${userInfo.CustomerCuid}`;
          }

          set({ isAuthenticated: true, token: result.body.token, selectedTypeAndEntityCuid });
        });

        return loginResult;
      },
      logout: () => {
        set({ token: "", isAuthenticated: false, selectedTypeAndEntityCuid: undefined });
      },
      getUserInfo: (): InfoToken => {
        let token = get().token;
        if (token === "") {
          get().logout();
          return {} as InfoToken;
        } else {
          const body = JSON.parse(atob(token.split(".")[1]));

          return {
            ...body.info,
            ExpirationToken: body.exp,
          } as InfoToken;
        }
      },
      getInitialName: (): string | undefined => {
        let user = get().getUserInfo();
        if (user !== undefined) {
          return user.Name[0].toLocaleUpperCase();
        }
      },
      getAbility: (): AbilityType => {
        const ability = defineAbility<AbilityType>((can, cannot) => {
          let user = get().getUserInfo();
          if (user !== undefined) {
            user?.Permissions.forEach((permission) => {
              let [subject, action] = permission.split(".");
              can(action, subject);
            });
          }
        });
        return ability;
      },
      getCustomerCuid: (): string | undefined => {
        let user = get().getUserInfo();
        if (user !== undefined && user?.CustomerCuid !== "") {
          return user.CustomerCuid;
        }
        return undefined;
      },
      updateTokenByTypeAndEntityCuid: async (selectedTypeAndEntityCuid: string): Promise<void> => {
        let [type, entityCuid] = selectedTypeAndEntityCuid.split(":");

        await new ClientHttp().put<any, Success<{ new_token: string }>, StandardError>(
          `/api/v1/auth/${type}/${entityCuid}/change-token`,
          undefined,
          (result: Success<{ new_token: string }>) => {
            set({ token: result.body.new_token, selectedTypeAndEntityCuid });
          },
          (error: StandardError) => {
            showMessage(error);
          }
        );
      },
      resetToken: async () => {
        await new ClientHttp().put<any, Success<{ new_token: string }>, StandardError>(
          "/api/v1/auth/reset-token",
          undefined,
          (result: Success<{ new_token: string }>) => {
            set({ token: result.body.new_token, selectedTypeAndEntityCuid: undefined });
          },
          (error: StandardError) => {
            showMessage(error);
          }
        );
      },
      refreshToken: async () => {
        await new ClientHttp().put<any, Success<{ new_token: string }>, StandardError>(
          "/api/v1/auth/refresh-token",
          undefined,
          (result: Success<{ new_token: string }>) => {
            set({ token: result.body.new_token, selectedTypeAndEntityCuid: get().getUserInfo().CustomerCuid });
            showMessage(result, "Sessão renovada com sucesso.");
          },
          (error: StandardError) => {
            showMessage(error);
          }
        );
      },
    }),
    {
      name: "gedocs-storage",
      storage: createJSONStorage(() => {
        return {
          getItem: async (name: string): Promise<string | null> => {
            return localStorage.getItem(name) || null;
          },
          setItem: async (name: string, value: string): Promise<void> => {
            await localStorage.setItem(name, value);
          },
          removeItem: async (name: string): Promise<void> => {
            await localStorage.removeItem(name);
          },
        };
      }),
    }
  )
);

export default useAuthStore;
