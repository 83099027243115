import { Form, Button, Spin, Input } from "antd";
import { useCompaniesStore } from "../store";
import { useState } from "react";
import { UpdatePassowrdSystemUserType } from "../types";
import showMessage from "@utils/showMessage";

function TabPrivacy() {
  const [form] = Form.useForm<UpdatePassowrdSystemUserType>();

  const { updatePasswordSystemUser } = useCompaniesStore();

  const [loading, setLoading] = useState<boolean>(false);

  return (
    <>
      <Spin spinning={loading}>
        <div className="w-[380px]">
          <div className="mb-5">
            <h2 className="my-2 text-lg font-semibold text-center">Segurança</h2>
            <h5 className="text-sm font-thin text-center">
              Configurações e recomendações para ajudar você a manter sua conta segura
            </h5>
          </div>

          <Form
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 14 }}
            className="select-none w-[600px]"
            scrollToFirstError
            form={form}
            layout="horizontal"
            onFinish={async (payload: UpdatePassowrdSystemUserType) => {
              setLoading(true);
              const result = await updatePasswordSystemUser(payload);
              if (result.status === "success") {
                showMessage(result, "Senha atualizada com sucesso.");
                form.resetFields();
              } else {
                showMessage(result);
              }
              setLoading(false);
            }}
            autoComplete="off"
          >
            <Form.Item<UpdatePassowrdSystemUserType>
              name="old_password"
              label="Senha atual"
              rules={[
                {
                  required: true,
                  message: "Por favor, digite sua senha atual.",
                },
                {
                  min: 6,
                  message: "A senha deve ter no mínimo 6 caracteres.",
                },
              ]}
              hasFeedback
            >
              <Input.Password className="w-64" autoFocus />
            </Form.Item>

            <Form.Item<UpdatePassowrdSystemUserType>
              name="new_password"
              label="Nova senha"
              rules={[
                {
                  required: true,
                  message: "Por favor, digite sua nova senha.",
                },
                {
                  min: 6,
                  message: "A senha deve ter no mínimo 6 caracteres.",
                },
              ]}
              hasFeedback
            >
              <Input.Password className="w-64" />
            </Form.Item>

            <Form.Item<UpdatePassowrdSystemUserType>
              name="repeated_new_password"
              label="Confirme senha"
              dependencies={["new_password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Por favor, confirme sua nova senha.",
                },
                {
                  min: 6,
                  message: "A senha deve ter no mínimo 6 caracteres.",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("new_password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("As senhas não são iguais."));
                  },
                }),
              ]}
            >
              <Input.Password className="w-64" />
            </Form.Item>
            <div className="flex items-center justify-end w-[380px]">
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Atualizar
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </Spin>
    </>
  );
}

export default TabPrivacy;
