import { Icon } from "@iconify/react";
import useAuthStore from "@provider/AuthProvider";
import { Button, Space, TimePicker, Tooltip, notification } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";

const Timer: React.FC = () => {
	const { getUserInfo, refreshToken } = useAuthStore();
	const userInfo = getUserInfo();
	const calculateTimeLeft = () => {
		const difference = userInfo.ExpirationToken * 1000 - Date.now();
		let timeLeft = {
			hours: 0,
			minutes: 0,
			seconds: 0,
		};

		if (difference > 0) {
			timeLeft = {
				hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
				minutes: Math.floor((difference / 1000 / 60) % 60),
				seconds: Math.floor((difference / 1000) % 60),
			};
		}

		return timeLeft;
	};

	const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

	const [api, contextHolder] = notification.useNotification();

	const openNotification = () => {
		const key = `open${Date.now()}`;
		const btn = (
			<Space>
				<Button
					type="primary"
					size="small"
					onClick={() => {
						refreshToken();
						api.destroy(key);
					}}
				>
					Renovar
				</Button>
			</Space>
		);
		api.info({
			message: "Sessão prestes a expirar",
			description: "Sua sessão vai expirar em 5 minutos. Deseja renovar a sessão?",
			btn,
			key,
			duration: 0,
			onClose: () => {},
		});
	};

	useEffect(() => {
		const timer = setInterval(() => {
			const newTimeLeft = calculateTimeLeft();
			setTimeLeft(newTimeLeft);
			const remainingTime = dayjs().hour(newTimeLeft.hours).minute(newTimeLeft.minutes).second(newTimeLeft.seconds);
			const notifyTime = dayjs().set("hour", 0).set("minute", 5).set("second", 0);
			if (remainingTime.isSame(notifyTime, "second")) {
				openNotification();
			}
		}, 1000);

		return () => clearInterval(timer);
	}, [userInfo]);

	const timeValue = dayjs().hour(timeLeft.hours).minute(timeLeft.minutes).second(timeLeft.seconds);

	return (
		<>
			{contextHolder}
			<TimePicker
				value={timeValue}
				readOnly
				size="small"
				open={false}
				className="w-[100px]"
				allowClear={{
					clearIcon: (
						<div className="flex items-center justify-end">
							<Tooltip title="Resetar sessão">
								<Icon className="text-blue-500" icon="zondicons:reload" onClick={() => refreshToken()} />
							</Tooltip>
						</div>
					),
				}}
			/>
		</>
	);
};

export default Timer;
