import { create } from "zustand";
import {
  ListServiceType,
  CreateServiceType,
  RowServiceType,
  LockOrUnlockPayloadType,
  SearchServiceType,
  UpdateServiceType,
  ViewServiceType,
  SelectOperationItemType,
  SimpleListOperationType,
} from "./types";

import showMessage from "@utils/showMessage";

import { ClientHttp, BadRequest, StandardError, Success } from "@utils/clientHttp";
import IsValidMultiSelectedRow from "@utils/table/IsValidMultiSelectedRow";
import { SetSort, SortType } from "@utils/table/SetSort";
import D3QSGenerator, { FilterDefinition } from "@utils/D3QSGenerator";

type ServiceStoreState = {
  apiResult: ListServiceType;
  selectedRows: { cuids: string[]; active: boolean };
  filter: SearchServiceType;
  pagination: { page: number; pageSize: number };
  sort: { sort_by: string; sort_direction: string };
  loading: boolean;
  setFilter: (filter: SearchServiceType) => void;
  setPagination: (page: number, pageSize: number) => void;
  setSort: (newSort: SortType) => void;
  getOperationsForSelect: () => Promise<SelectOperationItemType[]>;
  loadServices: () => Promise<Success<ListServiceType> | BadRequest | StandardError>;
  getService: (cuid: string) => Promise<Success<ViewServiceType> | StandardError>;
  createService: (service: CreateServiceType) => Promise<Success<{ cuid: string }> | BadRequest | StandardError>;
  updateService: (cuid: string, service: UpdateServiceType) => Promise<Success<void> | BadRequest | StandardError>;
  lockService: (cuids: string[]) => Promise<void>;
  unLockService: (cuids: string[]) => Promise<void>;
  onMultiSelectedRowChange: (selectedRows: RowServiceType[]) => void;
  resetSelectedRows: () => void;
  resetFilter: () => void;
  resetState: () => void;
};

const defaultSort = {
  sort_by: "srv_description",
  sort_direction: "asc",
};

const operationsFilter: FilterDefinition = {
  srv_description: { op: "lk", type: "string" },
  srv_active: { op: "eq", type: "active" },
};

const defaultState = {
  apiResult: {
    total: 0,
    data: [],
  },
  selectedRows: {
    cuids: [],
    active: false,
  },
  filter: {
    srv_description: "",
    srv_active: "on",
  } as SearchServiceType,
  pagination: {
    page: 1,
    pageSize: 10,
  },
  sort: defaultSort,
  loading: false,
};

export const useServicesStore = create<ServiceStoreState>((set, get) => ({
  ...defaultState,
  resetState: () => set(defaultState),
  setFilter: (filter: SearchServiceType) => {
    set({
      filter,
      selectedRows: { cuids: [], active: false },
      pagination: { ...get().pagination, page: 1 },
    });
    get().loadServices();
  },
  onMultiSelectedRowChange: (selectedRows: RowServiceType[]): void => IsValidMultiSelectedRow(selectedRows, get, set),
  resetSelectedRows: () => {
    set({ selectedRows: { cuids: [], active: false } });
  },
  resetFilter: () => {
    set({ filter: { srv_description: "", srv_active: "on" } });
    get().loadServices();
  },
  setPagination: (page: number, pageSize: number) => {
    if (pageSize !== get().pagination.pageSize) {
      page = 1;
    }
    set({ pagination: { page, pageSize } });
    get().resetSelectedRows();
    get().loadServices();
  },
  setSort: (newSort: SortType) =>
    SetSort(
      newSort,
      get().sort,
      defaultSort,
      new Map<string, string>([
        ["description", "srv_description"], // Column name from table and column name from api
        ["active", "srv_active"],
      ]),
      set,
      get().loadServices
    ),
  loadServices: async (): Promise<Success<ListServiceType> | BadRequest | StandardError> => {
    set({ loading: true });
    let qs = D3QSGenerator(get().filter, operationsFilter, get().pagination, get().sort);
    return await new ClientHttp().get<Success<ListServiceType>, BadRequest | StandardError>(
      `/api/v1/system/services?${qs}`,
      (result: Success<ListServiceType>) => {
        let newPage = get().pagination.page;
        const { body } = result;
        if (body.total > 0 && body.data.length === 0) {
          newPage = newPage - 1;
          set({
            apiResult: body,
            loading: false,
            pagination: { ...get().pagination, page: newPage },
          });
          get().loadServices();
        } else {
          set({ apiResult: body, loading: false });
        }
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
  },
  getService: async (cuid: string): Promise<Success<ViewServiceType> | StandardError> => {
    return await new ClientHttp().get<Success<ViewServiceType>, StandardError>(`/api/v1/system/services/${cuid}`);
  },

  getOperationsForSelect: async (): Promise<SelectOperationItemType[]> => {
    return await new ClientHttp().getItensForSelect<
      Success<SimpleListOperationType>,
      StandardError,
      SelectOperationItemType[]
    >(
      "/api/v1/system/operations/simple-list",
      (result: Success<SimpleListOperationType>): SelectOperationItemType[] => {
        return result.body.data.map(({ id, description, active }) => {
          return {
            value: id,
            label: description,
            active: active,
          } as SelectOperationItemType;
        });
      },
      (error: StandardError): SelectOperationItemType[] => {
        showMessage(error, "Erro ao carregar lista operações.");
        return [] as SelectOperationItemType[];
      }
    );
  },

  createService: async (
    service: CreateServiceType
  ): Promise<Success<{ cuid: string }> | BadRequest | StandardError> => {
    return await new ClientHttp().post<CreateServiceType, Success<{ cuid: string }>, BadRequest | StandardError>(
      "/api/v1/system/services",
      service,
      (result: Success<{ cuid: string }>) => {
        get().loadServices();
      }
    );
  },
  updateService: async (
    cuid: string,
    service: UpdateServiceType
  ): Promise<Success<void> | BadRequest | StandardError> => {
    return await new ClientHttp().put<UpdateServiceType, Success<void>, BadRequest | StandardError>(
      `/api/v1/system/services/${cuid}`,
      service,
      (result: Success<void>) => {
        get().loadServices();
      }
    );
  },
  lockService: async (cuids: string[]): Promise<void> => {
    await new ClientHttp().patch<
      LockOrUnlockPayloadType,
      Success<{ rows_affected: number }>,
      BadRequest | StandardError
    >(
      `/api/v1/system/services/lock`,
      { cuids: cuids },
      (result: Success<{ rows_affected: number }>) => {
        get().resetSelectedRows();
        get().loadServices();
        if (result.body.rows_affected > 1) {
          showMessage(result, "Itens bloqueados com sucesso.");
        } else {
          showMessage(result, "Item bloqueado com sucesso.");
        }
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
  },
  unLockService: async (cuids: string[]): Promise<void> => {
    await new ClientHttp().patch<
      LockOrUnlockPayloadType,
      Success<{ rows_affected: number }>,
      BadRequest | StandardError
    >(
      `/api/v1/system/services/unlock`,
      { cuids: cuids },
      (result: Success<{ rows_affected: number }>) => {
        get().resetSelectedRows();
        get().loadServices();
        if (result.body.rows_affected > 1) {
          showMessage(result, "Itens desbloqueados com sucesso.");
        } else {
          showMessage(result, "Item desbloqueado com sucesso.");
        }
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
  },
}));
