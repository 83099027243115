const returnPercentage = (value: number): string => {
  let formatted = value.toFixed(2);

  if (formatted.endsWith(".00")) {
    formatted = formatted.substring(0, formatted.length - 3);
  } else if (formatted[formatted.length - 1] === "0") {
    formatted = formatted.substring(0, formatted.length - 1);
  }
  return `${formatted} %`;
};

export const D3Percentage = {
  formatValueToPercentage: returnPercentage,
};
