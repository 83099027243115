import { useContext, useEffect, useState } from "react";

import { Button, Form, Input, Select, Space, Spin, Tag, Tooltip } from "antd";
import { useStoragesStore } from "../store";
import { CreateStorageType, SelectCustomerAddressItemType } from "../types";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import showMessage from "@utils/showMessage";
import D3Can from "@components/shared/D3Can";
import { Icon } from "@iconify/react";

export const CreateStorageForm = () => {
  const { createStorage, getCustomerAddressesForSelect } = useStoragesStore();
  const { onClose } = useContext(D3DrawerContext);
  const [form] = Form.useForm<CreateStorageType>();
  const [loading, setLoading] = useState(true);
  const [customerAddress, setCustomerAddresss] = useState<
    SelectCustomerAddressItemType[]
  >([]);

  useEffect(() => {
    (async () => {
      const result = await getCustomerAddressesForSelect();
      setCustomerAddresss(result);
      setLoading(false);
    })();
  }, [getCustomerAddressesForSelect]);
  return (
    <>
      <Spin spinning={loading}>
        <Form
          className="select-none"
          form={form}
          layout="vertical"
          onFinish={async (payload: CreateStorageType) => {
            setLoading(true);
            const result = await createStorage(payload);
            if (result.status === "success") {
              showMessage(result, "Galpão cadastrado com sucesso.");
              onClose();
            } else {
              showMessage(result);
            }
            setLoading(false);
          }}
          autoComplete="off"
        >
          <Form.Item<CreateStorageType>
            name="description"
            label="Descrição"
            rules={[
              { required: true, message: "Por favor, insira descrição." },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item<CreateStorageType>
            name="key_document_storage"
            label="Código do galpão"
            normalize={(value) => value.toUpperCase()}
            rules={[
              {
                required: true,
                message: "Por favor, insira código do galpão.",
              },
              {
                pattern: /^[A-Z0-9]{1,3}$/,
                message:
                  "O código do galpão deve conter de 1 a 3 caracteres, que podem ser letras ou números, sem incluir caracteres especiais ou o 'ç'.",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item<CreateStorageType>
            label="Endereços"
            name="address_cuid"
            rules={[
              {
                required: true,
                message: "Por favor, insira endereço.",
              },
            ]}
          >
            <Select
              listItemHeight={10}
              listHeight={250}
              allowClear
              removeIcon={
                <Icon
                  icon="iconamoon:close-duotone"
                  className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
                />
              }
              menuItemSelectedIcon={
                <Icon
                  icon="eva:checkmark-outline"
                  className="w-5 h-5 text-blue-500"
                />
              }
              optionLabelProp="dataLabel"
              showSearch
              maxTagCount="responsive"
              size="middle"
              className="w-full truncate select-none"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option?.dataFilter.toLowerCase().includes(input.toLowerCase())
              }
            >
              {customerAddress.map(
                ({ value, active, address, label, is_default }) => (
                  <Select.Option
                    key={value}
                    value={value}
                    dataFilter={`${label} ${address}`}
                    dataLabel={`${label}`}
                  >
                    <div className="flex items-center justify-between ">
                      <div className="flex flex-col truncate">
                        <span className="truncate">{label}</span>
                        <span className="text-[10px] truncate">{address}</span>
                      </div>
                      <div className="flex items-center justify-between gap-2">
                        {is_default && (
                          <Tooltip title="Endereço Padrão" placement="right">
                            <Tag
                              className="flex items-center justify-between gap-1"
                              icon={<Icon icon="bi:geo-alt-fill" />}
                              color="success"
                            >
                              Padrão
                            </Tag>
                          </Tooltip>
                        )}

                        {active ? (
                          <Tooltip
                            title="Endereço desbloqueado"
                            placement="right"
                          >
                            <Icon
                              icon="basil:unlock-solid"
                              className="text-green-500"
                              height={16}
                              width={16}
                            />
                          </Tooltip>
                        ) : (
                          <Tooltip title="Endereço bloqueado" placement="right">
                            <Icon
                              icon="basil:lock-solid"
                              className="text-red-500"
                              height={16}
                              width={16}
                            />
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  </Select.Option>
                )
              )}
            </Select>
          </Form.Item>
          <Form.Item>
            <div className="text-right">
              <Space size="small">
                <Button type="default" danger onClick={() => onClose()}>
                  Cancelar
                </Button>
                <D3Can action="create" resource="customer_storage">
                  <Button type="primary" htmlType="submit">
                    Cadastrar
                  </Button>
                </D3Can>
              </Space>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};
