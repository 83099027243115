import { useContext, useEffect } from "react";
import { Button, Form, Input, Segmented, Space } from "antd";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import D3Can from "@components/shared/D3Can";
import SpinLoading from "@components/shared/SpinLoading";
import { useNotesStore } from "../store";
import { UpdateNoteType } from "../types";
import hasObjectChanges from "@utils/isObjectModified";

type UpdateNoteFormProps = {
  noteCuid: string;
};

const { TextArea } = Input;

export const UpdateNoteForm = ({ noteCuid }: UpdateNoteFormProps) => {
  const { loadView, view, loadingView, updateNote, loadingUpdated } = useNotesStore();
  const { onClose } = useContext(D3DrawerContext);
  const [form] = Form.useForm<UpdateNoteType>();

  useEffect(() => {
    if (view.cuid !== noteCuid) {
      (async () => {
        await loadView(noteCuid);
      })();
    }
  }, [loadView, noteCuid]);

  useEffect(() => {
    if (view.cuid) {
      form.setFieldsValue({
        active: view.active,
        content: view.content,
        title: view.title,
      });
    }
  }, [view, form]);

  const watchedValues = Form.useWatch([], form);

  return loadingView ? (
    <SpinLoading />
  ) : (
    <Form
      className="select-none"
      form={form}
      layout="vertical"
      onFinish={async (payload: UpdateNoteType) => {
        const isCreated = await updateNote(noteCuid, payload);
        if (isCreated) {
          onClose();
        }
      }}
      autoComplete="off"
    >
      <Form.Item<UpdateNoteType>
        name="title"
        label="Descrição"
        rules={[{ required: true, message: "Por favor, insira a descrição." }]}
      >
        <Input />
      </Form.Item>

      <Form.Item<UpdateNoteType>
        name="content"
        label="Nota de observação"
        rules={[{ required: true, message: "Por favor, insira a nota de observação." }]}
      >
        <TextArea rows={6} />
      </Form.Item>

      <Form.Item<UpdateNoteType> name="active" label="Status">
        <Segmented
          options={[
            { label: "Desbloqueado", value: true },
            { label: "Bloqueado", value: false },
          ]}
          value={view.active}
        />
      </Form.Item>

      <Form.Item>
        <div className="text-right">
          <Space size="small">
            <Button disabled={loadingUpdated} type="default" danger onClick={() => onClose()}>
              Cancelar
            </Button>
            <D3Can action="customer_note" resource="update">
              <Button
                disabled={!hasObjectChanges(view, { ...watchedValues })}
                loading={loadingUpdated}
                type="primary"
                htmlType="submit"
              >
                Atualizar
              </Button>
            </D3Can>
          </Space>
        </div>
      </Form.Item>
    </Form>
  );
};
