import { green, red } from "@ant-design/colors";
import D3Can from "@components/shared/D3Can";
import D3Status from "@components/shared/D3Status";
import { Icon } from "@iconify/react";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { ToggleLockAction } from "@utils/ToggleLockAction";
import { Badge, Button, Col, Pagination, Popconfirm, Popover, Progress, Row, Space, Tooltip } from "antd";
import Table from "antd/es/table";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CreateStorageForm } from "./components/CreateStorageForm";
import { SearchStorageForm } from "./components/SearchStorageForm";
import { UpdateStorageForm } from "./components/UpdateStorageForm";
import { ViewStorageForm } from "./components/ViewStorageForm";
import { useStoragesStore } from "./store";
import { RowStorageType } from "./types";

const StoragesView: React.FC = () => {
	const navigate = useNavigate();
	const { openDrawer } = useContext(D3DrawerContext);
	const {
		apiResult,
		pagination,
		loading,
		selectedRows,
		setPagination,
		setSort,
		loadStorages,
		lockStorage,
		unLockStorage,
		onMultiSelectedRowChange,
		resetFilter,
		resetState,
	} = useStoragesStore();
	const [openSearch, setOpenSearch] = useState(false);

	useEffect(() => {
		(async () => {
			await loadStorages();
		})();
		return () => {
			resetState();
		};
	}, [loadStorages]);

	return (
		<div className="flex flex-col h-full mb-1 mr-1 overflow-auto bg-white rounded shadow-md">
			<Table<RowStorageType>
				size="small"
				rowKey="cuid"
				dataSource={apiResult.data as RowStorageType[]}
				bordered
				className="responsive-table"
				pagination={false}
				loading={loading}
				title={() => {
					return (
						<>
							<div className="flex items-center justify-between w-full">
								<div className="flex items-center justify-start">
									<Popover
										open={openSearch}
										placement="rightTop"
										title="Pesquisar Golpões"
										content={
											<SearchStorageForm
												onCallBackFilter={() => setOpenSearch(false)}
												onCallBackResetFilter={() => {
													setOpenSearch(false);
													resetFilter();
												}}
											/>
										}
										trigger="click"
										onOpenChange={(open) => setOpenSearch(open)}
									>
										<Button
											onClick={() => setOpenSearch(!openSearch)}
											type="primary"
											size="middle"
											icon={<Icon icon="fluent:search-12-filled" height={16} width={16} />}
										>
											Pesquisar
										</Button>
									</Popover>
								</div>
								<div className="flex items-center justify-center">
									<span className="text-xl font-semibold text-gray-600">Galpões</span>
								</div>
								<div className="flex items-center justify-end">
									<D3Can action="create" resource="company_storage">
										<Button
											type="primary"
											size="middle"
											icon={<Icon icon="ic:round-plus" height={20} width={20} />}
											onClick={() => openDrawer(<CreateStorageForm />, "right", 600, "Cadastrar Galpão")}
										>
											Novo Galpão
										</Button>
									</D3Can>
								</div>
							</div>
						</>
					);
				}}
				rowSelection={{
					hideSelectAll: false,
					type: "checkbox",
					selectedRowKeys: selectedRows.cuids,
					onChange: (_, selectRows: RowStorageType[]) => {
						onMultiSelectedRowChange(selectRows);
					},
				}}
				onChange={(a, b, sorter: any) => {
					if (sorter) {
						setSort({
							sort_by: sorter.field,
							sort_direction: sorter.order,
						});
					}
				}}
				footer={() => {
					return (
						<>
							<div className="flex gap-4">
								<D3Can action="lock" resource="company_storage">
									<Badge count={selectedRows.cuids.length > 0 && selectedRows.active === true ? selectedRows.cuids.length : undefined}>
										<Popconfirm
											disabled={!(selectedRows.cuids.length > 0 && selectedRows.active === true)}
											title={`Atenção!`}
											description={`Confirma bloqueio ${selectedRows.cuids.length} ${selectedRows.cuids.length > 1 ? "itens" : "item"}?`}
											onConfirm={async () => {
												await lockStorage(selectedRows.cuids);
											}}
											okText="Sim"
											cancelText="Não"
											placement="top"
										>
											<Button
												type="primary"
												danger
												disabled={!(selectedRows.cuids.length > 0 && selectedRows.active === true)}
												size="middle"
												icon={<Icon icon="basil:lock-solid" height={16} width={16} />}
											>
												Bloquear
											</Button>
										</Popconfirm>
									</Badge>
								</D3Can>
								<D3Can action="unlock" resource="company_storage">
									<Badge color="green" count={selectedRows.cuids.length > 0 && selectedRows.active === false ? selectedRows.cuids.length : undefined}>
										<Popconfirm
											disabled={!(selectedRows.cuids.length > 0 && selectedRows.active === false)}
											title={`Atenção!`}
											description={`Confirma desbloqueio ${selectedRows.cuids.length} ${selectedRows.cuids.length > 1 ? "itens" : "item"}?`}
											onConfirm={async () => {
												await unLockStorage(selectedRows.cuids);
											}}
											okText="Sim"
											cancelText="Não"
											placement="top"
										>
											<Button
												className=""
												disabled={!(selectedRows.cuids.length > 0 && selectedRows.active === false)}
												size="middle"
												icon={<Icon icon="basil:unlock-solid" height={16} width={16} />}
											>
												Desbloquear
											</Button>
										</Popconfirm>
									</Badge>
								</D3Can>
							</div>

							<Space>
								<Pagination
									showLessItems
									showTotal={(total, range) => `Exibindo de ${range[0]} a ${range[1]} do total ${total} ${total > 1 ? "registros" : "registro"}`}
									showSizeChanger={true}
									current={pagination.page}
									onChange={setPagination}
									pageSize={pagination.pageSize}
									total={apiResult.total}
								/>
							</Space>
						</>
					);
				}}
			>
				<Table.Column<RowStorageType> showSorterTooltip={false} defaultSortOrder="ascend" key="id" title="Código" dataIndex="id" />
				<Table.Column<RowStorageType>
					showSorterTooltip={false}
					defaultSortOrder="ascend"
					key="key_document_storage"
					title="Galpão"
					dataIndex="key_document_storage"
				/>
				<Table.Column<RowStorageType>
					showSorterTooltip={false}
					defaultSortOrder="ascend"
					key="description"
					title="Descrição"
					dataIndex="description"
					sorter
				/>
				<Table.Column<RowStorageType>
					showSorterTooltip={false}
					defaultSortOrder="ascend"
					key="description"
					title="Ocupação"
					render={(value, storage, index) => {
						const percent = Number(((storage.number_of_repositories / storage.number_of_positions) * 100).toPrecision(2));

						return (
							<Progress
								steps={10}
								size={[8, 20]}
								percent={percent}
								strokeColor={[green[6], green[5], green[4], green[3], green[2], red[2], red[3], red[4], red[4], red[6]]}
								status="normal"
							/>
						);
					}}
				/>

				<Table.Column<RowStorageType>
					showSorterTooltip={false}
					key="active"
					title="Status"
					dataIndex="active"
					sorter
					render={(value, storage, index) => <D3Status status={storage.active} />}
				/>
				<Table.Column<RowStorageType>
					key="action"
					title="Ações"
					width={170}
					render={(value, storage, index) => {
						return (
							<>
								<Space>
									<D3Can action="view" resource="company_storage">
										<Tooltip title="Estantes" destroyTooltipOnHide mouseLeaveDelay={0} zIndex={11}>
											<Button
												type="text"
												shape="default"
												icon={
													<Icon
														icon="material-symbols:shelves-outline-rounded"
														height={18}
														width={18}
														className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
													/>
												}
												size={"middle"}
												onClick={() => navigate(`/app/company/storages/${storage.cuid}/shelves`)}
											/>
										</Tooltip>
									</D3Can>
									<D3Can action="update" resource="company_storage">
										<Tooltip title="Editar" destroyTooltipOnHide mouseLeaveDelay={0} zIndex={11}>
											<Button
												type="text"
												shape="default"
												icon={
													<Icon
														icon="iconamoon:edit-fill"
														height={18}
														width={18}
														className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
													/>
												}
												size={"middle"}
												onClick={() => openDrawer(<UpdateStorageForm cuid={storage.cuid} />, "right", 600, "Editar Galpão")}
											/>
										</Tooltip>
									</D3Can>
									<D3Can action="view" resource="company_storage">
										<Tooltip title="Visualizar" destroyTooltipOnHide mouseLeaveDelay={0} zIndex={11}>
											<Button
												className="peer"
												type="text"
												shape="default"
												icon={
													<Icon
														icon="carbon:document-view"
														height={18}
														width={18}
														className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
													/>
												}
												size={"middle"}
												onClick={() => openDrawer(<ViewStorageForm cuid={storage.cuid} />, "right", 600, "Visualizar Galpão")}
											/>
										</Tooltip>
									</D3Can>
									<D3Can action="lock" resource="company_storage">
										<ToggleLockAction
											description="Confirma bloqueio?"
											title="Atenção!"
											isVisible={storage.active}
											tooltip="Bloquear"
											paramsToAction={[storage.cuid]}
											action={async (params: string[]) => {
												await lockStorage(params);
											}}
										/>
									</D3Can>
									<D3Can action="unlock" resource="company_storage">
										<ToggleLockAction
											title="Atenção!"
											description="Confirma desbloqueio?"
											isVisible={!storage.active}
											tooltip="Desbloquear"
											paramsToAction={[storage.cuid]}
											action={async (params: string[]) => {
												await unLockStorage(params);
											}}
										/>
									</D3Can>
								</Space>
							</>
						);
					}}
				/>
			</Table>
		</div>
	);
};

export default StoragesView;
