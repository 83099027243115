import { create } from "zustand";
import {
  CreateMaterialType,
  ListMaterialType,
  LockOrUnlockPayloadType,
  RowMaterialType,
  SearchMaterialType,
  UpdateMaterialType,
  ViewMaterialType,
} from "./types";
import { BadRequest, ClientHttp, StandardError, Success } from "@utils/clientHttp";
import IsValidMultiSelectedRow from "@utils/table/IsValidMultiSelectedRow";
import { SetSort, SortType } from "@utils/table/SetSort";
import showMessage from "@utils/showMessage";
import D3QSGenerator, { FilterDefinition } from "@utils/D3QSGenerator";

type MaterialStoreState = {
  apiResult: ListMaterialType;
  selectedRows: { cuids: string[]; active: boolean };
  filter: SearchMaterialType;
  pagination: { page: number; pageSize: number };
  sort: { sort_by: string; sort_direction: string };
  loading: boolean;
  setFilter: (filter: SearchMaterialType) => void;
  setPagination: (page: number, pageSize: number) => void;
  setSort: (newSort: SortType) => void;
  loadMaterials: () => Promise<Success<ListMaterialType> | BadRequest | StandardError>;
  getMaterial: (cuid: string) => Promise<Success<ViewMaterialType> | StandardError>;
  createMaterial: (material: CreateMaterialType) => Promise<Success<{ cuid: string }> | BadRequest | StandardError>;
  updateMaterial: (cuid: string, material: UpdateMaterialType) => Promise<Success<void> | BadRequest | StandardError>;
  lockMaterial: (cuids: string[]) => Promise<void>;
  unLockMaterial: (cuids: string[]) => Promise<void>;
  onMultiSelectedRowChange: (selectedRows: RowMaterialType[]) => void;
  resetSelectedRows: () => void;
  resetFilter: () => void;
  resetState: () => void;
};

const defaultSort = {
  sort_by: "mat_description",
  sort_direction: "asc",
} as SortType;

const operationsFilter: FilterDefinition = {
  mat_description: { op: "lk", type: "string" },
  mat_active: { op: "eq", type: "active" },
};

const defaultState = {
  apiResult: {
    total: 0,
    data: [],
  },
  selectedRows: {
    cuids: [],
    active: false,
  },
  pagination: {
    page: 1,
    pageSize: 10,
  },
  filter: {
    mat_description: "",
    mat_active: "on",
  } as SearchMaterialType,
  sort: defaultSort,
  loading: false,
};

export const useMaterialsStore = create<MaterialStoreState>((set, get) => ({
  ...defaultState,
  resetState: () => set(defaultState),
  setFilter: (filter: SearchMaterialType) => {
    set({
      filter,
      selectedRows: { cuids: [], active: false },
      pagination: { ...get().pagination, page: 1 },
    });
    get().loadMaterials();
  },

  onMultiSelectedRowChange: (selectedRows: RowMaterialType[]): void => IsValidMultiSelectedRow(selectedRows, get, set),
  resetSelectedRows: () => {
    set({ selectedRows: { cuids: [], active: false } });
  },
  resetFilter: () => {
    set({ filter: { mat_description: "", mat_active: "on" } });
    get().loadMaterials();
  },
  setPagination: (page: number, pageSize: number) => {
    if (pageSize !== get().pagination.pageSize) {
      page = 1;
    }
    set({ pagination: { page, pageSize } });
    get().resetSelectedRows();
    get().loadMaterials();
  },
  setSort: (newSort: SortType) =>
    SetSort(
      newSort,
      get().sort,
      defaultSort,
      new Map<string, string>([
        ["description", "mat_description"],
        ["price", "mat_price"],
        ["active", "mat_active"],
      ]),
      set,
      get().loadMaterials
    ),
  loadMaterials: async (): Promise<Success<ListMaterialType> | BadRequest | StandardError> => {
    set({ loading: true });

    const qs = D3QSGenerator(get().filter, operationsFilter, get().pagination, get().sort);

    return await new ClientHttp().get<Success<ListMaterialType>, BadRequest | StandardError>(
      `/api/v1/system/materials?${qs}`,
      (result: Success<ListMaterialType>) => {
        let newPage = get().pagination.page;
        const { body } = result;
        if (body.total > 0 && body.data.length === 0) {
          newPage = newPage - 1;
          set({
            apiResult: body,
            loading: false,
            pagination: { ...get().pagination, page: newPage },
          });
          get().loadMaterials();
        } else {
          set({ apiResult: body, loading: false });
        }
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
  },
  getMaterial: async (cuid: string): Promise<Success<ViewMaterialType> | StandardError> => {
    return await new ClientHttp().get<Success<ViewMaterialType>, StandardError>(`/api/v1/system/materials/${cuid}`);
  },
  createMaterial: async (
    material: CreateMaterialType
  ): Promise<Success<{ cuid: string }> | BadRequest | StandardError> => {
    return await new ClientHttp().post<CreateMaterialType, Success<{ cuid: string }>, BadRequest | StandardError>(
      "/api/v1/system/materials",
      material,
      (result: Success<{ cuid: string }>) => {
        get().loadMaterials();
      }
    );
  },
  updateMaterial: async (
    cuid: string,
    material: UpdateMaterialType
  ): Promise<Success<void> | BadRequest | StandardError> => {
    return await new ClientHttp().put<UpdateMaterialType, Success<void>, BadRequest | StandardError>(
      `/api/v1/system/materials/${cuid}`,
      material,
      (result: Success<void>) => {
        get().loadMaterials();
      }
    );
  },
  lockMaterial: async (cuids: string[]): Promise<void> => {
    await new ClientHttp().patch<
      LockOrUnlockPayloadType,
      Success<{ rows_affected: number }>,
      BadRequest | StandardError
    >(
      `/api/v1/system/materials/lock`,
      { cuids: cuids },
      (result: Success<{ rows_affected: number }>) => {
        get().resetSelectedRows();
        get().loadMaterials();
        if (result.body.rows_affected > 1) {
          showMessage(result, "Itens bloqueados com sucesso.");
        } else {
          showMessage(result, "Item bloqueado com sucesso.");
        }
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
  },
  unLockMaterial: async (cuids: string[]): Promise<void> => {
    await new ClientHttp().patch<
      LockOrUnlockPayloadType,
      Success<{ rows_affected: number }>,
      BadRequest | StandardError
    >(
      `/api/v1/system/materials/unlock`,
      { cuids: cuids },
      (result: Success<{ rows_affected: number }>) => {
        get().resetSelectedRows();
        get().loadMaterials();
        if (result.body.rows_affected > 1) {
          showMessage(result, "Itens desbloqueados com sucesso.");
        } else {
          showMessage(result, "Item desbloqueado com sucesso.");
        }
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
  },
}));
