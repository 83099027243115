import D3Can from "@components/shared/D3Can";
import D3Status from "@components/shared/D3Status";
import { Icon } from "@iconify/react";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { ToggleLockAction } from "@utils/ToggleLockAction";
import { Badge, Button, Col, Pagination, Popconfirm, Popover, Row, Space, Tooltip } from "antd";
import Table from "antd/es/table";
import { useContext, useEffect, useState } from "react";
import { CreateCustomerForm } from "./components/CreateCustomerForm";
import { SearchCustomerForm } from "./components/SearchCustomerForm";
import { UpdateCustomerForm } from "./components/UpdateCustomerForm";
import { ViewCustomerForm } from "./components/ViewCustomerForm";
import { useCustomersStore } from "./store";
import { RowCustomerType } from "./types";

const CustomersView: React.FC = () => {
	const { openDrawer } = useContext(D3DrawerContext);
	const {
		apiResult,
		pagination,
		loading,
		selectedRows,
		setPagination,
		setSort,
		loadCustomers,
		lockCustomer,
		unLockCustomer,
		onMultiSelectedRowChange,
		resetFilter,
		resetState,
	} = useCustomersStore();

	const [openSearch, setOpenSearch] = useState(false);

	useEffect(() => {
		(async () => {
			await loadCustomers();
		})();
		return () => {
			resetState();
		};
	}, [loadCustomers]);

	return (
		<div className="flex flex-col h-full mb-1 mr-1 overflow-auto bg-white rounded shadow-md">
			<Table<RowCustomerType>
				size="small"
				rowKey="cuid"
				dataSource={apiResult.data as RowCustomerType[]}
				bordered
				className="responsive-table"
				pagination={false}
				loading={loading}
				title={() => {
					return (
						<>
							<div className="flex items-center justify-between w-full">
								<div className="flex items-center justify-start">
									<Popover
										open={openSearch}
										placement="rightTop"
										title="Pesquisar Clientes"
										content={
											<SearchCustomerForm
												onCallBackFilter={() => setOpenSearch(false)}
												onCallBackResetFilter={() => {
													setOpenSearch(false);
													resetFilter();
												}}
											/>
										}
										trigger="click"
										onOpenChange={(open) => setOpenSearch(open)}
									>
										<Button
											onClick={() => setOpenSearch(!openSearch)}
											type="primary"
											size="middle"
											icon={<Icon icon="fluent:search-12-filled" height={16} width={16} />}
										>
											Pesquisar
										</Button>
									</Popover>
								</div>
								<div className="flex items-center justify-center">
									<span className="text-xl font-semibold text-gray-600">Clientes</span>
								</div>
								<div className="flex items-center justify-end">
									<D3Can action="create" resource="customer">
										<Button
											type="primary"
											size="middle"
											icon={<Icon icon="ic:round-plus" height={20} width={20} />}
											onClick={() => openDrawer(<CreateCustomerForm />, "right", 646, "Cadastrar Cliente")}
										>
											Novo Cliente
										</Button>
									</D3Can>
								</div>
							</div>
						</>
					);
				}}
				rowSelection={{
					hideSelectAll: false,
					type: "checkbox",
					selectedRowKeys: selectedRows.cuids,
					onChange: (_, selectRows: RowCustomerType[]) => {
						onMultiSelectedRowChange(selectRows);
					},
				}}
				onChange={(a, b, sorter: any) => {
					if (sorter) {
						setSort({
							sort_by: sorter.field,
							sort_direction: sorter.order,
						});
					}
				}}
				footer={() => {
					return (
						<>
							<div className="flex gap-4">
								<D3Can action="lock" resource="customer">
									<Badge count={selectedRows.cuids.length > 0 && selectedRows.active === true ? selectedRows.cuids.length : undefined}>
										<Popconfirm
											disabled={!(selectedRows.cuids.length > 0 && selectedRows.active === true)}
											title={`Atenção!`}
											description={`Confirma bloqueio ${selectedRows.cuids.length} ${selectedRows.cuids.length > 1 ? "itens" : "item"}?`}
											onConfirm={async () => {
												await lockCustomer(selectedRows.cuids);
											}}
											okText="Sim"
											cancelText="Não"
											placement="top"
										>
											<Button
												type="primary"
												danger
												disabled={!(selectedRows.cuids.length > 0 && selectedRows.active === true)}
												size="middle"
												icon={<Icon icon="basil:lock-solid" height={16} width={16} />}
											>
												Bloquear
											</Button>
										</Popconfirm>
									</Badge>
								</D3Can>
								<D3Can action="unlock" resource="customer">
									<Badge color="green" count={selectedRows.cuids.length > 0 && selectedRows.active === false ? selectedRows.cuids.length : undefined}>
										<Popconfirm
											disabled={!(selectedRows.cuids.length > 0 && selectedRows.active === false)}
											title={`Atenção!`}
											description={`Confirma desbloqueio ${selectedRows.cuids.length} ${selectedRows.cuids.length > 1 ? "itens" : "item"}?`}
											onConfirm={async () => {
												await unLockCustomer(selectedRows.cuids);
											}}
											okText="Sim"
											cancelText="Não"
											placement="top"
										>
											<Button
												className=""
												disabled={!(selectedRows.cuids.length > 0 && selectedRows.active === false)}
												size="middle"
												icon={<Icon icon="basil:unlock-solid" height={16} width={16} />}
											>
												Desbloquear
											</Button>
										</Popconfirm>
									</Badge>
								</D3Can>
							</div>

							<Space>
								<Pagination
									showLessItems
									showTotal={(total, range) => `Exibindo de ${range[0]} a ${range[1]} do total ${total} ${total > 1 ? "registros" : "registro"}`}
									showSizeChanger={true}
									current={pagination.page}
									onChange={setPagination}
									pageSize={pagination.pageSize}
									total={apiResult.total}
								/>
							</Space>
						</>
					);
				}}
			>
				<Table.Column<RowCustomerType> width={90} showSorterTooltip={false} key="id" title="Código" dataIndex="id" sorter />
				<Table.Column<RowCustomerType>
					showSorterTooltip={false}
					defaultSortOrder="ascend"
					key="fantasy_name"
					title="Nome Fantasia"
					dataIndex="fantasy_name"
					sorter
				/>
				<Table.Column<RowCustomerType> showSorterTooltip={false} key="name" title="Razão Social" dataIndex="name" sorter />
				<Table.Column<RowCustomerType>
					showSorterTooltip={false}
					key="created_at"
					title="Data Inclusão"
					dataIndex="created_at"
					width={150}
					render={(value, customer, index) => {
						return new Date(customer.created_at).toLocaleDateString("pt-BR");
					}}
				/>

				<Table.Column<RowCustomerType>
					showSorterTooltip={false}
					key="active"
					title="Status"
					dataIndex="active"
					sorter
					width={150}
					render={(value, customer, index) => <D3Status status={customer.active} />}
				/>
				<Table.Column<RowCustomerType>
					key="action"
					title="Ações"
					width={130}
					render={(value, customer) => {
						return (
							<>
								<Space>
									<D3Can action="update" resource="customer">
										<Tooltip title="Editar" destroyTooltipOnHide mouseLeaveDelay={0} zIndex={11}>
											<Button
												type="text"
												shape="default"
												icon={
													<Icon
														icon="iconamoon:edit-fill"
														height={18}
														width={18}
														className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
													/>
												}
												size={"middle"}
												onClick={() => openDrawer(<UpdateCustomerForm cuid={customer.cuid} />, "right", 646, "Editar Cliente")}
											/>
										</Tooltip>
									</D3Can>
									<D3Can action="view" resource="customer">
										<Tooltip title="Visualizar" destroyTooltipOnHide mouseLeaveDelay={0} zIndex={11}>
											<Button
												className="peer"
												type="text"
												shape="default"
												icon={
													<Icon
														icon="carbon:document-view"
														height={18}
														width={18}
														className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
													/>
												}
												size={"middle"}
												onClick={() => openDrawer(<ViewCustomerForm cuid={customer.cuid} />, "right", 646, "Visualizar Cliente")}
											/>
										</Tooltip>
									</D3Can>
									<D3Can action="lock" resource="customer">
										<ToggleLockAction
											description="Confirma bloqueio?"
											title="Atenção!"
											isVisible={customer.active}
											tooltip="Bloquear"
											paramsToAction={[customer.cuid]}
											action={async (params: string[]) => {
												await lockCustomer(params);
											}}
										/>
									</D3Can>
									<D3Can action="unlock" resource="customer">
										<ToggleLockAction
											title="Atenção!"
											description="Confirma desbloqueio?"
											isVisible={!customer.active}
											tooltip="Desbloquear"
											paramsToAction={[customer.cuid]}
											action={async (params: string[]) => {
												await unLockCustomer(params);
											}}
										/>
									</D3Can>
								</Space>
							</>
						);
					}}
				/>
			</Table>
		</div>
	);
};

export default CustomersView;
