import { Icon } from "@iconify/react";
import { Button, Divider, Timeline } from "antd";
import { useContext, useEffect } from "react";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { IncidentForm } from "../incident/IncidentForm";
import SpinLoading from "@components/shared/SpinLoading";
import { useIncidentsStore } from "../incident/store";
import D3DateHourSorter from "@utils/D3DateHourSorter";

function TabIncident() {
  const { openDrawer } = useContext(D3DrawerContext);
  const { loadNonPayments, loading, listNonPayments } = useIncidentsStore();

  useEffect(() => {
    (async () => {
      await loadNonPayments();
    })();
  }, [loadNonPayments]);

  const renderItems = () => {
    if (listNonPayments.length > 0) {
      return listNonPayments.map(
        ({
          active,
          added_in,
          name_user_added,
          name_user_removed,
          removed_in,
          non_payment_reason,
          removing_non_payment_reason,
        }) => {
          return {
            color: !active ? "green" : "red",
            status: active ? "true" : "false",
            children: (
              <div className="flex flex-col">
                {!active && (
                  <>
                    <span>
                      <strong>Encerrada</strong>
                    </span>
                    <span>
                      <strong>Motivo:</strong>
                      {` ${removing_non_payment_reason}`}
                    </span>
                    <span>
                      <strong>Autor:</strong> {name_user_removed}
                    </span>
                    <span>
                      <strong>Data:</strong> {D3DateHourSorter(removed_in)}
                    </span>
                    <Divider className="my-1" />
                  </>
                )}
                <span>
                  <strong>Registrada</strong>
                </span>
                <span>
                  <strong>Motivo:</strong>
                  {` ${non_payment_reason}`}
                </span>
                <span>
                  <strong>Autor:</strong> {name_user_added}
                </span>
                <span>
                  <strong>Data:</strong> {D3DateHourSorter(added_in)}
                </span>
              </div>
            ),
          };
        }
      );
    } else {
      return undefined;
    }
  };

  const isActive = listNonPayments.length > 0 ? listNonPayments[0].active : false;

  return (
    <div className="flex flex-col h-full p-2 overflow-hidden">
      <div className="flex-none">
        <div className="flex items-center justify-between mb-2">
          <h2 className="ml-1 text-lg font-semibold text-gray-600">Registros de inadimplência</h2>
          {!loading && (
            <Button
              className="flex items-center justify-start"
              type="primary"
              size="middle"
              danger={isActive && loading === false ? false : true}
              icon={<Icon icon="ion:list-sharp" height={18} width={18} />}
              onClick={() =>
                openDrawer(
                  <IncidentForm />,
                  "right",
                  600,
                  loading === false && isActive
                    ? "Encerrar ocorrência de inadimplência"
                    : "Registrar ocorrência de inadimplência"
                )
              }
            >
              {loading === false && isActive ? "Encerrar ocorrência" : "Registrar ocorrência"}
            </Button>
          )}
        </div>
        <Divider className="my-1" />
      </div>
      <div className="flex-grow overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100">
        {loading ? <SpinLoading /> : <Timeline className="py-2" items={renderItems()} />}
      </div>
    </div>
  );
}

export default TabIncident;
