import useAuthStore from "@provider/AuthProvider";
import { ClientHttp, StandardError, Success } from "@utils/clientHttp";
import showMessage from "@utils/showMessage";
import { create } from "zustand";
import { ItemEntityType, SimpleListEntityType } from "../types";

type D3SelectEntityState = {
  loading: boolean;
  isOpen: boolean;
  simpleListEntity: ItemEntityType[];
  loadSimpleListEntity: () => Promise<void>;
  onChangeToken: (selectedTypeAndEntityCuid: string) => Promise<void>;
  onResetToken: () => void;
  getCustomerCuid: () => string | undefined;
  getCustomerName: () => string | undefined;
  setIsOpen: (isOpen: boolean) => void;
  getSelectedTypeAndEntityCuid: () => string | undefined;
};

const defaultState = {
  loading: true,
  isOpen: true,
  simpleListEntity: [],
};

export const useD3SelectEntityStore = create<D3SelectEntityState>((set, get) => ({
  ...defaultState,
  loadSimpleListEntity: async (): Promise<void> => {
    set({ loading: true });
    await new ClientHttp().getItensForSelect<Success<SimpleListEntityType>, StandardError, void>(
      `/api/v1/system/select/simple-list`,
      (result: Success<SimpleListEntityType>): void => {
        set({
          simpleListEntity: result.body.data as ItemEntityType[],
          isOpen: false,
          loading: false,
        });
      },
      (error: StandardError): void => {
        showMessage(error);
        set({
          simpleListEntity: [],
          loading: false,
        });
      }
    );
  },

  onChangeToken: async (selectedTypeAndEntityCuid: string): Promise<void> => {
    useAuthStore
      .getState()
      .updateTokenByTypeAndEntityCuid(selectedTypeAndEntityCuid)
      .then((isSuccess) => {
        set({ isOpen: false });
        return isSuccess;
      });
  },
  onResetToken: async () => {
    await useAuthStore.getState().resetToken();
  },
  getCustomerCuid: (): string | undefined => {
    return useAuthStore.getState().getCustomerCuid();
  },
  getSelectedTypeAndEntityCuid: (): string | undefined => {
    return useAuthStore.getState().selectedTypeAndEntityCuid;
  },

  getCustomerName: (): string | undefined => {
    const customerCuid = get().getCustomerCuid();
    const customer = get().simpleListEntity.filter((item) => item.cuid === customerCuid);
    return customer[0]?.fantasy_name;
  },

  setIsOpen: (isOpen: boolean) => {
    set({ isOpen });
  },
}));
