import { useContext, useEffect, useState } from "react";

import { Button, Form, Input, Radio, Space, Spin, Table, Tabs, Tag } from "antd";
import { useServicesStore } from "../store";
import { CreateServiceType, OperationType, ViewServiceType } from "../types";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import showMessage from "@utils/showMessage";
import { ColumnType, Data } from "../data/data";
import { Icon } from "@iconify/react";

import { D3Date } from "@utils/date";
import { UpdateServiceForm } from "./UpdateServiceForm";
import D3Can from "@components/shared/D3Can";

type ViewServiceProps = {
  cuid: string;
};

export const ViewServiceForm = ({ cuid }: ViewServiceProps) => {
  const { getService } = useServicesStore();
  const { onClose, openDrawer } = useContext(D3DrawerContext);
  const [form] = Form.useForm<ViewServiceType>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const result = await getService(cuid);
      if (result.status === "success") {
        const viewMaterial = result.body as ViewServiceType;
        form.setFieldsValue({
          ...result.body,
          created_at: D3Date.FormatValueApiToPtBR(viewMaterial.created_at),
          updated_at: D3Date.FormatValueApiToPtBR(viewMaterial.updated_at),
        });
        setLoading(false);
      } else {
        showMessage(result);
      }
    })();
  }, [cuid, form, getService]);

  return (
    <>
      <Spin spinning={loading}>
        <Form className="select-none" form={form} layout="vertical">
          <Tabs
            defaultActiveKey="1"
            className="select-none"
            items={[
              {
                key: "1",
                label: (
                  <span className="flex items-center justify-center gap-2 ">
                    <Icon icon="fluent:form-24-filled" className="w-5 h-5" />
                    Dados
                  </span>
                ),
                children: (
                  <>
                    <Form.Item<ViewServiceType> name="description" label="Descrição">
                      <Input disabled />
                    </Form.Item>

                    <Form.Item<ViewServiceType> name="active" label="Status">
                      <Radio.Group
                        options={[
                          { label: "Desbloqueado", value: true },
                          { label: "Bloqueado", value: false },
                        ]}
                        disabled
                      />
                    </Form.Item>

                    <Form.Item<ViewServiceType> name="created_at" label="Criado em">
                      <Input disabled />
                    </Form.Item>
                    <Form.Item<ViewServiceType> name="updated_at" label="Atualizado em">
                      <Input disabled />
                    </Form.Item>
                  </>
                ),
              },
              {
                key: "2",
                label: (
                  <span className="flex items-center justify-center gap-2 ">
                    <Icon icon="carbon:settings-services" className="w-5 h-5" />
                    Condições
                  </span>
                ),
                children: (
                  <>
                    <Form.Item<CreateServiceType> label="Condições do serviço">
                      <Table
                        className="mb-5 table-service"
                        key="key"
                        size="small"
                        bordered
                        pagination={false}
                        dataSource={Data}
                      >
                        <Table.Column<ColumnType>
                          showSorterTooltip={false}
                          defaultSortOrder="ascend"
                          key="description"
                          title="Descrição"
                          dataIndex="description"
                        />

                        <Table.Column<ColumnType>
                          className="register-service"
                          showSorterTooltip={false}
                          defaultSortOrder="ascend"
                          key="radio"
                          title="Sim ou Não"
                          dataIndex="radio"
                          render={(text, record) => (
                            <Form.Item initialValue={record.radio} name={record.name} className="register-service">
                              <Radio.Group
                                disabled
                                size="small"
                                options={[
                                  { label: "Sim", value: true },
                                  { label: "Não", value: false },
                                ]}
                                value={record.radio}
                                optionType="button"
                                buttonStyle="solid"
                              />
                            </Form.Item>
                          )}
                        />
                      </Table>
                    </Form.Item>
                  </>
                ),
              },
              {
                key: "3",
                label: (
                  <span className="flex items-center justify-center gap-2 ">
                    <Icon icon="carbon:operations-record" className="w-5 h-5" />
                    Operações
                  </span>
                ),
                children: (
                  <>
                    <Form.Item label="Operações do serviço" name="operations">
                      <Table
                        rowKey="operation_id"
                        size="small"
                        dataSource={form.getFieldValue("operations")}
                        bordered
                        pagination={false}
                      >
                        <Table.Column<OperationType>
                          showSorterTooltip={false}
                          defaultSortOrder="ascend"
                          key="operation"
                          title="Operação"
                          dataIndex="operation_description"
                        />
                        <Table.Column<OperationType>
                          showSorterTooltip={false}
                          defaultSortOrder="ascend"
                          key="active"
                          title="Status"
                          dataIndex="active"
                          render={(value, record) =>
                            record.active ? (
                              <Tag
                                className="flex items-center justify-start gap-1 max-w-max"
                                icon={<Icon icon="basil:unlock-solid" height={14} width={14} />}
                                color="success"
                              >
                                Desbloqueado
                              </Tag>
                            ) : (
                              <Tag
                                className="flex items-center justify-start gap-1 max-w-max"
                                icon={<Icon icon="basil:lock-solid" height={14} width={14} />}
                                color="error"
                              >
                                Bloqueado
                              </Tag>
                            )
                          }
                        />

                        <Table.Column<OperationType>
                          showSorterTooltip={false}
                          defaultSortOrder="ascend"
                          key="variable"
                          title="Variável"
                          render={(text, record) => <>{record.variable ? <>Sim</> : <>Não</>}</>}
                        />
                        <Table.Column<OperationType>
                          showSorterTooltip={false}
                          defaultSortOrder="ascend"
                          key="repository"
                          title="Repositório"
                          render={(text, record) => <>{record.repository ? <>Sim</> : <>Não</>}</>}
                        />
                      </Table>
                    </Form.Item>
                  </>
                ),
              },
            ]}
          />
          <Form.Item>
            <div className="text-right">
              <Space size="small">
                <Button type="default" danger onClick={() => onClose()}>
                  Cancelar
                </Button>
                <D3Can action="update" resource="system_service">
                  <Button
                    type="primary"
                    onClick={() => openDrawer(<UpdateServiceForm cuid={cuid} />, "right", 600, "Editar Serviço")}
                  >
                    Editar
                  </Button>
                </D3Can>
              </Space>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};
