export const enumSituation:Map<string, string> = new Map<string, string>([
  [ "STO","Armazenado" ],
  [ "REG","Cadastrado" ],
  [ "PUR","Expurgado" ],
  [ "DEL","Deletado" ],
  [ "MIG","Em migração" ],
  [ "REQ","Solicitado" ],
  [ "PRO","Em processamento" ],
]);

function D3RepositorySituation(key: string): string | undefined {
  return enumSituation.get(key);
}


export default D3RepositorySituation;

