import { Icon } from "@iconify/react";
import { Button, Input } from "antd";
import { ToolItems } from "./itens";
import { useContext, useState } from "react";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import type { SearchProps } from "antd/es/input/Search";
const { Search } = Input;

function AdministrativeTools() {
  const { openDrawer } = useContext(D3DrawerContext);
  const [itens, setItens] = useState(ToolItems);

  const onSearch: SearchProps["onSearch"] = (value) => {
    if (value) {
      const filteredItems = ToolItems.filter((item) =>
        item.Children.some((child) =>
          child.label.toLowerCase().includes(value.toLowerCase())
        )
      );
      setItens(filteredItems);
    } else {
      setItens(ToolItems);
    }
  };

  return (
    <>
      <div className="flex flex-col h-full mb-1 mr-1 overflow-hidden bg-white rounded shadow select-none">
        <div className="grid items-center grid-cols-3 p-2 border-b border-gray-100 min-h-10">
          <div>
            <Search
              className="w-56"
              placeholder="Pesquisar ferramentas"
              onSearch={onSearch}
              enterButton
            />
          </div>
          <div className="text-center">
            <span className="text-xl font-semibold text-gray-600">
              Ferramentas Administrativas
            </span>
          </div>
        </div>
        <div className="overflow-y-auto grow scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100">
          <div>
            {itens.map((item) => (
              <div key={item.key} className="p-2">
                <div className="mb-2 text-base text-gray-700 border-b border-gray-200 border-dashed">
                  {item.grupo}
                </div>
                <div className="flex items-center justify-start gap-2 p-1">
                  {item.Children.map((chil) => (
                    <Button
                      key={chil.key}
                      className="flex items-center justify-start text-gray-500"
                      type="dashed"
                      icon={<Icon height={20} icon={chil.icon} />}
                      size="middle"
                      onClick={() =>
                        openDrawer(chil.drawer, "right", 600, chil.label)
                      }
                    >
                      {chil.label}
                    </Button>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default AdministrativeTools;
