import { EditOutlined, LockOutlined, PlusCircleOutlined, UnlockOutlined } from "@ant-design/icons";
import { Spin, Timeline, DatePicker, Space } from "antd";

const { RangePicker } = DatePicker;

function TabLogs() {
  return (
    <>
      <Spin spinning={false}>
        <Space direction="vertical" className="mt-2" size={12}>
          <RangePicker format="DD/MM/YYYY" allowClear />
          <Timeline
            reverse
            className="p-4"
            items={[
              {
                color: "blue",
                children: "Acessou 30/10/23 09:20",
              },
              {
                color: "blue",
                dot: <PlusCircleOutlined />,
                children: (
                  <>
                    <p>Cadastro de Material</p>
                    <p>30/10/23 09:55</p>
                  </>
                ),
              },
              {
                color: "red",
                dot: <LockOutlined />,
                children: (
                  <>
                    <p>Bloqueio de Operação</p>
                    <p>30/10/23 11:20</p>
                  </>
                ),
              },

              {
                color: "blue",
                dot: <EditOutlined />,
                children: (
                  <>
                    <p>Editou de Tipo de Repositório</p>
                    <p>30/10/23 15:45</p>
                  </>
                ),
              },
              {
                color: "green",
                dot: <UnlockOutlined />,
                children: (
                  <>
                    <p>Desbloqueio de Usuário</p>
                    <p>30/10/23 17:45</p>
                  </>
                ),
              },
              {
                color: "red",
                children: "Saiu 30/10/23 19:02",
              },
            ]}
          />
        </Space>
      </Spin>
    </>
  );
}

export default TabLogs;
