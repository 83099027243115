import { useState } from "react";

type SelectRowProps = {
    row: number;
    click: (row: number, newValue: boolean) => void;
}

const SelectRow: React.FC<SelectRowProps> = ({row, click}) => {
    const [selected, setSelected] = useState<boolean>(false);
    return <div className="px-2 py-6 border-2" onClick={() => {
        setSelected(!selected);
        click(row, !selected);
    }} style={{ cursor: "pointer" }}>{`P${row}`}</div>
};

export default SelectRow;