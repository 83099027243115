import { useContext, useState } from "react";

import { Button, Form, Input, InputNumber, Space, Spin } from "antd";

import { useShelvesStore } from "../store";
import { CreateShelfType } from "../types";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import showMessage from "@utils/showMessage";
import D3Can from "@components/shared/D3Can";

export const CreateShelfForm = () => {
  const { createShelf } = useShelvesStore();
  const { onClose } = useContext(D3DrawerContext);
  const [form] = Form.useForm<CreateShelfType>();
  const [loading, setLoading] = useState(false);
  return (
    <>
      <Spin spinning={loading}>
        <Form
          className="select-none"
          form={form}
          layout="vertical"
          onFinish={async (payload: CreateShelfType) => {
            setLoading(true);
            const result = await createShelf(payload);
            if (result.status === "success") {
              showMessage(result, "Estante cadastrada com sucesso.");
              onClose();
            } else {
              showMessage(result);
            }
            setLoading(false);
          }}
          autoComplete="off"
        >
          <Form.Item<CreateShelfType>
            label="Abreviação"
            name="abbreviation"
            normalize={(value) => value.toUpperCase()}
            rules={[
              {
                required: true,
                message: "Por favor, insira abreviação.",
              },
              {
                pattern: /^[A-Z0-9]{1,3}$/,
                message:
                  "A abreviação deve conter de 1 a 3 caracteres, que podem ser letras ou números, sem incluir caracteres especiais ou o 'ç'.",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item<CreateShelfType>
            label="Nº de Prateleiras"
            name="number_of_rows"
            rules={[
              {
                required: true,
                message: "Por favor, insira nº de prateleiras.",
              },
              {
                type: "number",
                min: 1,
                message: "O valor deve ser maior que 0.",
              },
            ]}
          >
            <InputNumber min={1} className="w-32" />
          </Form.Item>
          <Form.Item<CreateShelfType>
            label="Nº de Vãos"
            name="number_of_columns"
            rules={[
              { required: true, message: "Por favor, insira nº de vãos." },
              {
                type: "number",
                min: 1,
                message: "O valor deve ser maior que 0",
              },
            ]}
          >
            <InputNumber className="w-32" min={1} />
          </Form.Item>

          <Form.Item>
            <div className="text-right">
              <Space size="small">
                <Button type="default" danger onClick={() => onClose()}>
                  Cancelar
                </Button>
                <D3Can action="create" resource="company_storage">
                  <Button type="primary" htmlType="submit">
                    Cadastrar
                  </Button>
                </D3Can>
              </Space>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};
