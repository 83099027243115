import { create } from "zustand";
import showMessage from "@utils/showMessage";
import { ClientHttp, BadRequest, StandardError, Success } from "@utils/clientHttp";
import {
  CreateAmendmentType,
  CreateContractType,
  ItemsListContractsType,
  ListContractsType,
  CreationResponseType,
  UpdateContractType,
  ViewContractType,
  ViewFileContractType,
  CreateRescissionType,
  UploadContractType,
} from "./types";

type ContractStoreState = {
  loading: boolean;
  listOfContracts: ItemsListContractsType[];
  loadContracts: () => Promise<void>;

  loadingCreateContract: boolean;
  createContract: (payload: CreateContractType) => Promise<boolean>;

  loadingView: boolean;
  viewContract: (contractCuid: string) => Promise<void>;
  viewContractForm: ViewContractType;

  loadingUpdate: boolean;
  updateContract: (
    contractCuid: string,
    contractType: "C" | "A" | "R",
    payload: UpdateContractType
  ) => Promise<boolean>;

  loadingCreateAmendment: boolean;
  createAmendment: (contractCuid: string, payload: CreateAmendmentType) => Promise<boolean>;

  loadingViewFile: boolean;
  viewFileContract: (contractCuid: string) => Promise<void>;
  viewFileUrl: string;

  loadingCreateRescission: boolean;
  createRescission: (contractCuid: string, payload: CreateRescissionType) => Promise<boolean>;

  uploadContract: (contractCuid: string) => Promise<boolean>;
  uploadUrl: string;

  resetState: () => void;
};

const defaultState = {
  loadingView: false,
  viewContractForm: {} as ViewContractType,

  loadingViewFile: false,
  viewFileUrl: "",

  uploadUrl: "",
};

const keepState = {
  loading: false,
  listOfContracts: [],

  loadingCreateContract: false,

  loadingUpdate: false,

  loadingCreateAmendment: false,

  loadingCreateRescission: false,
};

export const useContractStore = create<ContractStoreState>((set, get) => ({
  ...defaultState,
  ...keepState,
  resetState: () => set(defaultState),
  loadContracts: async (): Promise<void> => {
    set({ loading: true });
    await new ClientHttp().get<Success<ListContractsType>, BadRequest | StandardError>(
      `/api/v1/customer/contracts`,
      (result: Success<ListContractsType>) => {
        set({
          listOfContracts: result.body.data,
          loading: false,
        });
      },
      (error: BadRequest | StandardError) => {
        set({
          loading: false,
        });
        showMessage(error);
      }
    );
  },
  createContract: async (payload: CreateContractType): Promise<boolean> => {
    set({ loadingCreateContract: true });
    const result = await new ClientHttp().post<
      CreateContractType,
      Success<CreationResponseType>,
      BadRequest | StandardError
    >(
      "/api/v1/customer/contracts",
      payload,
      (result: Success<CreationResponseType>) => {
        showMessage(result, "Contrato cadastrado com sucesso.");
        set({ loadingCreateContract: false });
        get().loadContracts();
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
        set({ loadingCreateContract: false });
      }
    );
    return result.status === "success";
  },

  viewContract: async (contractCuid: string): Promise<void> => {
    set({ loadingView: true });
    await new ClientHttp().get<Success<ViewContractType>, BadRequest | StandardError>(
      `/api/v1/customer/contracts/${contractCuid}`,
      (result: Success<ViewContractType>) => {
        set({
          viewContractForm: result.body,
          loadingView: false,
        });
      },
      (error: BadRequest | StandardError) => {
        set({
          loadingView: false,
        });
        showMessage(error);
      }
    );
  },

  updateContract: async (
    contractCuid: string,
    contractType: "C" | "A" | "R",
    payload: UpdateContractType
  ): Promise<boolean> => {
    set({ loadingUpdate: true });
    const result = await new ClientHttp().put<UpdateContractType, Success<null>, BadRequest | StandardError>(
      `/api/v1/customer/contracts/${contractCuid}`,
      payload,
      (result: Success<null>) => {
        showMessage(
          result,
          `${contractType === "C" ? "Contrato" : contractType === "A" ? "Aditivo" : "Distrato"} atualizado com sucesso.`
        );
        set({ loadingUpdate: false });
        get().loadContracts();
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
        set({ loadingUpdate: false });
      }
    );
    return result.status === "success";
  },
  createAmendment: async (contractCuid: string, payload: CreateAmendmentType): Promise<boolean> => {
    set({ loadingCreateAmendment: true });
    const result = await new ClientHttp().post<
      CreateAmendmentType,
      Success<CreationResponseType>,
      BadRequest | StandardError
    >(
      `/api/v1/customer/contracts/${contractCuid}/amendment`,
      payload,
      (result: Success<CreationResponseType>) => {
        showMessage(result, "Aditivo cadastrado com sucesso.");
        set({ loadingCreateAmendment: false });
        get().loadContracts();
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
        set({ loadingCreateAmendment: false });
      }
    );
    return result.status === "success";
  },
  viewFileContract: async (contractCuid: string): Promise<void> => {
    set({ loadingViewFile: true });
    await new ClientHttp().get<Success<ViewFileContractType>, BadRequest | StandardError>(
      `/api/v1/customer/contracts/${contractCuid}/print`,
      (result: Success<ViewFileContractType>) => {
        set({
          viewFileUrl: result.body.url,
          loadingViewFile: false,
        });
      },
      (error: BadRequest | StandardError) => {
        set({
          loadingViewFile: false,
        });
        showMessage(error);
      }
    );
  },
  createRescission: async (contractCuid: string, payload: CreateRescissionType): Promise<boolean> => {
    set({ loadingCreateRescission: true });
    const result = await new ClientHttp().post<
      CreateRescissionType,
      Success<CreationResponseType>,
      BadRequest | StandardError
    >(
      `/api/v1/customer/contracts/${contractCuid}/rescission`,
      payload,
      (result: Success<CreationResponseType>) => {
        showMessage(result, "Distrato cadastrado com sucesso.");
        set({ loadingCreateRescission: false });
        get().loadContracts();
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
        set({ loadingCreateRescission: false });
      }
    );
    return result.status === "success";
  },
  uploadContract: async (contractCuid: string): Promise<boolean> => {
    const result = await new ClientHttp().post<null, Success<UploadContractType>, BadRequest | StandardError>(
      `/api/v1/customer/contracts/${contractCuid}/upload`,
      null,
      (result: Success<UploadContractType>) => {
        set({
          uploadUrl: result.body.url,
        });
        get().loadContracts();
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
    return result.status === "success";
  },
}));
