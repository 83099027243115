import { Skeleton } from "antd";

function SkeletonFile() {
  return (
    <div
      className="
        flex flex-col
        border-[1px] 
        border-gray-100 
        m-2  
        text-gray-500 
        transition-all duration-300 ease-in-out 
        rounded 
        select-none  
        min-w-60 max-w-60 max-h-48 min-h-48 
        hover:drop-shadow-lg 
        bg-gray-50
        hover:bg-gray-100
        cursor-pointer
      "
    >
      <div className="flex items-center justify-between min-h-8 px-2 gap-2  text-gray-600 border-b-[1px] border-gray-200">
        <Skeleton.Avatar active shape="square" size="small" />
        <Skeleton.Input block active size="small" />
      </div>

      <div className="flex items-center justify-center grow ">
        <Skeleton.Image className="space" />
      </div>

      <div className="flex items-center justify-between min-h-8 px-2 gap-2  text-gray-600 border-t-[1px] border-gray-200">
        <Skeleton.Avatar active shape="circle" size="small" />
        <Skeleton.Input block active size="small" />
      </div>
    </div>
  );
}

export default SkeletonFile;
