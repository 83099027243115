import { Icon } from "@iconify/react";
import { Button, Divider, Spin, Timeline } from "antd";
import { useContext, useEffect, useState } from "react";

import { D3DrawerContext } from "@provider/D3DrawerContext";
import { useCustomerStore } from "../../store";
import { IncidentForm } from "../incident/IncidentForm";

function TabIncident() {
  const { openDrawer } = useContext(D3DrawerContext);
  const { getNoPayments, resetState, itemsNoPayments } = useCustomerStore();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    (async () => {
      await getNoPayments();
      setLoading(false);
    })();
    return () => resetState();
  }, [getNoPayments, resetState]);

  return (
    <div className="flex flex-col h-full p-2 overflow-hidden">
      <div className="flex-none">
        <div className="flex items-center justify-between mb-2">
          <h2 className="ml-1 text-lg font-semibold text-gray-600">
            Registros de inadimplência
          </h2>

          <div>
            {!loading && (
              <Button
                className="flex items-center justify-start"
                type="primary"
                size="middle"
                danger={
                  loading === false && itemsNoPayments[0]?.status ? false : true
                }
                icon={<Icon icon="ion:list-sharp" height={18} width={18} />}
                onClick={() =>
                  openDrawer(
                    <IncidentForm
                      isActive={loading === false && itemsNoPayments[0]?.status}
                    />,
                    "right",
                    600,
                    loading === false && itemsNoPayments[0]?.status
                      ? "Encerrar ocorrência de inadimplência"
                      : "Regristrar ocorrência de inadimplência"
                  )
                }
              >
                {loading === false && itemsNoPayments[0]?.status
                  ? "Encerrar ocorrência"
                  : "Registrar ocorrência"}
              </Button>
            )}
          </div>
        </div>
        <Divider className="my-1" />
      </div>
      <div className="flex-grow overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100">
        {loading ? (
          <div className="flex items-center justify-center w-full h-full ">
            <Spin spinning={true} />
          </div>
        ) : (
          <Timeline className="py-2" items={itemsNoPayments} />
        )}
      </div>
    </div>
  );
}

export default TabIncident;
